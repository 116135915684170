/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the StatoAvanzamentoLavoriDto interface.
 */
export function instanceOfStatoAvanzamentoLavoriDto(value) {
    return true;
}
export function StatoAvanzamentoLavoriDtoFromJSON(json) {
    return StatoAvanzamentoLavoriDtoFromJSONTyped(json, false);
}
export function StatoAvanzamentoLavoriDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idInvoiceMilestone': json['idInvoiceMilestone'] == null ? undefined : json['idInvoiceMilestone'],
        'isInviato': json['isInviato'] == null ? undefined : json['isInviato'],
        'tipologiaBlocco': json['tipologiaBlocco'] == null ? undefined : json['tipologiaBlocco'],
        'dataDiInvio': json['dataDiInvio'] == null ? undefined : (new Date(json['dataDiInvio'])),
        'importo': json['importo'] == null ? undefined : json['importo'],
    };
}
export function StatoAvanzamentoLavoriDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idInvoiceMilestone': value['idInvoiceMilestone'],
        'isInviato': value['isInviato'],
        'tipologiaBlocco': value['tipologiaBlocco'],
        'dataDiInvio': value['dataDiInvio'] == null ? undefined : ((value['dataDiInvio']).toISOString()),
        'importo': value['importo'],
    };
}
