import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { DatePicker, Input, Select, SelectProps, Switch, Tag } from 'antd';
import dayjs from 'dayjs';
import '../../style.scss';
import { t } from 'i18next';
import { useState } from 'react';
import { upperSnakeCaseFormatter } from '../../../../../utils/common.utils';
import {
  filtersUsedSelector,
  filtriProgettiSelector,
} from '../../../../../../pages/private/project-list/_redux/selectors';
import { useAppDispatch, useAppSelector } from '../../../../../../core/store/hooks';
import PriceRange from '../../../custom-priceRange';
import LinksButton from '../../../links-button';
import { saveFiltersUsed } from '../../../../../../pages/private/project-list/_redux/actions';
import { typologiesData } from '../../../../../../enums/typologies';
import { StatusBadgeData } from '../../../../../../enums/status';

export const projectListFormData: any = {
  dateRange: [],
  nomeProgetto: '',
  stato: [],
  nomeCliente: undefined,
  businessManager: undefined,
  tipologia: [],
  priceRange: [null, null],
  idProgetto: '',
  flagPlaceholderPlanned: false,
  quoteId: '',
  opportunityId: '',
};

interface Props {
  onSubmit: any;
  handleReset: () => void;
  // setIsOpen: (isOpen: boolean) => any;
  // isOpen: any;
  setControlledDate: any;
}
const AccordionForm = ({ onSubmit, handleReset, setControlledDate }: Props) => {
  const filterUsed = useAppSelector(filtersUsedSelector);
  const dispatch = useAppDispatch();
  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: projectListFormData,
    values: filterUsed,
  });
  const filtri = useAppSelector(filtriProgettiSelector);

  const Reset = () => {
    reset();
    handleReset();
    setControlledDate([]);
    clearPriceRange();
  };
  const options: SelectProps['options'] = filtri?.stati?.map((status: any, index: number) => ({
    value: upperSnakeCaseFormatter(status),
  }));

  const [clearPR, setClearPR] = useState(false);
  const clearPriceRange = () => {
    setClearPR(!clearPR);
  };
  const { RangePicker } = DatePicker;
  const handleFormSubmit = async (formData: any) => {
    dispatch(saveFiltersUsed(formData));
    try {
      await onSubmit(formData);
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  return (
    <Form className="grid" onSubmit={handleSubmit(handleFormSubmit)}>
      <Row>
        <Col sm="3">
          <Form.Label>{t('project-list.id-project')}</Form.Label>
          <Controller
            name="idProgetto"
            control={control}
            render={({ field }) => (
              <Input
                type="number"
                placeholder={t('project-list.id-project-placeholder')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('project-list.project-name')}</Form.Label>
          <Controller
            name="nomeProgetto"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('project-list.search-name-project')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('project-list.quote-id')}</Form.Label>
          <Controller
            name="quoteId"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('project-list.quote-id-placeholder')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('project-list.opportunity-id')}</Form.Label>
          <Controller
            name="opportunityId"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('project-list.opportunity-id-placeholder')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
      </Row>
      <Form.Group className="grid mt-3">
        <Row>
          <Col sm="3">
            {' '}
            <Form.Label>{t('project-list.date-last-modify')}</Form.Label>
            <Controller
              name="dateRange"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <RangePicker
                  placeholder={[
                    `${t('project-list.start-date-placeholder')}`,
                    `${t('project-list.end-date-placeholder')}`,
                  ]}
                  className="w-100 createDateRangePicker"
                  popupClassName="createDateRangePicker"
                  {...field}
                  ref={field.ref}
                  name={field.name}
                  onBlur={field.onBlur}
                  format="DD/MM/YYYY"
                  value={
                    field.value && field.value.length === 2 ? [dayjs(field.value[0]), dayjs(field.value[1])] : null
                  }
                  size="large"
                  onChange={(dates: any) => {
                    field.onChange(dates ? dates.map((date: any) => date.format('YYYY-MM-DD')) : []);
                  }}
                />
              )}
            />
          </Col>
          <Col sm="3">
            {' '}
            <Form.Label>{t('project-list.project-states')}</Form.Label>
            <Controller
              name="stato"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder={t('project-list.select-status-placeholder')}
                  mode="multiple"
                  size="large"
                  allowClear
                  tagRender={tagRender}
                  defaultValue={[]}
                  style={{ width: '100%' }}
                  options={filtri?.stati?.map((el: any, index: any) => ({
                    value: el,
                    label: StatusBadgeData?.find((type) => type.name === el)?.label,
                  }))}
                  {...field}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('project-list.customer')}</Form.Label>
            <Controller
              name="nomeCliente"
              control={control}
              render={({ field }) => (
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  allowClear
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  onBlur={field.onBlur}
                  placeholder={t('project-list.select-client-placeholder')}
                >
                  {filtri?.clienti?.map((el, index) => (
                    <Select.Option key={index} value={el.denominazione}>
                      {el.denominazione}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Col>

          <Col sm="3">
            <Form.Label>{t('project-list.business-manager')}</Form.Label>
            <Controller
              name="businessManager"
              control={control}
              render={({ field }) => (
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  allowClear
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  onBlur={field.onBlur}
                  placeholder={t('project-list.select-cl-am-placeholder')}
                >
                  {filtri?.businessManager?.map((el, index) => (
                    <Select.Option key={index} value={el.nominativo}>
                      {el.nominativo}
                    </Select.Option>
                  )) || undefined}
                </Select>
              )}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="grid mt-3">
        <Row>
          <Col sm="3">
            <Form.Label>{t('project-list.project-manager')}</Form.Label>
            <Controller
              name="projectManager"
              control={control}
              render={({ field }) => (
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  allowClear
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  onBlur={field.onBlur}
                  placeholder={t('project-list.select-project-lead-placeholder')}
                >
                  {filtri?.projectManager?.map((el, index) => (
                    <Select.Option key={index} value={el.nominativo}>
                      {el.nominativo}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('project-list.typology')}</Form.Label>
            <Controller
              name="tipologia"
              control={control}
              render={({ field }) => (
                <Select
                  mode="multiple"
                  showSearch
                  tagRender={tagRender}
                  className="w-100"
                  size="large"
                  allowClear
                  placeholder={t('project-list.typology-placeholder')}
                  options={filtri?.tipologie?.map((el, index) => ({
                    value: el,
                    label: typologiesData?.find((type) => type.name === el)?.label,
                  }))}
                  {...field}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('project-list.import')}</Form.Label>
            <div>
              <Controller
                name="priceRange"
                control={control}
                render={({ field }) => (
                  <PriceRange
                    value={field.value}
                    onPriceRangeChange={(newValue) => field.onChange(newValue)}
                    clear={clearPR}
                  />
                )}
              />
            </div>
          </Col>
          <Col sm="3">
            <Form.Label>{t('project-list.flag-planned')}</Form.Label>
            <div>
              <Controller
                name="flagPlaceholderPlanned"
                control={control}
                render={({ field }: any) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(checked) => field.onChange(checked)}
                    className="mt-2"
                  />
                )}
              />
            </div>
          </Col>
        </Row>
      </Form.Group>
      <div className="d-flex">
        <div className="d-flex ms-auto">
          <Button className="btn text-danger trash-btn" type="button" variant="icon" onClick={Reset}>
            <i className="fa-regular fa-trash-can me-2"></i>
            {t('project-list.delete-filters')}
          </Button>
          <LinksButton type="submit" className="btn-sm btn-primary btn-links px-4">
            <i className="fa-solid fa-magnifying-glass me-2"></i>
            {t('project-list.search')}
          </LinksButton>
        </div>
      </div>
    </Form>
  );
};
export default AccordionForm;

type TagRender = SelectProps['tagRender'];

const tagRender: TagRender = (props) => {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={'blue'}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4 }}
    >
      {label}
    </Tag>
  );
};
