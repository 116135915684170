/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoleEffortDtoFromJSON, RoleEffortDtoToJSON, } from './RoleEffortDto';
/**
 * Check if a given object implements the InsertByRolesDto interface.
 */
export function instanceOfInsertByRolesDto(value) {
    return true;
}
export function InsertByRolesDtoFromJSON(json) {
    return InsertByRolesDtoFromJSONTyped(json, false);
}
export function InsertByRolesDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'inizio': json['inizio'] == null ? undefined : (new Date(json['inizio'])),
        'fine': json['fine'] == null ? undefined : (new Date(json['fine'])),
        'ruoliTeam': json['ruoliTeam'] == null ? undefined : (json['ruoliTeam'].map(RoleEffortDtoFromJSON)),
    };
}
export function InsertByRolesDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'inizio': value['inizio'] == null ? undefined : ((value['inizio']).toISOString()),
        'fine': value['fine'] == null ? undefined : ((value['fine']).toISOString()),
        'ruoliTeam': value['ruoliTeam'] == null ? undefined : (value['ruoliTeam'].map(RoleEffortDtoToJSON)),
    };
}
