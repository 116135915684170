/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CostTypeFromJSON, CostTypeToJSON, } from './CostType';
import { ProjectTypeFromJSON, ProjectTypeToJSON, } from './ProjectType';
/**
 * Check if a given object implements the ProjectCostType interface.
 */
export function instanceOfProjectCostType(value) {
    return true;
}
export function ProjectCostTypeFromJSON(json) {
    return ProjectCostTypeFromJSONTyped(json, false);
}
export function ProjectCostTypeFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'projectType': json['projectType'] == null ? undefined : ProjectTypeFromJSON(json['projectType']),
        'costType': json['costType'] == null ? undefined : CostTypeFromJSON(json['costType']),
    };
}
export function ProjectCostTypeToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'projectType': ProjectTypeToJSON(value['projectType']),
        'costType': CostTypeToJSON(value['costType']),
    };
}
