/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { DeleteMonthsRequestDtoToJSON, DeleteStaffInputDtoToJSON, InsertByRolesDtoToJSON, ListCostTypeDtoFromJSON, SearchRuoliResponseDtoFromJSON, } from '../models/index';
/**
 *
 */
export class ControllerStaffApi extends runtime.BaseAPI {
    /**
     * Recupera l\'elenco completo delle tipologie di costi esterni
     * Elenco dei tipoCosti
     */
    costTypeListRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/staff/cost-type-list`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListCostTypeDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera l\'elenco completo delle tipologie di costi esterni
     * Elenco dei tipoCosti
     */
    costTypeList(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.costTypeListRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Elimina mesi dalla pianificazione di un progetto
     */
    deleteMonthRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['deleteMonthsRequestDto'] == null) {
                throw new runtime.RequiredError('deleteMonthsRequestDto', 'Required parameter "deleteMonthsRequestDto" was null or undefined when calling deleteMonth().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/staff/project/delete-month`,
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: DeleteMonthsRequestDtoToJSON(requestParameters['deleteMonthsRequestDto']),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Elimina mesi dalla pianificazione di un progetto
     */
    deleteMonth(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteMonthRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Elimina i membri dello staff se il progetto non è ancora partito. Per i progetti in corso azzera le allocazioni non consolidate
     * Azzera allocazione su progetto
     */
    deleteStaffRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idProject'] == null) {
                throw new runtime.RequiredError('idProject', 'Required parameter "idProject" was null or undefined when calling deleteStaff().');
            }
            if (requestParameters['deleteStaffInputDto'] == null) {
                throw new runtime.RequiredError('deleteStaffInputDto', 'Required parameter "deleteStaffInputDto" was null or undefined when calling deleteStaff().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/staff/project/{idProject}/deallocate`.replace(`{${"idProject"}}`, encodeURIComponent(String(requestParameters['idProject']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: DeleteStaffInputDtoToJSON(requestParameters['deleteStaffInputDto']),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Elimina i membri dello staff se il progetto non è ancora partito. Per i progetti in corso azzera le allocazioni non consolidate
     * Azzera allocazione su progetto
     */
    deleteStaff(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteStaffRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * In fase di stima associo al progetto i dati relativi a
     * Creazione dello staff tramite definizione dei ruoli
     */
    insertDateAndRoleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idProject'] == null) {
                throw new runtime.RequiredError('idProject', 'Required parameter "idProject" was null or undefined when calling insertDateAndRole().');
            }
            if (requestParameters['insertByRolesDto'] == null) {
                throw new runtime.RequiredError('insertByRolesDto', 'Required parameter "insertByRolesDto" was null or undefined when calling insertDateAndRole().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/staff/project/{idProject}/insertbyroles`.replace(`{${"idProject"}}`, encodeURIComponent(String(requestParameters['idProject']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: InsertByRolesDtoToJSON(requestParameters['insertByRolesDto']),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * In fase di stima associo al progetto i dati relativi a
     * Creazione dello staff tramite definizione dei ruoli
     */
    insertDateAndRole(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.insertDateAndRoleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera l\'elenco completo dei ruoli con relativo costo e ricavo associati filtrati per input. Se l\'input è null recupera tutti i ruoli disponibili.
     * Elenco dei ruoli
     */
    ruoliSearchRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/staff/roles-search`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => SearchRuoliResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera l\'elenco completo dei ruoli con relativo costo e ricavo associati filtrati per input. Se l\'input è null recupera tutti i ruoli disponibili.
     * Elenco dei ruoli
     */
    ruoliSearch(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.ruoliSearchRaw(initOverrides);
            return yield response.value();
        });
    }
}
