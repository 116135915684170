/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const FilterProjectMonthDtoProjectTypeEnum = {
    Corpo: 'CORPO',
    Canone: 'CANONE',
    Costo: 'COSTO',
    Consumo: 'CONSUMO'
};
/**
 * @export
 */
export const FilterProjectMonthDtoStatiEnum = {
    Pending: 'PENDING',
    Planning: 'PLANNING',
    ClosedLost: 'CLOSED_LOST',
    IntranetPending: 'INTRANET_PENDING',
    WorkInProgress: 'WORK_IN_PROGRESS',
    Completed: 'COMPLETED',
    ClosedWonPlanning: 'CLOSED_WON_PLANNING',
    PlanningOk: 'PLANNING_OK',
    ControlloCdg: 'CONTROLLO_CDG',
    ControlloCl: 'CONTROLLO_CL',
    ReportingClosed: 'REPORTING_CLOSED',
    EconomicsClosed: 'ECONOMICS_CLOSED'
};
/**
 * Check if a given object implements the FilterProjectMonthDto interface.
 */
export function instanceOfFilterProjectMonthDto(value) {
    return true;
}
export function FilterProjectMonthDtoFromJSON(json) {
    return FilterProjectMonthDtoFromJSONTyped(json, false);
}
export function FilterProjectMonthDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'projectName': json['projectName'] == null ? undefined : json['projectName'],
        'clientLeadId': json['clientLeadId'] == null ? undefined : json['clientLeadId'],
        'businessUnit': json['businessUnit'] == null ? undefined : json['businessUnit'],
        'deliveryUnit': json['deliveryUnit'] == null ? undefined : json['deliveryUnit'],
        'projectLeadName': json['projectLeadName'] == null ? undefined : json['projectLeadName'],
        'projectType': json['projectType'] == null ? undefined : json['projectType'],
        'amountFrom': json['amountFrom'] == null ? undefined : json['amountFrom'],
        'amountTo': json['amountTo'] == null ? undefined : json['amountTo'],
        'stati': json['stati'] == null ? undefined : json['stati'],
    };
}
export function FilterProjectMonthDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'projectId': value['projectId'],
        'projectName': value['projectName'],
        'clientLeadId': value['clientLeadId'],
        'businessUnit': value['businessUnit'],
        'deliveryUnit': value['deliveryUnit'],
        'projectLeadName': value['projectLeadName'],
        'projectType': value['projectType'],
        'amountFrom': value['amountFrom'],
        'amountTo': value['amountTo'],
        'stati': value['stati'],
    };
}
