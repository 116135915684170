import { StaffExtendedDto } from '@api/client';
import React from 'react';
import { useEffect } from 'react';

const useValidationGu = ({
  totalGUPlanned,
  fullAllocations,
  setTotalGuValidation,
}: {
  totalGUPlanned: number[] | undefined;
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined;
  setTotalGuValidation: (value: boolean) => void;
}) => {
  const [areTotalsGUMatch, setAreTotalsGUMatch] = React.useState<boolean[]>();

  useEffect(() => {
    if (totalGUPlanned && totalGUPlanned.length > 0) {
      let validation = true;
      totalGUPlanned.forEach((el, index) => {
        if (
          el !==
          fullAllocations?.[index]?.allocazioni
            ?.filter((alloc) => alloc.editEnable)
            ?.reduce((acc, el) => acc + (el.actualAllocation ?? 0), 0)
        ) {
          validation = false;
        }
      });

      setTotalGuValidation(validation);

      setAreTotalsGUMatch(
        totalGUPlanned.map(
          (el, index) =>
            el ==
            fullAllocations?.[index]?.allocazioni
              ?.filter((alloc) => alloc.editEnable)
              ?.reduce((acc, el) => acc + (el.actualAllocation ?? 0), 0)
        )
      );
    }
  }, [totalGUPlanned]);

  const getTooltip = (index: number) => {
    const difference =
      totalGUPlanned?.[index]!! -
      (fullAllocations?.[index]?.allocazioni
        ?.filter((alloc) => alloc.editEnable)
        ?.reduce((acc, el) => acc + (el.actualAllocation ?? 0), 0) ?? 0);
    return `${difference > 0 ? '+' : ''}${Number(difference.toFixed(2))} giorni inseriti`;
  };

  return {
    areTotalsGUMatch,
    getTooltip,
  };
};

export default useValidationGu;
