export enum topbar {
  TITOLO_PAGINA = 'Gestore Progetti',
}

export enum roles {
  BM = 'Business Manager',
  PM = 'Project Manager',
  PMO = 'Project Manager O.',
}

export enum projectStatus {
  OFFERTA = 'Offerta',
  PENDING = 'Pending',
  IN_COMPILAZIONE = 'In Compilazione',
  COMPLETATO = 'Completato',
  IN_LAVORAZIONE = 'Progetto in Lavorazione',
}

export enum NewProjectStatus {
  Offering = 'OFFERING',
  Pending = 'PENDING',
  Planning = 'PLANNING',
  CloseWon = 'CLOSE_WON',
  CloseLost = 'CLOSE_LOST',
  IntranetPending = 'INTRANET_PENDING',
  WorkInProgress = 'WORK_IN_PROGRESS',
  Completed = 'COMPLETED',
  ClosedWonPlanning = 'CLOSED_WON_PLANNING',
  PlanningOk = 'PLANNING_OK',
}

export enum DashboardUserTables {
  RESOURCE_PLANNING = 'Resource Planning',
  UNDERBOOKING = 'Underbooking',
  OVERBOOKING = 'Overbooking',
}

export enum DashboardFieldsColor {
  REVENUES = '#228086',
  COSTS = '#d81212',
  MARGINS = '#1b7112',
  RATE = '#a600b0',
  FTE = '#ce9e32',
}
