import { Nav } from 'react-bootstrap';
import '../style.scss';
import { useAppSelector } from '../../../../../core/store/hooks';
import { layoutSidebarToggleSelector } from '../../../_redux/selectors';
import AuthService from '../../../../../core/auth/auth.service';
import { Tooltip } from 'antd';

const UserBadge: React.FC = () => {
  const sidebarToggled = useAppSelector(layoutSidebarToggleSelector);

  const currentUser = AuthService.getUser();
  let fullName = currentUser.name?.split(' ');
  let nome = fullName?.[0];
  let cognome = fullName?.slice(1)?.join(' ');
  const getInitials = (name: string, surname: string) => {
    return `${name?.charAt(0) ?? ''}${surname?.charAt(0) ?? ''}`.toUpperCase();
  };
  return (
    <Tooltip title={currentUser.name} color="white" placement="right">
      <div className={`user-badge-container ms-1 py-0 my-0}`}>
        <div
          className={`link-item mx-auto ${
            !sidebarToggled && 'profile-item active'
          } position-relative d-flex ms-1 py-2 px-0`}
        >
          <div className="rounded-image-container profile-image align-items-center ms-2">
            <span>{getInitials(cognome, nome)}</span>
          </div>{' '}
          {!sidebarToggled && currentUser !== null && (
            <>
              <div className="name-profile ms-2 pe-2">
                <span style={{ fontSize: '1em' }}>
                  <b>
                    {cognome} {nome}
                  </b>{' '}
                </span>{' '}
                <br />
                <span style={{ fontSize: '0.8em' }}>{currentUser.email}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </Tooltip>
  );
};
export default UserBadge;
