/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MonthYearDtoFromJSON, MonthYearDtoToJSON, } from './MonthYearDto';
/**
 * Check if a given object implements the DeleteMonthsRequestDto interface.
 */
export function instanceOfDeleteMonthsRequestDto(value) {
    return true;
}
export function DeleteMonthsRequestDtoFromJSON(json) {
    return DeleteMonthsRequestDtoFromJSONTyped(json, false);
}
export function DeleteMonthsRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idProject': json['idProject'] == null ? undefined : json['idProject'],
        'monthsYears': json['monthsYears'] == null ? undefined : (json['monthsYears'].map(MonthYearDtoFromJSON)),
    };
}
export function DeleteMonthsRequestDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idProject': value['idProject'],
        'monthsYears': value['monthsYears'] == null ? undefined : (value['monthsYears'].map(MonthYearDtoToJSON)),
    };
}
