/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserDtoFromJSON, UserDtoToJSON, } from './UserDto';
/**
 * Check if a given object implements the SearchUserResponseDto interface.
 */
export function instanceOfSearchUserResponseDto(value) {
    return true;
}
export function SearchUserResponseDtoFromJSON(json) {
    return SearchUserResponseDtoFromJSONTyped(json, false);
}
export function SearchUserResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'utenti': json['utenti'] == null ? undefined : (json['utenti'].map(UserDtoFromJSON)),
    };
}
export function SearchUserResponseDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'utenti': value['utenti'] == null ? undefined : (value['utenti'].map(UserDtoToJSON)),
    };
}
