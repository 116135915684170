/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SeniorityDtoFromJSON, SeniorityDtoToJSON, } from './SeniorityDto';
/**
 * Check if a given object implements the RoleEffortDto interface.
 */
export function instanceOfRoleEffortDto(value) {
    return true;
}
export function RoleEffortDtoFromJSON(json) {
    return RoleEffortDtoFromJSONTyped(json, false);
}
export function RoleEffortDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idPps': json['idPps'] == null ? undefined : json['idPps'],
        'descrizioneProfiloProfessionale': json['descrizioneProfiloProfessionale'] == null ? undefined : json['descrizioneProfiloProfessionale'],
        'seniorityPpt': json['seniorityPpt'] == null ? undefined : SeniorityDtoFromJSON(json['seniorityPpt']),
        'giorniUomo': json['giorniUomo'] == null ? undefined : json['giorniUomo'],
        'numPersone': json['numPersone'] == null ? undefined : json['numPersone'],
    };
}
export function RoleEffortDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idPps': value['idPps'],
        'descrizioneProfiloProfessionale': value['descrizioneProfiloProfessionale'],
        'seniorityPpt': SeniorityDtoToJSON(value['seniorityPpt']),
        'giorniUomo': value['giorniUomo'],
        'numPersone': value['numPersone'],
    };
}
