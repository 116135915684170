import { StatoAvanzamentoFieldsDtoTipologiaBloccoEnumListEnum } from '@api/client';
import i18n from '../i18n/i18n';

export interface MappedBillingOptions {
  mappedTipologiaBloccoEnumList: BillingOption[] | undefined;
}

export interface BillingOption {
  value: string | undefined;
  label: string | undefined;
}

export const mappedTipologiaBloccoEnumList: BillingOption[] = [
  {
    value: StatoAvanzamentoFieldsDtoTipologiaBloccoEnumListEnum.ChiusoFinanziariamente,
    label: i18n.t('project-detail.billing-plan.block-typology-options.financially-closed'),
  },
  {
    value: StatoAvanzamentoFieldsDtoTipologiaBloccoEnumListEnum.InApprovazioneDelCliente,
    label: i18n.t('project-detail.billing-plan.block-typology-options.for-customer-approval'),
  },
  {
    value: StatoAvanzamentoFieldsDtoTipologiaBloccoEnumListEnum.InRedazioneDelPartner,
    label: i18n.t('project-detail.billing-plan.block-typology-options.in-partner-review'),
  },
  {
    value: StatoAvanzamentoFieldsDtoTipologiaBloccoEnumListEnum.LavorazioniInCorso,
    label: i18n.t('project-detail.billing-plan.block-typology-options.work-in-progress'),
  },
  {
    value: StatoAvanzamentoFieldsDtoTipologiaBloccoEnumListEnum.SalDelDelivery,
    label: i18n.t('project-detail.billing-plan.block-typology-options.delivery-sal'),
  },
  {
    value: StatoAvanzamentoFieldsDtoTipologiaBloccoEnumListEnum.VerificaCicloAttivo,
    label: i18n.t('project-detail.billing-plan.block-typology-options.active-cycle-check'),
  },
];
