/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the RendicontazioneMeseDto interface.
 */
export function instanceOfRendicontazioneMeseDto(value) {
    return true;
}
export function RendicontazioneMeseDtoFromJSON(json) {
    return RendicontazioneMeseDtoFromJSONTyped(json, false);
}
export function RendicontazioneMeseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'mese': json['mese'] == null ? undefined : json['mese'],
        'anno': json['anno'] == null ? undefined : json['anno'],
        'totaleGiorni': json['totaleGiorni'] == null ? undefined : json['totaleGiorni'],
        'totaleOre': json['totaleOre'] == null ? undefined : json['totaleOre'],
    };
}
export function RendicontazioneMeseDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'mese': value['mese'],
        'anno': value['anno'],
        'totaleGiorni': value['totaleGiorni'],
        'totaleOre': value['totaleOre'],
    };
}
