/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DettaglioMeseAnnoDtoFromJSON, DettaglioMeseAnnoDtoToJSON, } from './DettaglioMeseAnnoDto';
import { ProjectDtoFromJSON, ProjectDtoToJSON, } from './ProjectDto';
import { RendicontazioneMeseDtoFromJSON, RendicontazioneMeseDtoToJSON, } from './RendicontazioneMeseDto';
import { UserDaysDtoFromJSON, UserDaysDtoToJSON, } from './UserDaysDto';
/**
 * Check if a given object implements the DashboardBookingDto interface.
 */
export function instanceOfDashboardBookingDto(value) {
    return true;
}
export function DashboardBookingDtoFromJSON(json) {
    return DashboardBookingDtoFromJSONTyped(json, false);
}
export function DashboardBookingDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'fkUtente': json['fkUtente'] == null ? undefined : json['fkUtente'],
        'nomeUtente': json['nomeUtente'] == null ? undefined : json['nomeUtente'],
        'dettagliMeseAnno': json['dettagliMeseAnno'] == null ? undefined : (json['dettagliMeseAnno'].map(DettaglioMeseAnnoDtoFromJSON)),
        'userDaysDtoList': json['userDaysDtoList'] == null ? undefined : (json['userDaysDtoList'].map(UserDaysDtoFromJSON)),
        'projects': json['projects'] == null ? undefined : (json['projects'].map(ProjectDtoFromJSON)),
        'rendicontazioniMeseTotali': json['rendicontazioniMeseTotali'] == null ? undefined : RendicontazioneMeseDtoFromJSON(json['rendicontazioniMeseTotali']),
    };
}
export function DashboardBookingDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'fkUtente': value['fkUtente'],
        'nomeUtente': value['nomeUtente'],
        'dettagliMeseAnno': value['dettagliMeseAnno'] == null ? undefined : (value['dettagliMeseAnno'].map(DettaglioMeseAnnoDtoToJSON)),
        'userDaysDtoList': value['userDaysDtoList'] == null ? undefined : (value['userDaysDtoList'].map(UserDaysDtoToJSON)),
        'projects': value['projects'] == null ? undefined : (value['projects'].map(ProjectDtoToJSON)),
        'rendicontazioniMeseTotali': RendicontazioneMeseDtoToJSON(value['rendicontazioniMeseTotali']),
    };
}
