import AuthService from '../../core/auth/auth.service';
import ReactGA from 'react-ga4';
import { UrlEnum } from '../../enums/url.enum';
import { TrackPagesGA4Enum } from '../../enums/global.enum';

export const trackUserIdGa4 = (page: TrackPagesGA4Enum) => {
  if (window.location.hostname === UrlEnum.HOSTNAME_PRODUCTION) {
    ReactGA.send({ hitType: 'pageview', page: window.location.href, title: page });
    ReactGA.event({
      category: 'pageview',
      action: page,
      label: page,
      value: Number(AuthService.getUser()?.userId),
    });
  }
};

export const trackButtonClickGa4 = (buttonName: string) => {
  if (window.location.hostname === UrlEnum.HOSTNAME_PRODUCTION) {
    ReactGA.event({
      category: 'button_click',
      action: buttonName,
      label: buttonName,
      value: Number(AuthService.getUser()?.userId),
    });
  }
};
