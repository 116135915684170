/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SeniorityDto interface.
 */
export function instanceOfSeniorityDto(value) {
    return true;
}
export function SeniorityDtoFromJSON(json) {
    return SeniorityDtoFromJSONTyped(json, false);
}
export function SeniorityDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idCostoProfilo': json['idCostoProfilo'] == null ? undefined : json['idCostoProfilo'],
        'descrizioneCostoProfilo': json['descrizioneCostoProfilo'] == null ? undefined : json['descrizioneCostoProfilo'],
        'costo': json['costo'] == null ? undefined : json['costo'],
        'ricavo': json['ricavo'] == null ? undefined : json['ricavo'],
    };
}
export function SeniorityDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idCostoProfilo': value['idCostoProfilo'],
        'descrizioneCostoProfilo': value['descrizioneCostoProfilo'],
        'costo': value['costo'],
        'ricavo': value['ricavo'],
    };
}
