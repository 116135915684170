import { jwtDecode } from 'jwt-decode';
import { AuthToken } from './token.model';
import { LoggedUserDtoRolesEnum } from '@api/client';
import { UrlEnum } from '../../enums/url.enum';
import ReactGA from 'react-ga4';
import { StorageKeysEnum } from '../../enums/storageKeys.enum';
interface UserId {
  USERID: number;
}
namespace AuthService {
  export const getToken = () => {
    let token = localStorage.getItem(StorageKeysEnum.TOKEN);

    return token ? JSON.parse(token) : '';
  };

  export const getUser = () => {
    let user = localStorage.getItem(StorageKeysEnum.USER);

    return user ? JSON.parse(user) : '';
  };

  const checkAuthentication = () => {
    let token = getToken();
    if (token) return true;

    return false;
  };

  export const isAuthenticated = () => checkAuthentication();

  export const setAuthentication = (token: string | undefined) => {
    if (token) localStorage.setItem(StorageKeysEnum.TOKEN, JSON.stringify(token));
  };

  export const logout = (returnToLogin?: boolean) => {
    localStorage.removeItem(StorageKeysEnum.TOKEN);
    localStorage.removeItem(StorageKeysEnum.USER);
    localStorage.removeItem(StorageKeysEnum.IS_HCC);
    sessionStorage.clear();
    if (
      window.location.hostname !== UrlEnum.HOSTNAME_PRODUCTION &&
      window.location.hostname !== UrlEnum.HOSTNAME_WEB_SVIL
    ) {
      window.location.reload();
    } else {
      ReactGA.set({ userId: undefined });
      const logout = returnToLogin ? '' : '/logout';
      const urlLogin =
        window.location.hostname !== UrlEnum.HOSTNAME_PRODUCTION
          ? UrlEnum.URL_MICROSOFT_LOGIN_SVIL
          : UrlEnum.URL_MICROSOFT_LOGIN;
      window.location.href = `${urlLogin}${logout}`;
    }
  };

  const decodeToken = (): (AuthToken & UserId) | undefined => {
    const token = getToken();

    return token ? jwtDecode(token) : undefined;
  };

  export const setUser = () => {
    const tokenData = decodeToken();
    const user = {
      role: tokenData?.ROLE,
      name: tokenData?.NAME,
      email: tokenData?.EMAIL,
      language: tokenData?.LANGUAGE,
      userId: tokenData?.USERID,
    };
    if (window.location.hostname !== UrlEnum.HOSTNAME_PRODUCTION) {
      ReactGA.set({ userId: Number(tokenData?.USERID) });
    }

    localStorage.setItem(StorageKeysEnum.USER, JSON.stringify(user));
  };

  export const canViewPlPage = () => {
    const user = getUser();
    return user
      ? (user.role.includes(LoggedUserDtoRolesEnum.Pl) && !user.role.includes(LoggedUserDtoRolesEnum.Am)) ||
          user.role.includes(LoggedUserDtoRolesEnum.SuperAdmin)
      : false;
  };

  export const isSuperAdmin = () => {
    const user = getUser();
    return user ? user.role.includes(LoggedUserDtoRolesEnum.SuperAdmin) : false;
  };

  export const isPl = () => {
    const user = getUser();
    return user
      ? user.role.includes(LoggedUserDtoRolesEnum.Pl) || user.role.includes(LoggedUserDtoRolesEnum.SuperAdmin)
      : false;
  };

  export const isAm = () => {
    const user = getUser();
    return user
      ? user.role.includes(LoggedUserDtoRolesEnum.Am) || user.role.includes(LoggedUserDtoRolesEnum.SuperAdmin)
      : false;
  };

  export const isDm = () => {
    const user = getUser();
    return user
      ? user.role.includes(LoggedUserDtoRolesEnum.Dm) || user.role.includes(LoggedUserDtoRolesEnum.SuperAdmin)
      : false;
  };

  export const isWFM = () => {
    const user = getUser();
    return user
      ? user.role.includes(LoggedUserDtoRolesEnum.Wfm) || user.role.includes(LoggedUserDtoRolesEnum.SuperAdmin)
      : false;
  };

  export const isCgd = () => {
    const user = getUser();
    return user
      ? user.role.includes(LoggedUserDtoRolesEnum.Cdg) || user.role.includes(LoggedUserDtoRolesEnum.SuperAdmin)
      : false;
  };

  export const isCp = () => {
    const user = getUser();
    return user
      ? user.role.includes(LoggedUserDtoRolesEnum.Cp) || user.role.includes(LoggedUserDtoRolesEnum.SuperAdmin)
      : false;
  };

  export const isHCC = () => {
    const user = getUser();
    return (
      localStorage.getItem(StorageKeysEnum.IS_HCC) === 'true' ||
      user?.role?.includes(LoggedUserDtoRolesEnum.Dm) ||
      user?.role?.includes(LoggedUserDtoRolesEnum.SuperAdmin)
    );
  };
}
export default AuthService;
