import { ProjectMonthDto, ProjectMonthEditRequest } from '@api/client';
import { Session } from '../../../../enums/project.enum';
import { useAppDispatch } from '../../../../core/store/hooks';
import useGetSessionActions from './useGetSessionActions';

interface Props {
  session: Session;
  revenueAndCostsData: any[];
  setRevenueAndCostsData: (data: any[]) => void;
  originalsMTProjects: ProjectMonthDto[];
  filtersData: any;
  onSubmit: (data: any) => void;
}

const useConsolidateRevenues = ({
  session,
  revenueAndCostsData,
  setRevenueAndCostsData,
  originalsMTProjects,
  filtersData,
  onSubmit,
}: Props) => {
  const dispatch = useAppDispatch();
  const { editProjectsAction } = useGetSessionActions(session);

  const handleCellEdit = (value: string | null, id: number, field?: string) => {
    const newData = revenueAndCostsData.map((item) =>
      item.id?.value === id ? { ...item, actualRevenue: { ...item.actualRevenue, value: value } } : item
    );
    setRevenueAndCostsData(newData);
  };

  const consolidateRevenues = (reload?: boolean, projectId?: number) => {
    const payload: ProjectMonthEditRequest = getPayloadEdit(projectId);

    dispatch(editProjectsAction(payload))
      .unwrap()
      .then(() => {
        if (reload) {
          onSubmit(filtersData);
        }
      });
  };

  const getPayloadEdit = (projectId?: number) => {
    if (!projectId) {
      // consolidate all projects in table
      return {
        projectMonthListDto: {
          projectList: originalsMTProjects.map((project, i) => {
            const { pregresso, ...rest } = project;
            return {
              ...rest,
              actualRevenue: Number(revenueAndCostsData[i].actualRevenue?.value),
            };
          }),
        },
      };
    } else {
      // consolidate only one project
      const projectToConsolidate = originalsMTProjects.filter((project) => project.id === projectId);
      const newActualRevenue = revenueAndCostsData.find((item) => item.id?.value === projectId)?.actualRevenue?.value;
      return {
        projectMonthListDto: {
          projectList: projectToConsolidate.map((project) => {
            const { pregresso, ...rest } = project;
            return {
              ...rest,
              actualRevenue: Number(newActualRevenue),
            };
          }),
        },
      };
    }
  };

  const copyPlRevenue = (value: string, id: number) => {
    const newData = revenueAndCostsData.map((item) =>
      item.id?.value === id
        ? {
            ...item,
            actualRevenue: { ...item.actualRevenue, value: Number(value.replace(/\./g, '').replace(',', '.')) },
          }
        : item
    );
    setRevenueAndCostsData(newData);
  };

  return {
    handleCellEdit,
    consolidateRevenues,
    copyPlRevenue,
  };
};

export default useConsolidateRevenues;
