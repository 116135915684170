import { Col, Form, Row } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Switch } from 'antd';
import { ProfiloProfessionaleDto, SeniorityDto, StaffExtendedDto, UserDto } from '@api/client';
import TagMultipleSelect from '../../../../../../shared/design-system/components/app-tag-multiple-select';

interface MoveDaysProps {
  onChangeSwitch: (checked: boolean) => void;
  newCollaborator: boolean | undefined;
  handleSelectChange: (value: any) => void;
  collaboratorReceiver: any;
  fullAllocationsShown: Partial<StaffExtendedDto>[] | null | undefined;
  roleList: ProfiloProfessionaleDto[];
  filterOption: (input: string, option?: any) => boolean;
  handleClearPps: () => void;
  handleChangeNewProfile: (value: any, field: 'Pps' | 'Ppt') => void;
  newCollaboratorPPS: string | undefined;
  newCollaboratorPPT: string | undefined;
  handleChangeModalCollaborator: (value: any) => void;
  getOptions: (pps: string | undefined, ppt: string | undefined) => any[];
  newCollaboratorName: string | undefined;
  collaborators: UserDto[];
  currentCollaborator: Partial<StaffExtendedDto> | null | undefined;
}

const MoveDays: React.FC<MoveDaysProps> = ({
  currentCollaborator,
  onChangeSwitch,
  newCollaborator,
  handleSelectChange,
  collaboratorReceiver,
  fullAllocationsShown,
  roleList,
  filterOption,
  handleClearPps,
  handleChangeNewProfile,
  newCollaboratorPPS,
  newCollaboratorPPT,
  handleChangeModalCollaborator,
  getOptions,
  newCollaboratorName,
  collaborators,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="p-1">
        <div className="d-flex">
          <Switch onChange={onChangeSwitch} />
          <p className="text-gray fs-7 ms-2">{t('project-detail.move-days.new-collaborator')}</p>
        </div>

        {!newCollaborator ? (
          <Row>
            <Col sm="6">
              <Form.Label className="text-dark">{t('project-detail.move-days.select-collaborator')}</Form.Label>
              <Select
                placeholder={t('project-detail.move-days.select-collaborator-placeholder')}
                size="large"
                dropdownStyle={{ zIndex: 2000 }}
                allowClear
                className="mb-2"
                tagRender={TagMultipleSelect}
                onChange={handleSelectChange}
                style={{ width: '100%' }}
                // value={collaboratorReceiver}
                options={fullAllocationsShown
                  ?.filter((user: StaffExtendedDto) => user.userId !== currentCollaborator?.userId)
                  .map((el: StaffExtendedDto) => ({
                    value: el?.userId,
                    label: `${el?.name} - ${
                      roleList.find((elem: ProfiloProfessionaleDto) => elem.idPps == el?.idPps)?.descrizionePps
                    }`,
                  }))}
              />
            </Col>
            {!newCollaborator && collaboratorReceiver && (
              <Col sm="6">
                <div className="alert alert-primary fs-7 text-center" role="alert">
                  <i className="fa-solid fa-circle-exclamation me-2"></i>
                  {t('project-detail.move-days.current-collaborator-alert')}
                  <strong>
                    {' '}
                    {fullAllocationsShown?.find((el: StaffExtendedDto) => el.userId === collaboratorReceiver)?.name}
                  </strong>
                </div>
              </Col>
            )}
          </Row>
        ) : (
          <div>
            <Row>
              <Col sm="6">
                <Form.Label className="text-dark">{t('project-detail.move-days.select-pps')}</Form.Label>
                <Select
                  className="w-100 mb-2"
                  placeholder={t('project-detail.pps-profile-placeholder')}
                  showSearch
                  optionFilterProp="children"
                  dropdownStyle={{ zIndex: 2000 }}
                  filterOption={filterOption}
                  allowClear
                  onClear={handleClearPps}
                  onChange={(value) => handleChangeNewProfile(value, 'Pps')}
                  value={
                    newCollaboratorPPS
                      ? {
                          value: newCollaboratorPPS,
                          label: roleList.find((el: ProfiloProfessionaleDto) => String(el.idPps) == newCollaboratorPPS)
                            ?.descrizionePps,
                        }
                      : undefined
                  }
                >
                  {roleList.map((el: ProfiloProfessionaleDto) => (
                    <Select.Option key={el.idPps} value={el.idPps}>
                      {el.descrizionePps}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col sm="6">
                <Form.Label className="text-dark">{t('project-detail.move-days.select-ppt')}</Form.Label>

                <Select
                  className="w-100 mb-2"
                  placeholder={t('project-detail.ppt-seniority-placeholder')}
                  showSearch
                  optionFilterProp="children"
                  dropdownStyle={{ zIndex: 2000 }}
                  filterOption={filterOption}
                  allowClear
                  onChange={(value) => handleChangeNewProfile(value, 'Ppt')}
                  value={
                    newCollaboratorPPT
                      ? {
                          value: newCollaboratorPPT,
                          label: roleList
                            .find((el: ProfiloProfessionaleDto) => el.idPps == newCollaboratorPPS)
                            ?.pptList?.find((el: SeniorityDto) => String(el.idCostoProfilo) == newCollaboratorPPT)
                            ?.descrizioneCostoProfilo,
                        }
                      : undefined
                  }
                  disabled={!newCollaboratorPPS}
                >
                  {roleList
                    .find((el: ProfiloProfessionaleDto) => el.idPps == newCollaboratorPPS)
                    ?.pptList?.map((el: SeniorityDto) => (
                      <Select.Option key={el.idCostoProfilo} value={el.idCostoProfilo}>
                        {el.descrizioneCostoProfilo}
                      </Select.Option>
                    ))}
                </Select>
              </Col>
              <Col sm="6">
                <Form.Label className="text-dark">{t('project-detail.move-days.select-collaborator')}</Form.Label>

                <Select
                  className="w-100 mb-2"
                  placeholder={t('project-detail.name-placeholder')}
                  showSearch
                  optionFilterProp="children"
                  dropdownStyle={{ zIndex: 2000 }}
                  filterOption={filterOption}
                  allowClear
                  onChange={(value) => handleChangeModalCollaborator(value)}
                  value={
                    newCollaboratorName
                      ? {
                          value: newCollaboratorName,
                          label: collaborators?.find((el: UserDto) => String(el.id) === newCollaboratorName)
                            ?.nominativo,
                        }
                      : undefined
                  }
                >
                  {getOptions(newCollaboratorPPS, newCollaboratorPPT).map((el: any) => (
                    <Select.Option key={`${el.value}-`} value={el.value}>
                      {el.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col sm="6">
                {newCollaboratorName && newCollaborator ? (
                  <div className="alert alert-primary fs-7 text-center" role="alert">
                    <i className="fa-solid fa-circle-exclamation me-2"></i>
                    {t('project-detail.move-days.new-collaborator-alert')}
                  </div>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default MoveDays;
