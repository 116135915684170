/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProfiloProfessionaleDtoFromJSON, ProfiloProfessionaleDtoToJSON, } from './ProfiloProfessionaleDto';
/**
 * Check if a given object implements the SearchRuoliResponseDto interface.
 */
export function instanceOfSearchRuoliResponseDto(value) {
    return true;
}
export function SearchRuoliResponseDtoFromJSON(json) {
    return SearchRuoliResponseDtoFromJSONTyped(json, false);
}
export function SearchRuoliResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'listaRuoli': json['listaRuoli'] == null ? undefined : (json['listaRuoli'].map(ProfiloProfessionaleDtoFromJSON)),
    };
}
export function SearchRuoliResponseDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'listaRuoli': value['listaRuoli'] == null ? undefined : (value['listaRuoli'].map(ProfiloProfessionaleDtoToJSON)),
    };
}
