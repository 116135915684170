/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TipoStrutturaOutputDto interface.
 */
export function instanceOfTipoStrutturaOutputDto(value) {
    return true;
}
export function TipoStrutturaOutputDtoFromJSON(json) {
    return TipoStrutturaOutputDtoFromJSONTyped(json, false);
}
export function TipoStrutturaOutputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'nome': json['nome'] == null ? undefined : json['nome'],
    };
}
export function TipoStrutturaOutputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'nome': value['nome'],
    };
}
