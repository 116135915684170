import React, { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../style.scss';
import { useAppDispatch } from '../../../../../core/store/hooks';
import { authLogout } from '../../../../../pages/auth/_redux/actions';
import { useTranslation } from 'react-i18next';
import UserBadge from '../user-badge';
import { generateSidebarLinks } from '../../../../../enums/sidebarLinks';

const HamburgerMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(authLogout());
  };
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);

  const sidebarLinks = generateSidebarLinks(t);

  return (
    <>
      <Navbar className="hamburger position-absolute" expand="md">
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setShowMenu(!showMenu)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {sidebarLinks.map(
              (link) =>
                link.authCheck() && (
                  <Nav.Link as={NavLink} to={link.path} disabled={link.disabled} key={link.path}>
                    {link.label}
                  </Nav.Link>
                )
            )}
            <Nav.Link className='mb-2' onClick={handleLogout}>
              {t('common.logout')}
            </Nav.Link>
          </Nav>
          <Nav>
            <UserBadge />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default HamburgerMenu;
