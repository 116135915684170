import { CreateUpdateProjectQuoteDto } from '@api/client';
import { useState } from 'react';
import { useAppDispatch } from '../../../../core/store/hooks';
import { closeRendicontazione } from '../../project-list/project-detail/_redux/actions';
import { crudCreateIntranetProject, crudGetProject } from '../_redux/actions';
import { AppToastService } from '../../../../shared/design-system/components/app-toast/app-toast.service';
import { useTranslation } from 'react-i18next';

const useCloseRendicontation = (projectWrapper: CreateUpdateProjectQuoteDto | null | undefined) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showModalCloseReporting, setshowModalCloseReporting] = useState<boolean>(false);
  const [showModalOpenIntranetProject, setshowModalOpenIntranetProject] = useState<boolean>(false);
  const projectId = projectWrapper?.createUpdateProjectDto?.id;

  const closeReporting = () => {
    if (projectId) {
      dispatch(closeRendicontazione(projectId)).then(() => {
        dispatch(crudGetProject(projectId));
      });
      return AppToastService.success(`${t('project-detail.toast-reporting-success')}-${projectId}`);
    }
  };

  const createIntranetProject = () => {
    if (projectId) {
      dispatch(crudCreateIntranetProject(projectId)).then(() => {
        dispatch(crudGetProject(projectId));
      });
      return AppToastService.success(`${t('Project created successfully')}-${projectId}`);
    }
  };

  return {
    showModalCloseReporting,
    setshowModalCloseReporting,
    closeReporting,
    showModalOpenIntranetProject,
    setshowModalOpenIntranetProject,
    createIntranetProject,
  };
};

export default useCloseRendicontation;
