import { Col, Modal, Row } from 'react-bootstrap';
import LinksButton from '../../../../shared/design-system/components/links-button';
import { DatePicker, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik, FormikState } from 'formik';
import dayjs from 'dayjs';
import { filterOption } from '../../../../shared/utils/common.utils';
import { CreateCicloPassivoRequest, CreateContractOrderDto } from '@api/client';
import FormBootstrap from 'react-bootstrap/Form';
import { useAppDispatch } from '../../../../core/store/hooks';
import { createOrder, searchOrders } from '../_redux/actions';
import { MappedOrderOptions } from '../../../../enums/orderOptions';

interface Props {
  setShow: (show: boolean) => void;
  show: boolean;
  mappedPassiveCycleOptions: MappedOrderOptions | undefined;
}

const AddOrderModal: React.FC<Props> = ({ show, setShow, mappedPassiveCycleOptions }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = (values: any) => {
    const payload: CreateCicloPassivoRequest = {
      createContractOrderDto: {
        idExternalContract: values.idExternalContract,
        contractStatus: values.contractStatus,
        processingStatus: values.processingStatus,
        expenseItemList: values.expenseItemList,
        fornitore: values.fornitore,
        contractValue: values.contractValue,
        resaleProjectCode: values.resaleProjectCode,
        resaleDetachmentRate: values.resaleDetachmentRate,
        linkDocumentation: values.linkDocumentation,
        category: values.category,
        legalEntity: values.legalEntity,
        object: values.object,
        automaticRenewal: values.automaticRenewal,
        startDate: values.startDate,
        endDate: values.endDate,
        signatureDate: values.signatureDate,
        cancellationWithin: values.cancellationWithin,
        withdrawalWithinLiteral: values.withdrawalWithinLiteral,
        cancellationProcedure: values.cancellationProcedure,
        cancelAtAnyTime: values.cancelAtAnyTime,
        note: values.note,
      },
    };
    dispatch(createOrder(payload))
      .unwrap()
      .then((res) => {
        if (res) {
          setShow(false);
          dispatch(searchOrders({ contractOrderSearchDto: {} }));
        }
      });
  };

  const initialValues: Partial<FormikState<CreateContractOrderDto>> = {
    values: {
      idExternalContract: undefined,
      contractStatus: undefined,
      processingStatus: undefined,
      expenseItemList: undefined,
      fornitore: undefined,
      contractValue: undefined,
      resaleProjectCode: undefined,
      resaleDetachmentRate: undefined,
      linkDocumentation: undefined,
      category: undefined,
      legalEntity: undefined,
      object: undefined,
      automaticRenewal: false,
      startDate: undefined,
      endDate: undefined,
      signatureDate: undefined,
      cancellationWithin: undefined,
      withdrawalWithinLiteral: undefined,
      cancellationProcedure: undefined,
      cancelAtAnyTime: false,
      note: undefined,
    },
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} size={'xl'} backdrop="static" keyboard={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title className="title-modal-s" style={{ backgroundColor: 'f3f4f7', fontSize: '1em' }}>
            {'Add Order'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik onSubmit={handleSubmit} initialValues={initialValues?.values as CreateContractOrderDto}>
            {({ handleSubmit: _handleSubmit, handleChange, setFieldValue, values, touched, errors }) => {
              const handleChangeCustom = (input: string) => (e: any) => {
                setFieldValue(input, e);
              };

              const onChangeDate = (input: string) => (date: dayjs.Dayjs) => {
                setFieldValue(input, date ? new Date(date.format('YYYY-MM-DD')) : null);
              };

              const handleChangeCheckbox = (input: string) => (e: any) => {
                setFieldValue(input, e.target.checked);
              };

              return (
                <Form onFinish={_handleSubmit}>
                  <Row>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('ID *')}</label>
                        <Form.Item
                          name={t('ID')}
                          className="mb-3"
                          initialValue={values.idExternalContract}
                          rules={[{ required: true }]}
                        >
                          <Input
                            type="text"
                            name="idExternalContract"
                            placeholder={t('Insert ID')}
                            value={values.idExternalContract}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Contract status *')}</label>
                        <Form.Item
                          name={t('Contract status')}
                          className="mb-3"
                          initialValue={values.contractStatus}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('Select Contract status')}
                            onChange={handleChangeCustom('contractStatus')}
                            value={values.contractStatus}
                            getPopupContainer={(triggerMode) => {
                              return triggerMode.parentNode as HTMLElement;
                            }}
                          >
                            {mappedPassiveCycleOptions?.mappedOrderContractStatusEnumList?.map((el) => (
                              <Select.Option key={`status-${el.value}`} value={el.value}>
                                {el.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Processing status *')}</label>
                        <Form.Item
                          name={t('Processing status')}
                          className="mb-3"
                          initialValue={values.processingStatus}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('Select Processing status')}
                            onChange={handleChangeCustom('processingStatus')}
                            value={values.processingStatus}
                            getPopupContainer={(triggerMode) => {
                              return triggerMode.parentNode as HTMLElement;
                            }}
                          >
                            {mappedPassiveCycleOptions?.mappedOrderProcessingStatusEnumList?.map((el) => (
                              <Select.Option key={`status-${el.value}`} value={el.value}>
                                {el.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Supplier *')}</label>
                        <Form.Item
                          name={t('Supplier')}
                          className="mb-3"
                          initialValue={values.fornitore}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('Select Supplier')}
                            onChange={handleChangeCustom('fornitore')}
                            value={values.fornitore}
                            getPopupContainer={(triggerMode) => {
                              return triggerMode.parentNode as HTMLElement;
                            }}
                          >
                            {mappedPassiveCycleOptions?.fornitoriList?.map((el) => (
                              <Select.Option key={`fornitore-${el.id}`} value={el.denominazione}>
                                {el.denominazione}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Supply amount *')}</label>
                        <Form.Item
                          name={t('Supply amount')}
                          className="mb-3"
                          initialValue={values.contractValue}
                          rules={[{ required: true }]}
                        >
                          <Input
                            type="number"
                            name="contractValue"
                            placeholder={t('Insert supply amount')}
                            value={values.contractValue}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Order Category *')}</label>
                        <Form.Item
                          name={t('Order Category')}
                          className="mb-3"
                          initialValue={values.category}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('Select order category')}
                            onChange={handleChangeCustom('category')}
                            value={values.category}
                            getPopupContainer={(triggerMode) => {
                              return triggerMode.parentNode as HTMLElement;
                            }}
                          >
                            {mappedPassiveCycleOptions?.orderCategoryList?.map((el) => (
                              <Select.Option key={`category-${el.id}`} value={el.id}>
                                {el.value}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Legal Entity *')}</label>
                        <Form.Item
                          name={t('Legal Entity')}
                          className="mb-3"
                          initialValue={values.legalEntity}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('Select legal entity')}
                            onChange={handleChangeCustom('legalEntity')}
                            value={values.legalEntity}
                            getPopupContainer={(triggerMode) => {
                              return triggerMode.parentNode as HTMLElement;
                            }}
                          >
                            {mappedPassiveCycleOptions?.aziendaList?.map((el) => (
                              <Select.Option key={`legalEntity-${el.id}`} value={el.id}>
                                {el.nome}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Order supervisor *')}</label>
                        <Form.Item
                          name={t('Order supervisor')}
                          className="mb-3"
                          initialValue={values.resaleDetachmentRate}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('Select order supervisor')}
                            onChange={handleChangeCustom('resaleDetachmentRate')}
                            value={values.legalEntity}
                            getPopupContainer={(triggerMode) => {
                              return triggerMode.parentNode as HTMLElement;
                            }}
                            disabled
                          >
                            {mappedPassiveCycleOptions?.aziendaList?.map((el) => (
                              <Select.Option key={`legalEntity-${el.id}`} value={el.id}>
                                {el.nome}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  {/* <p className="text-gray-600 fw-bold">Deadlines</p> */}
                  <Row>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <div className="form-group">
                        <label>{t('Start date *')}</label>
                        <Form.Item
                          name={t('Start date')}
                          className="mb-3"
                          initialValue={values.startDate ? values.startDate : null}
                          rules={[{ required: true }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD/MM/YYYY"
                            allowClear
                            placeholder={t('Insert start date *')}
                            value={values.startDate ? dayjs(values.startDate) : null}
                            onChange={onChangeDate('startDate')}
                            suffixIcon={<i className="far fa-calendar text-blue-links" />}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <div className="form-group">
                        <label>{t('End date *')}</label>
                        <Form.Item
                          name={t('End date')}
                          className="mb-3"
                          initialValue={values.endDate ? dayjs(values.endDate) : null}
                          rules={[{ required: true }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD/MM/YYYY"
                            allowClear
                            placeholder={t('Insert end date')}
                            value={values.endDate ? dayjs(values.endDate) : null}
                            onChange={onChangeDate('endDate')}
                            suffixIcon={<i className="far fa-calendar text-blue-links" />}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <div className="form-group">
                        <label>{t('Signature date *')}</label>
                        <Form.Item
                          name={t('Signature date')}
                          className="mb-3"
                          initialValue={values.signatureDate ? dayjs(values.signatureDate) : null}
                          rules={[{ required: true }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD/MM/YYYY"
                            allowClear
                            placeholder={t('Insert signature date')}
                            value={values.signatureDate ? dayjs(values.signatureDate) : null}
                            onChange={onChangeDate('signatureDate')}
                            suffixIcon={<i className="far fa-calendar text-blue-links" />}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <div className="form-group">
                        <label>{t('Cancellation Within')}</label>
                        <Form.Item
                          name={t('Cancellation Within')}
                          className="mb-3"
                          initialValue={values.cancellationWithin ? values.cancellationWithin : null}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD/MM/YYYY"
                            allowClear
                            placeholder={t('Insert date')}
                            value={values.cancellationWithin ? dayjs(values.cancellationWithin) : null}
                            onChange={onChangeDate('cancellationWithin')}
                            suffixIcon={<i className="far fa-calendar text-blue-links" />}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Expenses')}</label>
                        <Form.Item name={t('Expenses')} className="mb-3" initialValue={values.expenseItemList}>
                          <Select
                            showSearch
                            className="w-100"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            mode="multiple"
                            placeholder={t('Select Expenses')}
                            onChange={handleChangeCustom('expenseItemList')}
                            value={values.expenseItemList}
                            getPopupContainer={(triggerMode) => {
                              return triggerMode.parentNode as HTMLElement;
                            }}
                          >
                            {mappedPassiveCycleOptions?.mappedExpenseItemEnumList?.map((el) => (
                              <Select.Option key={`status-${el.value}`} value={el.value}>
                                {el.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Resale project code')}</label>
                        <Form.Item
                          name={t('Resale project code')}
                          className="mb-3"
                          initialValue={values.resaleProjectCode}
                        >
                          <Input
                            type="text"
                            name="resaleProjectCode"
                            placeholder={t('Insert resale project code')}
                            value={values.resaleProjectCode}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    {/* <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Resale detachment rate')}</label>
                        <Form.Item
                          name={t('Resale detachment rate')}
                          className="mb-3"
                          initialValue={values.resaleDetachmentRate}
                        >
                          <Input
                            type="number"
                            name="resaleDetachmentRate"
                            placeholder={t('Insert resale detachment rate')}
                            value={values.resaleDetachmentRate}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col> */}
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Link documentation')}</label>
                        <Form.Item
                          name={t('Link documentation')}
                          className="mb-3"
                          initialValue={values.linkDocumentation}
                        >
                          <Input
                            type="text"
                            name="linkDocumentation"
                            placeholder={t('Insert link documentation')}
                            value={values.linkDocumentation}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    {/* RIMAPPARE QUANDO C'é IL BE */}
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Link ticket')}</label>
                        <Form.Item name={t('Link ticket')} className="mb-3" initialValue={values.resaleDetachmentRate}>
                          <Input
                            type="text"
                            name="linkDocumentation"
                            placeholder={t('Insert link ticket')}
                            value={values.resaleDetachmentRate}
                            onChange={handleChange}
                            disabled
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <div className="form-group">
                        <label>{t('Withdrawal within')}</label>
                        <Form.Item
                          name={t('Withdrawal within')}
                          className="mb-3"
                          initialValue={values.withdrawalWithinLiteral}
                        >
                          <Input
                            type="text"
                            name="withdrawalWithinLiteral"
                            placeholder={t('Insert withdrawal within')}
                            value={values.withdrawalWithinLiteral}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Cancellation procedure')}</label>
                        <Form.Item
                          name={t('Cancellation procedure')}
                          className="mb-3"
                          initialValue={values.cancellationProcedure}
                        >
                          <Input
                            type="text"
                            name="cancellationProcedure"
                            placeholder={t('Insert cancellation procedure')}
                            value={values.cancellationProcedure}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group" style={{ marginTop: '1.75rem' }}>
                        <FormBootstrap.Check
                          type={'checkbox'}
                          onChange={handleChangeCheckbox('automaticRenewal')}
                          checked={values.automaticRenewal}
                          label={`Automatic renewal`}
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group" style={{ marginTop: '1.75rem' }}>
                        <FormBootstrap.Check
                          type={'checkbox'}
                          onChange={handleChangeCheckbox('cancelAtAnyTime')}
                          checked={values.cancelAtAnyTime}
                          label={`Cancel at any time`}
                        />
                      </div>
                    </Col>
                  </Row>
                  {/* <p className="text-gray-600 fw-bold">Object and Notes</p> */}
                  <Row className="align-items-center">
                    <Col lg={6} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Object *')}</label>
                        <Form.Item name={t('Object')} initialValue={values.object} rules={[{ required: true }]}>
                          <Input.TextArea
                            name="object"
                            placeholder={t('Insert object')}
                            value={values.object}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Notes')}</label>
                        <Form.Item name={t('Notes')} initialValue={values.note}>
                          <Input.TextArea
                            name="note"
                            placeholder={t('Insert notes')}
                            value={values.note}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-end">
                    <LinksButton className="btn btn-links-outline" onClick={() => setShow(false)}>
                      Cancel
                    </LinksButton>
                    <LinksButton type="submit" className="btn-primary btn-links">
                      Associate
                    </LinksButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddOrderModal;
