/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectDaysDtoFromJSON, ProjectDaysDtoToJSON, } from './ProjectDaysDto';
import { RendicontazioneMeseDtoFromJSON, RendicontazioneMeseDtoToJSON, } from './RendicontazioneMeseDto';
/**
 * Check if a given object implements the ProjectDto interface.
 */
export function instanceOfProjectDto(value) {
    return true;
}
export function ProjectDtoFromJSON(json) {
    return ProjectDtoFromJSONTyped(json, false);
}
export function ProjectDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'projectName': json['projectName'] == null ? undefined : json['projectName'],
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'intranetId': json['intranetId'] == null ? undefined : json['intranetId'],
        'projectDaysDtoList': json['projectDaysDtoList'] == null ? undefined : (json['projectDaysDtoList'].map(ProjectDaysDtoFromJSON)),
        'rendicontazioneMese': json['rendicontazioneMese'] == null ? undefined : RendicontazioneMeseDtoFromJSON(json['rendicontazioneMese']),
    };
}
export function ProjectDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'projectName': value['projectName'],
        'projectId': value['projectId'],
        'intranetId': value['intranetId'],
        'projectDaysDtoList': value['projectDaysDtoList'] == null ? undefined : (value['projectDaysDtoList'].map(ProjectDaysDtoToJSON)),
        'rendicontazioneMese': RendicontazioneMeseDtoToJSON(value['rendicontazioneMese']),
    };
}
