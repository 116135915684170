/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the RevenueMonthYearDto interface.
 */
export function instanceOfRevenueMonthYearDto(value) {
    return true;
}
export function RevenueMonthYearDtoFromJSON(json) {
    return RevenueMonthYearDtoFromJSONTyped(json, false);
}
export function RevenueMonthYearDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'year': json['year'] == null ? undefined : json['year'],
        'month': json['month'] == null ? undefined : json['month'],
        'actualRevenue': json['actualRevenue'] == null ? undefined : json['actualRevenue'],
    };
}
export function RevenueMonthYearDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'year': value['year'],
        'month': value['month'],
        'actualRevenue': value['actualRevenue'],
    };
}
