import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ContractOrderSearchDto } from '@api/client';
import { useAppDispatch, useAppSelector } from '../../../../../core/store/hooks';
import { StorageKeysEnum } from '../../../../../enums/storageKeys.enum';
import { filtersOrdersUsedSelector } from '../../../../../pages/private/passive-cycle/_redux/selectors';
import { saveFiltersOrdersUsed, searchOrders } from '../../../../../pages/private/passive-cycle/_redux/actions';

const useFormPassiveCycle = (initialData: any, setIsOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useRef(false);
  const filterUsed = useAppSelector(filtersOrdersUsedSelector);
  // const filters = useAppSelector(filtriProgettiSelector);
  const [chipsFormData, setChipsFormData] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<any[]>([]);
  const { handleSubmit, control, register, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues: initialData,
  });

  const onSubmit = (data: any) => {
    sessionStorage.setItem(StorageKeysEnum.CURRENT_PAGE_ORDERS, String(0));
    isSubmitting.current = true;
    const formDataArray = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] !== initialData[key]) {
          formDataArray.push([key, data[key]]);
        }
      }
    }

    setFilterParams([...formDataArray]);
    setChipsFormData([...formDataArray]);

    const filterDto: ContractOrderSearchDto = {};

    if (data.projectId) {
      filterDto.projectId = Number(data.projectId);
    }
    if (data.orderId) {
      filterDto.idExternalContract = data.orderId;
    }
    if (data.startEndRange && data.startEndRange.length > 0) {
      filterDto.startDate = new Date(data.startEndRange[0]);
      filterDto.endDate = new Date(data.startEndRange[1]);
    }
    if (data.signatureRange && data.signatureRange.length > 0) {
      filterDto.startSignatureDate = new Date(data.signatureRange[0]);
      filterDto.endSignatureDate = new Date(data.signatureRange[1]);
    }
    if (data.contractStatus) {
      filterDto.contractStatus = data.contractStatus;
    }
    if (data.orderProcessingStatus) {
      filterDto.orderProcessingStatus = data.orderProcessingStatus;
    }
    if (data.expenseItems) {
      filterDto.expenseItems = data.expenseItems;
    }
    if (data.suppliers) {
      filterDto.fornitoreIds = data.suppliers;
    }
    if (data.object) {
      filterDto.object = data.object;
    }
    if (data.categories) {
      filterDto.categoryIds = data.categories;
    }
    if (data.legalEntities) {
      filterDto.legalEntityIds = data.legalEntities;
    }
    if (data.automaticRenewal) {
      filterDto.automaticRenewal = data.automaticRenewal;
    }
    if (data.withoutExternalCosts) {
      filterDto.withoutExternalCosts = data.withoutExternalCosts;
    }
    dispatch(searchOrders({ contractOrderSearchDto: filterDto }))
      .unwrap()
      .then((res: any) => {});
    handleSubmit(data);
    dispatch(saveFiltersOrdersUsed(data));
    setIsOpen(false);
  };

  const onReset = () => {
    reset(initialData);
    dispatch(saveFiltersOrdersUsed(initialData));
    setChipsFormData([]);
  };

  return { onSubmit, onReset, filterParams, chipsFormData, setChipsFormData, reset, formState };
};

export default useFormPassiveCycle;
