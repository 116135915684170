/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GiorniLavorativiMeseAnnoDto interface.
 */
export function instanceOfGiorniLavorativiMeseAnnoDto(value) {
    return true;
}
export function GiorniLavorativiMeseAnnoDtoFromJSON(json) {
    return GiorniLavorativiMeseAnnoDtoFromJSONTyped(json, false);
}
export function GiorniLavorativiMeseAnnoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'mese': json['mese'] == null ? undefined : json['mese'],
        'anno': json['anno'] == null ? undefined : json['anno'],
        'giorniLvorativi': json['giorniLvorativi'] == null ? undefined : json['giorniLvorativi'],
        'giorniLavorativiReali': json['giorniLavorativiReali'] == null ? undefined : json['giorniLavorativiReali'],
    };
}
export function GiorniLavorativiMeseAnnoDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'mese': value['mese'],
        'anno': value['anno'],
        'giorniLvorativi': value['giorniLvorativi'],
        'giorniLavorativiReali': value['giorniLavorativiReali'],
    };
}
