/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { UpdateCostsInputDtoFromJSON, UpdateCostsInputDtoToJSON, } from '../models/index';
/**
 *
 */
export class PlanningControllerApi extends runtime.BaseAPI {
    /**
     * Invia il planning a Salesforce/intranet e aggiorna lo stato del progetto
     * Invia planning
     */
    inviaPlanningRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling inviaPlanning().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/planning/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Invia il planning a Salesforce/intranet e aggiorna lo stato del progetto
     * Invia planning
     */
    inviaPlanning(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.inviaPlanningRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Aggiorna la pianificazione del progetto selezionato
     * Aggiorna la pianificazione di un progetto
     */
    ripianificaRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling ripianifica().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/planning/ripianifica/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Aggiorna la pianificazione del progetto selezionato
     * Aggiorna la pianificazione di un progetto
     */
    ripianifica(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.ripianificaRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Aggiorna staff e costi esterni utilizzando i dati forniti.
     * Aggiorna staff e costi esterni
     */
    saveScheduleRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idProject'] == null) {
                throw new runtime.RequiredError('idProject', 'Required parameter "idProject" was null or undefined when calling saveSchedule().');
            }
            if (requestParameters['updateCostsInputDto'] == null) {
                throw new runtime.RequiredError('updateCostsInputDto', 'Required parameter "updateCostsInputDto" was null or undefined when calling saveSchedule().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/planning/save-schedule/project/{idProject}`.replace(`{${"idProject"}}`, encodeURIComponent(String(requestParameters['idProject']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateCostsInputDtoToJSON(requestParameters['updateCostsInputDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCostsInputDtoFromJSON(jsonValue));
        });
    }
    /**
     * Aggiorna staff e costi esterni utilizzando i dati forniti.
     * Aggiorna staff e costi esterni
     */
    saveSchedule(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.saveScheduleRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Aggiorna staff e costi esterni utilizzando i dati forniti.
     * Aggiorna staff e costi esterni
     */
    updateCostsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idProject'] == null) {
                throw new runtime.RequiredError('idProject', 'Required parameter "idProject" was null or undefined when calling updateCosts().');
            }
            if (requestParameters['updateCostsInputDto'] == null) {
                throw new runtime.RequiredError('updateCostsInputDto', 'Required parameter "updateCostsInputDto" was null or undefined when calling updateCosts().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/planning/update/project/{idProject}`.replace(`{${"idProject"}}`, encodeURIComponent(String(requestParameters['idProject']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateCostsInputDtoToJSON(requestParameters['updateCostsInputDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCostsInputDtoFromJSON(jsonValue));
        });
    }
    /**
     * Aggiorna staff e costi esterni utilizzando i dati forniti.
     * Aggiorna staff e costi esterni
     */
    updateCosts(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateCostsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
