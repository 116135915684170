import { GiorniLavorativiMeseAnnoDto, RevenuePlanDto, StaffExtendedDto } from '@api/client';
import TotalsRevenuesAndGUSticky from './totals-revenues-sticky';
import TotalsAllocations from './totals-allocations';
import { Card, Table } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { TableProjectKeys } from '../../../../../../enums/project.enum';

interface Props {
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined;
  fullAllocationsShown: Partial<StaffExtendedDto>[] | null | undefined;
  totalsRevenuePlan: RevenuePlanDto[] | null | undefined;
  totalsRevenuePlanShown: RevenuePlanDto[] | null | undefined;
  isWorkInProgress: boolean;
  parentScrollLeft: number;
  onChildScroll: (scrollLeft: number) => void;
  giorniLavorativi: GiorniLavorativiMeseAnnoDto[] | null | undefined;
  visibleRows: { [key: string]: boolean };
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  visibleColumns: any;
  table: string;
  totalsTableRef: React.RefObject<HTMLDivElement>;
}

const TotalsTable: React.FC<Props> = ({
  table,
  visibleColumns,
  isCollapsed,
  setIsCollapsed,
  fullAllocations,
  fullAllocationsShown,
  totalsRevenuePlan,
  totalsRevenuePlanShown,
  isWorkInProgress,
  parentScrollLeft,
  onChildScroll,
  giorniLavorativi,
  visibleRows,
  totalsTableRef,
}) => {
  const { t } = useTranslation();
  const [scrollbarPresent, setScrollbarPresent] = useState(false);
  // const totalsTableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const content = totalsTableRef.current;

    if (content) {
      const hasHorizontalScrollbar = content.scrollWidth > content.clientWidth;
      if (hasHorizontalScrollbar !== scrollbarPresent) setScrollbarPresent(hasHorizontalScrollbar);
    }
  }, [isCollapsed, fullAllocationsShown, table]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleChildScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollLeft = target.scrollLeft;
    onChildScroll(scrollLeft);
  };

  useEffect(() => {
    if (totalsTableRef.current) {
      totalsTableRef.current.scrollLeft = parentScrollLeft;
    }
  }, [parentScrollLeft]);

  const calcMinWidth = () => {
    if (isWorkInProgress && table === TableProjectKeys.EXTERNAL_COSTS) {
      return '970px';
    } else if (!isWorkInProgress && table === TableProjectKeys.EXTERNAL_COSTS) {
      return '770px';
    }
  };

  const defaultColumns = {
    ppsProfile: true,
    pptSeniority: true,
    name: true,
    guRemaining: true,
    currentMonthRendicontations: false,
    worked: true,
    totalPlanned: true,
  };

  return (
    <Card className="totals-card">
      <Card.Header onClick={toggleCollapse} className="d-flex justify-content-end">
        <p className="text-blue-links m-0" style={{ cursor: 'pointer' }}>
          {isCollapsed ? `${t('project-detail.show-sums')}` : `${t('project-detail.hide-sums')}`}
        </p>
        <i className={`text-blue-links align-content-center fa-solid fa-chevron-${isCollapsed ? 'up' : 'down'} ms-2`} />
      </Card.Header>
      <Card.Body className="pt-0">
        <div style={{ height: isCollapsed ? (scrollbarPresent ? '9px' : '0') : '100%', overflowY: 'hidden' }}>
          <div
            className="d-flex table-responsive table-bordered"
            style={{ height: isCollapsed ? (scrollbarPresent ? '9px' : '0') : '100%', overflow: 'auto' }}
            ref={totalsTableRef}
            onScroll={handleChildScroll}
          >
            <Table
              className={'revenue-table sticky-table mt-0 mb-0'}
              style={{
                maxWidth: table === TableProjectKeys.TEAM ? '200px' : '',
                minWidth: calcMinWidth(),
              }}
            >
              <tbody className="piede-tab">
                <TotalsRevenuesAndGUSticky
                  visibleColumns={table === TableProjectKeys.TEAM ? visibleColumns : defaultColumns}
                  fullAllocations={fullAllocations}
                  isWorkInProgress={isWorkInProgress}
                  totalsRevenuePlan={totalsRevenuePlan}
                  visibleRows={visibleRows}
                  colSpan={3}
                />
              </tbody>
            </Table>
            <Table className={'revenue-table mt-0 mb-0'}>
              <tbody>
                <TotalsAllocations
                  visibleRows={visibleRows}
                  fullAllocationsShown={fullAllocationsShown}
                  totalsRevenuePlanShown={totalsRevenuePlanShown}
                  isWorkInProgress={isWorkInProgress}
                  giorniLavorativi={giorniLavorativi}
                />
              </tbody>
            </Table>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TotalsTable;
