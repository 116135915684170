import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import LinksButton from '../../../../shared/design-system/components/links-button';
import ProjectData from './_components/project-data';
import { useAppDispatch } from '../../../../core/store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { formatCurrency } from '../../../../shared/utils/common.utils';
import { DatePicker, InputNumber, Select } from 'antd';
import { getCosts, getUsers, resetTeamAndCostsData } from './_redux/actions';
import { DettagliProgettoDtoStatoEnum, SearchUsersRequest } from '@api/client';
import locale from 'antd/es/date-picker/locale/en_US';
import StatusBadge from '../../../../shared/design-system/components/project-status-badge';
import StartRevenuePlanning from './_components/start-revenue-plan';
import RevenuePlan from './_components/revenue-plan';
import useCalculateIndicatori from './_hooks/useCalculateIndicatori';
import AppModal from '../../../../shared/design-system/components/app-modal';
import { useTranslation } from 'react-i18next';
import { PathEnum } from '../../../../core/router/path.enum';
import useRoles from './_hooks/project-detail/useRoles';
import useProjectDetails from './_hooks/project-detail/useProjectDetails';
import useTeamManagement from './_hooks/project-detail/useTeamManagement';
import useDateRange from './_hooks/project-detail/useDateRange';
import useProjectActions from './_hooks/project-detail/useProjectActions';
import AppBlocker from './_components/blocker';
import { type unstable_BlockerFunction as BlockerFunction } from 'react-router-dom';
import { trackUserIdGa4 } from '../../../../shared/utils/gtag';
import AuthService from '../../../../core/auth/auth.service';
import { crudGetProject } from '../../crud/_redux/actions';
import { TrackPagesGA4Enum } from '../../../../enums/global.enum';
import { resetBillingPlan } from './_components/billing-plan/_redux/actions';

const ProjectDetail: React.FC = () => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { id } = useParams<{ id: any }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const roleList = useRoles();
  const { projectDetails, setProjectDetails, isPlanning, isWorkInProgress, getProjectDetail } = useProjectDetails();
  const { dateRange, onDateChange, getDefaultDateRange } = useDateRange(projectDetails?.mesiProgetto);
  const { ruoliTeam, handleRoleChange, handleSeniorityChange, handleChangeAmounts, addRole, deleteRole } =
    useTeamManagement(roleList);
  const { indicatori } = useCalculateIndicatori(projectDetails, ruoliTeam);
  const [totalGuValidation, setTotalGuValidation] = useState(true);
  const [totalCostsValidation, setTotalCostsValidation] = useState(true);
  const [draftValidation, setDraftValidation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const {
    createTeam,
    saveAndConsolidaPlanning,
    isConfermaEnabled,
    filterOption,
    handleRicominciaPlanning,
    startPlanningInTable,
  } = useProjectActions(
    projectDetails!,
    setProjectDetails,
    id,
    ruoliTeam,
    dateRange,
    handleCloseModal,
    getProjectDetail,
    isPlanning
  );
  const [show, setShow] = useState(false);

  useEffect(() => {
    trackUserIdGa4(TrackPagesGA4Enum.PROJECT_DETAIL);

    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      dispatch(resetBillingPlan());
      dispatch(resetTeamAndCostsData());
    };
  }, []);

  useEffect(() => {
    dispatch(getCosts());
    const request: SearchUsersRequest = {
      userSearchRequest: {},
    };
    dispatch(getUsers(request));
  }, []);

  const calculateCost = (index: number) => {
    const ruolo = ruoliTeam[index];
    if (!ruolo.seniority?.costo || !ruolo.giorniUomo || !ruolo.numPersone) return '-';
    return ruolo.seniority.costo * ruolo.giorniUomo + ' €/gg';
  };

  const goToEditProject = () => {
    if (AuthService.isSuperAdmin()) {
      dispatch(crudGetProject(id))
        .unwrap()
        .then((res) => {
          if (res) navigate(`/${PathEnum.PRIVATE}/${PathEnum.CRUD}`);
        });
    } else {
      navigate(`/${PathEnum.PRIVATE}/${PathEnum.CRUD}`);
    }
  };

  const ricominciaPlanning = () => {
    setShow(true);
  };

  const modalPlanningTitle = t('project-detail.restart-planning-title-modal');
  const modalPlanningContent = t('project-detail.restart-planning-content-modal');

  let shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      (projectDetails?.staffList?.length ?? 0) > 0 &&
      !draftValidation,
    [draftValidation]
  );

  const canViewRevenuePlan = () => {
    return (projectDetails?.staffList?.length ?? 0) > 0;
  };
  return (
    <>
      <AppBlocker shouldBlock={shouldBlock} />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center col-8">
          <h3>
            ({projectDetails?.dettaglioOfferta?.intranetId}) {projectDetails?.dettaglioOfferta?.name}
          </h3>
          <div className="align-center d-flex">
            <StatusBadge label={true} className="mt-2 ms-3">
              {projectDetails?.dettaglioOfferta?.stato}
            </StatusBadge>
            {/* {projectDetails?.dettaglioOfferta?.stato === FilterDtoStatoEnum.WorkInProgressDraft ? (
              <Badge className="mt-2 me-auto ms-2 badge bg-warning align-content-center">
                <i className="fa-solid fa-triangle-exclamation me-1 "></i>
                {t('project-detail.draft')}
              </Badge>
            ) : (
              ''
            )} */}
          </div>
        </div>
        {/* {projectDetails?.dettaglioOfferta?.stato === FilterDtoStatoEnum.WorkInProgressDraft && (
          <Tooltip title={t('project-detail.draft-tooltip')} color="white" className="cursor-pointer ms-auto pe-3">
            <i className="fa-solid fa-triangle-exclamation me-1 text-danger"></i>
          </Tooltip>
        )} */}
        <div className="d-flex">
          {/* {!isWorkInProgress && (
            <LinksButton
              className="btn-sm btn-outline-danger px-4 d-flex align-items-center"
              onClick={ricominciaPlanning}
            >
              <i className="icon-delete"></i>
              {t('project-detail.restart-planning')}
            </LinksButton>
          )} */}

          <AppModal
            setShow={setShow}
            show={show}
            title="Ricomincia pianificazione da Team"
            cancelText={t('project-detail.restart-planning-cancel')}
            confirmText={t('project-detail.restart-planning-confirm')}
            onConfirm={() => {
              handleRicominciaPlanning();
            }}
          >
            <span className="text-danger">
              <i className="fa-solid fa-triangle-exclamation"></i> <b>{modalPlanningTitle}</b>{' '}
            </span>{' '}
            <br />
            <p className="mt-3">{modalPlanningContent}</p>
          </AppModal>
          {projectDetails?.dettaglioOfferta?.stato !== DettagliProgettoDtoStatoEnum.Pending && (
            <>
              <LinksButton
                className="btn-sm btn-primary btn-teal px-2"
                onClick={goToEditProject}
                isDisabled={projectDetails?.readOnly}
              >
                <i className="icon-edit" />
              </LinksButton>

              <LinksButton
                className="btn-sm btn-primary btn-teal px-4"
                onClick={saveAndConsolidaPlanning}
                isDisabled={!totalGuValidation || !totalCostsValidation || projectDetails?.readOnly}
              >
                <i className="fas fa-check me-2" />
                {isWorkInProgress ? `${t('project-detail.save-planning')}` : `${t('project-detail.send-planning')}`}
              </LinksButton>
            </>
          )}
        </div>
      </div>

      <ProjectData projectDetails={projectDetails} />
      {canViewRevenuePlan() ? (
        <RevenuePlan
          projectDetails={projectDetails}
          roleList={roleList}
          idProject={projectDetails?.dettaglioOfferta?.id}
          isWorkInProgress={isWorkInProgress}
          setTotalGuValidation={setTotalGuValidation}
          setTotalCostsValidation={setTotalCostsValidation}
          setDraftValidation={setDraftValidation}
        />
      ) : (
        <StartRevenuePlanning
          readOnly={projectDetails?.readOnly}
          projectMonths={projectDetails?.mesiProgetto}
          handleShowModal={handleShowModal}
          startPlanningInTable={startPlanningInTable}
        />
      )}

      <Modal show={showModal} onHide={handleCloseModal} size="xl" centered scrollable>
        <Modal.Header className="card-header card-header-border" closeButton>
          <Modal.Title>
            <b className="text-blue-links">{t('project-detail.compile-before-start')}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pe-4 modal-520h">
          <Row
            className="d-flex align-items-center pt-3 sticky-top"
            style={{ backgroundColor: '#f3f4f7', boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)' }}
          >
            <Col xs={2} className="mb-3">
              <b className="text-gray-links text-sm">{t('project-detail.quadrature-indicators')}</b>
            </Col>
            <Col xs={10}>
              <Row>
                <Col xs={2}>
                  <div className="span-values">
                    <div className="value text-teal-links">{formatCurrency(indicatori.importoOfferta)}</div>
                    <div className="head">{t('project-detail.offer-amount-€')}</div>
                  </div>
                </Col>
                <Col xs={2}>
                  <div className="span-values">
                    <div className="value">{formatCurrency(indicatori.costoTotaleStimato)}</div>
                    <div className="head">{t('project-detail.extimated-costs-€')}</div>
                  </div>
                </Col>
                <Col xs={2}>
                  <div className="span-values">
                    <div className="value">{formatCurrency(indicatori.scostamento)}</div>
                    <div className="head">{t('project-detail.deviation')}</div>
                  </div>
                </Col>
                <Col xs={2}>
                  <div className="span-values">
                    <div className="d-flex">
                      <div className="value text-teal-links">{formatCurrency(indicatori.totaleGiorniUomo, 0)}</div>
                      <div className="value">{`/${formatCurrency(indicatori.nRisorse, 0)}`}</div>
                    </div>
                    <div className="head">{t('project-detail.gu-number-persons')}</div>
                  </div>
                </Col>
                <Col xs={2}>
                  <div className="span-values">
                    <div className="value">{formatCurrency(indicatori.ricavi, 2)}</div>
                    <div className="head">{t('project-detail.revenues-€')}</div>
                  </div>
                </Col>
                <Col xs={2}>
                  <div className="span-values">
                    <div className="value">{formatCurrency(indicatori.margine, 2)}</div>
                    <div className="head">{t('project-detail.margin-%')}</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="mt-3">
            <div className="d-flex flex-column">
              <h6 className="text-blue-links mb-3">{t('project-detail.define-project-duration')}</h6>
              <Form>
                <Col sm="4">
                  <Form.Label className="text-gray-links text-sm">{t('project-detail.select-period')}</Form.Label>
                  <RangePicker
                    picker="month"
                    format="MM/YYYY"
                    locale={locale}
                    getPopupContainer={(triggerMode) => {
                      return triggerMode.parentNode as HTMLElement;
                    }}
                    onChange={(dates: any, dateStrings: [string, string]) => onDateChange(dates, dateStrings)}
                    defaultValue={getDefaultDateRange()}
                  />
                </Col>
              </Form>
            </div>
          </div>
          <div className="mt-3">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="text-blue-links mb-3">{t('project-detail.team-members')}</h6>
                <LinksButton className="btn-sm btn-links-outline px-4" onClick={() => addRole()}>
                  {t('project-detail.add-role')}
                </LinksButton>
              </div>

              {ruoliTeam.map((ruolo, index) => (
                <Row
                  className="d-flex align-items-center py-3"
                  style={{ borderBottom: '1px solid #dee2e6' }}
                  key={ruolo.id}
                >
                  <Col xs={3}>
                    <Form.Label className="text-gray-links text-sm">
                      {t('project-detail.professional-profile')}
                    </Form.Label>
                    <Select
                      className="w-100"
                      placeholder={t('project-detail.select-profile')}
                      getPopupContainer={(triggerMode) => {
                        return triggerMode.parentNode as HTMLElement;
                      }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      value={
                        ruoliTeam[index].idPps
                          ? {
                              value: ruoliTeam[index].idPps,
                              label: roleList.find((el) => el.idPps == ruoliTeam[index].idPps)?.descrizionePps,
                            }
                          : undefined
                      }
                      onChange={(e) => handleRoleChange(e, index)}
                    >
                      {roleList.map((el) => (
                        <Select.Option key={`${el.idPps}-${index}`} value={el.idPps}>
                          {el.descrizionePps}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col xs={3}>
                    <Form.Label className="text-gray-links text-sm">{t('project-detail.seniority')}</Form.Label>
                    <Select
                      className="w-100"
                      placeholder={t('project-detail.select-seniority')}
                      getPopupContainer={(triggerMode) => {
                        return triggerMode.parentNode as HTMLElement;
                      }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      value={
                        ruoliTeam[index].seniority?.ppt
                          ? {
                              value: ruoliTeam[index].seniority?.ppt,
                              label: roleList
                                .find((el) => el.idPps == ruoliTeam[index].seniority?.ppt)
                                ?.pptList?.find((el) => el.idCostoProfilo == ruoliTeam[index].seniority?.ppt)
                                ?.descrizioneCostoProfilo,
                            }
                          : undefined
                      }
                      onChange={(e) => handleSeniorityChange(e, index)}
                    >
                      {roleList
                        .find((role) => role.descrizionePps === ruolo.descrizionePps)
                        ?.pptList?.map((ppt, index) => (
                          <Select.Option key={`${ppt.idCostoProfilo}-${index}`} value={ppt.idCostoProfilo}>
                            {ppt.descrizioneCostoProfilo}
                          </Select.Option>
                        ))}
                    </Select>
                  </Col>
                  <Col xs={2}>
                    <Form.Label className="text-gray-links text-sm">{t('project-detail.gu-days')}</Form.Label>
                    <InputNumber
                      className="w-100"
                      key={'GU-' + index}
                      value={ruoliTeam[index].giorniUomo}
                      min={1}
                      name="giorniUomo"
                      onChange={(e) => handleChangeAmounts(e, index, 'giorniUomo')}
                    />
                  </Col>
                  <Col xs={1}>
                    <Form.Label className="text-gray-links text-sm">{t('project-detail.number-persons')}</Form.Label>
                    <InputNumber
                      className="w-100"
                      key={'nPersone-' + index}
                      value={ruoliTeam[index].numPersone}
                      min={1}
                      name="numPersone"
                      onChange={(e) => handleChangeAmounts(e, index, 'numPersone')}
                    />
                  </Col>
                  <Col xs={2} className="d-flex flex-column">
                    <Form.Label className="text-gray-links text-sm">{t('project-detail.standard-cost')}</Form.Label>
                    <span className="fw-bold text-gray-links py-1">{calculateCost(index)}</span>
                  </Col>
                  <Col xs={1}>
                    <LinksButton className="btn-icon" onClick={() => deleteRole(index)}>
                      <i className="icon-delete" style={{ color: 'red' }}></i>
                    </LinksButton>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <LinksButton className="btn-sm btn-links-outline px-4" onClick={handleCloseModal}>
            {t('common.cancel')}
          </LinksButton>
          <LinksButton
            className="btn-sm btn-primary btn-links px-4"
            isDisabled={!isConfermaEnabled()}
            onClick={() => createTeam()}
          >
            {t('common.confirm')}
          </LinksButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectDetail;
