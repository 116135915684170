/* eslint-disable jsx-a11y/anchor-is-valid */
import './styles.scss';
import React, { useEffect, useRef, useState } from 'react';
import AppCard from '../../../shared/design-system/components/app-card';
import usePagination from '../../../core/hooks/usePagination';
import AppAccordion from '../../../shared/design-system/components/app-accordion';
import { useTranslation } from 'react-i18next';
import MTForm, { projecMTFormData } from './mt-accordion-form';
import useGetAndMapManagementToolProjects, { ProjectCdgData } from './_hooks/useGetAndMapManagementToolProjects';
import useFormMTPage from '../../../shared/design-system/components/app-accordion/_hooks/useFormMTPage';
import LinksButton from '../../../shared/design-system/components/links-button';
import { Button, Table } from 'react-bootstrap';
import { Dropdown, InputNumber, Tabs, Tooltip } from 'antd';
import { ManagementToolsTabs, Session } from '../../../enums/project.enum';
import OtherCostsModal from './other-costs-modal';
import NoData from '../../../shared/design-system/components/no-data-box';
import { trackUserIdGa4 } from '../../../shared/utils/gtag';
import RefreshCdgHrCosts from './refresh-hr-costs';
import AppPaginator from '../../../shared/design-system/components/app-paginator';
import { PathEnum } from '../../../core/router/path.enum';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { formatCurrency } from '../../../shared/utils/common.utils';
import { StorageKeysEnum } from '../../../enums/storageKeys.enum';
import ExcelExport from '../../../shared/design-system/components/excel-export';
import useGetExportableExcelData from './_hooks/useGetExportableExcelData';
import ModalAction from '../project-list/project-detail/_components/modal-action';
import useHandleManagementToolsActions from './_hooks/useHandleManagementToolsActions';
import useCdgTabs from './_hooks/useCdgTabs';
import useConsolidateRevenues from './_hooks/useConsolidateRevenues';
import useOtherCostsModal from './_hooks/useOtherCostsModal';
import useGetSessionActions from './_hooks/useGetSessionActions';

interface Props {
  session: Session;
}

const ManagementTool: React.FC<Props> = ({ session }) => {
  const { t } = useTranslation();
  const { projects, revenueAndCostsData, setRevenueAndCostsData, originalsMTProjects } =
    useGetAndMapManagementToolProjects(session);
  const { excelData } = useGetExportableExcelData(projects, revenueAndCostsData);
  const { selectedTab, cdgTabs, onChangeTab } = useCdgTabs(session);
  const [isOpen, setIsOpen] = useState(true);
  const tableRef = useRef<HTMLTableElement>(null);
  const [filtersData, setFiltersData] = useState<any>();

  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset } = useFormMTPage(projecMTFormData, session);

  const { handleCellEdit, consolidateRevenues, copyPlRevenue } = useConsolidateRevenues({
    session,
    revenueAndCostsData,
    setRevenueAndCostsData,
    originalsMTProjects,
    filtersData,
    onSubmit,
  });

  const { openCostsModal, handleSaveClose, showCostsModal, setShowCostsModal, otherCosts, idCurrentProject } =
    useOtherCostsModal({ session, consolidateRevenues });

  const {
    menuProps,
    showConfirmCloseEconomicsModal,
    setShowConfirmCloseEconomicsModal,
    closeEconomics,
    showConfirmCompleteProjectModal,
    setShowConfirmCompleteProjectModal,
    completeProject,
    idToClose,
  } = useHandleManagementToolsActions({ session, consolidateRevenues });

  const {
    pageNumber: pageNumberProject,
    setPageNumber: setPageNumberProject,
    totalPages: totalPagesProject,
    visibleItems: visibleProjects,
    visibleSecondaryItems: visibleRevenueAndCostsData,
  } = usePagination(projects, 10, revenueAndCostsData);

  const { getSessionTracker } = useGetSessionActions(session);

  useEffect(() => {
    trackUserIdGa4(getSessionTracker());
  }, []);

  useEffect(() => {
    scrollToRight();
  }, [projects, revenueAndCostsData]);

  const scrollToRight = () => {
    setTimeout(() => {
      if (tableRef.current) {
        const container = tableRef.current;
        container.scrollTo({
          left: container.scrollWidth,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  const handleSubmit = (data: any) => {
    setFiltersData(data);
    onSubmit(data);
    setIsOpen(!isOpen);
  };

  const handleReset = () => {
    onReset();
    chipsFormData && setChipsFormData([]);
  };

  ////// TABLE DATA
  const [visProj, setVisProj] = useState(visibleProjects);
  const [visRevAndCosts, setVisRevAndCosts] = useState(visibleRevenueAndCostsData);

  useEffect(() => {
    if (visibleProjects) {
      setVisProj(visibleProjects);
      setVisRevAndCosts(visibleRevenueAndCostsData);
    }
  }, [visibleProjects]);

  const getCloseEconomicMessageModal = () => {
    const project = originalsMTProjects.find((p) => p.id === idToClose);
    const { totalActualRevenue, totalBidAmount } = project || {};

    if (totalBidAmount) {
      if ((totalActualRevenue ?? 0) < totalBidAmount) {
        return t('cdg.close-economics.message-modal-confirm-with-diff', {
          diff: formatCurrency(totalBidAmount - (totalActualRevenue ?? 0)),
        });
      } else {
        return t('cdg.close-economics.message-modal-confirm');
      }
    }
    return t('cdg.close-economics.message-modal-confirm');
  };

  const uniqueMonths: string[] = [];
  const monthOccurrences: { [label: string]: number } = {};

  if (revenueAndCostsData && revenueAndCostsData.length > 0) {
    // Calcola le label uniche e tiene traccia delle occorrenze
    Object.keys(revenueAndCostsData[0]).forEach((key) => {
      const column = revenueAndCostsData[0][key as keyof ProjectCdgData];
      if (column?.visible) {
        if (!uniqueMonths.includes(column.month)) {
          uniqueMonths.push(column.month);
        }
        monthOccurrences[column.month] = (monthOccurrences[column.month] || 0) + 1;
      }
    });
  }

  return (
    <>
      <div className="d-flex flex-column">
        <Tabs defaultActiveKey={ManagementToolsTabs.SENDING_PROGRESS_DATA} items={cdgTabs} onChange={onChangeTab} />
        {selectedTab === ManagementToolsTabs.SENDING_PROGRESS_DATA && (
          <div className="d-flex justify-content-between mt-3">
            <h4 className="text-gray-800 my-0">{t('common.closing-data')}</h4>
            <div>
              {projects.length && <ExcelExport data={excelData} fileName="Sending progress data" />}
              <LinksButton
                className="btn-sm btn-primary btn-teal px-4 ms-auto"
                isDisabled={projects.length === 0}
                onClick={() => consolidateRevenues(true)}
              >
                <i className="fas fa-check me-2" />
                {t('cdg.consolidate-all')}
              </LinksButton>
            </div>
          </div>
        )}
      </div>
      {selectedTab === ManagementToolsTabs.SENDING_PROGRESS_DATA && (
        <>
          <AppAccordion
            formData={projecMTFormData}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            chipsFormData={chipsFormData}
            setChipsFormData={setChipsFormData}
            reset={reset}
            location={session}
          >
            <MTForm onSubmit={handleSubmit} handleReset={handleReset} session={session} />
          </AppAccordion>
          <AppCard title={t('project-list.all-projects')}>
            {projects.length === 0 ? (
              <NoData msg={t('project-list.no-projects')} />
            ) : (
              <div className="d-flex table-responsive table-bordered" ref={tableRef}>
                <Table className="revenue-table sticky-table mt-0 mb-0">
                  <thead className="bg-red">
                    <tr className="tr-head">
                      {Object.keys(projects[0]).map((key) => {
                        const column = projects[0][key as keyof ProjectCdgData];
                        if (column?.visible) {
                          return (
                            <th key={key} style={{ width: column.minWidth }}>
                              {column.label}
                            </th>
                          );
                        }
                        return null;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {visProj.map((row: ProjectCdgData, index) => (
                      <tr key={index} className="tr-cdg">
                        {Object.keys(row).map((key) => {
                          const cellData = row[key as keyof ProjectCdgData];
                          if (cellData.visible) {
                            return (
                              <td
                                key={`${key}${index}`}
                                style={{ minWidth: cellData.minWidth }}
                                className="align-content-center"
                              >
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  style={{ maxHeight: '30px', minHeight: '30px' }}
                                >
                                  {key === 'intranetId' ? (
                                    <a
                                      href={`${window.location.origin}${window.location.pathname}#/${PathEnum.PRIVATE}/${PathEnum.PROGETTI}/${cellData.uniqueId}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-links"
                                    >
                                      {cellData.value}
                                    </a>
                                  ) : (
                                    cellData.value
                                  )}
                                </div>
                              </td>
                            );
                          }
                          return null;
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Table className="revenue-table mt-0 mb-0">
                  <thead className="bg-red">
                    <tr className="">
                      {uniqueMonths.map((month) => {
                        const colSpan = monthOccurrences[month];
                        return (
                          <th key={month} colSpan={colSpan} className={colSpan === 2 ? 'bg-disabled' : ''}>
                            <div className="d-flex justify-content-center align-items-center">
                              <span>{month}</span>
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                    <tr>
                      {Object.keys(revenueAndCostsData[0]).map((key) => {
                        const column = revenueAndCostsData[0][key as keyof ProjectCdgData];
                        if (column?.visible) {
                          return (
                            <th key={key} className={column.className} style={{ width: column.minWidth }}>
                              {column.label}
                              {column.tooltip && (
                                <Tooltip title={column.tooltip}>
                                  <i className="fas fa-circle-info text-blue-links ps-2" />
                                </Tooltip>
                              )}
                            </th>
                          );
                        }
                        return null;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {visRevAndCosts.map((row: ProjectCdgData, index) => (
                      <tr key={index} className="tr-cdg">
                        {Object.keys(row).map((key) => {
                          const cellData = row[key as keyof ProjectCdgData];
                          if (cellData.visible) {
                            if (cellData.type === 'text') {
                              return (
                                <td
                                  key={`${key}${index}`}
                                  style={{ minWidth: cellData.minWidth }}
                                  className={`align-content-center ${cellData.className}`}
                                >
                                  <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ maxHeight: '30px' }}
                                  >
                                    {cellData.value}
                                    {key === 'plRevenue' && (
                                      <Button
                                        onClick={() =>
                                          copyPlRevenue(cellData.value as string, cellData.uniqueId as number)
                                        }
                                        className="btn-icon border-none"
                                        variant="transparent"
                                        disabled={cellData.value === '-'}
                                      >
                                        <i className="fa fas fa-arrow-right text-blue-links pb-2" />
                                      </Button>
                                    )}
                                    {key === 'actualOtherCosts' && (
                                      <button
                                        onClick={() => openCostsModal(cellData.uniqueId as number)}
                                        className="btn-icon"
                                      >
                                        <i
                                          className={`${
                                            session === Session.PL ? 'icon-eye-shows' : 'icon-edit'
                                          } text-blue-links pb-2`}
                                        />
                                      </button>
                                    )}
                                    {key === 'actions' && (
                                      <Tooltip title={'Actions'} color="white" key={`actions-${index}`}>
                                        <Dropdown
                                          menu={menuProps(index, cellData.uniqueId as number)}
                                          trigger={['click']}
                                        >
                                          <i
                                            className="fa-solid fa-ellipsis-vertical text-blue-links ps-1"
                                            role="button"
                                            onClick={(e) => e.preventDefault()}
                                          ></i>
                                        </Dropdown>
                                      </Tooltip>
                                    )}
                                  </div>
                                </td>
                              );
                            } else if (cellData.type === 'number') {
                              return (
                                <td
                                  key={`${key}${index}`}
                                  style={{ minWidth: cellData.minWidth }}
                                  className="pe-0 me-0 td-cdg align-content-center"
                                >
                                  <Tooltip
                                    title={
                                      (Number(cellData.value) || 0) > (cellData.maxRevenue || 0)
                                        ? t('cdg.table-cdg.validation-revenues-tooltip', {
                                            amount: formatCurrency(
                                              (Number(cellData.value) || 0) - (cellData.maxRevenue || 0)
                                            ),
                                          })
                                        : ``
                                    }
                                    color="red"
                                    key={`tooltip-validation-${index}`}
                                  >
                                    <div className="input-num-wrapper h-100">
                                      <InputNumber<string>
                                        className="input-num h-100 w-100"
                                        variant="borderless"
                                        min="0"
                                        decimalSeparator=","
                                        step="0.01"
                                        keyboard={false}
                                        value={cellData.value as string}
                                        onChange={(e) => handleCellEdit(e, cellData.uniqueId as number)}
                                        placeholder="Insert revenue"
                                        suffix={
                                          <Tooltip title={'text'} key={index}>
                                            <ExclamationCircleOutlined
                                              className="pe-4"
                                              style={{
                                                color:
                                                  (Number(cellData.value) || 0) > (cellData.maxRevenue || 0)
                                                    ? 'red'
                                                    : 'transparent',
                                              }}
                                            />
                                          </Tooltip>
                                        }
                                      />
                                      <i className="icon-edit text-gray-links" />
                                    </div>
                                  </Tooltip>
                                </td>
                              );
                            }
                          }
                          return null;
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}

            {visibleProjects?.length > 0 ? (
              <AppPaginator
                keyPaginator={StorageKeysEnum.CURRENT_PAGE_MANAGEMENT_TOOLS}
                currentPage={pageNumberProject}
                totalPages={totalPagesProject}
                totalElements={projects.length}
                pageSize={10}
                onPageChange={setPageNumberProject}
              />
            ) : (
              ''
            )}
          </AppCard>
          <OtherCostsModal
            isOpen={showCostsModal}
            onClose={() => setShowCostsModal(false)}
            onSaveClose={() => handleSaveClose()}
            otherCostsFromService={otherCosts}
            session={session}
            idProject={idCurrentProject}
          />
          {/* MODALE CONFERMA CLOSE ECONOMICS */}
          <ModalAction
            title={t('cdg.close-economics.action')}
            actionLabel={t('common.confirm')}
            cancelLabel={t('common.cancel')}
            action={() => {
              closeEconomics();
            }}
            setShow={(value) => setShowConfirmCloseEconomicsModal(value)}
            show={showConfirmCloseEconomicsModal}
          >
            <span className="text-danger">
              <i className="fa-solid fa-triangle-exclamation"></i> <b>{t('common.attention')}</b>{' '}
            </span>
            <div className="p-1">
              <p>{getCloseEconomicMessageModal()}</p>
            </div>
          </ModalAction>
          {/* MODALE CONFERMA COMPLETE PROJECT */}
          <ModalAction
            title={t('cdg.complete-project.action')}
            actionLabel={t('common.confirm')}
            cancelLabel={t('common.cancel')}
            action={() => {
              completeProject();
            }}
            setShow={(value) => setShowConfirmCompleteProjectModal(value)}
            show={showConfirmCompleteProjectModal}
          >
            <span className="text-danger">
              <i className="fa-solid fa-triangle-exclamation"></i> <b>{t('common.attention')}</b>{' '}
            </span>
            <div className="p-1">
              <p>{t('cdg.complete-project.message-modal-confirm')}</p>
            </div>
          </ModalAction>
        </>
      )}
      {selectedTab === ManagementToolsTabs.REFRESH_HR_COSTS && <RefreshCdgHrCosts />}
    </>
  );
};

export default ManagementTool;
