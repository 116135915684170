/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the RevenueMonthDto interface.
 */
export function instanceOfRevenueMonthDto(value) {
    return true;
}
export function RevenueMonthDtoFromJSON(json) {
    return RevenueMonthDtoFromJSONTyped(json, false);
}
export function RevenueMonthDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'year': json['year'] == null ? undefined : json['year'],
        'month': json['month'] == null ? undefined : json['month'],
        'amount': json['amount'] == null ? undefined : json['amount'],
        'consolidato': json['consolidato'] == null ? undefined : json['consolidato'],
        'editable': json['editable'] == null ? undefined : json['editable'],
    };
}
export function RevenueMonthDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'year': value['year'],
        'month': value['month'],
        'amount': value['amount'],
        'consolidato': value['consolidato'],
        'editable': value['editable'],
    };
}
