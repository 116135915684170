/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the DimAlberoDeliveryDto interface.
 */
export function instanceOfDimAlberoDeliveryDto(value) {
    return true;
}
export function DimAlberoDeliveryDtoFromJSON(json) {
    return DimAlberoDeliveryDtoFromJSONTyped(json, false);
}
export function DimAlberoDeliveryDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idStrutturaFunzione': json['idStrutturaFunzione'] == null ? undefined : json['idStrutturaFunzione'],
        'nomeStrutturaFunzione': json['nomeStrutturaFunzione'] == null ? undefined : json['nomeStrutturaFunzione'],
        'nomeTipoStruttura': json['nomeTipoStruttura'] == null ? undefined : json['nomeTipoStruttura'],
        'l1': json['l1'] == null ? undefined : json['l1'],
        'idTipoStruttura': json['idTipoStruttura'] == null ? undefined : json['idTipoStruttura'],
        'l1NomeStrutturaFunzione': json['l1NomeStrutturaFunzione'] == null ? undefined : json['l1NomeStrutturaFunzione'],
        'l1IdUtenteResp': json['l1IdUtenteResp'] == null ? undefined : json['l1IdUtenteResp'],
        'l1NominativoResp': json['l1NominativoResp'] == null ? undefined : json['l1NominativoResp'],
        'l1EmailResp': json['l1EmailResp'] == null ? undefined : json['l1EmailResp'],
        'l2': json['l2'] == null ? undefined : json['l2'],
        'l2NomeStrutturaFunzione': json['l2NomeStrutturaFunzione'] == null ? undefined : json['l2NomeStrutturaFunzione'],
        'l2IdUtenteResp': json['l2IdUtenteResp'] == null ? undefined : json['l2IdUtenteResp'],
        'l2NominativoResp': json['l2NominativoResp'] == null ? undefined : json['l2NominativoResp'],
        'l2EmailResp': json['l2EmailResp'] == null ? undefined : json['l2EmailResp'],
        'l3': json['l3'] == null ? undefined : json['l3'],
        'l3NomeStrutturaFunzione': json['l3NomeStrutturaFunzione'] == null ? undefined : json['l3NomeStrutturaFunzione'],
        'l3IdUtenteResp': json['l3IdUtenteResp'] == null ? undefined : json['l3IdUtenteResp'],
        'l3NominativoResp': json['l3NominativoResp'] == null ? undefined : json['l3NominativoResp'],
        'l3EmailResp': json['l3EmailResp'] == null ? undefined : json['l3EmailResp'],
    };
}
export function DimAlberoDeliveryDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idStrutturaFunzione': value['idStrutturaFunzione'],
        'nomeStrutturaFunzione': value['nomeStrutturaFunzione'],
        'nomeTipoStruttura': value['nomeTipoStruttura'],
        'l1': value['l1'],
        'idTipoStruttura': value['idTipoStruttura'],
        'l1NomeStrutturaFunzione': value['l1NomeStrutturaFunzione'],
        'l1IdUtenteResp': value['l1IdUtenteResp'],
        'l1NominativoResp': value['l1NominativoResp'],
        'l1EmailResp': value['l1EmailResp'],
        'l2': value['l2'],
        'l2NomeStrutturaFunzione': value['l2NomeStrutturaFunzione'],
        'l2IdUtenteResp': value['l2IdUtenteResp'],
        'l2NominativoResp': value['l2NominativoResp'],
        'l2EmailResp': value['l2EmailResp'],
        'l3': value['l3'],
        'l3NomeStrutturaFunzione': value['l3NomeStrutturaFunzione'],
        'l3IdUtenteResp': value['l3IdUtenteResp'],
        'l3NominativoResp': value['l3NominativoResp'],
        'l3EmailResp': value['l3EmailResp'],
    };
}
