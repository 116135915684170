/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClienteDtoFromJSON, ClienteDtoToJSON, } from './ClienteDto';
import { ProjectOrganizationDtoFromJSON, ProjectOrganizationDtoToJSON, } from './ProjectOrganizationDto';
import { ProjectUserDtoFromJSON, ProjectUserDtoToJSON, } from './ProjectUserDto';
/**
 * @export
 */
export const DashboardProjectFilterDtoStatoEnumEnum = {
    Pending: 'PENDING',
    Planning: 'PLANNING',
    ClosedLost: 'CLOSED_LOST',
    IntranetPending: 'INTRANET_PENDING',
    WorkInProgress: 'WORK_IN_PROGRESS',
    Completed: 'COMPLETED',
    ClosedWonPlanning: 'CLOSED_WON_PLANNING',
    PlanningOk: 'PLANNING_OK',
    ControlloCdg: 'CONTROLLO_CDG',
    ControlloCl: 'CONTROLLO_CL',
    ReportingClosed: 'REPORTING_CLOSED',
    EconomicsClosed: 'ECONOMICS_CLOSED'
};
/**
 * Check if a given object implements the DashboardProjectFilterDto interface.
 */
export function instanceOfDashboardProjectFilterDto(value) {
    return true;
}
export function DashboardProjectFilterDtoFromJSON(json) {
    return DashboardProjectFilterDtoFromJSONTyped(json, false);
}
export function DashboardProjectFilterDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'statoEnum': json['statoEnum'] == null ? undefined : json['statoEnum'],
        'businessUnit': json['businessUnit'] == null ? undefined : (json['businessUnit'].map(ProjectOrganizationDtoFromJSON)),
        'deliveryUnit': json['deliveryUnit'] == null ? undefined : (json['deliveryUnit'].map(ProjectOrganizationDtoFromJSON)),
        'projectLeads': json['projectLeads'] == null ? undefined : (json['projectLeads'].map(ProjectUserDtoFromJSON)),
        'clientIds': json['clientIds'] == null ? undefined : (json['clientIds'].map(ClienteDtoFromJSON)),
    };
}
export function DashboardProjectFilterDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'statoEnum': value['statoEnum'],
        'businessUnit': value['businessUnit'] == null ? undefined : (value['businessUnit'].map(ProjectOrganizationDtoToJSON)),
        'deliveryUnit': value['deliveryUnit'] == null ? undefined : (value['deliveryUnit'].map(ProjectOrganizationDtoToJSON)),
        'projectLeads': value['projectLeads'] == null ? undefined : (value['projectLeads'].map(ProjectUserDtoToJSON)),
        'clientIds': value['clientIds'] == null ? undefined : (value['clientIds'].map(ClienteDtoToJSON)),
    };
}
