/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { AuthenticationResponseDtoFromJSON, SignInRequestDtoToJSON, } from '../models/index';
/**
 *
 */
export class AuthenticationControllerApi extends runtime.BaseAPI {
    /**
     */
    authenticateRequestRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['signInRequestDto'] == null) {
                throw new runtime.RequiredError('signInRequestDto', 'Required parameter "signInRequestDto" was null or undefined when calling authenticateRequest().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/auth-service`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SignInRequestDtoToJSON(requestParameters['signInRequestDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    authenticateRequest(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticateRequestRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    authenticateRequestLdapRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['signInRequestDto'] == null) {
                throw new runtime.RequiredError('signInRequestDto', 'Required parameter "signInRequestDto" was null or undefined when calling authenticateRequestLdap().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/auth-service/login-ldap`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SignInRequestDtoToJSON(requestParameters['signInRequestDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    authenticateRequestLdap(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticateRequestLdapRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    performLogoutRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/auth-service/logout`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     */
    performLogout(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.performLogoutRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    renewAuthenticationRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/auth-service/renew`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    renewAuthentication(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.renewAuthenticationRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    ssoRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['accessToken'] == null) {
                throw new runtime.RequiredError('accessToken', 'Required parameter "accessToken" was null or undefined when calling sso().');
            }
            const queryParameters = {};
            if (requestParameters['accessToken'] != null) {
                queryParameters['access_token'] = requestParameters['accessToken'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/auth-service/sso`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    sso(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.ssoRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
