import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import itTranslation from './it.json';
import enTranslation from './en.json';

const defaultLanguage = 'en';

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    it: {
      translation: itTranslation,
    },
  },
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
