import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../core/store/hooks';
import { currentProjectSelector } from '../../../../pages/private/project-list/_redux/selectors';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { PathEnum } from '../../../../core/router/path.enum';

const BreadCrumb: React.FC = () => {
  const { t } = useTranslation();
  const project = useAppSelector(currentProjectSelector);
  const projectName = project?.dettaglioOfferta?.name || '';
  const location = useLocation();

  const mapPathName = (pathname: string) => {
    switch (pathname) {
      case PathEnum.DASHBOARD:
        return t('common.dashboard');
      case PathEnum.PROGETTI:
        return t('common.projects');
      case PathEnum.CICLO_PASSIVO:
        return t('common.passive-cycle');
      case PathEnum.PL:
        return t('common.pl');
      case PathEnum.CL:
        return t('common.cl');
      case PathEnum.CDG:
        return t('common.cdg');
      case PathEnum.CRUD:
        return t('common.crud');
      case PathEnum.WFM:
        return t('common.freeze-month');
      case PathEnum.DELEGATE_MANAGEMENT:
        return t('common.delegates-management');
      default:
        return pathname;
    }
  };

  const generateBreadcrumbItems = (): { title: string; to?: string }[] => {
    const pathnames = location.pathname.split('/').filter((x) => x !== 'private');
    const breadcrumbItems = pathnames.map((pathname, index) => {
      const idPattern = /^\d+$/;
      const isId = idPattern.test(pathname);
      if (isId) {
        return { title: pathnames.find((el) => el === PathEnum.PROGETTI) ? projectName : pathname };
      } else {
        return { title: mapPathName(pathname) };
      }
    });
    breadcrumbItems.unshift({ title: 'Home' });
    return breadcrumbItems.filter((item) => item.title.trim() !== '');
  };

  const items = generateBreadcrumbItems().map((item, index) => ({
    title:
      index === generateBreadcrumbItems().length - 1 ? (
        <span className="text-blue-links">
          <b>{item.title}</b>
        </span>
      ) : (
        <Link
          to={location.pathname
            .split('/')
            .slice(0, index + 2)
            .join('/')}
        >
          {item.title}
        </Link>
      ),
  }));

  return <Breadcrumb separator=">" className="ms-3 mb-2" items={items} />;
};

export default BreadCrumb;
