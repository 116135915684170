import { createReducer } from '@reduxjs/toolkit';
import { AuthReducer } from '../../pages/auth/_redux/actions';
import { hideErrorToast, showErrorToast } from './actions';

export interface GeneralReducerType {
  generalLoader: number;
  loader: { [key: string]: number };
  showError: boolean;
}

const initialState: GeneralReducerType = {
  generalLoader: 0,
  loader: {},
  showError: false,
};

const whitelistLoader: string[] = [AuthReducer.renew];

export const generalReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(showErrorToast, (state, action) => {
      state.showError = true;
    })
    .addCase(hideErrorToast, (state, action) => {
      state.showError = false;
    })

    .addMatcher(
      (action) => action.type.endsWith('/pending'),
      (state, action) => {
        if (!whitelistLoader.includes(action.type.replace('/pending', ''))) {
          state.generalLoader = state.generalLoader + 1;
        }
      }
    )
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected'),
      (state, action) => {
        if (!whitelistLoader.includes(action.type.replace(/\/(fulfilled|rejected)$/, ''))) {
          state.generalLoader = state.generalLoader - 1;
        }
      }
    );
});
