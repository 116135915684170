/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { AggregatedStrutturaInfoDtoFromJSON, DimAlberoCommercialeDtoFromJSON, DimAlberoCommercialeL1DtoFromJSON, DimAlberoDeliveryDtoFromJSON, DimAlberoDeliveryL1DtoFromJSON, OpportunityClosedEntityToJSON, ProjectPastDataDatailFromJSON, QuoteEntityToJSON, SavePastDataForProjectToJSON, SearchFilterDtoFromJSON, ServiceContractEntityToJSON, StrutturaFunzioneOutputDtoFromJSON, StrutturaOutputDtoFromJSON, StrutturaRuoloOutputDtoFromJSON, StrutturaUtenteFunzioneRuoloInputDtoToJSON, StrutturaUtenteFunzioneRuoloOutputDtoFromJSON, TipoStrutturaOutputDtoFromJSON, } from '../models/index';
/**
 *
 */
export class ControllerTecnicoApi extends runtime.BaseAPI {
    /**
     * Crea una nuova struttura utente funzione ruolo
     * Crea utente struttura funzione ruolo
     */
    createStrutturaUtenteFunzioneRuoloRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['strutturaUtenteFunzioneRuoloInputDto'] == null) {
                throw new runtime.RequiredError('strutturaUtenteFunzioneRuoloInputDto', 'Required parameter "strutturaUtenteFunzioneRuoloInputDto" was null or undefined when calling createStrutturaUtenteFunzioneRuolo().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/createUtenteStrutturaFunzioneRuolo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: StrutturaUtenteFunzioneRuoloInputDtoToJSON(requestParameters['strutturaUtenteFunzioneRuoloInputDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => StrutturaUtenteFunzioneRuoloOutputDtoFromJSON(jsonValue));
        });
    }
    /**
     * Crea una nuova struttura utente funzione ruolo
     * Crea utente struttura funzione ruolo
     */
    createStrutturaUtenteFunzioneRuolo(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createStrutturaUtenteFunzioneRuoloRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Elimina una struttura utente funzione ruolo in base all\'id utente, id funzione e id ruolo.
     * Elimina utente struttura funzione ruolo
     */
    deleteStrutturaUtenteFunzioneRuoloRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idFunzione'] == null) {
                throw new runtime.RequiredError('idFunzione', 'Required parameter "idFunzione" was null or undefined when calling deleteStrutturaUtenteFunzioneRuolo().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/deleteUtenteStrutturaFunzioneRuolo/{idFunzione}`.replace(`{${"idFunzione"}}`, encodeURIComponent(String(requestParameters['idFunzione']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Elimina una struttura utente funzione ruolo in base all\'id utente, id funzione e id ruolo.
     * Elimina utente struttura funzione ruolo
     */
    deleteStrutturaUtenteFunzioneRuolo(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteStrutturaUtenteFunzioneRuoloRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Recupera le strutture commerciali
     */
    getAlbBusinessRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['idStrutturaFunzione'] != null) {
                queryParameters['idStrutturaFunzione'] = requestParameters['idStrutturaFunzione'];
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/alberatura-business`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DimAlberoCommercialeDtoFromJSON));
        });
    }
    /**
     * Recupera le strutture commerciali
     */
    getAlbBusiness(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAlbBusinessRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera le strutture commerciali
     */
    getAlbBusinessNewRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['idStrutturaFunzione'] != null) {
                queryParameters['idStrutturaFunzione'] = requestParameters['idStrutturaFunzione'];
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/alberatura-business-new`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DimAlberoCommercialeL1DtoFromJSON));
        });
    }
    /**
     * Recupera le strutture commerciali
     */
    getAlbBusinessNew(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAlbBusinessNewRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera le strutture delivery
     */
    getAlbDeliveryRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['idStrutturaFunzione'] != null) {
                queryParameters['idStrutturaFunzione'] = requestParameters['idStrutturaFunzione'];
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/alberatura-delivery`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DimAlberoDeliveryDtoFromJSON));
        });
    }
    /**
     * Recupera le strutture delivery
     */
    getAlbDelivery(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAlbDeliveryRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera le strutture delivery
     */
    getAlbDeliveryNewRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['idStrutturaFunzione'] != null) {
                queryParameters['idStrutturaFunzione'] = requestParameters['idStrutturaFunzione'];
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/alberatura-delivery-new`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DimAlberoDeliveryL1DtoFromJSON));
        });
    }
    /**
     * Recupera le strutture delivery
     */
    getAlbDeliveryNew(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAlbDeliveryNewRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera i ruoli delle strutture
     * Recupera i ruoli delle strutture
     */
    getAllStrutturaRuoloRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/struttureRuolo`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StrutturaRuoloOutputDtoFromJSON));
        });
    }
    /**
     * Recupera i ruoli delle strutture
     * Recupera i ruoli delle strutture
     */
    getAllStrutturaRuolo(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllStrutturaRuoloRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutti i dettagli di tutte le strutture
     * Recupera tutte le strutture
     */
    getAllStruttureRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/strutture`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StrutturaOutputDtoFromJSON));
        });
    }
    /**
     * Recupera tutti i dettagli di tutte le strutture
     * Recupera tutte le strutture
     */
    getAllStrutture(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllStruttureRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutte le associazioni strutture funzione per scegliere quale inserire come idParent
     * Recupera tutte le associazioni strutture funzione
     */
    getAllStruttureFunzioneRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/struttureFunzione`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StrutturaFunzioneOutputDtoFromJSON));
        });
    }
    /**
     * Recupera tutte le associazioni strutture funzione per scegliere quale inserire come idParent
     * Recupera tutte le associazioni strutture funzione
     */
    getAllStruttureFunzione(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllStruttureFunzioneRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutti i tipi di struttura
     * Recupera tutti i tipi di struttura
     */
    getAllTipiStrutturaRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/tipiStruttura`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TipoStrutturaOutputDtoFromJSON));
        });
    }
    /**
     * Recupera tutti i tipi di struttura
     * Recupera tutti i tipi di struttura
     */
    getAllTipiStruttura(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllTipiStrutturaRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Restituisce un progetto con i dati di costi e plan consolidati fino a quel momento
     * Ricerca un progetto con relativo revenue plan e costi
     */
    getProjectWithRevenutAndCostsByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling getProjectWithRevenutAndCostsById().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/past-data/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProjectPastDataDatailFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce un progetto con i dati di costi e plan consolidati fino a quel momento
     * Ricerca un progetto con relativo revenue plan e costi
     */
    getProjectWithRevenutAndCostsById(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getProjectWithRevenutAndCostsByIdRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera le specifiche dei DTO di UtenteFunzioneRuolo e StrutturaFunzione dato un idFunzione
     * Recupera informazioni aggregate
     */
    getStrutturaInfoRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idFunzione'] == null) {
                throw new runtime.RequiredError('idFunzione', 'Required parameter "idFunzione" was null or undefined when calling getStrutturaInfo().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/getStrutturaInfo/{idFunzione}`.replace(`{${"idFunzione"}}`, encodeURIComponent(String(requestParameters['idFunzione']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AggregatedStrutturaInfoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera le specifiche dei DTO di UtenteFunzioneRuolo e StrutturaFunzione dato un idFunzione
     * Recupera informazioni aggregate
     */
    getStrutturaInfo(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getStrutturaInfoRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutti i possibili valori che un progetto può assumere
     * Possibili valori
     */
    projectFieldsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/projects-fields`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => SearchFilterDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti i possibili valori che un progetto può assumere
     * Possibili valori
     */
    projectFields(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectFieldsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Simula l\'evento di chiusura di una nuova quote Won or Closed
     * Close quote event
     */
    simulaCloseQuoteEventRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['opportunityClosedEntity'] == null) {
                throw new runtime.RequiredError('opportunityClosedEntity', 'Required parameter "opportunityClosedEntity" was null or undefined when calling simulaCloseQuoteEvent().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/close-opportunity`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: OpportunityClosedEntityToJSON(requestParameters['opportunityClosedEntity']),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Simula l\'evento di chiusura di una nuova quote Won or Closed
     * Close quote event
     */
    simulaCloseQuoteEvent(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.simulaCloseQuoteEventRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Simula l\'evento di creazione di una nuova quote da parte di salesforce
     * New quote event
     */
    simulaNewQuoteEventRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['quoteEntity'] == null) {
                throw new runtime.RequiredError('quoteEntity', 'Required parameter "quoteEntity" was null or undefined when calling simulaNewQuoteEvent().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/quote-event`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: QuoteEntityToJSON(requestParameters['quoteEntity']),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Simula l\'evento di creazione di una nuova quote da parte di salesforce
     * New quote event
     */
    simulaNewQuoteEvent(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.simulaNewQuoteEventRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Simula l\'evento di attivazione di un service contract
     * Service Contact event
     */
    simulaServiceContractEventRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['serviceContractEntity'] == null) {
                throw new runtime.RequiredError('serviceContractEntity', 'Required parameter "serviceContractEntity" was null or undefined when calling simulaServiceContractEvent().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/activate-service-contract`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ServiceContractEntityToJSON(requestParameters['serviceContractEntity']),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Simula l\'evento di attivazione di un service contract
     * Service Contact event
     */
    simulaServiceContractEvent(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.simulaServiceContractEventRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Aggiorna i costi HR per mese anno e progetto indicati
     * Aggiorna i costi HR
     * @deprecated
     */
    updateHrCostForProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling updateHrCostForProject().');
            }
            if (requestParameters['year'] == null) {
                throw new runtime.RequiredError('year', 'Required parameter "year" was null or undefined when calling updateHrCostForProject().');
            }
            if (requestParameters['month'] == null) {
                throw new runtime.RequiredError('month', 'Required parameter "month" was null or undefined when calling updateHrCostForProject().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/past-data/{projectId}/{year}/{month}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))).replace(`{${"year"}}`, encodeURIComponent(String(requestParameters['year']))).replace(`{${"month"}}`, encodeURIComponent(String(requestParameters['month']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProjectPastDataDatailFromJSON(jsonValue));
        });
    }
    /**
     * Aggiorna i costi HR per mese anno e progetto indicati
     * Aggiorna i costi HR
     * @deprecated
     */
    updateHrCostForProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateHrCostForProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Restituisce un progetto con i dati di costi e plan consolidati fino a quel momento
     * Salva revenue plan e costi
     * @deprecated
     */
    updateProjectWithRevenutAndCostsByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling updateProjectWithRevenutAndCostsById().');
            }
            if (requestParameters['savePastDataForProject'] == null) {
                throw new runtime.RequiredError('savePastDataForProject', 'Required parameter "savePastDataForProject" was null or undefined when calling updateProjectWithRevenutAndCostsById().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/tech/past-data/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SavePastDataForProjectToJSON(requestParameters['savePastDataForProject']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProjectPastDataDatailFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce un progetto con i dati di costi e plan consolidati fino a quel momento
     * Salva revenue plan e costi
     * @deprecated
     */
    updateProjectWithRevenutAndCostsById(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateProjectWithRevenutAndCostsByIdRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
