/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExternalCostDtoFromJSON, ExternalCostDtoToJSON, } from './ExternalCostDto';
import { RevenueMonthDtoFromJSON, RevenueMonthDtoToJSON, } from './RevenueMonthDto';
import { StaffInputDtoFromJSON, StaffInputDtoToJSON, } from './StaffInputDto';
/**
 * Check if a given object implements the UpdateCostsInputDto interface.
 */
export function instanceOfUpdateCostsInputDto(value) {
    return true;
}
export function UpdateCostsInputDtoFromJSON(json) {
    return UpdateCostsInputDtoFromJSONTyped(json, false);
}
export function UpdateCostsInputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'fullAllocations': json['fullAllocations'] == null ? undefined : (json['fullAllocations'].map(StaffInputDtoFromJSON)),
        'otherCosts': json['otherCosts'] == null ? undefined : (json['otherCosts'].map(ExternalCostDtoFromJSON)),
        'revenueDaConsolidare': json['revenueDaConsolidare'] == null ? undefined : RevenueMonthDtoFromJSON(json['revenueDaConsolidare']),
    };
}
export function UpdateCostsInputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'fullAllocations': value['fullAllocations'] == null ? undefined : (value['fullAllocations'].map(StaffInputDtoToJSON)),
        'otherCosts': value['otherCosts'] == null ? undefined : (value['otherCosts'].map(ExternalCostDtoToJSON)),
        'revenueDaConsolidare': RevenueMonthDtoToJSON(value['revenueDaConsolidare']),
    };
}
