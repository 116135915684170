/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectCostTypeFromJSON, ProjectCostTypeToJSON, } from './ProjectCostType';
/**
 * Check if a given object implements the CostType interface.
 */
export function instanceOfCostType(value) {
    return true;
}
export function CostTypeFromJSON(json) {
    return CostTypeFromJSONTyped(json, false);
}
export function CostTypeFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'value': json['value'] == null ? undefined : json['value'],
        'description': json['description'] == null ? undefined : json['description'],
        'projectCostTypes': json['projectCostTypes'] == null ? undefined : (json['projectCostTypes'].map(ProjectCostTypeFromJSON)),
        'duplicable': json['duplicable'] == null ? undefined : json['duplicable'],
    };
}
export function CostTypeToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'value': value['value'],
        'description': value['description'],
        'projectCostTypes': value['projectCostTypes'] == null ? undefined : (value['projectCostTypes'].map(ProjectCostTypeToJSON)),
        'duplicable': value['duplicable'],
    };
}
