import { useState } from 'react';
import StructureCrudForm from './structure-crud-form';
import { StrutturaUtenteFunzioneRuoloInputDto } from '@api/client';
import { useAppDispatch } from '../../../../core/store/hooks';
import { crudDeleteStructure, crudGetStructureDetail } from '../_redux/actions';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import AppCard from '../../../../shared/design-system/components/app-card';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import './styles.scss';
import { AppToastService } from '../../../../shared/design-system/components/app-toast/app-toast.service';
import AppModal from '../../../../shared/design-system/components/app-modal';
import { useTranslation } from 'react-i18next';
import useGetStructureTrees from '../_hooks/useGetStructureTrees';
import { StructureTypes } from '../../../../enums/global.enum';

interface Props {
  structureType: StructureTypes;
}

const StructureCrud: React.FC<Props> = ({ structureType }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [editData, setEditData] = useState<StrutturaUtenteFunzioneRuoloInputDto | undefined>(undefined);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined);

  const { mappedBusinessTrees, mappedDeliveryTrees, getStructures } = useGetStructureTrees(structureType);

  const actionTemplate = (rowData: any) => {
    return (
      <div className="d-flex">
        <button
          onClick={() => {
            handleEdit(rowData.data.idStrutturaFunzione);
          }}
          className="btn-icon"
        >
          <i className={'icon-edit text-blue-links me-1'}></i>
        </button>
        <button
          onClick={() => {
            setShowConfirmDelete(true);
            setIdToDelete(rowData.data.idStrutturaFunzione);
          }}
          className="btn-icon"
        >
          <i className={'icon-delete'} style={{ color: 'red' }}></i>
        </button>
      </div>
    );
  };

  const handleEdit = (id: number) => {
    dispatch(crudGetStructureDetail(id))
      .unwrap()
      .then((res) => {
        if (res) {
          const mappedRes: StrutturaUtenteFunzioneRuoloInputDto = {
            nome: res.strutturaFunzioneOutputDto?.nome,
            idUtente: res.strutturaUtenteFunzioneRuoloOutputDto?.idUtente,
            idRuolo: res.strutturaUtenteFunzioneRuoloOutputDto?.idRuolo?.id,
            idParent: res.strutturaFunzioneOutputDto?.idParent?.id,
            idStruttura: res.strutturaFunzioneOutputDto?.idStruttura?.id,
          };
          setEditData(mappedRes);
        }
      });
  };

  const handleDelete = (id: number) => {
    dispatch(crudDeleteStructure(id)).then(() => {
      AppToastService.success('Structure deleted');
      setShowConfirmDelete(false);
      getStructures();
    });
  };

  return (
    <>
      <StructureCrudForm structure={editData} />
      <AppCard title="Structures" className="mt-3">
        <PrimeReactProvider>
          <div className="tree-table">
            <TreeTable
              value={structureType === StructureTypes.BUSINESS ? mappedBusinessTrees : mappedDeliveryTrees}
              resizableColumns
              showGridlines
              tableStyle={{ minWidth: '50rem' }}
            >
              <Column style={{ width: '150px' }} field="idStrutturaFunzione" header="ID" expander></Column>
              <Column field="nomeStrutturaFunzione" header="Name"></Column>
              <Column field="nomeResp" header="User"></Column>
              <Column field="emailResp" header="Email"></Column>
              <Column header="Actions" body={actionTemplate} style={{ width: '120px' }} />
            </TreeTable>
          </div>
        </PrimeReactProvider>
      </AppCard>
      <AppModal
        setShow={setShowConfirmDelete}
        show={showConfirmDelete}
        title={t('common.attention')}
        cancelText={t('common.cancel')}
        confirmText={t('common.confirm')}
        onConfirm={() => handleDelete(idToDelete as number)}
        onCancel={() => setShowConfirmDelete(false)}
      >
        <p>{'Are you sure you want to delete this structure?'}</p>
      </AppModal>
    </>
  );
};

export default StructureCrud;
