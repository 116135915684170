import { Card } from 'react-bootstrap';
import { TotalsGroup } from '../../_hooks/projects-hooks/useGetProjectTotals';
import './styles.scss';
import { Col, Tooltip } from 'antd';

interface Props {
  totalsGroup: TotalsGroup;
}
const TotalsCard: React.FC<Props> = ({ totalsGroup }) => {
  return (
    <Col xs={totalsGroup.col}>
      <Card className="totals-card-dashboard h-100">
        <Card.Header className="totals-card-dashboard-header" style={{ backgroundColor: totalsGroup.color }}>
          <b>{totalsGroup.group}</b>
        </Card.Header>
        <Card.Body className="totals-card-dashboard-body-container" style={{ borderTopColor: totalsGroup.color }}>
          <div className={`d-grid ${totalsGroup.data.length === 4 ? 'totals-card-dashboard-body' : ''}`}>
            {totalsGroup.data.map((total, index) => (
              <div key={index} className="span-values ">
                <div className="head">{total.label}</div>
                <div className="value">
                  {total.value}
                  {total.span}
                  {total.tooltip && (
                    <Tooltip
                      title={<span style={{ whiteSpace: 'pre-wrap' }}>{total.tooltip}</span>}
                      color={totalsGroup.color}
                    >
                      <i className="fas fa-info-circle ms-1"></i>
                    </Tooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default TotalsCard;
