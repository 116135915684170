import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from '../../../../core/store';
import {
  CicloPassivoFieldsDto,
  Configuration,
  ContractOrderOutputDto,
  ContractOrderOutputListDto,
  ContractOrderSearchDto,
  ControllerPerLeFunzioniDedicateAlCicloPassivoApi,
  CreateCicloPassivoRequest,
  GetProjectsRequest,
  ProjectCicloPassivoOutputListDto,
  RemoveExternalCosts1Request,
  SearchContractOrdersRequest,
  UpdateContractAndAssociateExternalCostsRequest,
} from '@api/client';
import getConfiguration, { errorHandler } from '../../../../core/store/config';

export enum PassiveCycleActions {
  getPassiveCycleOptions = '[PassiveCycle] Get PassiveCycle Options',
  createOrder = '[PassiveCycle] Create Order',
  searchOrders = '[PassiveCycle] Search Orders',
  updateOrder = '[PassiveCycle] Update Order',
  deleteOrder = '[PassiveCycle] Delete Order',
  getOtherAssociatibleCosts = '[PassiveCycle] Get Other Associatible Costs',
  saveFiltersOrdersUsed = '[PassiveCycle] Save Filters Orders Used',
  removeOtherCosts = '[PassiveCycle] Remove Other Costs',
}

export const getPassiveCycleOptions = createAsyncThunk<CicloPassivoFieldsDto | undefined, void, ThunkApiConfig>(
  PassiveCycleActions.getPassiveCycleOptions,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
        getConfiguration(Configuration, extra, getState)
      ).getcicloPassivoFields();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const createOrder = createAsyncThunk<
  ContractOrderOutputDto | undefined,
  CreateCicloPassivoRequest,
  ThunkApiConfig
>(PassiveCycleActions.createOrder, async (request, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
      getConfiguration(Configuration, extra, getState)
    ).createCicloPassivo(request);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const searchOrders = createAsyncThunk<
  ContractOrderOutputListDto | undefined,
  SearchContractOrdersRequest,
  ThunkApiConfig
>(PassiveCycleActions.searchOrders, async (request, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
      getConfiguration(Configuration, extra, getState)
    ).searchContractOrders(request);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const updateOrder = createAsyncThunk<
  ContractOrderOutputDto | undefined,
  UpdateContractAndAssociateExternalCostsRequest,
  ThunkApiConfig
>(PassiveCycleActions.updateOrder, async (request, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
      getConfiguration(Configuration, extra, getState)
    ).updateContractAndAssociateExternalCosts(request);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const deleteOrder = createAsyncThunk<number | undefined, number, ThunkApiConfig>(
  PassiveCycleActions.deleteOrder,
  async (id, { dispatch, extra, getState }) => {
    try {
      await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
        getConfiguration(Configuration, extra, getState)
      ).deleteContractAndAssociations({ contractOrderId: id });
      return id;
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getOtherAssociatibleCosts = createAsyncThunk<
  ProjectCicloPassivoOutputListDto | undefined,
  GetProjectsRequest,
  ThunkApiConfig
>(PassiveCycleActions.getOtherAssociatibleCosts, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
      getConfiguration(Configuration, extra, getState)
    ).getProjects(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const removeOtherCosts = createAsyncThunk<
  ContractOrderOutputDto | undefined,
  RemoveExternalCosts1Request,
  ThunkApiConfig
>(PassiveCycleActions.removeOtherCosts, async (payload, { dispatch, extra, getState }) => {
  try {
    return await new ControllerPerLeFunzioniDedicateAlCicloPassivoApi(
      getConfiguration(Configuration, extra, getState)
    ).removeExternalCosts1(payload);
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const saveFiltersOrdersUsed = createAction<ContractOrderSearchDto>(PassiveCycleActions.saveFiltersOrdersUsed);
