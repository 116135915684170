/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AllocationDto interface.
 */
export function instanceOfAllocationDto(value) {
    return true;
}
export function AllocationDtoFromJSON(json) {
    return AllocationDtoFromJSONTyped(json, false);
}
export function AllocationDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'actualAllocation': json['actualAllocation'] == null ? undefined : json['actualAllocation'],
        'extimatedAllocation': json['extimatedAllocation'] == null ? undefined : json['extimatedAllocation'],
        'year': json['year'] == null ? undefined : json['year'],
        'month': json['month'] == null ? undefined : json['month'],
        'creationDate': json['creationDate'] == null ? undefined : (new Date(json['creationDate'])),
        'lastmodifiedDate': json['lastmodifiedDate'] == null ? undefined : (new Date(json['lastmodifiedDate'])),
        'realTimeAllocation': json['realTimeAllocation'] == null ? undefined : json['realTimeAllocation'],
        'editEnable': json['editEnable'] == null ? undefined : json['editEnable'],
    };
}
export function AllocationDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'actualAllocation': value['actualAllocation'],
        'extimatedAllocation': value['extimatedAllocation'],
        'year': value['year'],
        'month': value['month'],
        'creationDate': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'lastmodifiedDate': value['lastmodifiedDate'] == null ? undefined : ((value['lastmodifiedDate']).toISOString()),
        'realTimeAllocation': value['realTimeAllocation'],
        'editEnable': value['editEnable'],
    };
}
