/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the StrutturaUtenteFunzioneRuoloInputDto interface.
 */
export function instanceOfStrutturaUtenteFunzioneRuoloInputDto(value) {
    return true;
}
export function StrutturaUtenteFunzioneRuoloInputDtoFromJSON(json) {
    return StrutturaUtenteFunzioneRuoloInputDtoFromJSONTyped(json, false);
}
export function StrutturaUtenteFunzioneRuoloInputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idFunzione': json['idFunzione'] == null ? undefined : json['idFunzione'],
        'idUtente': json['idUtente'] == null ? undefined : json['idUtente'],
        'idRuolo': json['idRuolo'] == null ? undefined : json['idRuolo'],
        'idParent': json['idParent'] == null ? undefined : json['idParent'],
        'idStruttura': json['idStruttura'] == null ? undefined : json['idStruttura'],
        'nome': json['nome'] == null ? undefined : json['nome'],
    };
}
export function StrutturaUtenteFunzioneRuoloInputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idFunzione': value['idFunzione'],
        'idUtente': value['idUtente'],
        'idRuolo': value['idRuolo'],
        'idParent': value['idParent'],
        'idStruttura': value['idStruttura'],
        'nome': value['nome'],
    };
}
