import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../core/store/hooks';
import { Modal, Table } from 'react-bootstrap';
import {
  CdgProjectOtherCostsUpdateRequest,
  ClProjectOtherCostsUpdateRequest,
  ExternalCostAllocationDto,
  ExternalCostDto,
  OtherCostListDto,
} from '@api/client';
import LinksButton from '../../../../shared/design-system/components/links-button';
import { Input, InputNumber, Select } from 'antd';
import { filterOption, getFormattedDateHeader } from '../../../../shared/utils/common.utils';
import useHandleOtherCosts from '../../../../shared/_hooks/useHandleOtherCosts';
import { Session } from '../../../../enums/project.enum';
import NoData from '../../../../shared/design-system/components/no-data-box';
import useGetSessionActions from '../_hooks/useGetSessionActions';

interface OtherCostsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSaveClose: () => void;
  otherCostsFromService: OtherCostListDto | undefined;
  session: Session;
  idProject: number | null;
}

const OtherCostsModal: React.FC<OtherCostsModalProps> = ({
  isOpen,
  onClose,
  onSaveClose,
  otherCostsFromService,
  session,
  idProject,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    otherCosts,
    setOtherCosts,
    costList,
    getCostTypeDescriptions,
    getValue,
    handleChangeCost,
    handleChangeDescription,
    addCost,
  } = useHandleOtherCosts(otherCostsFromService);

  const { saveOtherCostsAction } = useGetSessionActions(session);

  const save = () => {
    if (!otherCosts || !idProject) return;

    const otherCostsMapped = otherCosts.map((el) => ({
      ...el,
      costAllocation: el.costAllocation
        ?.filter((alloc) => otherCostsFromService?.otherCosts?.length || alloc.actualCost !== 0)
        .map((alloc) => ({
          ...alloc,
          actualCost: Number(alloc.actualCost),
        })),
    }));

    const payload: CdgProjectOtherCostsUpdateRequest | ClProjectOtherCostsUpdateRequest = {
      idProject,
      otherCostListDto: {
        otherCosts: otherCostsMapped,
      },
    };
    // console.log(payload);
    dispatch(saveOtherCostsAction(payload))
      .unwrap()
      .then((_) => onSaveClose());
  };

  const isPL = session === Session.PL;

  return (
    <Modal title={t('project-detail.external-costs')} show={isOpen} onHide={onClose} size="xl" centered>
      <Modal.Header>
        <Modal.Title>{t('project-detail.external-costs')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isPL && (
          <div className="d-flex justify-content-start mb-3" id="header-buttons">
            <LinksButton className="btn-sm btn btn-links-outline px-3" disabled={!costList?.length} onClick={addCost}>
              <div className="d-flex align-items-center">
                <i className="fas fa-plus pe-2" />
                {t('project-detail.add-cost')}
              </div>
            </LinksButton>
          </div>
        )}
        {otherCosts?.length === 0 ? (
          <NoData msg={t('project-detail.no-costs')} />
        ) : (
          <div className="d-flex align-items-start table-responsive table-bordered" style={{ height: '230px' }}>
            <Table className={'revenue-table sticky-table mt-0 mb-0'}>
              <thead className="fixed-sticky-header">
                <tr>
                  <th className="text-left py-0" colSpan={2} style={{ minWidth: '170px', maxWidth: '170px' }}>
                    <span>{t('crud.crud-revenue-costs.table-title')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {otherCosts?.map((cost, index) => (
                  <tr key={'cost-' + index}>
                    <td className="text-left p-0" style={{ minWidth: '310px', maxWidth: '310px' }}>
                      <Select
                        className="w-100"
                        placeholder={t('project-detail.cost-type-amount-placeholder')}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        value={getValue(cost)}
                        variant="borderless"
                        getPopupContainer={(triggerMode) => {
                          return triggerMode.parentNode as HTMLElement;
                        }}
                        disabled={cost.costAllocation?.every((el) => (el.id ?? 0) >= 0) || isPL}
                        onChange={(e) => handleChangeCost(e, index)}
                      >
                        {costList?.map((el) => (
                          <Select.Option key={`${el.id}-${index}`} value={el.id}>
                            {getCostTypeDescriptions(el.id)}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    <td className="text-left p-0" style={{ minWidth: '270px', maxWidth: '270px' }}>
                      <Input
                        value={cost?.description}
                        placeholder={t('project-detail.cost-description-placeholder')}
                        onChange={(e) => handleChangeDescription(e.target.value, index)}
                        variant="borderless"
                        className="h-100"
                        disabled={isPL}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Table className={'revenue-table mt-0 mb-0'}>
              <thead>
                <tr className="fixed-scroll-header">
                  {otherCosts?.[0]?.costAllocation?.map((allocation, index: number) => (
                    <th
                      className={`text-left py-0 ${!allocation?.editEnabled && 'bg-disabled'}`}
                      scope="col"
                      key={'GU-' + index}
                    >
                      <span>{getFormattedDateHeader(allocation?.mese, allocation?.anno)}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {otherCosts?.map((cost: ExternalCostDto, index: number) => (
                  <tr key={'cost-' + index}>
                    {cost?.costAllocation?.map((allocation: ExternalCostAllocationDto, index: number) => (
                      <td
                        className={`text-center p-0 ${!allocation?.editEnabled && 'bg-disabled'}`}
                        key={'cost-' + index}
                        style={{ minWidth: '100px', maxWidth: '100px' }}
                      >
                        <InputNumber
                          className="w-100"
                          key={'cost-per-month-' + index}
                          value={Number(allocation.actualCost?.toFixed(2)) ?? 0}
                          decimalSeparator=","
                          min={0}
                          readOnly={!allocation.editEnabled}
                          onChange={(e) => {
                            const newAllocations = otherCosts?.map((el) => ({
                              ...el,
                              costAllocation: el.costAllocation?.map((alloc) => ({
                                ...alloc,
                                actualCost: alloc.id === allocation.id ? Number(e) : alloc.actualCost,
                              })),
                            }));
                            setOtherCosts(newAllocations);
                          }}
                          variant="borderless"
                          disabled={isPL}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isPL ? (
          <LinksButton className="btn btn-outline-danger px-4" onClick={onClose}>
            {t('common.close')}
          </LinksButton>
        ) : (
          <>
            <LinksButton className="btn btn-outline-danger px-4" onClick={onClose}>
              {t('common.cancel')}
            </LinksButton>
            <LinksButton className="btn btn-links" onClick={save}>
              {t('common.save')}
            </LinksButton>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default OtherCostsModal;
