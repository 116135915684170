import { useState } from 'react';
import { useAppDispatch } from '../../../../core/store/hooks';
import { Session } from '../../../../enums/project.enum';
import { OtherCostListDto } from '@api/client';
import useGetSessionActions from './useGetSessionActions';

interface Props {
  session: Session;
  consolidateRevenues: (reload?: boolean | undefined, projectId?: number | undefined) => void;
}

const useOtherCostsModal = ({ session, consolidateRevenues }: Props) => {
  const dispatch = useAppDispatch();
  const [otherCosts, setOtherCosts] = useState<OtherCostListDto | undefined>(undefined);
  const [showCostsModal, setShowCostsModal] = useState(false);
  const [idCurrentProject, setIdCurrentProject] = useState<number | null>(null);

  const { getOtherCostsAction } = useGetSessionActions(session);

  const openCostsModal = (id: number) => {
    dispatch(getOtherCostsAction(id))
      .unwrap()
      .then((res) => {
        if (res) {
          setOtherCosts(res);
          setShowCostsModal(true);
          setIdCurrentProject(id);
        }
      });
  };

  const handleSaveClose = () => {
    consolidateRevenues(true);
    setShowCostsModal(false);
  };

  return {
    openCostsModal,
    handleSaveClose,
    showCostsModal,
    setShowCostsModal,
    otherCosts,
    idCurrentProject,
  };
};

export default useOtherCostsModal;
