import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import LinksButton from '../links-button';

interface Props {
  data: any[];
  fileName: string;
}

const ExcelExport: React.FC<Props> = ({ data, fileName }) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <LinksButton className="btn-sm btn btn-links-outline px-3" onClick={exportToExcel}>
      <i className="fa-solid fa-table me-2" />
      Export to Excel
    </LinksButton>
  );
};

export default ExcelExport;
