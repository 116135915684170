import { ExternalCostDto, MeseAnnoLight, RevenuePlanDto, StaffExtendedDto, StaffInputDto, UserDto } from '@api/client';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../core/store/hooks';
import { saveTeamData } from '../../_redux/actions';
import { sortByMonthAndYear } from '../../../../../../shared/utils/common.utils';
import { allUsersSelector, teamDataSelector } from '../../_redux/selectors';

const useGetTeamTableData = (
  staffList: StaffExtendedDto[] | undefined,
  revenuePlan: RevenuePlanDto[] | undefined,
  mesiProgetto: MeseAnnoLight[] | undefined,
  otherCosts: ExternalCostDto[] | undefined
) => {
  const dispatch = useAppDispatch();
  const teamDataFromStore = useAppSelector(teamDataSelector);
  const allUsers = useAppSelector(allUsersSelector);
  const [fullAllocations, setFullAllocations] = useState<Partial<StaffExtendedDto>[] | null | undefined>(
    teamDataFromStore
  );
  const [fullAllocationsShown, setFullAllocationsShown] = useState<Partial<StaffExtendedDto>[] | null | undefined>(
    teamDataFromStore
  );
  const [totalsRevenuePlan, setTotalsRevenuePlan] = useState<RevenuePlanDto[] | null | undefined>(null);
  const [totalsRevenuePlanShown, setTotalsRevenuePlanShown] = useState<RevenuePlanDto[] | null | undefined>(null);
  const [combinationRoles, setCombinationRoles] = useState<StaffInputDto[] | null | undefined>(null);
  const [collaborators, setCollaborators] = useState<UserDto[]>(allUsers as UserDto[]);
  const [years, setYears] = useState<Array<number | undefined>>([]);
  const [currentYear, setCurrentYear] = useState<number | 'all'>('all');
  const [totalGUPlanned, setTotalGUPlanned] = useState<number[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  // inizializza la tabella
  useEffect(() => {
    if (!fullAllocations || refresh) {
      const mappedAllocations = staffList?.map((el) => ({
        idPps: el.idPps,
        idPpt: el.idPpt,
        userId: el.userId || undefined,
        name: el.name,
        allocazioni: el.allocazioni
          ?.map((el) => ({
            id: el.id || -Math.floor(Math.random() * 100000),
            month: el.month ?? 0,
            year: el.year,
            actualAllocation: el.actualAllocation,
            editEnable: el.editEnable,
            realTimeAllocation: el.realTimeAllocation,
          }))
          .sort((a, b) => {
            // Ordina per anno
            if (a.year && b.year && a.year !== b.year) {
              return a.year - b.year;
            }
            // Se gli anni sono uguali, ordina per mese
            return (a.month ?? 0) - (b.month ?? 0);
          }),
      }));
      setFullAllocations(mappedAllocations);
      setFullAllocationsShown(mappedAllocations);

      const yearsToSet: Array<number | undefined> = [];

      mappedAllocations?.[0]?.allocazioni?.forEach((alloc) => {
        if (!yearsToSet.includes(alloc?.year)) {
          yearsToSet.push(alloc?.year);
        }
      });
      setYears(yearsToSet);
      setRefresh(false);
    }
  }, [staffList, refresh]);

  useEffect(() => {
    let completeRevenuePlan: RevenuePlanDto[] | undefined;
    if (staffList?.length && staffList[0].allocazioni?.length) {
      completeRevenuePlan = mergeRevenuePlanWithStaffList();
    } else if (otherCosts?.length && otherCosts[0].costAllocation?.length) {
      completeRevenuePlan = mergeRevenuePlanWithOtherCosts();
    } else {
      completeRevenuePlan = mergeRevenuePlanWithMesiProgetto();
    }
    const clone = completeRevenuePlan ? [...completeRevenuePlan] : [];
    const sortedRevenuePlan = sortByMonthAndYear(clone);

    setTotalsRevenuePlan(sortedRevenuePlan);
    setTotalsRevenuePlanShown(sortedRevenuePlan);
  }, [revenuePlan]);

  // mi setta le combinazioni uniche di idPps e idPpt e mi ricalcola i totali giorni uomo
  useEffect(() => {
    handleCombinationsRoles();
    calculateTotalGU();
    dispatch(saveTeamData(fullAllocations));
  }, [fullAllocations]);

  useEffect(() => {
    setCollaborators(allUsers as UserDto[]);
  }, [allUsers]);

  // nel caso cancello l'ultimo collaboratore, mi permette di fare il refresh della tabella
  // useEffect(() => {
  //   // ho questa casistica quando cancellando l'ultimo collaboratore,
  //   //mantengo il revenuePlan valorizzato e parte il metodo startPlanningInTable() nell'hook useProjectActions
  //   if (staffList?.length === 1 && fullAllocations?.length === 1) {
  //     const canRefresh =
  //       !staffList?.[0].idPps &&
  //       !staffList?.[0].idPpt &&
  //       staffList?.[0].idPps !== fullAllocations?.[0].idPps &&
  //       staffList?.[0].idPpt !== fullAllocations?.[0].idPpt;
  //     if (canRefresh) {
  //       setRefresh(true);
  //     }
  //   }
  // }, [staffList]);

  const mergeRevenuePlanWithStaffList = () => {
    if (
      staffList &&
      staffList[0].allocazioni?.length &&
      revenuePlan &&
      staffList[0].allocazioni?.length > revenuePlan.length
    ) {
      const clone = [...revenuePlan];
      staffList[0].allocazioni?.forEach((mese) => {
        const found = clone.find((el) => el.year === mese.year && el.month === mese.month);
        if (!found) {
          clone.push({
            year: mese.year,
            month: mese.month,
            extimatedAmount: 0,
            extimatedRate: 0,
            extimatedCost: 0,
            extimatedHrCost: 0,
            extimatedExternalCost: 0,
            extimatedFte: 0,
            extimatedDays: 0,
            actualAmount: 0,
            actualRate: 0,
            actualCost: 0,
            actualHrCost: 0,
            actualExternalCost: 0,
            actualFte: 0,
            actualDays: 0,
            consolidato: !mese.editEnable,
          });
        }
      });
      return clone;
    }
    return revenuePlan;
  };

  const mergeRevenuePlanWithOtherCosts = () => {
    if (
      otherCosts &&
      otherCosts[0].costAllocation?.length &&
      revenuePlan &&
      otherCosts[0].costAllocation?.length > revenuePlan.length
    ) {
      const clone = [...revenuePlan];
      otherCosts[0].costAllocation?.forEach((mese) => {
        const found = clone.find((el) => el.year === mese.anno && el.month === mese.mese);
        if (!found) {
          clone.push({
            year: mese.anno,
            month: mese.mese,
            extimatedAmount: 0,
            extimatedRate: 0,
            extimatedCost: 0,
            extimatedHrCost: 0,
            extimatedExternalCost: 0,
            extimatedFte: 0,
            extimatedDays: 0,
            actualAmount: 0,
            actualRate: 0,
            actualCost: 0,
            actualHrCost: 0,
            actualExternalCost: 0,
            actualFte: 0,
            actualDays: 0,
            consolidato: !mese.editEnabled,
          });
        }
      });
      return clone;
    }
    return revenuePlan;
  };

  const mergeRevenuePlanWithMesiProgetto = () => {
    //controllo se mesiProgetto è valorizzato e ha la stessa lunghezza di revenuePlan. Se mesi progetto è più lungo di revenuePlan, aggiungo i mesi mancanti a revenuePlan
    if (mesiProgetto && revenuePlan && mesiProgetto.length > revenuePlan.length) {
      const clone = [...revenuePlan];
      mesiProgetto.forEach((mese) => {
        const found = clone.find((el) => el.year === mese.anno && el.month === mese.mese);
        if (!found) {
          clone.push({
            year: mese.anno,
            month: mese.mese,
            extimatedAmount: 0,
            extimatedRate: 0,
            extimatedCost: 0,
            extimatedHrCost: 0,
            extimatedExternalCost: 0,
            extimatedFte: 0,
            extimatedDays: 0,
            actualAmount: 0,
            actualRate: 0,
            actualCost: 0,
            actualHrCost: 0,
            actualExternalCost: 0,
            actualFte: 0,
            actualDays: 0,
            consolidato: mese.consolidato,
          });
        }
      });
      return clone;
    }
    return revenuePlan;
  };

  const areCombinationsChanged = (arr1: any[], arr2: StaffInputDto[] | null | undefined) => {
    if (arr1.length !== arr2?.length) {
      return true;
    }
    return !arr1.every((obj1, index) => compareObjects(obj1, arr2?.[index]));
  };

  const compareObjects = (obj1: any, obj2: any) => {
    if (!obj1?.idPps || !obj1?.idPpt) return true;
    return obj1.idPps == obj2.idPps && obj1.idPpt == obj2.idPpt;
  };

  const filterAllocationsByYear = (
    year: number | 'all',
    allocations: Partial<StaffExtendedDto>[] | null | undefined = fullAllocations,
    revenuePlan: RevenuePlanDto[] | null | undefined = totalsRevenuePlan
  ): void => {
    setCurrentYear(year);
    if (year === 'all') {
      setFullAllocationsShown(allocations);
      setTotalsRevenuePlanShown(revenuePlan);
    } else {
      const filteredAllocations = allocations?.map((el) => ({
        ...el,
        allocazioni: el.allocazioni?.filter((alloc) => alloc.year === year),
      }));
      setFullAllocationsShown(filteredAllocations);
      setTotalsRevenuePlanShown(revenuePlan?.filter((el) => el.year === year));
    }
  };

  const handleCombinationsRoles = () => {
    const uniqueCombinations: Record<string, boolean> = {};

    fullAllocations?.forEach((el) => {
      const key = `${el.idPps}-${el.idPpt}`;
      if (!uniqueCombinations[key]) {
        uniqueCombinations[key] = true;
      }
    });

    const combinations = Object.keys(uniqueCombinations).map((el) => {
      const [idPps, idPpt] = el.split('-');
      return {
        idPps: Number(idPps),
        idPpt: Number(idPpt),
      };
    });
    if (areCombinationsChanged(combinations, combinationRoles)) {
      setCombinationRoles(combinations);
    }
  };

  const calculateTotalGU = () => {
    const totalGUPlanned: number[] = [];
    fullAllocations?.forEach((allocation) => {
      let totalGU = 0;
      allocation.allocazioni?.forEach((alloc) => {
        if (alloc.editEnable) {
          totalGU += alloc.actualAllocation ?? 0;
        }
      });
      totalGUPlanned.push(totalGU);
    });
    setTotalGUPlanned(totalGUPlanned);
  };

  return {
    fullAllocations,
    setFullAllocations,
    fullAllocationsShown,
    totalsRevenuePlanShown,
    refresh,
    combinationRoles,
    collaborators,
    years,
    setYears,
    filterAllocationsByYear,
    currentYear,
    totalGUPlanned,
    setTotalGUPlanned,
    totalsRevenuePlan,
    setTotalsRevenuePlan,
    setTotalsRevenuePlanShown,
    setRefresh,
  };
};

export default useGetTeamTableData;
