import { Button, Modal } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LinksButton from '../../../../../../shared/design-system/components/links-button';
interface ModalActionProps {
  children: React.ReactNode;
  title: string;
  actionLabel: string;
  cancelLabel?: string;
  action: any;
  index?: number;
  setShow: (show: boolean) => void;
  show: boolean;
  size?: 'sm' | 'lg' | 'xl';
}

const ModalAction: React.FC<ModalActionProps> = ({
  title,
  actionLabel,
  cancelLabel,
  children,
  action,
  index,
  setShow,
  show,
  size,
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    action(index);
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} size={size}>
        <Modal.Header closeButton>
          <Modal.Title className="title-modal-s" style={{ backgroundColor: 'f3f4f7', fontSize: '1em' }}>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <LinksButton className="btn btn-outline-danger px-3" onClick={() => setShow(false)}>
            {cancelLabel || t('project-detail.close')}
          </LinksButton>
          <Button className="btn-primary btn-links" onClick={() => handleClose()}>
            {actionLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAction;
