/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DashboardBookingDtoFromJSON, DashboardBookingDtoToJSON, } from './DashboardBookingDto';
/**
 * Check if a given object implements the DashboardBookingListDto interface.
 */
export function instanceOfDashboardBookingListDto(value) {
    return true;
}
export function DashboardBookingListDtoFromJSON(json) {
    return DashboardBookingListDtoFromJSONTyped(json, false);
}
export function DashboardBookingListDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'dashboardBookingDtoList': json['dashboardBookingDtoList'] == null ? undefined : (json['dashboardBookingDtoList'].map(DashboardBookingDtoFromJSON)),
        'personCount': json['personCount'] == null ? undefined : json['personCount'],
        'totalNfte': json['totalNfte'] == null ? undefined : json['totalNfte'],
    };
}
export function DashboardBookingListDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'dashboardBookingDtoList': value['dashboardBookingDtoList'] == null ? undefined : (value['dashboardBookingDtoList'].map(DashboardBookingDtoToJSON)),
        'personCount': value['personCount'],
        'totalNfte': value['totalNfte'],
    };
}
