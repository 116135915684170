import './App.scss';
import { Provider } from 'react-redux';
import { store } from './core/store';
import AppRouter from './AppRouter';
import AppToast from './shared/design-system/components/app-toast';
import { useEffect } from 'react';
import AppScrollToTop from './shared/design-system/components/app-scoll-to-top';

function App() {
  return (
    <Provider store={store}>
      <div id="page-top" className="app-body">
        <AppRouter />
        <AppToast />
        {/* <AppScrollToTop /> */}
      </div>
    </Provider>
  );
}

export default App;
