import { BillingDto, StatoAvanzamentoFieldsDto } from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import { getBillingFields, getBillingPlan, resetBillingPlan } from './actions';

interface InitialState {
  billingData: BillingDto[] | undefined;
  billingFields: StatoAvanzamentoFieldsDto | undefined;
  isBillEditEnabled: boolean;
}

const initialState: InitialState = {
  billingData: undefined,
  billingFields: undefined,
  isBillEditEnabled: false,
};

export const billingPlanReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getBillingPlan.fulfilled, (state, action) => {
      state.billingData = action.payload?.billings?.sort((a, b) => (b.date?.getTime() ?? 0) - (a.date?.getTime() ?? 0));
      state.isBillEditEnabled = action.payload?.isEditable ?? false;
    })
    .addCase(resetBillingPlan, (state) => {
      state.billingData = undefined;
    })
    .addCase(getBillingFields.fulfilled, (state, action) => {
      state.billingFields = action.payload;
    });
});
