import React from 'react';
import { Card } from 'react-bootstrap';

interface Props {
  title?: string;
  children: React.ReactNode;
  className?: string;
  bodyClass?: string;
  style?: any;
  color?: string;
  headerClassname?: string;
}

const AppCard = ({ title, children, className, bodyClass, style, color, headerClassname }: Props) => {
  return (
    <Card className={className} style={style}>
      <Card.Header className={`card-header-border ${headerClassname}`}>
        <b style={{ color: color }}>{title}</b>
      </Card.Header>
      <Card.Body className={bodyClass}>{children}</Card.Body>
    </Card>
  );
};

export default AppCard;
