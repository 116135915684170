import { Card, Col, Row, Table } from 'react-bootstrap';
import AppCard from '../../../../shared/design-system/components/app-card';
import { useTranslation } from 'react-i18next';
import { DatePicker, DatePickerProps, Input, InputNumber, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../core/store/hooks';
import { getCosts } from '../../project-list/project-detail/_redux/actions';
import { costiSelector } from '../../project-list/project-detail/_redux/selectors';
import {
  ExternalCostAllocationDto,
  ExternalCostDto,
  ProjectPastDataDatail,
  UpdateHrCostForProjectRequest,
  UpdateProjectWithRevenutAndCostsByIdRequest,
} from '@api/client';
import { filterOption, getFormattedDateHeader } from '../../../../shared/utils/common.utils';
import LinksButton from '../../../../shared/design-system/components/links-button';
import { crudGetRevenueCostsProject, crudUpdateHrCosts, crudUpdateRevenueCostsProject } from '../_redux/actions';
import ModalAction from '../../project-list/project-detail/_components/modal-action';
import locale from 'antd/es/date-picker/locale/en_US';
import NoData from '../../../../shared/design-system/components/no-data-box';
import useHandleOtherCosts from '../../../../shared/_hooks/useHandleOtherCosts';

const RevenuesCostsCrud: React.FC = () => {
  const dispatch = useAppDispatch();
  const costList = useAppSelector(costiSelector);
  const { t } = useTranslation();
  const [id, setId] = useState<number | undefined>();
  const [showModalAddMonths, setShowModalAddMonths] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<number[]>([]);
  const [dataFromService, setDataFromService] = useState<ProjectPastDataDatail | undefined>();
  const { RangePicker } = DatePicker;

  const {
    otherCosts,
    revenuePlan,
    setOtherCosts,
    setRevenuePlan,
    getCostTypeDescriptions,
    getValue,
    handleChangeCost,
    handleChangeDescription,
    addCost,
  } = useHandleOtherCosts(dataFromService);

  useEffect(() => {
    if (!costList) dispatch(getCosts());
  }, []);

  const searchProject = () => {
    if (id) {
      dispatch(crudGetRevenueCostsProject(id))
        .unwrap()
        .then((res) => {
          setDataFromService(res);
        });
    }
  };

  const updateProject = () => {
    if (!id) return;
    const payload: UpdateProjectWithRevenutAndCostsByIdRequest = {
      projectId: id,
      savePastDataForProject: {
        ricavi: revenuePlan?.map((el) => ({
          id: el.id,
          actualRevenue: el.actualAmount,
          month: el.month,
          year: el.year,
        })),
        otherCosts: otherCosts || [],
      },
    };
    // console.log(payload);
    dispatch(crudUpdateRevenueCostsProject(payload));
  };

  const updateHrCost = (month: number, year: number) => {
    if (!id) return;
    const payload: UpdateHrCostForProjectRequest = {
      projectId: id,
      month,
      year,
    };
    dispatch(crudUpdateHrCosts(payload))
      .unwrap()
      .then((res) => {
        setDataFromService(res);
      });
  };

  const onDateChange: DatePickerProps['onChange'] = (dates, dateStr) => {
    if (!dates) setDateRange([]);
    const [meseInizio, annoInizio] = dateStr[0].split('/').map((str) => parseInt(str, 10));
    const [meseFine, annoFine] = dateStr[1].split('/').map((str) => parseInt(str, 10));
    setDateRange([meseInizio, annoInizio, meseFine, annoFine]);
  };

  const addMonths = () => {
    if (!dateRange.length) return;
    const [meseInizio, annoInizio, meseFine, annoFine] = dateRange;
    const newAllocations = revenuePlan?.slice();
    const newOtherCosts = otherCosts?.slice();
    for (let i = annoInizio; i <= annoFine; i++) {
      for (let j = 1; j <= 12; j++) {
        if ((i === annoInizio && j < meseInizio) || (i === annoFine && j > meseFine)) continue;
        if (!newAllocations?.find((el) => el.month === j && el.year === i)) {
          newAllocations?.push({
            month: j,
            year: i,
            actualAmount: 0,
            actualHrCost: 0,
          });
          newOtherCosts?.forEach((el) => {
            el.costAllocation?.push({
              id: -Math.floor(Math.random() * 100000),
              mese: j,
              anno: i,
              actualCost: 0,
              editEnabled: true,
            });
          });
        }
      }
    }
    setOtherCosts(
      newOtherCosts?.map((el) => ({
        ...el,
        costAllocation: el.costAllocation?.sort((a, b) => {
          if (a.anno && b.anno && a.anno !== b.anno) {
            return a.anno - b.anno;
          }
          return (a.mese ?? 0) - (b.mese ?? 0);
        }),
      }))
    );
    setRevenuePlan(
      newAllocations?.sort((a, b) => {
        if (a.year && b.year && a.year !== b.year) {
          return a.year - b.year;
        }
        return (a.month ?? 0) - (b.month ?? 0);
      })
    );
    setShowModalAddMonths(false);
  };

  return (
    <>
      <AppCard title="Search by ID" className="mt-3">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            searchProject();
          }}
        >
          <Row>
            <Col lg={3} md={6} sm={6} xs={6}>
              <div className="form-group">
                <label>{t('crud.crud-revenue-costs.label-id-project')}</label>
                <Input
                  className="w-100"
                  size="large"
                  type="number"
                  placeholder={t('crud.crud-revenue-costs.placeholder-id-project')}
                  onChange={(event) => {
                    const value = parseInt(event.target.value);
                    setId(value);
                  }}
                  value={id}
                />
              </div>
            </Col>
            <Col className="align-self-end mb-1" lg={3} md={3} sm={3} xs={3}>
              <LinksButton type="submit" className="btn btn-links">
                {t('crud.crud-revenue-costs.button-label-search')}
              </LinksButton>
            </Col>
          </Row>
        </form>
      </AppCard>
      <Card className="mt-3">
        <Card.Header className="card-header-border">
          <div className="d-flex align-items-center justify-content-between">
            <b>
              {revenuePlan?.length
                ? `ID project: ${dataFromService?.prjId} - (${dataFromService?.prjIntranetId}) ${dataFromService?.prjName}`
                : t('crud.crud-revenue-costs.title-label-revenue')}
            </b>
            <LinksButton
              className="btn-sm btn-primary btn-links px-2"
              isDisabled={!revenuePlan?.length}
              onClick={() => updateProject()}
            >
              <div className="d-flex align-items-center">
                <i className="icon-save pe-2" />
                {t('crud.crud-revenue-costs.button-label-save')}
              </div>
            </LinksButton>
          </div>
        </Card.Header>
        {revenuePlan?.length ? (
          <>
            <Card.Body>
              <div className="d-flex justify-content-start mb-3" id="header-buttons">
                <div>
                  <LinksButton
                    className="btn-sm btn btn-links-outline px-3"
                    disabled={!costList?.length}
                    onClick={addCost}
                  >
                    <div className="d-flex align-items-center">
                      <i className="fas fa-plus pe-2" />
                      {t('project-detail.add-cost')}
                    </div>
                  </LinksButton>
                  <LinksButton
                    className="btn-sm btn btn-links-outline px-1"
                    onClick={() => setShowModalAddMonths(true)}
                  >
                    <div className="d-flex align-items-center">
                      <i className="icon-calendar-add" />
                    </div>
                  </LinksButton>
                  <ModalAction
                    title={t('project-detail.add-months')}
                    actionLabel={t('common.add')}
                    action={addMonths}
                    setShow={(value) => setShowModalAddMonths(value)}
                    show={showModalAddMonths}
                  >
                    <p>
                      <b>{t('project-detail.add-new-months')}</b>
                    </p>
                    <div className="p-1">
                      <RangePicker
                        picker="month"
                        format="MM/YYYY"
                        locale={locale}
                        getPopupContainer={(triggerMode) => {
                          return triggerMode.parentNode as HTMLElement;
                        }}
                        onChange={(dates: any, dateStrings: [string, string]) => onDateChange(dates, dateStrings)}
                      />
                    </div>
                  </ModalAction>
                </div>
              </div>
              <div className="d-flex table-responsive table-bordered">
                <Table className={'revenue-table sticky-table mt-0 mb-0'}>
                  <thead className="fixed-sticky-header">
                    <tr>
                      <th className="text-left py-0" colSpan={2} style={{ minWidth: '170px', maxWidth: '170px' }}>
                        <span>{t('crud.crud-revenue-costs.table-title')}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left py-0" colSpan={2} style={{ minWidth: '170px', maxWidth: '170px' }}>
                        <span>{t('crud.crud-revenue-costs.table-label-revenue')}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left py-0" colSpan={2} style={{ minWidth: '170px', maxWidth: '170px' }}>
                        <span>{t('crud.crud-revenue-costs.table-label-hr-costs')}</span>
                      </td>
                    </tr>
                    {otherCosts?.map((cost, index) => (
                      <tr key={'cost-' + index}>
                        <td className="text-left p-0" style={{ minWidth: '310px', maxWidth: '310px' }}>
                          <Select
                            className="w-100"
                            placeholder={t('project-detail.cost-type-amount-placeholder')}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            value={getValue(cost)}
                            variant="borderless"
                            disabled={cost.costAllocation?.every((el) => (el.id ?? 0) >= 0)}
                            onChange={(e) => handleChangeCost(e, index)}
                          >
                            {costList?.map((el) => (
                              <Select.Option key={`${el.id}-${index}`} value={el.id}>
                                {getCostTypeDescriptions(el.id)}
                              </Select.Option>
                            ))}
                          </Select>
                        </td>
                        <td className="text-left p-0" style={{ minWidth: '270px', maxWidth: '270px' }}>
                          <Input
                            value={cost?.description}
                            placeholder={t('project-detail.cost-description-placeholder')}
                            onChange={(e) => handleChangeDescription(e.target.value, index)}
                            variant="borderless"
                            className="h-100"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Table className={'revenue-table mt-0 mb-0'}>
                  <thead>
                    <tr className="fixed-scroll-header">
                      {revenuePlan?.map((allocation, index: number) => (
                        <th className={`text-left`} scope="col" key={'GU-' + index} style={{ minWidth: '100px' }}>
                          <span>{getFormattedDateHeader(allocation?.month, allocation?.year)}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {revenuePlan?.map((allocation, index: number) => (
                        <td className="text-left p-0" key={'revenues-' + index} style={{ minWidth: '100px' }}>
                          <div className="d-flex align-items-center">
                            <InputNumber
                              value={Number(allocation?.actualAmount?.toFixed(2)) ?? 0}
                              placeholder={t('project-detail.cost-type-amount-placeholder')}
                              variant="borderless"
                              decimalSeparator=","
                              className="h-100 w-100"
                              onChange={(e) => {
                                setRevenuePlan(
                                  revenuePlan?.map((el, i) => ({
                                    ...el,
                                    actualAmount: i === index ? Number(e) : el.actualAmount,
                                  }))
                                );
                              }}
                            />
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      {revenuePlan?.map((allocation, index: number) => (
                        <td className="text-left p-0" key={'HR-' + index} style={{ minWidth: '100px' }}>
                          <div className="d-flex align-items-center">
                            <InputNumber
                              value={Number(allocation?.actualHrCost?.toFixed(2)) ?? 0}
                              placeholder={t('project-detail.cost-type-amount-placeholder')}
                              variant="borderless"
                              decimalSeparator=","
                              className="h-100"
                              readOnly={true}
                            />
                            <button
                              onClick={() => updateHrCost(allocation?.month as number, allocation?.year as number)}
                              className="btn-icon"
                            >
                              <i className="fa fas fa-rotate-right text-blue-links"></i>
                            </button>
                          </div>
                        </td>
                      ))}
                    </tr>
                    {otherCosts?.map((cost: ExternalCostDto, index: number) => (
                      <tr key={'cost-' + index}>
                        {cost?.costAllocation?.map((allocation: ExternalCostAllocationDto, index: number) => (
                          <td className={`text-center p-0`} key={'cost-' + allocation.id} style={{ minWidth: '100px' }}>
                            <InputNumber
                              className="w-100"
                              key={'cost-per-month-' + allocation.id}
                              value={Number(allocation.actualCost?.toFixed(2)) ?? 0}
                              decimalSeparator=","
                              min={0}
                              onChange={(e) => {
                                const newAllocations = otherCosts?.map((el) => ({
                                  ...el,
                                  costAllocation: el.costAllocation?.map((alloc) => ({
                                    ...alloc,
                                    actualCost: alloc.id === allocation.id ? Number(e) : alloc.actualCost,
                                  })),
                                }));
                                setOtherCosts(newAllocations);
                              }}
                              variant="borderless"
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <NoData msg={t('project-list.no-highlighted-projects')} />
          </div>
        )}
      </Card>
    </>
  );
};

export default RevenuesCostsCrud;
