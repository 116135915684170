/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CostTypeDtoFromJSON, CostTypeDtoToJSON, } from './CostTypeDto';
/**
 * Check if a given object implements the ListCostTypeDto interface.
 */
export function instanceOfListCostTypeDto(value) {
    return true;
}
export function ListCostTypeDtoFromJSON(json) {
    return ListCostTypeDtoFromJSONTyped(json, false);
}
export function ListCostTypeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'otherCostsList': json['otherCostsList'] == null ? undefined : (json['otherCostsList'].map(CostTypeDtoFromJSON)),
    };
}
export function ListCostTypeDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'otherCostsList': value['otherCostsList'] == null ? undefined : (value['otherCostsList'].map(CostTypeDtoToJSON)),
    };
}
