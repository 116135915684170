/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BillingDtoFromJSON, BillingDtoToJSON, } from './BillingDto';
/**
 * Check if a given object implements the BillingDataResult interface.
 */
export function instanceOfBillingDataResult(value) {
    return true;
}
export function BillingDataResultFromJSON(json) {
    return BillingDataResultFromJSONTyped(json, false);
}
export function BillingDataResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'billings': json['billings'] == null ? undefined : (json['billings'].map(BillingDtoFromJSON)),
        'isEditable': json['isEditable'] == null ? undefined : json['isEditable'],
    };
}
export function BillingDataResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'billings': value['billings'] == null ? undefined : (value['billings'].map(BillingDtoToJSON)),
        'isEditable': value['isEditable'],
    };
}
