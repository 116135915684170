/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { StatoAvanzamentoFieldsDtoFromJSON, StatoAvanzamentoLavoriDtoToJSON, StatoAvanzamentoOutputListFromJSON, } from '../models/index';
/**
 *
 */
export class StatoAvanzamentoLavoriControllerApi extends runtime.BaseAPI {
    /**
     * Recupera tutti i possibili valori che lo stato avanzamento lavori può assumere
     * Possibili valori nelle select dello stato avanzamento
     */
    getStatoAvanzamentoFieldsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/stato-avanzamento-lavori/fields`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => StatoAvanzamentoFieldsDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti i possibili valori che lo stato avanzamento lavori può assumere
     * Possibili valori nelle select dello stato avanzamento
     */
    getStatoAvanzamentoFields(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getStatoAvanzamentoFieldsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Aggiorna lo stato di avanzamento lavori associato a una milestone di fattura.
     * Modifica lo stato di avanzamento lavori
     */
    modificaStatoAvanzamentoRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['statoAvanzamentoLavoriDto'] == null) {
                throw new runtime.RequiredError('statoAvanzamentoLavoriDto', 'Required parameter "statoAvanzamentoLavoriDto" was null or undefined when calling modificaStatoAvanzamento().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/stato-avanzamento-lavori/stato-avanzamento`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: StatoAvanzamentoLavoriDtoToJSON(requestParameters['statoAvanzamentoLavoriDto']),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Aggiorna lo stato di avanzamento lavori associato a una milestone di fattura.
     * Modifica lo stato di avanzamento lavori
     */
    modificaStatoAvanzamento(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.modificaStatoAvanzamentoRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Ricerca stato avanzamento
     */
    searchStatoAvanzamentoRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['statoAvanzamentoLavoriDto'] == null) {
                throw new runtime.RequiredError('statoAvanzamentoLavoriDto', 'Required parameter "statoAvanzamentoLavoriDto" was null or undefined when calling searchStatoAvanzamento().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/stato-avanzamento-lavori/search`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: StatoAvanzamentoLavoriDtoToJSON(requestParameters['statoAvanzamentoLavoriDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => StatoAvanzamentoOutputListFromJSON(jsonValue));
        });
    }
    /**
     * Ricerca stato avanzamento
     */
    searchStatoAvanzamento(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.searchStatoAvanzamentoRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
