import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import './style.scss';
import { AccordionHeader } from './_components/accordion-header';
interface Props {
  formData: any;
  children?: React.ReactNode;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  chipsFormData?: any;
  setChipsFormData?: (chipsFormData: any) => void;
  reset?: any;
  location?: string;
}
const AppAccordion: React.FC<Props> = ({
  formData,
  children,
  isOpen,
  setIsOpen,
  chipsFormData,
  setChipsFormData,
  reset,
  location,
}) => {
  return (
    <>
      <Accordion defaultActiveKey="0" className=" acc-btn my-3">
        <Card className="border-left-primary acc-btn">
          <AccordionHeader
            formData={formData}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            chipsFormData={chipsFormData}
            reset={reset}
            setChipsFormData={setChipsFormData}
            location={location}
          />
          {isOpen && <Card.Body className="mx-2">{children}</Card.Body>}
        </Card>
      </Accordion>
    </>
  );
};

export default AppAccordion;
