/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractOrderOutputDtoFromJSON, ContractOrderOutputDtoToJSON, } from './ContractOrderOutputDto';
/**
 * Check if a given object implements the ContractOrderOutputListDto interface.
 */
export function instanceOfContractOrderOutputListDto(value) {
    return true;
}
export function ContractOrderOutputListDtoFromJSON(json) {
    return ContractOrderOutputListDtoFromJSONTyped(json, false);
}
export function ContractOrderOutputListDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'contractOrderOutputDtoList': json['contractOrderOutputDtoList'] == null ? undefined : (json['contractOrderOutputDtoList'].map(ContractOrderOutputDtoFromJSON)),
    };
}
export function ContractOrderOutputListDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'contractOrderOutputDtoList': value['contractOrderOutputDtoList'] == null ? undefined : (value['contractOrderOutputDtoList'].map(ContractOrderOutputDtoToJSON)),
    };
}
