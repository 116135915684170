import { useEffect, useState } from 'react';
import { ProfiloProfessionaleDto } from '@api/client';
import { useAppDispatch } from '../../../../../../core/store/hooks';
import { getRoles } from '../../_redux/actions';

const useRoles = () => {
    const dispatch = useAppDispatch();
    const [roleList, setRoleList] = useState<Array<ProfiloProfessionaleDto>>([]);

    useEffect(() => {
        dispatch(getRoles())
            .unwrap()
            .then((res) => {
                if (res && res.listaRuoli) {
                    setRoleList(res.listaRuoli);
                }
            });
    }, []);

    return roleList;
};

export default useRoles;