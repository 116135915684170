import { Col, Row } from 'react-bootstrap';
import AppCard from '../../../../../../shared/design-system/components/app-card';
import { useTranslation } from 'react-i18next';
import { ContractOrderOutputDto } from '@api/client';
import useGetPassiveCycleOptions from '../../../_hooks/useGetPassiveCycleOptions';
import { Tooltip } from 'antd';
import { formatCurrency } from '../../../../../../shared/utils/common.utils';

interface Props {
  order: ContractOrderOutputDto;
}

const OrderData: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation();
  const { mappedPassiveCycleOptions } = useGetPassiveCycleOptions();

  return (
    <AppCard title={t('Order data')} className="border-left-primary mt-3">
      <Row>
        <Col>
          <div className="span-values">
            <div className="head">{t('ID')}</div>
            <div className="value">{order.id || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('ID external contract')}</div>
            <div className="value">{order.idExternalContract || '-'}</div>
          </div>
        </Col>

        <Col>
          <div className="span-values">
            <div className="head">{t('Order type')}</div>
            <div className="value">
              {mappedPassiveCycleOptions?.mappedOrderTypeEnumList?.find((el) => el.value === order.orderType?.value)
                ?.label || '-'}
            </div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Contract status')}</div>
            <div className="value">
              {mappedPassiveCycleOptions?.mappedOrderContractStatusEnumList?.find(
                (el) => el.value === order.contractStatus?.value
              )?.label || '-'}
            </div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Processing status')}</div>
            <div className="value">
              {mappedPassiveCycleOptions?.mappedOrderProcessingStatusEnumList?.find(
                (el) => el.value === order.processingStatus?.value
              )?.label || '-'}
            </div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Expenses')}</div>
            <div className="value">
              {`${order.expenseItems?.length} expenses`}
              <Tooltip
                title={order.expenseItems
                  ?.map(
                    (item) =>
                      mappedPassiveCycleOptions?.mappedExpenseItemEnumList?.find(
                        (el) => el.value === item.expenseItemId?.value
                      )?.label || '-'
                  )
                  .join(', ')}
              >
                {!!order.expenseItems?.length && <i className="fas fa-circle-info ms-2" />}
              </Tooltip>
            </div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Supplier')}</div>
            <div className="value">{order.idFornitore?.denominazione || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Legal entity')}</div>
            <div className="value">{order.legalEntity?.nome || '-'}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="span-values">
            <div className="head">{t('Start date')}</div>
            <div className="value">{order.startDate?.toLocaleDateString('it-IT') || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('End date')}</div>
            <div className="value">{order.endDate?.toLocaleDateString('it-IT') || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Signature date')}</div>
            <div className="value">{order.signatureDate?.toLocaleDateString('it-IT') || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Cancellation within')}</div>
            <div className="value">{order.cancellationWithin?.toLocaleDateString('it-IT') || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Supply amount')}</div>
            <div className="value">{`${formatCurrency(order.contractValue)}€`}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Remaining balance')}</div>
            <div className="value">{`${formatCurrency(order.remainingBalance)}€`}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Link documentation')}</div>
            <div className="value" title={order.linkDocumentation}>
              {order.linkDocumentation ? (
                <a href={order.linkDocumentation} target="_blank" rel="noreferrer">
                  {order.linkDocumentation && order.linkDocumentation?.length > 30
                    ? order.linkDocumentation?.substring(0, 30) + '...'
                    : order.linkDocumentation}
                </a>
              ) : (
                '-'
              )}
            </div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Category')}</div>
            <div className="value" title={order.category?.value}>
              {order.category?.value && order.category?.value?.length > 30
                ? order.category?.value?.split('/').join(' / ').substring(0, 30) + '...'
                : order.category?.value?.split('/').join(' / ') || '-'}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="span-values">
            <div className="head">{t('Resale project code')}</div>
            <div className="value">{order.resaleProjectCode || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Resale detachment rate')}</div>
            <div className="value">{`${formatCurrency(order.resaleDetachmentRate)}€`}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Automatic renewal')}</div>
            <div className="value">{order.automaticRenewal ? 'Yes' : 'No'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Cancel at any time')}</div>
            <div className="value">{order.cancelAtAnyTime ? 'Yes' : 'No'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('External costs')}</div>
            <div className="value">{`${order.projectExternalCost?.length} associated costs`}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Last modification date')}</div>
            <div className="value">{order.lastmodifiedDate?.toLocaleDateString('it-IT') || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Withdrawal within')}</div>
            <div className="value">{order.withdrawalWithinLiteral || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Cancellation procedure')}</div>
            <div className="value">{order.cancellationProcedure || '-'}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="span-values">
            <div className="head">{t('Object')}</div>
            <div className="value">{order.object || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Notes')}</div>
            <div className="value">{order.note || '-'}</div>
          </div>
        </Col>
      </Row>
    </AppCard>
  );
};

export default OrderData;
