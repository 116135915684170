/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { DashboardBookingListDtoFromJSON, DashboardProjectFilterDtoFromJSON, DashboardProjectFilterInputDtoToJSON, DashboardProjectListDtoFromJSON, DashboardUserListDtoFromJSON, UserListFilterDtoToJSON, } from '../models/index';
/**
 *
 */
export class DashboardControllerApi extends runtime.BaseAPI {
    /**
     * Recupera i filtri di projects
     */
    getDashboardProjectFilterRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/dashboard/filters`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DashboardProjectFilterDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera i filtri di projects
     */
    getDashboardProjectFilter(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDashboardProjectFilterRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getDistinctUserCountRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/dashboard/count-users-total-elements`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     */
    getDistinctUserCount(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDistinctUserCountRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera la lista di overBooking
     */
    getOverBookingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['userListFilterDto'] == null) {
                throw new runtime.RequiredError('userListFilterDto', 'Required parameter "userListFilterDto" was null or undefined when calling getOverBooking().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/dashboard/over-booking`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: UserListFilterDtoToJSON(requestParameters['userListFilterDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DashboardBookingListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera la lista di overBooking
     */
    getOverBooking(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getOverBookingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera la lista di tutti i progetti dell\'anno corrente
     */
    getProjectCurrentYearListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['dashboardProjectFilterInputDto'] == null) {
                throw new runtime.RequiredError('dashboardProjectFilterInputDto', 'Required parameter "dashboardProjectFilterInputDto" was null or undefined when calling getProjectCurrentYearList().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/dashboard/projects-current-year`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: DashboardProjectFilterInputDtoToJSON(requestParameters['dashboardProjectFilterInputDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DashboardProjectListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera la lista di tutti i progetti dell\'anno corrente
     */
    getProjectCurrentYearList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getProjectCurrentYearListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera la lista di tutti i progetti
     */
    getProjectListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['dashboardProjectFilterInputDto'] == null) {
                throw new runtime.RequiredError('dashboardProjectFilterInputDto', 'Required parameter "dashboardProjectFilterInputDto" was null or undefined when calling getProjectList().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/dashboard/projects`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: DashboardProjectFilterInputDtoToJSON(requestParameters['dashboardProjectFilterInputDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DashboardProjectListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera la lista di tutti i progetti
     */
    getProjectList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getProjectListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera la lista di underBooking
     */
    getUnderBookingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['userListFilterDto'] == null) {
                throw new runtime.RequiredError('userListFilterDto', 'Required parameter "userListFilterDto" was null or undefined when calling getUnderBooking().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/dashboard/under-booking`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: UserListFilterDtoToJSON(requestParameters['userListFilterDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DashboardBookingListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera la lista di underBooking
     */
    getUnderBooking(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getUnderBookingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera la lista di tutti gli utenti
     */
    getUserListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['userListFilterDto'] == null) {
                throw new runtime.RequiredError('userListFilterDto', 'Required parameter "userListFilterDto" was null or undefined when calling getUserList().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/dashboard/users`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: UserListFilterDtoToJSON(requestParameters['userListFilterDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DashboardUserListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera la lista di tutti gli utenti
     */
    getUserList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getUserListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
