/* eslint-disable eqeqeq */
import {
  AllocationDto,
  DettaglioProjectDto,
  DettagliProgettoDtoStatoEnum,
  ProfiloProfessionaleDto,
  StaffExtendedDto,
} from '@api/client';
import {
  DatePicker,
  DatePickerProps,
  Dropdown,
  Input,
  InputNumber,
  MenuProps,
  Select,
  Tabs,
  TabsProps,
  Tooltip,
} from 'antd';
import { Button, Card, Table } from 'react-bootstrap';
import {
  compareByKeys,
  filterOption,
  formatCurrency,
  formatCurrencyNumber,
  getFormattedDateHeader,
} from '../../../../../../shared/utils/common.utils';
import './styles.scss';
import LinksButton from '../../../../../../shared/design-system/components/links-button';
import { useAppDispatch, useAppSelector } from '../../../../../../core/store/hooks';
import locale from 'antd/es/date-picker/locale/en_US';
import useStaffManagement from '../../_hooks/team-table/useStaffManagement';
import useSpreadOutDays from '../../_hooks/useSpreadOutDays';
import React, { useEffect, useRef, useState } from 'react';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { TableProjectKeys } from '../../../../../../enums/project.enum';
import useValidationGu from '../../_hooks/team-table/useValidationGu';
import OtherCostsTable from '../other-costs-table';
import ProjectDetails from '../project-details';
import useGetTeamTableData from '../../_hooks/team-table/useGetTeamTableData';
import { costiSelector, costsDataSelector } from '../../_redux/selectors';
import TotalsTable from '../totals-table';
import { useTranslation } from 'react-i18next';
import useGetOtherCostsTableData from '../../_hooks/other-costs/useGetOtherCostsTableData';
import AppNote from '../../../../../../shared/design-system/components/notes';
import {
  getAllocationLavorate,
  getFirstAllocationDate,
  getLastAllocationDate,
  isAllocationWorked,
} from '../../_utils/revenue-plan.utils';
import ModalAction from '../modal-action';
import MonthCheckList from '../months-check-list';
import useMoveDays from '../../_hooks/team-table/useMoveDays';
import MoveDays from '../move-days';
import AuthService from '../../../../../../core/auth/auth.service';
import DropdownConfig from '../dropdown-config';
import BillingPlan from '../billing-plan';
import dayjs from 'dayjs';
import {
  getCompetenzeAnnoCorrente,
  getCompetenzeComplessive,
  saveRevenuesToBeConsolidated,
} from '../../_redux/actions';

interface Props {
  projectDetails: DettaglioProjectDto | null;
  roleList: ProfiloProfessionaleDto[];
  idProject: number | undefined;
  isWorkInProgress: boolean;
  setTotalGuValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalCostsValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setDraftValidation: React.Dispatch<React.SetStateAction<boolean>>;
}

const RevenuePlan: React.FC<Props> = ({
  projectDetails,
  roleList,
  idProject,
  isWorkInProgress,
  setTotalGuValidation,
  setTotalCostsValidation,
  setDraftValidation,
}: Props) => {
  let {
    staffList,
    revenuePlan,
    otherCosts,
    readOnly,
    baselineOfferta,
    ricaviMeseDaConsolidare,
    dettaglioOfferta,
    mesiProgetto,
  } = projectDetails!!;
  staffList = staffList?.slice().sort(compareByKeys('userId'));
  const dispatch = useAppDispatch();
  const costList = useAppSelector(costiSelector);
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<number[]>([]);
  const [revenueMonthToBeConsolidated, setRevenueMonthToBeConsolidated] = useState<number | undefined>(
    ricaviMeseDaConsolidare?.amount
  );

  const {
    fullAllocations,
    setFullAllocations,
    fullAllocationsShown,
    totalsRevenuePlanShown,
    refresh,
    collaborators,
    years,
    setYears,
    filterAllocationsByYear,
    currentYear,
    totalGUPlanned,
    setTotalGUPlanned,
    totalsRevenuePlan,
    setTotalsRevenuePlan,
    setTotalsRevenuePlanShown,
    setRefresh,
  } = useGetTeamTableData(staffList, revenuePlan, mesiProgetto, otherCosts);

  const {
    handleChangeAllocation,
    handleChangeTotalGU,
    handleChangeCollaborator,
    handleChangeProfile,
    getOptions,
    addMonths,
    addCollaborator,
    deleteCollaborator,
    saveBozza,
    disableAllocatedMonths,
  } = useStaffManagement(
    fullAllocations,
    setFullAllocations,
    collaborators,
    filterAllocationsByYear,
    currentYear,
    setTotalGUPlanned,
    revenuePlan,
    setTotalsRevenuePlan,
    setTotalsRevenuePlanShown,
    setDraftValidation,
    setRefresh,
    setYears,
    dateRange,
    ricaviMeseDaConsolidare,
    revenueMonthToBeConsolidated
  );

  const {
    monthOptionsObj,
    checkedList,
    onChange,
    onCheckAllChange,
    spalmaGiorni,
    removeMonths,
    clearForecastDays,
    clearForecastDaysFromID,
  } = useSpreadOutDays(
    fullAllocations,
    currentYear,
    totalGUPlanned,
    idProject,
    setFullAllocations,
    filterAllocationsByYear
  );

  const { areTotalsGUMatch, getTooltip } = useValidationGu({
    totalGUPlanned,
    fullAllocations,
    setTotalGuValidation,
  });
  const [currentCollaborator, setCurrentCollaborator] = useState<any | undefined>();
  const [showModalMoveDays, setShowModalMoveDays] = useState(false);
  const [isBillingDirty, setIsBillingDirty] = useState(false);
  const [showModalBilling, setShowModalBilling] = useState(false);
  const [newTabValue, setNewTabValue] = useState<string | undefined>(undefined);

  const {
    moveDaysToNewCollaborator,
    moveDaysToExistingCollaborator,
    newCollaborator,
    moveDaysProps,
    setNewCollaborator,
  } = useMoveDays(
    collaborators,
    currentCollaborator,
    fullAllocations,
    setFullAllocations,
    filterAllocationsByYear,
    setDraftValidation,
    setShowModalMoveDays,
    currentYear
  );
  const {} = useGetOtherCostsTableData(otherCosts, costList, refresh);

  const [showModalSpalma, setShowModalSpalma] = useState(Array(fullAllocationsShown?.length).fill(false));
  const [showModalAddMonths, setShowModalAddMonths] = useState(false);
  const [showModalDeleteMonths, setShowModalDeleteMonths] = useState(false);
  const handleShowModalMoveDays = (value: boolean) => {
    setShowModalMoveDays(value);
    setNewCollaborator(false);
  };
  const { RangePicker } = DatePicker;

  const costsData = useAppSelector(costsDataSelector);

  const [table, setTable] = React.useState<TableProjectKeys>(TableProjectKeys.TEAM);
  const [currentCollaboratorName, setCurrentCollaboratorName] = useState('');
  const [currentRole, setCurrentRole] = useState('');
  const [currentGu, setCurrentGu] = useState<number>(0);

  const onChangeTab = (key: TableProjectKeys) => {
    if (isBillingDirty) {
      setShowModalBilling(true);
      setNewTabValue(key);
      return;
    }
    setTable(key);
  };

  const workInProgressTabs: TabsProps['items'] = [
    {
      key: TableProjectKeys.OVERALL_DATA,
      label: `${t('project-detail.complessive-data')}`,
      icon: <i className="fa-solid fa-chart-column" />,
    },
    {
      key: TableProjectKeys.CURRENT_YEAR_DATA,
      label: `${t('project-detail.current-year-data')}`,
      icon: <i className="fa-regular fa-clock" />,
    },
    {
      key: TableProjectKeys.TEAM,
      label: `${t('project-detail.team')}`,
      icon: <i className="fa-solid fa-people-group" />,
    },
    {
      key: TableProjectKeys.EXTERNAL_COSTS,
      label: `${t('project-detail.external-costs')}`,
      icon: <i className="fa-solid fa-euro-sign" />,
    },
    {
      key: TableProjectKeys.BILLING,
      label: `${t('project-detail.billing')}`,
      icon: <i className="fa-regular fa-money-bill-1" />,
    },
    {
      key: TableProjectKeys.NOTES,
      label: `${t('project-detail.notes')}`,
      icon: <i className="fa-regular fa-clipboard" />,
    },
  ];

  const parentTableRef = useRef<HTMLTableElement>(null);
  const totalsTableRef = useRef<HTMLDivElement>(null);
  const otherCostsTableRef = useRef<HTMLTableElement>(null);
  const [parentScrollLeft, setParentScrollLeft] = useState<number>(0);

  const handleParentScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (totalsTableRef.current) {
      totalsTableRef.current.scrollLeft = target.scrollLeft;
    }
  };

  const handleChildScroll = (totalsScrollLeft: number) => {
    if (parentTableRef.current) {
      parentTableRef.current.scrollLeft = totalsScrollLeft;
    }
    if (otherCostsTableRef.current) {
      otherCostsTableRef.current.scrollLeft = totalsScrollLeft;
    }
    if (totalsTableRef.current) {
      totalsTableRef.current.scrollLeft = totalsScrollLeft;
    }
  };

  const onDateChange: DatePickerProps['onChange'] = (dates, dateStr) => {
    if (!dates) setDateRange([]);
    const [meseInizio, annoInizio] = dateStr[0].split('/').map((str) => parseInt(str, 10));
    const [meseFine, annoFine] = dateStr[1].split('/').map((str) => parseInt(str, 10));
    setDateRange([meseInizio, annoInizio, meseFine, annoFine]);
  };

  useEffect(() => {
    const monthsWorked = fullAllocationsShown?.[0].allocazioni?.filter((el) => el.editEnable === false).length ?? 0;
    scrollToRight(monthsWorked * 100);
  }, [years, table]);

  const scrollToRight = (px: number) => {
    if (parentTableRef.current) {
      parentTableRef.current.scrollBy({
        top: 0,
        left: px,
        behavior: 'smooth',
      });
      setParentScrollLeft(px);
    }
  };

  const handleShowModalSpalma = (index: number, value: boolean) => {
    const newShowModal = [...showModalSpalma];
    newShowModal[index] = value;
    setShowModalSpalma(newShowModal);

    const collaboratorName = fullAllocationsShown![index].name;
    setCurrentCollaboratorName(collaboratorName!);
    const totalGu = totalGUPlanned?.[index]?.toFixed(2);
    setCurrentGu(+totalGu);
  };

  const handleShowMoveDaysModal = (index: number) => {
    setShowModalMoveDays(true);
    setCurrentCollaborator(fullAllocationsShown![index]);
    const collaboratorName = fullAllocationsShown![index].name;
    const collaboratorRole = roleList.find((el) => el.idPps == fullAllocationsShown![index].idPps)?.descrizionePps;
    const collaboratorSeniority = roleList
      .find((el) => el.idPps == fullAllocationsShown![index].idPps)
      ?.pptList?.find((el) => el.idCostoProfilo == fullAllocationsShown![index].idPpt)?.descrizioneCostoProfilo;
    setCurrentCollaboratorName(collaboratorName!);
    setCurrentRole(`${collaboratorRole!} - ${collaboratorSeniority}`);
    const totalGu = totalGUPlanned?.[index]?.toFixed(2);
    setCurrentGu(+totalGu);
  };

  const handleMenuClick: (rowIndex: number) => MenuProps['onClick'] = (rowIndex) => (e) => {
    switch (e.key) {
      case '1':
        clearForecastDays(rowIndex);
        break;
      case '2':
        handleShowMoveDaysModal(rowIndex);
        break;
      case '3':
        deleteCollaborator(rowIndex, idProject);
        break;
    }
  };

  const generateMenuItems = (rowIndex: number) => {
    const isDisabled = Number(totalGUPlanned?.[rowIndex]) <= 0 ? 'lightgrey' : 'text-blue-links';
    return [
      {
        label: t('project-detail.clean-forecast-days'),
        key: '1',
        icon: <i className={`icon-pulisci text-xl ${isDisabled}`} />,
        danger: false,
        disabled: Number(totalGUPlanned?.[rowIndex]) <= 0,
      },
      {
        label: t('project-detail.move-days.label'),
        key: '2',
        icon: <i className={`icon-sposta-giorni text-xl ${isDisabled}`} />,
        danger: false,
        disabled: Number(totalGUPlanned?.[rowIndex]) <= 0,
      },
      {
        label: t('project-detail.delete-collaborator'),
        key: '3',
        icon: <i className="icon-delete text-xl" />,
        danger: true,
        disabled: !isDeleteCollaboratorEnabled(rowIndex),
      },
    ];
  };

  const menuProps = (rowIndex: number) => ({
    items: generateMenuItems(rowIndex),
    onClick: handleMenuClick(rowIndex),
  });

  const isDeleteCollaboratorEnabled = (index: number) => {
    const allocationLavorate =
      Number(getAllocationLavorate(fullAllocations, index)?.reduce((acc, el) => acc + (el.actualAllocation ?? 0), 0)) ??
      0;
    // se il collaboratore ha già rendicontato non posso cancellarlo
    if (allocationLavorate !== 0) {
      return false;
    }
    // se è un collaboratore fittizio creato e FE ed è l'unico in tabella non posso cancellarlo
    if (fullAllocations?.length === 1 && !fullAllocations?.[0]?.idPps && !fullAllocations?.[0]?.idPpt) {
      return false;
    }
    return true;
  };

  const initialVisibleRows = localStorage.getItem('visibleRows')
    ? JSON.parse(localStorage.getItem('visibleRows') || '{}')
    : {
        totalGu: true,
        fte: true,
        personnelCosts: true,
        externalCosts: true,
        totalCosts: true,
        rate: true,
        revenues: true,
      };

  const [visibleRows, setVisibleRows] = useState<{ [key: string]: boolean }>(initialVisibleRows);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const savedColumns = localStorage.getItem('visibleColumns');
    return savedColumns
      ? JSON.parse(savedColumns)
      : {
          ppsProfile: true,
          pptSeniority: true,
          name: true,
          guRemaining: true,
          currentMonthRendicontations: true,
          worked: true,
          totalPlanned: true,
        };
  });

  const getCurrentMonthRendicontations = (index: number) => {
    const a = fullAllocations?.[index]?.allocazioni?.find(
      (el) => el.month === new Date().getMonth() + 1 && el.year === new Date().getFullYear()
    )?.realTimeAllocation;
    return a || 0;
  };

  const setRevenueDaConsolidare = (value: number) => {
    dispatch(saveRevenuesToBeConsolidated(value || 0));
    setRevenueMonthToBeConsolidated(value || 0);
  };

  const canShowSaveDraft = () => {
    return (
      [TableProjectKeys.TEAM, TableProjectKeys.EXTERNAL_COSTS].includes(table) &&
      !isWorkInProgress &&
      projectDetails?.dettaglioOfferta?.stato !== DettagliProgettoDtoStatoEnum.IntranetPending
    );
  };

  return (
    <>
      <Card className="table-wrapper revenue-card">
        <Card.Header className="card-header-border py-0">
          <div className="d-flex align-items-center justify-content-between">
            <div className="col-8">
              <Tabs
                defaultActiveKey={TableProjectKeys.TEAM}
                items={workInProgressTabs}
                className="text-blue-links"
                onChange={(key: string) => onChangeTab(key as TableProjectKeys)}
              />
            </div>

            {canShowSaveDraft() && (
              <LinksButton
                className="btn-sm btn-primary btn-links px-2 ms-2"
                isDisabled={readOnly}
                onClick={() => saveBozza(idProject, costsData)}
              >
                <div className="d-flex align-items-center">
                  <i className="fas fa-rotate-right pe-2" />
                  {t('project-detail.save-draft')}
                </div>
              </LinksButton>
            )}
            {[TableProjectKeys.OVERALL_DATA, TableProjectKeys.CURRENT_YEAR_DATA].includes(table) && (
              <LinksButton
                className="btn-sm btn-primary btn-links px-2 ms-2"
                isDisabled={readOnly}
                onClick={() =>
                  dispatch(
                    table === TableProjectKeys.OVERALL_DATA
                      ? getCompetenzeComplessive(idProject!)
                      : getCompetenzeAnnoCorrente(idProject!)
                  )
                }
              >
                <div className="d-flex align-items-center">
                  <i className="fas fa-rotate-right pe-2" />
                  {t('project-detail.refresh-data')}
                </div>
              </LinksButton>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {fullAllocationsShown && table === TableProjectKeys.TEAM && (
            <div>
              <div className="d-flex justify-content-between mb-3" id="header-buttons">
                <div className="col-7 d-flex align-items-start">
                  <LinksButton
                    className="btn-sm btn btn-links-outline px-3"
                    isDisabled={readOnly}
                    onClick={addCollaborator}
                  >
                    <div className="d-flex align-items-center">
                      <i className="icon-aggiungi-collaboratore" />
                      {t('project-detail.add-collaborator')}
                    </div>
                  </LinksButton>
                  <LinksButton
                    className="btn-sm btn btn-links-outline px-1"
                    isDisabled={readOnly}
                    onClick={() => setShowModalAddMonths(true)}
                  >
                    <div className="d-flex align-items-center">
                      <i className="icon-calendar-add" />
                    </div>
                  </LinksButton>
                  <ModalAction
                    title={t('project-detail.add-months')}
                    actionLabel={t('common.add')}
                    action={addMonths}
                    setShow={(value) => setShowModalAddMonths(value)}
                    show={showModalAddMonths}
                  >
                    <p>
                      <b>{t('project-detail.add-new-months')}</b>
                    </p>
                    <div className="p-1">
                      <RangePicker
                        picker="month"
                        format="MM/YYYY"
                        minDate={dayjs(new Date())}
                        locale={locale}
                        dropdownClassName="createDateRangePicker"
                        getPopupContainer={(triggerMode) => {
                          return triggerMode.parentNode as HTMLElement;
                        }}
                        disabledDate={!AuthService.isSuperAdmin() ? disableAllocatedMonths : undefined}
                        onChange={(dates: any, dateStrings: [string, string]) => onDateChange(dates, dateStrings)}
                      />
                    </div>
                  </ModalAction>
                  <ModalAction
                    title={t('project-detail.move-days.label')}
                    actionLabel={'Confirm'}
                    action={() => {
                      newCollaborator ? moveDaysToNewCollaborator() : moveDaysToExistingCollaborator();
                    }}
                    setShow={(value) => handleShowModalMoveDays(value)}
                    show={showModalMoveDays}
                    size="lg"
                  >
                    <p>
                      {`${currentCollaboratorName ?? ''} - ${currentRole}`}:{' '}
                      <b className="ms-2">{`${currentGu === 0 ? '' : currentGu} ${t(
                        'project-detail.move-days.md-remaining'
                      )}`}</b>
                    </p>
                    {showModalMoveDays ? (
                      <MoveDays
                        fullAllocationsShown={fullAllocationsShown}
                        roleList={roleList}
                        filterOption={filterOption}
                        getOptions={getOptions}
                        collaborators={collaborators}
                        currentCollaborator={currentCollaborator}
                        {...moveDaysProps}
                      />
                    ) : (
                      ''
                    )}
                  </ModalAction>
                  {monthOptionsObj.length > 0 && (
                    <LinksButton
                      className="btn-sm btn btn-outline-danger px-1"
                      isDisabled={readOnly}
                      onClick={() => setShowModalDeleteMonths(true)}
                    >
                      <div className="d-flex align-items-center">
                        <i className="icon-calendar-delete" />
                      </div>
                    </LinksButton>
                  )}
                  <ModalAction
                    title={t('project-detail.delete-months')}
                    actionLabel={t('common.delete')}
                    action={removeMonths}
                    setShow={(value) => setShowModalDeleteMonths(value)}
                    show={showModalDeleteMonths}
                    size="lg"
                  >
                    <p>
                      <b>{t('project-detail.delete-months')}</b>
                    </p>
                    <MonthCheckList
                      monthOptionsObj={monthOptionsObj}
                      checkedList={checkedList}
                      onChange={onChange}
                      onCheckAllChange={onCheckAllChange}
                      years={years}
                    />
                  </ModalAction>
                  <div className="d-flex align-items-center">
                    <span className="mx-1 notification-header">
                      {`${t('project-detail.revenues')} ${getFormattedDateHeader(
                        ricaviMeseDaConsolidare?.month,
                        ricaviMeseDaConsolidare?.year
                      )} (€) :`}
                    </span>
                    <Tooltip
                      title={
                        (baselineOfferta?.ricaviConsolidati || 0) + (revenueMonthToBeConsolidated || 0) >
                        (baselineOfferta?.ricaviTotali || 0)
                          ? t('cdg.table-cdg.validation-revenues-tooltip', {
                              amount: formatCurrency(
                                (baselineOfferta?.ricaviConsolidati || 0) +
                                  (Number(revenueMonthToBeConsolidated) || 0) -
                                  (baselineOfferta?.ricaviTotali || 0)
                              ),
                            })
                          : ``
                      }
                      color="red"
                    >
                      <InputNumber
                        style={{ width: '140px' }}
                        decimalSeparator=","
                        step={0.01}
                        min={0}
                        keyboard={false}
                        disabled={ricaviMeseDaConsolidare?.editable === false}
                        value={revenueMonthToBeConsolidated}
                        onChange={(e) => setRevenueDaConsolidare(e || 0)}
                        placeholder={t('project-detail.revenues-tooltip')}
                        suffix={
                          <Tooltip title={`text`}>
                            <ExclamationCircleOutlined
                              className="pe-3"
                              style={{
                                color:
                                  (baselineOfferta?.ricaviConsolidati || 0) + (revenueMonthToBeConsolidated || 0) >
                                  (baselineOfferta?.ricaviTotali || 0)
                                    ? 'red'
                                    : 'transparent',
                              }}
                            />
                          </Tooltip>
                        }
                      />
                    </Tooltip>
                  </div>
                  {(baselineOfferta?.ricaviConsolidati || 0) > (baselineOfferta?.ricaviTotali || 0) && (
                    <div className="ms-3" style={{ maxWidth: '250px' }}>
                      <span className="revenues-exceeded-span text-red">
                        {t('cdg.table-cdg.revenues-exceded', {
                          amount: formatCurrency(
                            (baselineOfferta?.ricaviConsolidati || 0) - (baselineOfferta?.ricaviTotali || 0)
                          ),
                        })}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-5 d-flex align-items-start">
                  <span className="ms-auto me-5 my-1">
                    <span className="mx-1 notification-header"> {t('project-detail.period')} :</span>
                    <span className="mx-1">
                      <b>{getFirstAllocationDate(fullAllocationsShown)}</b>
                    </span>
                    -
                    <span className="mx-1">
                      <b>{getLastAllocationDate(fullAllocationsShown)}</b>
                    </span>
                  </span>
                  <Select defaultValue={'all'} onChange={(e) => filterAllocationsByYear(e as number | 'all')}>
                    <Select.Option value="all">{t('project-detail.all')}</Select.Option>
                    {years.map((year, index) => (
                      <Select.Option key={'year-' + index} value={year}>
                        {year}
                      </Select.Option>
                    ))}
                  </Select>
                  <DropdownConfig
                    visibleColumns={visibleColumns}
                    setVisibleColumns={setVisibleColumns}
                    setIsCollapsed={setIsCollapsed}
                    isWorkInProgress={isWorkInProgress}
                    isCollapsed={isCollapsed}
                    visibleRows={visibleRows}
                    setVisibleRows={setVisibleRows}
                  />
                </div>
              </div>

              <div
                className="d-flex table-responsive table-bordered container-table"
                style={{ maxHeight: 'calc(100vh - 420px)', overflowY: 'auto', overflowX: 'auto' }}
                onScroll={handleParentScroll}
                ref={parentTableRef}
              >
                <Table className={'revenue-table sticky-table mt-0 mb-0'} style={{ maxWidth: '200px' }}>
                  <thead className="fixed-sticky-header">
                    <tr>
                      {visibleColumns.ppsProfile && (
                        <th className="text-left">
                          <span>{t('project-detail.pps-profile')}</span>
                        </th>
                      )}
                      {visibleColumns.pptSeniority && (
                        <th className="text-left" style={{ minWidth: '170px', maxWidth: '170px' }}>
                          <span>{t('project-detail.ppt-seniority')}</span>
                        </th>
                      )}
                      {visibleColumns.name && (
                        <th className="text-left">
                          <span>{t('project-detail.name')}</span>
                        </th>
                      )}

                      {visibleColumns.guRemaining && (
                        <th className="text-left" style={{ minWidth: isWorkInProgress ? '190px' : '130px' }}>
                          <span>
                            {isWorkInProgress
                              ? `${t('project-detail.gu-remaining')}`
                              : `${t('project-detail.planned-gu')}`}
                          </span>
                        </th>
                      )}
                      {visibleColumns.currentMonthRendicontations && (
                        <th className="text-left bg-disabled" style={{ minWidth: '100px' }}>
                          <span>{t('project-detail.current-month-rendicontations')}</span>
                          <Tooltip title={t('project-detail.current-month-rendicontations-tooltip')} color="white">
                            <i className="fas fa-circle-info text-blue-links ps-2" />
                          </Tooltip>
                        </th>
                      )}
                      {isWorkInProgress && (
                        <>
                          {visibleColumns.worked && (
                            <th className="text-left bg-disabled" style={{ minWidth: '100px' }}>
                              <span>{t('project-detail.worked')}</span>
                            </th>
                          )}
                          {visibleColumns.totalPlanned && (
                            <th className="text-left bg-disabled" style={{ minWidth: '100px' }}>
                              <span>{t('project-detail.total-planned')}</span>
                            </th>
                          )}
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {fullAllocationsShown?.map((teamMember: StaffExtendedDto, index: number) => (
                      <tr key={'row-' + index}>
                        {visibleColumns.ppsProfile && (
                          <td
                            className="text-left p-0"
                            key={'Profilo-' + index}
                            style={{ minWidth: '200px', maxWidth: '200px' }}
                          >
                            <Select
                              className="w-100"
                              placeholder={t('project-detail.pps-profile-placeholder')}
                              showSearch
                              optionFilterProp="children"
                              filterOption={filterOption}
                              disabled={
                                readOnly ||
                                (isWorkInProgress &&
                                  (Number(
                                    getAllocationLavorate(fullAllocations, index)?.reduce(
                                      (acc, el) => acc + (el.actualAllocation ?? 0),
                                      0
                                    )
                                  ) ?? 0) !== 0)
                              }
                              value={
                                teamMember.idPps
                                  ? {
                                      value: teamMember.idPps,
                                      label: roleList.find((el) => el.idPps == teamMember.idPps)?.descrizionePps,
                                    }
                                  : undefined
                              }
                              allowClear
                              variant="borderless"
                              onChange={(e) => handleChangeProfile(e, index, 'idPps')}
                            >
                              {roleList.map((el) => (
                                <Select.Option key={`${el.idPps}-${index}`} value={el.idPps}>
                                  {el.descrizionePps}
                                </Select.Option>
                              ))}
                            </Select>
                          </td>
                        )}
                        {visibleColumns.pptSeniority && (
                          <td
                            className="text-left p-0"
                            key={'Seniority-' + index}
                            style={{ minWidth: '200px', maxWidth: '200px' }}
                          >
                            <Select
                              className="w-100"
                              placeholder={t('project-detail.ppt-seniority-placeholder')}
                              showSearch
                              optionFilterProp="children"
                              filterOption={filterOption}
                              disabled={
                                readOnly ||
                                (isWorkInProgress &&
                                  (Number(
                                    getAllocationLavorate(fullAllocations, index)?.reduce(
                                      (acc, el) => acc + (el.actualAllocation ?? 0),
                                      0
                                    )
                                  ) ?? 0) !== 0)
                              }
                              value={
                                teamMember.idPpt
                                  ? {
                                      value: teamMember.idPpt,
                                      label: roleList
                                        .find((el) => el.idPps == teamMember.idPps)
                                        ?.pptList?.find((el) => el.idCostoProfilo == teamMember.idPpt)
                                        ?.descrizioneCostoProfilo,
                                    }
                                  : undefined
                              }
                              allowClear
                              variant="borderless"
                              onChange={(e) => handleChangeProfile(e, index, 'idPpt')}
                            >
                              {roleList
                                .find((el) => el.idPps == teamMember.idPps)
                                ?.pptList?.map((el) => (
                                  <Select.Option key={`${el.idCostoProfilo}-${index}`} value={el.idCostoProfilo}>
                                    {el.descrizioneCostoProfilo}
                                  </Select.Option>
                                ))}
                            </Select>
                          </td>
                        )}
                        {visibleColumns.name && (
                          <td
                            className="text-left p-0"
                            key={'Collaboratore-' + index}
                            style={{ minWidth: '180px', maxWidth: '180px' }}
                          >
                            <Select
                              className="w-100"
                              placeholder={t('project-detail.name-placeholder')}
                              variant="borderless"
                              showSearch
                              optionFilterProp="children"
                              filterOption={filterOption}
                              suffixIcon={
                                teamMember.active === false ? (
                                  <Tooltip title={`Inactive team member`} key={index}>
                                    <ExclamationCircleOutlined className="pe-3 text-red" />
                                  </Tooltip>
                                ) : (
                                  <DownOutlined style={{ pointerEvents: 'none' }} />
                                )
                              }
                              disabled={
                                readOnly ||
                                (isWorkInProgress &&
                                  (Number(
                                    getAllocationLavorate(fullAllocations, index)?.reduce(
                                      (acc, el) => acc + (el.actualAllocation ?? 0),
                                      0
                                    )
                                  ) ?? 0) !== 0 &&
                                  teamMember.name !== null)
                              }
                              value={
                                teamMember.userId && teamMember.name
                                  ? {
                                      value: teamMember.userId,
                                      label:
                                        collaborators?.find((el) => el.id == teamMember.userId)?.nominativo ||
                                        fullAllocationsShown?.[index]?.name,
                                    }
                                  : undefined
                              }
                              onChange={(e) => {
                                handleChangeCollaborator(e, index);
                              }}
                            >
                              {getOptions(teamMember.idPps, teamMember.idPpt).map((el) => (
                                <Select.Option key={`${el.value}-${index}`} value={el.value}>
                                  {el.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </td>
                        )}
                        {visibleColumns.guRemaining && (
                          <td className="text-center p-0" key={'GU-' + index}>
                            <div className="d-flex justify-content-between">
                              <Tooltip
                                title={areTotalsGUMatch?.[index] ? '' : getTooltip(index)}
                                color="red"
                                key={`tooltip-total-${index}`}
                                className="w-100"
                              >
                                <InputNumber
                                  value={Number(totalGUPlanned?.[index]?.toFixed(2)) ?? 0}
                                  onChange={(e) => handleChangeTotalGU(e, index)}
                                  decimalSeparator=","
                                  variant="borderless"
                                  readOnly={readOnly}
                                  className={areTotalsGUMatch?.[index] ? '' : 'red-label'}
                                  suffix={
                                    <Tooltip title={`text`} key={index}>
                                      <ExclamationCircleOutlined
                                        className="pe-3"
                                        style={{ color: !areTotalsGUMatch?.[index] ? 'red' : 'transparent' }}
                                      />
                                    </Tooltip>
                                  }
                                />
                              </Tooltip>
                              <Tooltip
                                title={t('project-detail.spread-out-days')}
                                color="white"
                                key={`tooltip-spalma-${index}`}
                              >
                                <Button
                                  className="btn-sm btn-icon border-none me-0"
                                  disabled={readOnly}
                                  onClick={() => handleShowModalSpalma(index, true)}
                                >
                                  <i className="icon-calendar-spalma icon-primary align-middle"></i>
                                </Button>
                              </Tooltip>
                              <ModalAction
                                title={t('project-detail.spread-out-days')}
                                actionLabel={t('project-detail.divide')}
                                action={spalmaGiorni}
                                index={index}
                                setShow={(value) => handleShowModalSpalma(index, value)}
                                show={showModalSpalma[index]}
                                size="lg"
                              >
                                <p>
                                  {`${currentCollaboratorName ?? ''}`} :{' '}
                                  <b>{`${currentGu === 0 ? 'Inserire Gu' : currentGu + ' Gu'}`}</b>
                                </p>
                                <MonthCheckList
                                  monthOptionsObj={monthOptionsObj}
                                  checkedList={checkedList}
                                  onChange={onChange}
                                  onCheckAllChange={onCheckAllChange}
                                  years={years}
                                />
                              </ModalAction>
                              <Tooltip title={'Actions'} color="white" key={`actions-${index}`}>
                                <Dropdown menu={menuProps(index)} trigger={['click']}>
                                  <i
                                    className="fa-solid fa-ellipsis-vertical text-blue-links pt-2 pe-3 ps-1"
                                    role="button"
                                    onClick={(e) => e.preventDefault()}
                                  ></i>
                                </Dropdown>
                              </Tooltip>
                            </div>
                          </td>
                        )}
                        {visibleColumns.currentMonthRendicontations && (
                          <td className="text-left p-0 bg-disabled" key={'Rend-' + index}>
                            <Input
                              readOnly
                              className="bg-disabled"
                              value={formatCurrency(getCurrentMonthRendicontations(index))}
                            />
                          </td>
                        )}
                        {isWorkInProgress && (
                          <>
                            {visibleColumns.worked && (
                              <td className="text-left p-0 bg-disabled" key={'Lavorati-' + index}>
                                <Input
                                  readOnly
                                  className="bg-disabled"
                                  value={formatCurrency(
                                    Number(
                                      getAllocationLavorate(fullAllocations, index)?.reduce(
                                        (acc, el) => acc + (el.actualAllocation ?? 0),
                                        0
                                      )
                                    ) ?? 0
                                  )}
                                />
                              </td>
                            )}
                            {visibleColumns.totalPlanned && (
                              <td className="text-left p-0 bg-disabled" key={'Tot/Pian-' + index}>
                                <Input
                                  readOnly
                                  className="bg-disabled"
                                  value={formatCurrency(
                                    totalGUPlanned?.[index] +
                                      Number(
                                        getAllocationLavorate(fullAllocations, index)?.reduce(
                                          (acc, el) => acc + (el.actualAllocation ?? 0),
                                          0
                                        )
                                      )
                                  )}
                                />
                              </td>
                            )}
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <Table className={'revenue-table mt-0 mb-0'}>
                  <thead>
                    <tr className="fixed-scroll-header">
                      {fullAllocationsShown?.[0]?.allocazioni?.map((allocation: AllocationDto, index: number) => (
                        <th
                          className={`text-left ${isAllocationWorked(allocation) && 'bg-disabled'}`}
                          scope="col"
                          key={'GU-' + index}
                          style={{ minWidth: '100px', maxWidth: '100px' }}
                        >
                          <span>{getFormattedDateHeader(allocation?.month, allocation?.year)}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {fullAllocationsShown?.map((teamMember: StaffExtendedDto, index: number) => (
                      <React.Fragment key={'row-' + index}>
                        <tr key={'row-' + index}>
                          {teamMember.allocazioni?.map((allocation: AllocationDto, index: number) => (
                            <td
                              className={`text-center p-0 ${isAllocationWorked(allocation) && 'bg-disabled'}`}
                              key={'allocation-' + index}
                            >
                              <InputNumber
                                className={`w-100 ${
                                  (allocation?.actualAllocation || 0) <=
                                  (projectDetails?.giorniLavorativi?.find(
                                    (el) => el.mese === allocation?.month && el.anno === allocation?.year
                                  )?.giorniLavorativiReali || 26)
                                    ? ''
                                    : 'red-label'
                                }`}
                                key={'GU-per-month-' + index}
                                value={formatCurrencyNumber(allocation?.actualAllocation, 2) ?? 0}
                                decimalSeparator=","
                                readOnly={readOnly}
                                disabled={allocation?.editEnable === false}
                                min={0}
                                max={31}
                                onChange={(e) => handleChangeAllocation(e, allocation?.id)}
                                variant="borderless"
                              />
                            </td>
                          ))}
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          {otherCosts && table === TableProjectKeys.EXTERNAL_COSTS && (
            <OtherCostsTable
              otherCosts={otherCosts}
              years={years}
              setYears={setYears}
              isWorkInProgress={isWorkInProgress}
              setTotalCostsValidation={setTotalCostsValidation}
              setDraftValidation={setDraftValidation}
              fullAllocations={fullAllocations}
              totalsRevenuePlan={totalsRevenuePlan}
              readOnly={readOnly}
              revenueMonthToBeConsolidated={revenueMonthToBeConsolidated}
              setRevenueMonthToBeConsolidated={setRevenueMonthToBeConsolidated}
              ricaviMeseDaConsolidare={ricaviMeseDaConsolidare}
              idProject={idProject}
              otherCostsTableRef={otherCostsTableRef}
              onOtherCostsScroll={handleChildScroll}
            />
          )}
          {table === TableProjectKeys.NOTES && <AppNote idProject={idProject!} />}
          {table === TableProjectKeys.OVERALL_DATA && (
            <ProjectDetails projectId={idProject} keyData={TableProjectKeys.OVERALL_DATA} />
          )}
          {table === TableProjectKeys.CURRENT_YEAR_DATA && (
            <ProjectDetails projectId={idProject} keyData={TableProjectKeys.CURRENT_YEAR_DATA} />
          )}
          {table === TableProjectKeys.BILLING && (
            <BillingPlan
              projectName={dettaglioOfferta?.name}
              projectId={idProject!}
              setIsBillingDirty={setIsBillingDirty}
            />
          )}
        </Card.Body>
        {(table === TableProjectKeys.TEAM || table === TableProjectKeys.EXTERNAL_COSTS) && (
          <TotalsTable
            table={table}
            visibleColumns={visibleColumns}
            visibleRows={visibleRows}
            fullAllocations={fullAllocations}
            fullAllocationsShown={fullAllocationsShown}
            totalsRevenuePlan={totalsRevenuePlan}
            totalsRevenuePlanShown={totalsRevenuePlanShown}
            isWorkInProgress={isWorkInProgress}
            parentScrollLeft={parentScrollLeft}
            onChildScroll={handleChildScroll}
            giorniLavorativi={projectDetails?.giorniLavorativi}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            totalsTableRef={totalsTableRef}
          />
        )}
      </Card>
      <ModalAction
        title={t('project-detail.billing')}
        actionLabel={t('common.confirm')}
        action={() => {
          setTable(newTabValue as TableProjectKeys);
          setIsBillingDirty(false);
        }}
        setShow={(value) => setShowModalBilling(value)}
        show={showModalBilling}
      >
        <p>{t('project-detail.billing-warning')}</p>
      </ModalAction>
    </>
  );
};

export default RevenuePlan;
