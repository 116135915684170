/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CostiRicaviMeseAnnoDtoFromJSON, CostiRicaviMeseAnnoDtoToJSON, } from './CostiRicaviMeseAnnoDto';
/**
 * Check if a given object implements the ProjectMonthDto interface.
 */
export function instanceOfProjectMonthDto(value) {
    return true;
}
export function ProjectMonthDtoFromJSON(json) {
    return ProjectMonthDtoFromJSONTyped(json, false);
}
export function ProjectMonthDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'intranetId': json['intranetId'] == null ? undefined : json['intranetId'],
        'name': json['name'] == null ? undefined : json['name'],
        'account': json['account'] == null ? undefined : json['account'],
        'clientLead': json['clientLead'] == null ? undefined : json['clientLead'],
        'projectLead': json['projectLead'] == null ? undefined : json['projectLead'],
        'year': json['year'] == null ? undefined : json['year'],
        'month': json['month'] == null ? undefined : json['month'],
        'actualOtherCosts': json['actualOtherCosts'] == null ? undefined : json['actualOtherCosts'],
        'actualRevenue': json['actualRevenue'] == null ? undefined : json['actualRevenue'],
        'automaticRevenue': json['automaticRevenue'] == null ? undefined : json['automaticRevenue'],
        'plRevenue': json['plRevenue'] == null ? undefined : json['plRevenue'],
        'clRevenue': json['clRevenue'] == null ? undefined : json['clRevenue'],
        'actualHRCosts': json['actualHRCosts'] == null ? undefined : json['actualHRCosts'],
        'actualHRDays': json['actualHRDays'] == null ? undefined : json['actualHRDays'],
        'totalBidAmount': json['totalBidAmount'] == null ? undefined : json['totalBidAmount'],
        'totalActualRevenue': json['totalActualRevenue'] == null ? undefined : json['totalActualRevenue'],
        'pregresso': json['pregresso'] == null ? undefined : (json['pregresso'].map(CostiRicaviMeseAnnoDtoFromJSON)),
    };
}
export function ProjectMonthDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'intranetId': value['intranetId'],
        'name': value['name'],
        'account': value['account'],
        'clientLead': value['clientLead'],
        'projectLead': value['projectLead'],
        'year': value['year'],
        'month': value['month'],
        'actualOtherCosts': value['actualOtherCosts'],
        'actualRevenue': value['actualRevenue'],
        'automaticRevenue': value['automaticRevenue'],
        'plRevenue': value['plRevenue'],
        'clRevenue': value['clRevenue'],
        'actualHRCosts': value['actualHRCosts'],
        'actualHRDays': value['actualHRDays'],
        'totalBidAmount': value['totalBidAmount'],
        'totalActualRevenue': value['totalActualRevenue'],
        'pregresso': value['pregresso'] == null ? undefined : (value['pregresso'].map(CostiRicaviMeseAnnoDtoToJSON)),
    };
}
