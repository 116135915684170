import { ChatAdapterExtras, StreamSend, StreamingAdapterObserver } from '@nlux/react';
import { GuidGenerator } from './GuidGenerator';

// A demo API by NLUX that connects to OpenAI
// and returns a stream of Server-Sent events

const API_URL = 'https://topstart-chatbot.azurewebsites.net/stream';
const conversationId = GuidGenerator.standard();

// Function to send query to the server and receive a stream of chunks as response
export const send: StreamSend = async (
  prompt: string,
  observer: StreamingAdapterObserver,
  extras: ChatAdapterExtras
) => {
  console.log('history', extras.conversationHistory);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        '9TEiw6qDlpbNmaPMe6w3HWv0NqcvPXxGJP1AWgdICOFK67bk2hjMCl8qjcr172EJ2T2EqahMKHEcoxGOAp0K0ts0j7rvsH9rVLJaiyqOi68YnwWKQRFYhUy46ivElKsA',
      'User-Id': 'user_links',
      'Conversation-Id': conversationId,
    },
    body: JSON.stringify({
      input: prompt,
      history: extras.conversationHistory,
    }),
  };

  const response = await fetch(API_URL, requestOptions);

  if (response.status !== 200) {
    observer.error(new Error('Failed to connect to the server'));
    return;
  }

  if (!response.body) {
    return;
  }

  // Read a stream of server-sent events
  // and feed them to the observer as they are being generated
  const reader = response.body.getReader();
  const textDecoder = new TextDecoder();

  while (true) {
    const { value, done } = await reader.read();
    if (done) {
      break;
    }

    const content = textDecoder.decode(value);
    if (content) {
      observer.next(content);
    }
  }

  observer.complete();
};
