/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProgettoDtoFromJSON, ProgettoDtoToJSON, } from './ProgettoDto';
/**
 * Check if a given object implements the SearchProjectResponseDto interface.
 */
export function instanceOfSearchProjectResponseDto(value) {
    return true;
}
export function SearchProjectResponseDtoFromJSON(json) {
    return SearchProjectResponseDtoFromJSONTyped(json, false);
}
export function SearchProjectResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'listaProgetti': json['listaProgetti'] == null ? undefined : (json['listaProgetti'].map(ProgettoDtoFromJSON)),
    };
}
export function SearchProjectResponseDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'listaProgetti': value['listaProgetti'] == null ? undefined : (value['listaProgetti'].map(ProgettoDtoToJSON)),
    };
}
