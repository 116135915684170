/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the UserDaysDto interface.
 */
export function instanceOfUserDaysDto(value) {
    return true;
}
export function UserDaysDtoFromJSON(json) {
    return UserDaysDtoFromJSONTyped(json, false);
}
export function UserDaysDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'month': json['month'] == null ? undefined : json['month'],
        'year': json['year'] == null ? undefined : json['year'],
        'isConsolidato': json['isConsolidato'] == null ? undefined : json['isConsolidato'],
        'daysActualForecast': json['daysActualForecast'] == null ? undefined : json['daysActualForecast'],
    };
}
export function UserDaysDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'month': value['month'],
        'year': value['year'],
        'isConsolidato': value['isConsolidato'],
        'daysActualForecast': value['daysActualForecast'],
    };
}
