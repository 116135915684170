import { Configuration, ControllerProjectApi, ListNoteDto, NoteDto } from '@api/client';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import getConfiguration, { errorHandler } from '../../../../../core/store/config';
import { ThunkApiConfig } from '../../../../../core/store';

enum NotesAction {
  fetchProjectMessages = '[ProjectList] Fetch Project Messages',
  insertProjectMessage = '[ProjectList] Insert Project Message',
  updateProjectMessage = '[ProjectList] Update Project Message',
  deleteProjectMessage = '[ProjectList] Delete Project Message',
}
export const fetchProjectMessages = createAsyncThunk<ListNoteDto | undefined, number, ThunkApiConfig>(
  NotesAction.fetchProjectMessages,
  async (projectId, { dispatch, extra, getState }) => {
    try {
      const response = await new ControllerProjectApi(
        getConfiguration(Configuration, extra, getState)
      ).projectGetMessage({ projectId });
      return response;
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const insertProjectMessage = createAsyncThunk<
  NoteDto | undefined,
  { projectId: number; noteDto: NoteDto },
  ThunkApiConfig
>(NotesAction.insertProjectMessage, async ({ projectId, noteDto }, { dispatch, extra, getState }) => {
  try {
    const response = await new ControllerProjectApi(
      getConfiguration(Configuration, extra, getState)
    ).projectInsertMessage({ projectId, noteDto });
    return response;
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const updateProjectMessage = createAsyncThunk<
  NoteDto | undefined,
  { messageId: number; noteDto: NoteDto },
  ThunkApiConfig
>(NotesAction.updateProjectMessage, async ({ messageId, noteDto }, { dispatch, extra, getState }) => {
  try {
    const response = await new ControllerProjectApi(
      getConfiguration(Configuration, extra, getState)
    ).projectUpdateMessage({ messageId, noteDto });
    return response;
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const deleteProjectMessage = createAsyncThunk<number | undefined, number, ThunkApiConfig>(
  NotesAction.deleteProjectMessage,
  async (messageId, { dispatch, extra, getState }) => {
    try {
      await new ControllerProjectApi(getConfiguration(Configuration, extra, getState)).projectDeleteMessage({
        messageId,
      });
      return messageId;
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);
