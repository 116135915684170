import React, { useState, useEffect } from 'react';
import "./styles.scss";
import { t } from "i18next";
import AppModal from '../app-modal';
import { Button, Col, Form, Row } from 'react-bootstrap';
import AuthService from '../../../../core/auth/auth.service';
import QuillEditor, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { format } from 'date-fns';
import { DatePicker, Select } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { deleteProjectMessage, fetchProjectMessages, insertProjectMessage, updateProjectMessage } from './_redux/actions';
import { AppDispatch } from '../../../../core/store';
import { ListNoteDto, NoteDto, SearchUsersRequest } from '@api/client';
import { getUsers } from '../../../../pages/private/project-list/project-detail/_redux/actions';
import { useAppSelector } from '../../../../core/store/hooks';
import { allUsersSelector } from '../../../../pages/private/project-list/project-detail/_redux/selectors';


interface propsNote {
    idProject: number;
}
const AppNote = ({ idProject }: propsNote) => {

    const [showModal, setShowModal] = useState(false);
    let dispatchedNotes = {} as any | undefined;
    const [users, setUsers] = useState<any[]>([]);
    const [notes, setNotes] = useState<any[] | undefined>(undefined);
    const [input, setInput] = useState("");
    const [edit, setEdit] = useState<boolean | null>(null);
    const [editText, setEditText] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [currentNoteId, setCurrentNoteId] = useState<number | null>(null);
    const [dateRange, setDateRange] = useState([]);
    const [selectedCollaborator, setSelectedCollaborator] = useState(null);
    const currentUser = AuthService.getUser();

    const email = currentUser.email;
    const defaultNotesForm = {
        dataCommento: [],
        collaboratore: null
    }
    const allUsers = useAppSelector(allUsersSelector);
    const currentUserId = allUsers?.find((u: any) => u.email === currentUser.email)?.id?.toString();

    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        const request: SearchUsersRequest = {
            userSearchRequest: {},
        };
        if (!allUsers) {
            dispatch(getUsers(request))
                .unwrap()
                .then((res: any) => {
                    if (res && res.utenti) {
                        setUsers(res.utenti);
                    }
                });
        }
    }, []);


    const getUserNameById = (userId: number) => {
        const userFound = allUsers?.find((u: any) => u.id === userId);
        return userFound ? userFound.nominativo : '';
    };
    useEffect(() => {

        dispatch(fetchProjectMessages(idProject))
            .then((action) => {
                if (fetchProjectMessages.fulfilled.match(action)) {
                    const res = action.payload;
                    const fetchedNotes = res?.note?.map((n: any) => ({
                        ...n,
                        isDeleted: !n.text
                    }));
                    setNotes(fetchedNotes);
                }
            });
    }, [dispatch, notes?.length]);


    const handleSave = () => {
        const noteDate = new Date();
        if (edit) {
            const noteToUpdate = notes?.find(note => note.idNote === edit);
            if (noteToUpdate) {
                const updatedNote = {
                    ...noteToUpdate,
                    text: editText,
                };
                dispatch(updateProjectMessage({
                    noteDto: updatedNote,
                    messageId: updatedNote?.idNote!
                })).then(() => {
                    setNotes((prevNotes) => prevNotes?.map((note) =>
                        note.idNote === updatedNote.idNote ? { ...note, ...updatedNote } : note));
                    setEdit(null);
                    setInput("");
                    setEditText("");
                    setIsEditing(false);
                })
                    .catch((error) => {
                        console.log(error);
                    });;
            }
            setEdit(null);
            setInput("");
            setEditText("");
            setIsEditing(false);
        } else if (input.trim()) {
            const newNote = {
                idProject: idProject,
                text: input,
            };
            dispatch(insertProjectMessage({ projectId: idProject, noteDto: newNote }))
                .then(() => {
                    setNotes(prevNotes => [...(prevNotes || []), newNote]);
                    setInput("");
                })
                .catch((error) => {
                    console.log(error);
                });
            setInput("");
        }
    };

    const handleEdit = (note: any) => {
        setEdit(note.idNote);
        setEditText(note.text);
        setIsEditing(true);
    };

    const handleDelete = (id: number) => {
        dispatch(deleteProjectMessage(id))
            .then(() => {
                setNotes((currentNotes) => currentNotes?.map(note => {
                    if (note.idNote === id) {
                        return { ...note, isDeleted: true, text: "" };
                    }
                    return note;
                }));
            })
            .catch(error => {
                console.error("Failed to delete the note:", error);
            });
        setIsEditing(!isEditing);
        setEdit(!edit);
    };

    const handleCancelEdit = () => {
        setEdit(null);
        setEditText("");
        setInput("");

    };

    const handleReset = () => {
        if (input) {
            setInput("");
            setEditText("");
            setEdit(null);
            setIsEditing(false);
        } else {
            const originalContent = notes?.find(note => note.idNote === edit)?.text || "";
            setEditText(originalContent);
        }
    };
    const openDeleteModal = (id: any, e: React.MouseEvent) => {
        e.stopPropagation();
        setCurrentNoteId(id);
        setShowModal(true);
    };
    const modalContent = (
        <p>{t("project-detail.note.deleteConfirmationMessage")}</p>

    );
    const { RangePicker } = DatePicker;
    const { handleSubmit, control, register, reset } = useForm({
        mode: 'onChange',
        defaultValues: defaultNotesForm,
    });
    function isContentEmpty(htmlString: string) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlString;
        const text = tempDiv.textContent || tempDiv.innerText || "";
        return !text.trim();
    }

    const onSubmit = (data: any) => {
        setDateRange(data.dataCommento);
        setSelectedCollaborator(data.collaboratore);

    }

    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            [{ 'align': [] }],

            // [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'color': [] }],
            ['clean']
        ]
    };

    const filteredNotes = notes?.filter((note) => {
        const noteDate = note?.dataUltimaModifica ? dayjs(note.dataUltimaModifica) : null;
        const startDate = dateRange[0] ? dayjs(dateRange[0]).startOf('day') : null;
        const endDate = dateRange[1] ? dayjs(dateRange[1]).endOf('day') : null;
        const isWithinDateRange = startDate && endDate
            ? (noteDate ? noteDate.isAfter(startDate.subtract(1, 'day')) && noteDate.isBefore(endDate) : true)
            : true;
        const byCollaborator = selectedCollaborator
            ? getUserNameById(+note.author!) === selectedCollaborator
            : true;
        return isWithinDateRange && byCollaborator;
    });

    const formattedDate = (date: Date) => {
        if (date) return format(date, 'dd/MM/yy')
        else return;
        ;
    }

    function initialsFromId(userById: any) {
        let fullName = userById.split(' ');
        let name = fullName?.[0];
        let surname = fullName?.slice(1)?.join(' ');
        return `${surname?.charAt(0) ?? ''}${name?.charAt(0) ?? ''}`.toUpperCase();
    }
    const onReset = () => {
        reset(defaultNotesForm)
    }
    return (
        <>                <Row>
            <Col sm="12" md="6">
                <div className="div-left-container m-0 p-0">
                    <Row>
                        {filteredNotes && filteredNotes.length > 0 ? (

                            filteredNotes.map((note) => {
                                return (
                                    <Col key={note?.idNote} sm={11} className={`${note?.author !== currentUserId ? "note-card-other-user" : "note-card"} ${edit === note.idNote ? "isEditing" : ""} cursor-pointer my-2`} onClick={() => { if (note?.author === currentUserId && !note.isDeleted) { handleEdit(note); } }}>
                                        <div className="d-flex flex-column justify-content-between h-100">
                                            <div className="d-flex">
                                                <div className="d-flex flex-column ms-3">
                                                    <div className="d-flex">
                                                        <span className="profile-img-note me-2">
                                                            <span>{initialsFromId(getUserNameById(+note?.author))}</span>
                                                        </span>
                                                        <div className="d-flex flex-column">
                                                            <b className="username">{note.author ? getUserNameById(+note?.author) : "Utente Sconosciuto"}</b>
                                                            {!note.isDeleted ? <span className="me-auto date-note">{note.edited ? <span className="me-2">{t("project-detail.note.modifiedOnLabel")}</span> : <span className="me-2">{t("project-detail.note.sentOnLabel")}</span>}{formattedDate(note?.dataUltimaModifica!)}</span> :
                                                                <span className="me-auto date-note"><span className="me-2">{t("project-detail.note.modifiedOnLabel")}</span>------</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                {edit !== note.idNote && !note.isDeleted ? (
                                                    <Button disabled={note?.author !== currentUserId} variant="white" className={`${note?.author !== currentUserId && "d-none"} btn-edit-note border-none btn-sm ms-auto mt-0 me-1`} onClick={() => handleEdit(note)}>
                                                        <i className=" fa-solid fa-pencil"></i>
                                                    </Button>
                                                ) : null}
                                            </div>
                                            <div>
                                                {edit === note.idNote ? (
                                                    editText && (
                                                        <div id="#text" className="innerHtml ms-3 content-text ql-editor" dangerouslySetInnerHTML={{ __html: editText }}></div>
                                                    )
                                                ) : (
                                                    note.text ? (
                                                        <div id="#text" className="innerHtml ms-3 content-text  ql-editor" dangerouslySetInnerHTML={{ __html: note.text }}></div>
                                                    ) :
                                                        (
                                                            <div className="ms-3 content-text d-flex ql-editor d-flex"><em>{t("project-detail.note.noteDeleted")}</em></div>
                                                        )
                                                )}
                                                <div className="d-flex bottom-card-note ms-auto me-2 mb-0 p-0 mt-auto btn-cancel-note position-absolute">
                                                    {edit === note.idNote && (
                                                        <Button disabled={note?.author !== currentUserId} variant="transparent" className={`${note.author !== currentUserId && "d-none"} border-none btn-sm ms-0`} onClick={(e: React.MouseEvent) =>
                                                            openDeleteModal(note?.idNote, e)
                                                        }>
                                                            <i className="text-danger fa-solid fa-trash-can"></i>
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            }
                            )
                        ) : (
                            <div className="no-notes" style={{ height: "100%" }}>
                                <i className=" my-3 pt-2 fa-regular fa-clipboard note-icon"></i>
                                <p>{t("No notes found")}</p>
                            </div>
                        )}
                    </Row>
                </div>
            </Col>
            <Col sm="6" className={`div-right-container screen-note`}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <div className="mt-2">
                            <h6 className="text-gray-600 filter-header mt-3 mb-2">
                                <b>{t("project-detail.note.filterMessagesHeader")}</b>
                            </h6>
                        </div>
                        <Col sm="6">
                            <Form.Label>{t("project-detail.note.lastModifiedLabel")}</Form.Label>
                            <Controller
                                name="dataCommento"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <RangePicker
                                        placeholder={[
                                            `${t('project-list.start-date-placeholder')}`,
                                            `${t('project-list.end-date-placeholder')}`,
                                        ]}
                                        className="w-100 createDateRangePicker"
                                        popupClassName="createDateRangePicker"
                                        {...field}
                                        ref={field.ref}
                                        name={field.name}
                                        onBlur={field.onBlur}
                                        format="DD/MM/YYYY"
                                        value={
                                            field.value && field.value.length === 2
                                                ? [dayjs(field.value[0]), dayjs(field.value[1])]
                                                : null
                                        }
                                        size="large"
                                        onChange={(dates: any) => {
                                            field.onChange(dates ? dates.map((date: any) => date.format('YYYY-MM-DD')) : []);
                                        }}
                                    />
                                )}
                            />
                        </Col>
                        <Col sm="6">

                            <Form.Label>{t("project-detail.note.collaboratorLabel")}</Form.Label>
                            <Controller
                                name="collaboratore"
                                control={control}
                                render={({ field }) => {
                                    const uniqueCollaborators: any[] = [];
                                    const collaboratorMap = new Map();
                                    notes?.forEach(note => {
                                        if (!collaboratorMap.has(getUserNameById(+note?.author!))) {
                                            collaboratorMap.set(getUserNameById(+note?.author!), true);
                                            uniqueCollaborators.push({ value: getUserNameById(+note?.author!), label: getUserNameById(+note?.author!) });
                                        }
                                    });

                                    return (
                                        <Select
                                            showSearch
                                            allowClear
                                            className="w-100"
                                            size="large"
                                            placeholder={t('project-list.typology-placeholder')}
                                            options={uniqueCollaborators}
                                            {...field}
                                        />
                                    );
                                }}
                            />
                        </Col>

                    </Row>
                    <div className='d-flex mt-1'>
                        <Button className="btn text-danger trash-btn" type="button" variant="icon" onClick={onReset}>
                            <i className="fa-regular fa-trash-can me-2"></i>
                            {t('project-list.delete-filters')}
                        </Button>
                        <Button type='submit' variant='transparent' className='ms-auto btn-sm btn btn-links-outline'>
                            {t("project-detail.note.filter")}
                        </Button></div>
                </Form>

                <AppModal
                    setShow={setShowModal}
                    show={showModal}
                    title={t("Are you sure?")}
                    cancelText={t("Cancel")}
                    confirmText={t("Delete")}
                    onConfirm={() => currentNoteId && handleDelete(currentNoteId)}
                    children={modalContent}
                />
                <div className="note-input-container mt-auto">
                    <div className=" wrapper-editor my-2">
                        <QuillEditor
                            className={"editor "}
                            theme="snow"
                            value={edit ? editText : input}
                            placeholder={t("Enter a note...")}
                            onChange={(newValue) => edit ? setEditText(newValue) : setInput(newValue)}
                            modules={quillModules}

                        />
                        <div className=' div-buttons d-flex '>

                            {edit && (
                                <Button variant='transparent'
                                    onClick={handleCancelEdit}
                                    className="btn-sm my-1 ms-1 btn-links-danger-outline">
                                    {t("project-detail.note.cancelAction")}
                                </Button>
                            )}
                            {!edit && input &&
                                (<Button variant='transparent' onClick={handleReset} className="reset-btn  "><i className="text-danger fa-solid fa-trash-can"></i></Button>
                                )}
                            <div className='ms-auto my-1 me-1'><Button variant='white' onClick={handleSave}
                                disabled={(!edit && isContentEmpty(input)) ||
                                    (edit && isContentEmpty(editText)) || false}
                                className="btn-sm btn btn-links-outline ">{edit ? t("project-detail.note.updateButton") : t("project-detail.note.saveButton")}</Button></div>
                        </div>

                    </div>
                </div>
            </Col></Row >
        </>
    );
};

export default AppNote;
