/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Quote interface.
 */
export function instanceOfQuote(value) {
    return true;
}
export function QuoteFromJSON(json) {
    return QuoteFromJSONTyped(json, false);
}
export function QuoteFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
        'id': json['id'] == null ? undefined : json['id'],
        'idProject': json['idProject'] == null ? undefined : json['idProject'],
        'bidAmount': json['bidAmount'] == null ? undefined : json['bidAmount'],
        'bidCosts': json['bidCosts'] == null ? undefined : json['bidCosts'],
        'bidDays': json['bidDays'] == null ? undefined : json['bidDays'],
        'bidHrcosts': json['bidHrcosts'] == null ? undefined : json['bidHrcosts'],
        'bidOthercosts': json['bidOthercosts'] == null ? undefined : json['bidOthercosts'],
        'rejected': json['rejected'] == null ? undefined : json['rejected'],
        'creationDate': json['creationDate'] == null ? undefined : (new Date(json['creationDate'])),
        'lastmodifiedDate': json['lastmodifiedDate'] == null ? undefined : (new Date(json['lastmodifiedDate'])),
        'externalQuoteId': json['externalQuoteId'] == null ? undefined : json['externalQuoteId'],
        'idContratto': json['idContratto'] == null ? undefined : json['idContratto'],
    };
}
export function QuoteToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'createdBy': value['createdBy'],
        'modifiedBy': value['modifiedBy'],
        'id': value['id'],
        'idProject': value['idProject'],
        'bidAmount': value['bidAmount'],
        'bidCosts': value['bidCosts'],
        'bidDays': value['bidDays'],
        'bidHrcosts': value['bidHrcosts'],
        'bidOthercosts': value['bidOthercosts'],
        'rejected': value['rejected'],
        'creationDate': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'lastmodifiedDate': value['lastmodifiedDate'] == null ? undefined : ((value['lastmodifiedDate']).toISOString()),
        'externalQuoteId': value['externalQuoteId'],
        'idContratto': value['idContratto'],
    };
}
