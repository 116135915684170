import { Controller, useForm } from 'react-hook-form';
import { useAppSelector } from '../../../../core/store/hooks';
import { Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { t } from 'i18next';
import PriceRange from '../../../../shared/design-system/components/custom-priceRange';
import LinksButton from '../../../../shared/design-system/components/links-button';
import { Session } from '../../../../enums/project.enum';
import { typologiesData } from '../../../../enums/typologies';
import { StatusBadgeData } from '../../../../enums/status';
import TagMultipleSelect from '../../../../shared/design-system/components/app-tag-multiple-select';
import useGetSessionActions from '../_hooks/useGetSessionActions';

export const projecMTFormData: any = {
  projectId: '',
  projectName: '',
  // clientLead: '',
  // projectLead: '',
  businessUnit: null,
  deliveryUnit: null,
  projectType: [],
  stati: [],
  priceRange: [null, null],
};
interface MTFormProps {
  onSubmit: (data: any) => void;
  handleReset: any;
  session: Session;
}
const MTForm: React.FC<MTFormProps> = ({ onSubmit, handleReset, session }) => {
  const { filtersSelector, filtersUsedSelector } = useGetSessionActions(session);
  const filtri = useAppSelector(filtersSelector());
  const filtriUsed = useAppSelector(filtersUsedSelector());
  const [formValues, setFormValues] = useState(projecMTFormData);

  useEffect(() => {
    setFormValues({
      projectId: filtriUsed?.projectId || '',
      projectName: filtriUsed?.projectName || '',
      businessUnit: filtri?.businessUnit?.find((unit) => unit.id === filtriUsed?.businessUnit)?.name || null,
      deliveryUnit: filtri?.deliveryUnit?.find((unit) => unit.id === filtriUsed?.deliveryUnit)?.name || null,
      projectType: filtriUsed?.projectType || [],
      stati: filtriUsed?.stati || [],
      priceRange: [filtriUsed?.amountFrom, filtriUsed?.amountTo],
    });
  }, [filtriUsed, filtri]);

  const { handleSubmit, control, register, setValue, reset } = useForm({
    mode: 'onChange',
    defaultValues: projecMTFormData,
    values: formValues,
  });

  // useEffect(() => {
  //   if (filtriUsed) {
  //     setValue('idProgetto', filtriUsed?.projectId, { shouldValidate: true });
  //     setValue('nomeProgetto', filtriUsed?.projectName, { shouldValidate: true });
  //     setValue('businessUnit', filtri?.businessUnit?.find(unit => unit.id === filtriUsed?.businessUnit)?.name || null, { shouldValidate: true });
  //     setValue('deliveryUnit', filtri?.deliveryUnit?.find(unit => unit.id === filtriUsed?.deliveryUnit)?.name || null, { shouldValidate: true });
  //     setValue('tipologia', filtriUsed?.projectType || [], { shouldValidate: true });
  //     setValue('priceRange', [filtriUsed?.amountFrom, filtriUsed?.amountTo], { shouldValidate: true });
  //   }
  // }, [filtriUsed, setValue, filtri]);

  const Reset = () => {
    reset(projecMTFormData);
    handleReset();
    clearPriceRange();
  };
  const [clearPR, setClearPR] = useState(false);
  const clearPriceRange = () => {
    setClearPR(!clearPR);
  };

  return (
    <Form className="grid" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm="3">
          <Form.Label>{t('cdg.project-id')}</Form.Label>
          <Controller
            name="projectId"
            control={control}
            render={({ field }) => (
              <Input
                type="number"
                placeholder={t('cdg.project-id-placeholder')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('cdg.project-name')}</Form.Label>
          <Controller
            name="projectName"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('cdg.project-name-placeholder')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        {/* <Col sm="3">
          <Form.Label>{t('cdg.client-lead')}</Form.Label>
          <Controller
            name="clientLead"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                size="large"
                className="w-100"
                value={field.value}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
                placeholder={t('cdg.client-lead-placeholder')}
              >
                {filtri?.clientLeads?.map((el, index) => (
                  <Select.Option key={index} value={el.nominativo}>
                    {el.nominativo}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('cdg.project-lead')}</Form.Label>
          <Controller
            name="projectLead"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                size="large"
                className="w-100"
                value={field.value}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
                placeholder={t('cdg.project-lead-placeholder')}
              >
                {filtri?.projectLeads?.map((el, index) => (
                  <Select.Option key={index} value={el.nominativo}>
                    {el.nominativo}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col> */}
        {session !== Session.PL && (
          <>
            <Col sm="3">
              <Form.Label>{t('cdg.business-unit')}</Form.Label>
              <Controller
                name="businessUnit"
                control={control}
                render={({ field }) => (
                  <Select
                    showSearch
                    size="large"
                    className="w-100"
                    allowClear
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    onBlur={field.onBlur}
                    placeholder={t('cdg.business-unit-placeholder')}
                  >
                    {filtri?.businessUnit?.map((el, index) => (
                      <Select.Option key={index} value={el.name}>
                        {el.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
            </Col>
            <Col sm="3">
              <Form.Label>{t('cdg.delivery-unit')}</Form.Label>
              <Controller
                name="deliveryUnit"
                control={control}
                render={({ field }) => (
                  <Select
                    showSearch
                    size="large"
                    className="w-100"
                    allowClear
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    onBlur={field.onBlur}
                    placeholder={t('cdg.delivery-unit-placeholder')}
                  >
                    {filtri?.deliveryUnit?.map((el, index) => (
                      <Select.Option key={index} value={el.name}>
                        {el.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
            </Col>
          </>
        )}
      </Row>
      <Form.Group className="grid mt-3">
        <div className="mt-2">
          <h6 className="text-gray-600 filter-header mt-3 mb-2">
            <b>{t('cdg.filter-by-amount').toUpperCase()}</b>
          </h6>
        </div>
        <Row>
          <Col sm="3">
            <Form.Label>{t('project-list.typology')}</Form.Label>
            <Controller
              name="projectType"
              control={control}
              render={({ field }) => (
                <Select
                  mode="multiple"
                  tagRender={TagMultipleSelect}
                  showSearch
                  allowClear
                  className="w-100"
                  size="large"
                  placeholder={t('project-list.typology-placeholder')}
                  onChange={(value) => field.onChange(value)}
                  onBlur={field.onBlur}
                  value={field.value}
                  options={filtri?.projectType?.map((el, index) => ({
                    value: el,
                    label: typologiesData?.find((type) => type.name === el)?.label,
                  }))}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('project-list.project-states')}</Form.Label>
            <Controller
              name="stati"
              control={control}
              render={({ field }) => (
                <Select
                  mode="multiple"
                  tagRender={TagMultipleSelect}
                  showSearch
                  allowClear
                  className="w-100"
                  size="large"
                  placeholder={t('project-list.select-status-placeholder')}
                  onChange={(value) => field.onChange(value)}
                  onBlur={field.onBlur}
                  value={field.value}
                  options={filtri?.stati?.map((el, index) => ({
                    value: el,
                    label: StatusBadgeData?.find((state) => state.name === el)?.label,
                  }))}
                />
              )}
            />
          </Col>
          <Col sm="3">
            <Form.Label>{t('project-list.import')}</Form.Label>
            <div>
              <Controller
                name="priceRange"
                control={control}
                render={({ field }) => (
                  <PriceRange value={field.value} onPriceRangeChange={field.onChange} clear={clearPR} />
                )}
              />
            </div>
          </Col>
        </Row>
      </Form.Group>
      <div className="d-flex">
        <div className="d-flex ms-auto">
          <Button className="btn text-danger trash-btn" type="button" variant="icon" onClick={Reset}>
            <i className="fa-regular fa-trash-can me-2"></i>
            {t('project-list.delete-filters')}
          </Button>
          <LinksButton type="submit" className="btn-sm btn-primary btn-links px-4">
            <i className="fa-solid fa-magnifying-glass me-2"></i>
            {t('project-list.search')}
          </LinksButton>
        </div>
      </div>
    </Form>
  );
};

export default MTForm;
