import { Switch } from "antd";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LinksButton from "../../../../../../shared/design-system/components/links-button";
import { RevenueColumnsConfig, TotalsRevenuesAndGUStickyLabels } from "../../_utils/revenue-plan.utils";
import React, { useEffect, useState } from "react";
import { t } from "i18next";

interface PropsDC {
    visibleRows: { [key: string]: boolean };
    setVisibleRows: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
    isCollapsed: boolean;
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    isWorkInProgress: boolean;
    visibleColumns: { [key: string]: boolean };
    setVisibleColumns: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
}

const DropdownConfig: React.FC<PropsDC> = ({ setVisibleRows, visibleRows, isCollapsed, setIsCollapsed, isWorkInProgress, visibleColumns, setVisibleColumns }) => {
    const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        e.preventDefault();
    };
    const handleToggle = (key: string, checked: boolean) => {
        setVisibleRows((prev: any) => ({ ...prev, [key]: checked }));
    };
    useEffect(() => {
        localStorage.setItem("visibleRows", JSON.stringify(visibleRows));
    }, [visibleRows]);
    const handleToggleRevenue = (key: string, checked: boolean) => {
        setVisibleColumns((prev) => ({ ...prev, [key]: checked }));

    };

    useEffect(() => {
        localStorage.setItem("visibleColumns", JSON.stringify(visibleColumns));
    }, [visibleColumns]);
    if (
        !isWorkInProgress
    ) {
        TotalsRevenuesAndGUStickyLabels.rate.disabled = true;
        TotalsRevenuesAndGUStickyLabels.revenues.disabled = true;
        RevenueColumnsConfig.worked.disabled = true;
        RevenueColumnsConfig.totalPlanned.disabled = true;
        RevenueColumnsConfig.guRemaining.label = `${t('project-detail.planned-gu')}`;

    } else {
        TotalsRevenuesAndGUStickyLabels.rate.disabled = false;
        TotalsRevenuesAndGUStickyLabels.revenues.disabled = false;
        RevenueColumnsConfig.worked.disabled = false;
        RevenueColumnsConfig.totalPlanned.disabled = false;
        RevenueColumnsConfig.guRemaining.label = `${t('project-detail.gu-remaining')}`;


    }
    return (
        <>
            <Dropdown className="ms-2 nav-item no-arrow">
                <Dropdown.Toggle as="div" className="nav-link">
                    <LinksButton className="btn-sm btn btn-links-outline px-2" onClick={() => { isCollapsed !== false && setIsCollapsed(!isCollapsed) }}>
                        <i className="fa-solid fa-gear"></i>
                    </LinksButton>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-right shadow animated--grow-in drop-width" >
                    {Object.entries(RevenueColumnsConfig).map(([key, el]) => (
                        !el.disabled && (<Dropdown.Item onClick={onClick} key={key} className="d-flex" style={{ backgroundColor: "transparent", color: "black" }}>
                            <span>{el.label}</span>
                            <Switch
                                checked={visibleColumns[key]}
                                className="ms-auto"
                                onChange={(checked) => handleToggleRevenue(key, checked)}
                            />
                        </Dropdown.Item>)
                    ))}
                    <Dropdown.Divider></Dropdown.Divider>
                    <div className="d-flex">
                        <span className="mx-auto text-gray">Footer</span>
                    </div>
                    {Object.entries(TotalsRevenuesAndGUStickyLabels).map(([key, el], index) => (
                        !el.disabled && (
                            <Dropdown.Item key={key} onClick={onClick} className="d-flex" style={{ backgroundColor: "transparent", color: "black" }}>
                                <span>{el.label}</span>
                                <Switch checked={visibleRows[key]} className="ms-auto" onChange={(checked) => handleToggle(key, checked)} />
                            </Dropdown.Item>
                        )
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default DropdownConfig;
