/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectMonthDtoFromJSON, ProjectMonthDtoToJSON, } from './ProjectMonthDto';
/**
 * Check if a given object implements the ProjectMonthListDto interface.
 */
export function instanceOfProjectMonthListDto(value) {
    return true;
}
export function ProjectMonthListDtoFromJSON(json) {
    return ProjectMonthListDtoFromJSONTyped(json, false);
}
export function ProjectMonthListDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'projectList': json['projectList'] == null ? undefined : (json['projectList'].map(ProjectMonthDtoFromJSON)),
    };
}
export function ProjectMonthListDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'projectList': value['projectList'] == null ? undefined : (value['projectList'].map(ProjectMonthDtoToJSON)),
    };
}
