/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectDtoFromJSON, ProjectDtoToJSON, } from './ProjectDto';
import { RendicontazioneMeseDtoFromJSON, RendicontazioneMeseDtoToJSON, } from './RendicontazioneMeseDto';
import { UserDaysDtoFromJSON, UserDaysDtoToJSON, } from './UserDaysDto';
/**
 * Check if a given object implements the DashboardUserDto interface.
 */
export function instanceOfDashboardUserDto(value) {
    return true;
}
export function DashboardUserDtoFromJSON(json) {
    return DashboardUserDtoFromJSONTyped(json, false);
}
export function DashboardUserDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'userName': json['userName'] == null ? undefined : json['userName'],
        'userDaysDtoList': json['userDaysDtoList'] == null ? undefined : (json['userDaysDtoList'].map(UserDaysDtoFromJSON)),
        'projects': json['projects'] == null ? undefined : (json['projects'].map(ProjectDtoFromJSON)),
        'rendicontazioniMeseTotali': json['rendicontazioniMeseTotali'] == null ? undefined : RendicontazioneMeseDtoFromJSON(json['rendicontazioniMeseTotali']),
        'isAttivo': json['isAttivo'] == null ? undefined : json['isAttivo'],
    };
}
export function DashboardUserDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'userName': value['userName'],
        'userDaysDtoList': value['userDaysDtoList'] == null ? undefined : (value['userDaysDtoList'].map(UserDaysDtoToJSON)),
        'projects': value['projects'] == null ? undefined : (value['projects'].map(ProjectDtoToJSON)),
        'rendicontazioniMeseTotali': RendicontazioneMeseDtoToJSON(value['rendicontazioniMeseTotali']),
        'isAttivo': value['isAttivo'],
    };
}
