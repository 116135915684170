import { useEffect, useState } from 'react';
import { TableProps } from '../project-list-table';
import { StatusBadgeData } from '../../../../enums/status';
import { useAppSelector } from '../../../../core/store/hooks';
import { progettiFiltratiSelector } from '../_redux/selectors';
import { format } from 'date-fns';
import { ProgettoDto } from '@api/client';
import { useTranslation } from 'react-i18next';
import AuthService from '../../../../core/auth/auth.service';

const useGetAndMapProjects = () => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState<TableProps['data']>([]);

  const progettiFiltrati = useAppSelector(progettiFiltratiSelector);

  useEffect(() => {
    setProjects(mapProjects(progettiFiltrati));
  }, [progettiFiltrati]);

  const mapProjects = (projects: ProgettoDto[]): TableProps['data'] => {
    return projects.map((project: ProgettoDto) => {
      return {
        id: { label: t('project-list.id'), value: project.id, visible: true },
        nomeProgetto: {
          label: t('project-list.project-name'),
          value: `(${project.intranetId}) ${project.progetto}`,
          visible: true,
        },
        nomeCliente: { label: t('project-list.customer'), value: project.cliente, visible: true },
        businessManager: { label: t('project-list.business-manager'), value: project.businessManager, visible: true },
        projectLeader: { label: t('project-list.project-manager'), value: project.projectLeader, visible: true },
        status: {
          label: t('project-list.status'),
          value: StatusBadgeData.find((x) => x.id === project.stato)?.label || project.stato,
          visible: true,
          statusBadge: StatusBadgeData.find((x) => x.id === project.stato)?.name || project.stato,
        },
        Date: {
          label: t('project-list.date-last-modify'),
          value: project?.dataModifica ? format(new Date(project?.dataModifica), 'dd/MM/yyyy') : '-',
          visible: true,
        },
        azione: {
          label: t('project-list.action'),
          value: '',
          visible: AuthService.isAm() || AuthService.isPl() || AuthService.isDm(),
          isButton: true,
          icon: 'fa fas fa-arrow-right',
          className: 'text-center',
          tooltip: t('project-list.action-tooltip'),
        },
        editDeliveryEnabled: {
          label: '',
          value: project.editDeliveryEnabled,
          visible: false,
        },
      };
    });
  };
  return { projects };
};

export default useGetAndMapProjects;
