import { useState } from 'react';
import { useAppDispatch } from '../../../../core/store/hooks';
import { CloseEconomicsProjectRequest } from '@api/client';
import { closeEconomicsCl } from '../_redux/actions';
import { AppToastService } from '../../../../shared/design-system/components/app-toast/app-toast.service';
import { useTranslation } from 'react-i18next';

const useCloseEconomics = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showConfirmCloseEconomicsModal, setShowConfirmCloseEconomicsModal] = useState(false);
  const [idToClose, setIdToClose] = useState<number | null>(null);

  const closeEconomics = () => {
    setShowConfirmCloseEconomicsModal(false);
    const payload: CloseEconomicsProjectRequest = {
      projectId: idToClose as number,
    };
    dispatch(closeEconomicsCl(payload)).then((res) => {
      if (res) {
        AppToastService.success(t('common.action-completed-successfully'));
      }
    });
  };

  return {
    closeEconomics,
    showConfirmCloseEconomicsModal,
    setShowConfirmCloseEconomicsModal,
    idToClose,
    setIdToClose,
    t,
  };
};

export default useCloseEconomics;
