import { AllocationDto, StaffExtendedDto } from '@api/client';
import { getFormattedDateString } from '../../../../../shared/utils/common.utils';
import { CheckboxOptionType } from 'antd';
import { t } from 'i18next';

export const getFirstAllocationDate = (fullAllocationsShown: Partial<StaffExtendedDto>[]) => {
  if (!fullAllocationsShown || fullAllocationsShown.length === 0) return null;

  const firstAllocation = fullAllocationsShown[0]?.allocazioni?.[0];
  if (!firstAllocation) return null;

  return getFormattedDateString(firstAllocation.month, firstAllocation.year);
};

export const getLastAllocationDate = (fullAllocationsShown: Partial<StaffExtendedDto>[]) => {
  if (!fullAllocationsShown || fullAllocationsShown.length === 0) return null;

  const lastAllocation = fullAllocationsShown[fullAllocationsShown.length - 1]?.allocazioni?.slice(-1)[0];
  if (!lastAllocation) return null;

  return getFormattedDateString(lastAllocation.month, lastAllocation.year);
};

export const getOptionsByYear = (
  monthOptionsObj: {
    label: number | undefined;
    value: string;
  }[],
  year: number
): CheckboxOptionType[] => {
  return monthOptionsObj.filter((el) => el.value.includes(year.toString()));
};

export const getAllocationLavorate = (
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined,
  index: number
) => {
  return fullAllocations?.[index].allocazioni?.filter((el) => el.editEnable === false);
};

export const isAllocationWorked = (allocation: AllocationDto) => {
  return allocation.editEnable === false;
};
export const TotalsRevenuesAndGUStickyLabels = {
  totalGu: {
    key: 'totalGu',
    label: t('project-detail.total-gu'),
    checked: true,
    disabled: true,
  },
  fte: {
    key: 'fte',
    label: t('project-detail.fte'),
    checked: true,
    disabled: false,
  },
  personnelCosts: {
    key: 'personnelCosts',
    label: t('project-detail.personnel-costs'),
    checked: true,
    disabled: false,
  },
  externalCosts: {
    key: 'externalCosts',
    label: t('project-detail.external-costs'),
    checked: true,
    disabled: false,
  },
  totalCosts: {
    key: 'totalCosts',
    label: t('project-detail.total-costs'),
    checked: true,
    disabled: false,
  },
  rate: {
    key: 'rate',
    label: t('project-detail.rate'),
    checked: true,
    disabled: false,
  },
  revenues: {
    key: 'revenues',
    label: t('project-detail.revenues'),
    checked: true,
    disabled: false,
  },
};
export const RevenueColumnsConfig = {
  ppsProfile: {
    key: 'ppsProfile',
    label: `${t('project-detail.pps-profile')}`,
    checked: true,
    disabled: false,
  },
  pptSeniority: {
    key: 'pptSeniority',
    label: `${t('project-detail.ppt-seniority')}`,
    checked: true,
    disabled: false,
  },
  name: {
    key: 'name',
    label: `${t('project-detail.name')}`,
    checked: true,
    disabled: true,
  },
  guRemaining: {
    key: 'guRemaining',
    label: `${t('project-detail.gu-remaining')}`,
    checked: true,
    disabled: false,
  },
  currentMonthRendicontations: {
    key: 'currentMonthRendicontations',
    label: `${t('Reporting')}`,
    checked: true,
    disabled: false,
  },
  worked: {
    key: 'worked',
    label: t('project-detail.worked'),
    checked: true,
    disabled: false,
  },
  totalPlanned: {
    key: 'totalPlanned',
    label: t('project-detail.total-planned'),
    checked: true,
    disabled: false,
  },
};
