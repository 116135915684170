import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LinksLogo from '../../../shared/design-system/assets/svg/logo_bianco_links_small.png';
import LinksButton from '../../../shared/design-system/components/links-button';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from '../../../core/auth/auth.service';
import { UrlEnum } from '../../../enums/url.enum';

const LoginPageSSO: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    accessWithToken();
  }, []);

  const accessWithToken = () => {
    const token = getTokenFromUrl();
    if (token) {
      AuthService.setAuthentication(token);
      AuthService.setUser();
      navigate('/');
    } else {
      navigateToMicrosoftLogin();
    }
  };

  const navigateToMicrosoftLogin = () => {
    window.location.href =
      window.location.hostname !== UrlEnum.HOSTNAME_PRODUCTION
        ? UrlEnum.URL_MICROSOFT_LOGIN_SVIL
        : UrlEnum.URL_MICROSOFT_LOGIN;
  };

  const getTokenFromUrl = () => {
    const url = window.location.href;
    const queryStringIndex = url.indexOf('?');
    if (queryStringIndex !== -1) {
      const queryString = url.substring(queryStringIndex + 1);
      const tokenIndex = queryString.indexOf('token=');
      if (tokenIndex !== -1) {
        const tokenStartIndex = tokenIndex + 6;
        const tokenEndIndex =
          queryString.indexOf('&', tokenStartIndex) !== -1
            ? queryString.indexOf('&', tokenStartIndex)
            : queryString.length;
        const token = queryString.substring(tokenStartIndex, tokenEndIndex);
        return token;
      }
    }
    return null;
  };

  return (
    <div className="wrapper min-vh-100 bg-light">
      <nav className="navbar navbar-expand navbar-light topbar mb-4 static-top shadow app-header bg-gradient-primary justify-content-center">
        <img src={LinksLogo} className="logo" alt="Logo" />
      </nav>

      <div className="d-flex justify-content-center mt-5">
        <Card style={{ width: '500px' }}>
          <h3 className="m-4">{t('login.title')}</h3>
          <Card.Body>
            <div className="d-flex justify-content-center">
              <LinksButton className="btn-primary btn-links w-100" onClick={navigateToMicrosoftLogin}>
                Accedi
              </LinksButton>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default LoginPageSSO;
