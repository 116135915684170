import { Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch } from '../store/hooks';
import AuthService from '../auth/auth.service';
import { PathEnum } from '../router/path.enum';
import { UrlEnum } from '../../enums/url.enum';

const AuthGuard: React.FC = () => {
  const dispatch = useAppDispatch();

  const isAuthenticate = AuthService.isAuthenticated();

  return !!isAuthenticate ? (
    <Outlet />
  ) : (
    <Navigate
      to={
        window.location.hostname !== UrlEnum.HOSTNAME_PRODUCTION &&
        window.location.hostname !== UrlEnum.HOSTNAME_WEB_SVIL
          ? `/${PathEnum.AUTH}`
          : `/${PathEnum.AUTHSSO}`
      }
      replace
    />
  );
};
export default AuthGuard;
