import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { DatePicker, Input, Select, Switch } from 'antd';
// import '../../style.scss';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from '../../../../core/store/hooks';
import LinksButton from '../../../../shared/design-system/components/links-button';
import { filtersDashboardPeopleUsedSelector } from '../_redux/selectors';
import { saveDashboardPeopleFilters } from '../_redux/actions';
import { allUsersSelector } from '../../project-list/project-detail/_redux/selectors';
import { filterOption, generateMeseAnnoLightArray } from '../../../../shared/utils/common.utils';
import TagMultipleSelect from '../../../../shared/design-system/components/app-tag-multiple-select';
import locale from 'antd/es/date-picker/locale/en_US';

export const filtersDashboardPeopleData: any = {
  plUserId: null,
  personaId: null,
  projectName: '',
  idProject: '',
  mesiAnni: [],
  isUserActive: true,
};

interface Props {
  onSubmit: any;
  handleReset: () => void;
}
const FiltersDashboardPeople = ({ onSubmit, handleReset }: Props) => {
  const filterUsed = useAppSelector(filtersDashboardPeopleUsedSelector);
  const allUsers = useAppSelector(allUsersSelector);
  const dispatch = useAppDispatch();
  const { RangePicker } = DatePicker;
  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: filtersDashboardPeopleData,
    values: filterUsed,
  });

  const onDateChange = (dates: any, dateStrings: [string, string], field: ControllerRenderProps<any, 'mesiAnni'>) => {
    const monthYearArray = generateMeseAnnoLightArray(dateStrings);
    field.onChange(monthYearArray);
  };

  const Reset = () => {
    reset();
    handleReset();
  };

  const handleFormSubmit = async (formData: any) => {
    dispatch(saveDashboardPeopleFilters(formData));
    try {
      await onSubmit(formData);
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  return (
    <Form className="grid" onSubmit={handleSubmit(handleFormSubmit)}>
      <Row>
        <Col sm="3">
          <Form.Label>{t('dashboard.resources.filters.id-project')}</Form.Label>
          <Controller
            name="idProject"
            control={control}
            render={({ field }) => (
              <Input
                type="number"
                placeholder={t('project-list.id-project-placeholder')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('dashboard.resources.filters.project-name')}</Form.Label>
          <Controller
            name="projectName"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                placeholder={t('project-list.search-name-project')}
                size="large"
                allowClear
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          {' '}
          <Form.Label>{t('dashboard.resources.filters.project-lead')}</Form.Label>
          <Controller
            name="plUserId"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                size="large"
                className="w-100"
                allowClear
                value={field.value}
                optionFilterProp="children"
                filterOption={filterOption}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
                placeholder={t('Select PL')}
              >
                {allUsers?.map((el, index) => (
                  <Select.Option key={index} value={el.id}>
                    {el.nominativo}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('dashboard.resources.filters.collaborator')}</Form.Label>
          <Controller
            name="personaIdList"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                size="large"
                className="w-100"
                allowClear
                mode="multiple"
                tagRender={TagMultipleSelect}
                value={field.value}
                optionFilterProp="children"
                filterOption={filterOption}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
                placeholder={t('dashboard.resources.filters.select-collaborator-placeholder')}
              >
                {allUsers?.map((el, index) => (
                  <Select.Option key={index} value={el.id}>
                    {el.nominativo}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm="3">
          <Form.Label className="text-gray-links text-sm">{t('dashboard.resources.filters.period')}</Form.Label>
          <Controller
            name="mesiAnni"
            control={control}
            render={({ field }) => (
              <RangePicker
                picker="month"
                format="MM/YYYY"
                size="large"
                className="w-100"
                locale={locale}
                getPopupContainer={(triggerMode) => {
                  return triggerMode.parentNode as HTMLElement;
                }}
                onChange={(dates: any, dateStrings: [string, string]) => onDateChange(dates, dateStrings, field)}
              />
            )}
          />
        </Col>
        <Col sm="3">
          <Form.Label>{t('dashboard.resources.filters.active-resources')}</Form.Label>
          <div>
            <Controller
              name="isUserActive"
              control={control}
              render={({ field }: any) => (
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(checked) => field.onChange(checked)}
                  className="mt-2"
                />
              )}
            />
          </div>
        </Col>
      </Row>

      <div className="d-flex mt-3">
        <div className="d-flex ms-auto">
          <Button className="btn text-danger trash-btn" type="button" variant="icon" onClick={Reset}>
            <i className="fa-regular fa-trash-can me-2"></i>
            {t('project-list.delete-filters')}
          </Button>
          <LinksButton type="submit" className="btn-sm btn-primary btn-links px-4">
            <i className="fa-solid fa-magnifying-glass me-2"></i>
            {t('project-list.search')}
          </LinksButton>
        </div>
      </div>
    </Form>
  );
};
export default FiltersDashboardPeople;
