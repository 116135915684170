import { createAction } from '@reduxjs/toolkit';

const enum GeneralReducer {
  showErrorToast = '[COMMON] Show error toast',
  hideErrorToast = '[COMMON] Hide error toast',
}

export const showErrorToast = createAction(GeneralReducer.showErrorToast);

export const hideErrorToast = createAction(GeneralReducer.hideErrorToast);
