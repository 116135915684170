/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the BaselineOffertaDto interface.
 */
export function instanceOfBaselineOffertaDto(value) {
    return true;
}
export function BaselineOffertaDtoFromJSON(json) {
    return BaselineOffertaDtoFromJSONTyped(json, false);
}
export function BaselineOffertaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'importoOfferta': json['importoOfferta'] == null ? undefined : json['importoOfferta'],
        'ratePianificato': json['ratePianificato'] == null ? undefined : json['ratePianificato'],
        'rateOfferta': json['rateOfferta'] == null ? undefined : json['rateOfferta'],
        'margineProgettoPianificato': json['margineProgettoPianificato'] == null ? undefined : json['margineProgettoPianificato'],
        'margineProgettoOfferta': json['margineProgettoOfferta'] == null ? undefined : json['margineProgettoOfferta'],
        'margineProgettoPianificatoPerc': json['margineProgettoPianificatoPerc'] == null ? undefined : json['margineProgettoPianificatoPerc'],
        'margineProgettoOffertaPerc': json['margineProgettoOffertaPerc'] == null ? undefined : json['margineProgettoOffertaPerc'],
        'totaleGiorniUomoPianificati': json['totaleGiorniUomoPianificati'] == null ? undefined : json['totaleGiorniUomoPianificati'],
        'totaleGiorniUomoOfferta': json['totaleGiorniUomoOfferta'] == null ? undefined : json['totaleGiorniUomoOfferta'],
        'costoTotalePianificato': json['costoTotalePianificato'] == null ? undefined : json['costoTotalePianificato'],
        'costoTotaleOfferta': json['costoTotaleOfferta'] == null ? undefined : json['costoTotaleOfferta'],
        'numeroMembriStaffPianificato': json['numeroMembriStaffPianificato'] == null ? undefined : json['numeroMembriStaffPianificato'],
        'numeroMembriStaffOfferta': json['numeroMembriStaffOfferta'] == null ? undefined : json['numeroMembriStaffOfferta'],
        'sal': json['sal'] == null ? undefined : json['sal'],
        'ricaviTotali': json['ricaviTotali'] == null ? undefined : json['ricaviTotali'],
        'ricaviConsolidati': json['ricaviConsolidati'] == null ? undefined : json['ricaviConsolidati'],
        'costoHrConsolidato': json['costoHrConsolidato'] == null ? undefined : json['costoHrConsolidato'],
        'costoEsternoConsolidato': json['costoEsternoConsolidato'] == null ? undefined : json['costoEsternoConsolidato'],
    };
}
export function BaselineOffertaDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'importoOfferta': value['importoOfferta'],
        'ratePianificato': value['ratePianificato'],
        'rateOfferta': value['rateOfferta'],
        'margineProgettoPianificato': value['margineProgettoPianificato'],
        'margineProgettoOfferta': value['margineProgettoOfferta'],
        'margineProgettoPianificatoPerc': value['margineProgettoPianificatoPerc'],
        'margineProgettoOffertaPerc': value['margineProgettoOffertaPerc'],
        'totaleGiorniUomoPianificati': value['totaleGiorniUomoPianificati'],
        'totaleGiorniUomoOfferta': value['totaleGiorniUomoOfferta'],
        'costoTotalePianificato': value['costoTotalePianificato'],
        'costoTotaleOfferta': value['costoTotaleOfferta'],
        'numeroMembriStaffPianificato': value['numeroMembriStaffPianificato'],
        'numeroMembriStaffOfferta': value['numeroMembriStaffOfferta'],
        'sal': value['sal'],
        'ricaviTotali': value['ricaviTotali'],
        'ricaviConsolidati': value['ricaviConsolidati'],
        'costoHrConsolidato': value['costoHrConsolidato'],
        'costoEsternoConsolidato': value['costoEsternoConsolidato'],
    };
}
