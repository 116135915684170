import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../core/store';

const billingPlanReducer = (state: RootState) => state.private.billingPlan;

export const billingDataSelector = createSelector(billingPlanReducer, (billing) => billing.billingData);

export const isBillEditEnabledSelector = createSelector(billingPlanReducer, (billing) => billing.isBillEditEnabled);

export const billingFieldsSelector = createSelector(billingPlanReducer, (billing) => billing.billingFields);
