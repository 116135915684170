import { UserDto } from '@api/client';
import { StatusBadgeData } from '../../../../../../../enums/status';
import { typologiesData } from '../../../../../../../enums/typologies';
import {
  mappedExpenseItemEnumList,
  mappedOrderContractStatusEnumList,
  mappedOrderProcessingStatusEnumList,
} from '../../../../../../../enums/orderOptions';
import { useAppSelector } from '../../../../../../../core/store/hooks';
import { passiveCycleOptionsSelector } from '../../../../../../../pages/private/passive-cycle/_redux/selectors';

interface Props {
  allUsers: UserDto[] | null;
}

const useRenderChipTooltip = ({ allUsers }: Props) => {
  const passiveCycleFiltersSelector = useAppSelector(passiveCycleOptionsSelector);

  const renderStatusTooltip = (chipValue: any) => {
    if (Array.isArray(chipValue)) {
      return chipValue.map((value) => StatusBadgeData?.find((status) => status.name === value)?.label).join(', ');
    }
    return chipValue;
  };

  const renderTipologyTooltip = (chipValue: any) => {
    if (Array.isArray(chipValue)) {
      return chipValue.map((value) => typologiesData?.find((type) => type.name === value)?.label).join(', ');
    }
    return chipValue;
  };

  const renderCollaboratorListTooltip = (chipValue: any) => {
    if (Array.isArray(chipValue)) {
      return chipValue.map((value) => allUsers?.find((user) => user.id === value)?.nominativo).join(', ');
    }
    return chipValue;
  };

  const renderContractStatusTooltip = (chipValue: any) => {
    if (Array.isArray(chipValue)) {
      return chipValue
        .map((value) => mappedOrderContractStatusEnumList?.find((status) => status.value === value)?.label)
        .join(', ');
    }
    return chipValue;
  };

  const renderOrderProcessingStatusTooltip = (chipValue: any) => {
    if (Array.isArray(chipValue)) {
      return chipValue
        .map((value) => mappedOrderProcessingStatusEnumList?.find((status) => status.value === value)?.label)
        .join(', ');
    }
    return chipValue;
  };

  const renderExpenseItemTooltip = (chipValue: any) => {
    if (Array.isArray(chipValue)) {
      return chipValue
        .map((value) => mappedExpenseItemEnumList?.find((item) => item.value === value)?.label)
        .join(', ');
    }
    return chipValue;
  };

  const renderSupplierTooltip = (chipValue: any) => {
    if (Array.isArray(chipValue)) {
      return chipValue
        .map(
          (value) =>
            passiveCycleFiltersSelector?.fornitoriList?.find((supplier) => supplier.id === value)?.denominazione
        )
        .join(', ');
    }
    return chipValue;
  };

  const renderCategoriesTooltip = (chipValue: any) => {
    if (Array.isArray(chipValue)) {
      return chipValue
        .map(
          (value) => passiveCycleFiltersSelector?.orderCategoryList?.find((category) => category.id === value)?.value
        )
        .join(', ');
    }
    return chipValue;
  };

  const renderLegalEntitiesTooltip = (chipValue: any) => {
    if (Array.isArray(chipValue)) {
      return chipValue
        .map((value) => passiveCycleFiltersSelector?.aziendaList?.find((legalEntity) => legalEntity.id === value)?.nome)
        .join(', ');
    }
    return chipValue;
  };

  return {
    renderStatusTooltip,
    renderTipologyTooltip,
    renderCollaboratorListTooltip,
    renderContractStatusTooltip,
    renderOrderProcessingStatusTooltip,
    renderExpenseItemTooltip,
    renderSupplierTooltip,
    renderCategoriesTooltip,
    renderLegalEntitiesTooltip,
  };
};

export default useRenderChipTooltip;
