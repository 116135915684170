import { createReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NoteDto, ListNoteDto } from '@api/client';
import { fetchProjectMessages, insertProjectMessage, updateProjectMessage, deleteProjectMessage } from './actions';

interface ProjectMessagesState {
  messages: NoteDto[];
}

const initialState: ProjectMessagesState = {
  messages: [],
};

export const projectMessagesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchProjectMessages.fulfilled, (state, action) => {
      state.messages = action.payload?.note || [];
    })
    .addCase(insertProjectMessage.fulfilled, (state, action) => {
      if (action.payload) {
        state.messages.push(action.payload);
      }
    })
    .addCase(updateProjectMessage.fulfilled, (state, action) => {
      const index = state.messages.findIndex(msg => msg.idProject === action.payload?.idProject);
      if (index !== -1 && action.payload) {
        state.messages[index] = action.payload;
      }
    })
    .addCase(deleteProjectMessage.fulfilled, (state, action) => {
      state.messages = state.messages.filter(msg => msg.idProject !== action.payload);
    });
});
