import React from 'react';
import { useBlocker, type unstable_Blocker as Blocker, type unstable_BlockerFunction as BlockerFunction } from 'react-router-dom';

import { DettaglioProjectDto } from '@api/client';
import AppModal from '../../../../../../shared/design-system/components/app-modal';
import { t } from 'i18next';
interface PropsBlocker {
    shouldBlock: BlockerFunction | boolean;
}
const AppBlocker: React.FC<PropsBlocker> = ({ shouldBlock }) => {

    let blocker = useBlocker(shouldBlock);

    function ConfirmNavigation({ blocker }: { blocker: Blocker }) {
        if (blocker.state === 'blocked') {
            return (
                <AppModal
                    show={true}
                    title={t('common.attention')}
                    cancelText={t('common.cancel')}
                    confirmText={t('common.confirm')}
                    onConfirm={() => blocker.proceed?.()}
                    onCancel={() => blocker.reset?.()}
                    children={<p>{t('project-detail.exit-modal-message')}</p>}
                />
            );
        }
        if (blocker.state === 'proceeding') {
            return <p></p>;
        }
        return <p></p>;
    }

    return (
        <>
            {blocker ? <ConfirmNavigation blocker={blocker} /> : null}
        </>
    );
};

export default AppBlocker;
