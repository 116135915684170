import { Route, Routes } from 'react-router-dom';
import LoginPage from './login';
import LoginPageSSO from './login-sso';
import { UrlEnum } from '../../enums/url.enum';

const AuthRouter: React.FC = () => {
  return (
    <Routes>
      {window.location.hostname !== UrlEnum.HOSTNAME_PRODUCTION &&
        window.location.hostname !== UrlEnum.HOSTNAME_WEB_SVIL && <Route path="" element={<LoginPage />} />}
      <Route path="" element={<LoginPageSSO />} />
    </Routes>
  );
};
export default AuthRouter;
