import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../core/store/hooks';
import { useState } from 'react';
import AppCard from '../../../../shared/design-system/components/app-card';
import AppModal from '../../../../shared/design-system/components/app-modal';
import LinksButton from '../../../../shared/design-system/components/links-button';
import { getMonths } from '../../../../shared/utils/common.utils';
import { Select } from 'antd';
import { AggiornaCostiRendicontazioniRequest } from '@api/client';
import { refreshHrCosts } from '../_redux/actions';

const RefreshCdgHrCosts: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [month, setMonth] = useState<number>();
  const [year, setYear] = useState<number>();
  const [confirmVisible, setConfirmVisible] = useState(false);

  const confirmActionAndCloseModal = () => {
    if (!month || !year) return;

    const payload: AggiornaCostiRendicontazioniRequest = {
      mese: month,
      anno: year,
    };
    dispatch(refreshHrCosts(payload)).then(() => setConfirmVisible(false));
  };

  const openConfirmModal = () => {
    setConfirmVisible(true);
  };

  return (
    <AppCard title={t('common.refresh-hr-costs')} className="mt-3">
      <>
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label>{t('wfm.month')}</label>
              <Select
                className="w-100"
                placeholder={t('Select month') as string}
                size="large"
                onChange={(value) => {
                  setMonth(value);
                }}
                value={month}
              >
                {getMonths().map((el) => (
                  <Select.Option key={el.month} value={el.month}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>{t('wfm.year')}</label>
              <input
                type="number"
                placeholder={t('Insert year') as string}
                name="year"
                value={year}
                onChange={(e) => setYear(e.target.value as unknown as number)}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-3 align-self-end mb-1">
            <LinksButton className="btn btn-links" onClick={openConfirmModal} isDisabled={!month || !year}>
              {t('common.refresh-hr-costs')}
            </LinksButton>
          </div>
        </div>{' '}
      </>

      <AppModal
        setShow={setConfirmVisible}
        show={confirmVisible}
        title={t('common.attention')}
        cancelText={t('common.cancel')}
        confirmText={t('common.confirm')}
        onConfirm={confirmActionAndCloseModal}
        onCancel={() => setConfirmVisible(false)}
      >
        <p>{t('cdg.refresh-hr-costs.message-modal-confirm')}</p>
      </AppModal>
    </AppCard>
  );
};

export default RefreshCdgHrCosts;
