/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TipologiaBloccoFromJSON, TipologiaBloccoToJSON, } from './TipologiaBlocco';
/**
 * Check if a given object implements the StatoAvanzamentoLavori interface.
 */
export function instanceOfStatoAvanzamentoLavori(value) {
    return true;
}
export function StatoAvanzamentoLavoriFromJSON(json) {
    return StatoAvanzamentoLavoriFromJSONTyped(json, false);
}
export function StatoAvanzamentoLavoriFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'isInviato': json['isInviato'] == null ? undefined : json['isInviato'],
        'dataDiInvio': json['dataDiInvio'] == null ? undefined : (new Date(json['dataDiInvio'])),
        'importo': json['importo'] == null ? undefined : json['importo'],
        'idTipologiaBlocco': json['idTipologiaBlocco'] == null ? undefined : TipologiaBloccoFromJSON(json['idTipologiaBlocco']),
        'lastmodifiedDate': json['lastmodifiedDate'] == null ? undefined : (new Date(json['lastmodifiedDate'])),
        'creationDate': json['creationDate'] == null ? undefined : (new Date(json['creationDate'])),
    };
}
export function StatoAvanzamentoLavoriToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'isInviato': value['isInviato'],
        'dataDiInvio': value['dataDiInvio'] == null ? undefined : ((value['dataDiInvio']).toISOString()),
        'importo': value['importo'],
        'idTipologiaBlocco': TipologiaBloccoToJSON(value['idTipologiaBlocco']),
        'lastmodifiedDate': value['lastmodifiedDate'] == null ? undefined : ((value['lastmodifiedDate']).toISOString()),
        'creationDate': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
    };
}
