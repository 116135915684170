import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.scss';
import { useAppDispatch } from '../../../../core/store/hooks';
import { hideErrorToast } from '../../../../core/store/actions';

const AppToast: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <ToastContainer
      className="app-toast"
      position="top-center"
      autoClose={5000}
      closeButton={true}
      theme="colored"
      icon={({ theme, type }) => {
        if (type === 'success') return <i className="fas fa-check-circle" />;

        if (type === 'warning') return <i className="fas fa-exclamation-triangle" />;

        if (type === 'error') return <i className="fas fa-times" />;
      }}
      closeOnClick
      onClick={() => {
        dispatch(hideErrorToast());
      }}
    />
  );
};
export default AppToast;
