import { ChangeEventHandler, FocusEventHandler } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  name: string;
  value: string | number | string[] | undefined;

  type?: 'text' | 'number' | 'date' | 'password';
  placeholder?: string;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  className?: string;
  isInvalid?: boolean;
  error?: string;
}

const AppInput: React.FC<Props> = (props) => {
  const { label, error, isInvalid, className } = props;

  return (
    <Form.Group className={className}>
      {label && <Form.Label>{label}</Form.Label>}

      <Form.Control {...props} isInvalid={isInvalid} />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};
export default AppInput;
