import { PersonaOptions } from '@nlux/react';
import chatmouse from '../../../design-system/assets/svg/chatmouse_Black2.svg';
import userchatbot from '../../../design-system/assets/svg/userchatbot.svg';
import topinaAnimata from '../../../design-system/assets/svg/Topina_Animata_1.json';
import Lottie from 'react-lottie';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: topinaAnimata,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const personas: PersonaOptions = {
  assistant: {
    name: 'Toppy',
    avatar: chatmouse,
    tagline: 'Assistente AI di TopStar',
  },
  user: {
    name: 'Utente',
    avatar: userchatbot,
  },
};
