import { Card } from 'react-bootstrap';
import LinksButton from '../../../../../../shared/design-system/components/links-button';
import { useTranslation } from 'react-i18next';
import NoData from '../../../../../../shared/design-system/components/no-data-box';
import { MeseAnnoLight } from '@api/client';

interface Props {
  readOnly: boolean | undefined;
  projectMonths: MeseAnnoLight[] | undefined;
  handleShowModal: () => void;
  startPlanningInTable: () => void;
}

const StartRevenuePlanning: React.FC<Props> = ({
  readOnly,
  projectMonths,
  handleShowModal,
  startPlanningInTable,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header className="card-header-border">
        <div className="d-flex align-items-center">
          <b>{t('project-detail.revenue-plan')}</b>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h6 className="my-3">{t('project-detail.no-planning')}</h6>
          <NoData />
          <div className="mt-3">
            {projectMonths?.length && (
              <LinksButton
                className="btn-sm btn-links-outline px-4"
                isDisabled={readOnly}
                onClick={startPlanningInTable}
              >
                {t('project-detail.plan-in-table')}
              </LinksButton>
            )}

            <LinksButton className="btn-sm btn-primary btn-links px-4" isDisabled={readOnly} onClick={handleShowModal}>
              {t('project-detail.plan-by-team')}
            </LinksButton>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default StartRevenuePlanning;
