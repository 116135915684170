/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the QuoteEntity interface.
 */
export function instanceOfQuoteEntity(value) {
    return true;
}
export function QuoteEntityFromJSON(json) {
    return QuoteEntityFromJSONTyped(json, false);
}
export function QuoteEntityFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'createdDate': json['CreatedDate'] == null ? undefined : json['CreatedDate'],
        'createdById': json['CreatedById'] == null ? undefined : json['CreatedById'],
        'quoteIdC': json['QuoteId__c'] == null ? undefined : json['QuoteId__c'],
        'opportunityIdC': json['OpportunityId__c'] == null ? undefined : json['OpportunityId__c'],
        'typeC': json['Type__c'] == null ? undefined : json['Type__c'],
        'accountNameC': json['AccountName__c'] == null ? undefined : json['AccountName__c'],
        'accountIdC': json['AccountId__c'] == null ? undefined : json['AccountId__c'],
        'estimateStartC': json['EstimateStart__c'] == null ? undefined : json['EstimateStart__c'],
        'estimateEndC': json['EstimateEnd__c'] == null ? undefined : json['EstimateEnd__c'],
        'ownerC': json['Owner__c'] == null ? undefined : json['Owner__c'],
        'amountC': json['Amount__c'] == null ? undefined : json['Amount__c'],
        'codeBudgetPresalesC': json['CodeBudgetPresales__c'] == null ? undefined : json['CodeBudgetPresales__c'],
        'presalesEstimateEffortC': json['PresalesEstimateEffort__c'] == null ? undefined : json['PresalesEstimateEffort__c'],
        'dueDateforProposalC': json['DueDateforProposal__c'] == null ? undefined : json['DueDateforProposal__c'],
        'projectLeadEmailC': json['ProjectLeadEmail__c'] == null ? undefined : json['ProjectLeadEmail__c'],
        'projectLeadC': json['ProjectLead__c'] == null ? undefined : json['ProjectLead__c'],
        'nameC': json['Name__c'] == null ? undefined : json['Name__c'],
        'descriptionC': json['Description__c'] == null ? undefined : json['Description__c'],
        'ownerEmailC': json['OwnerEmail__c'] == null ? undefined : json['OwnerEmail__c'],
        'createByEmailC': json['CreateByEmail__c'] == null ? undefined : json['CreateByEmail__c'],
        'mailResponsabileStrutturaDeliveryC': json['MailResponsabileStrutturaDelivery__c'] == null ? undefined : json['MailResponsabileStrutturaDelivery__c'],
        'mailResponsabileStrutturaBusinessC': json['MailResponsabileStrutturaBusiness__c'] == null ? undefined : json['MailResponsabileStrutturaBusiness__c'],
        'idAziendaC': json['IdAzienda__c'] == null ? undefined : json['IdAzienda__c'],
        'revenueTypeC': json['RevenueType__c'] == null ? undefined : json['RevenueType__c'],
    };
}
export function QuoteEntityToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'CreatedDate': value['createdDate'],
        'CreatedById': value['createdById'],
        'QuoteId__c': value['quoteIdC'],
        'OpportunityId__c': value['opportunityIdC'],
        'Type__c': value['typeC'],
        'AccountName__c': value['accountNameC'],
        'AccountId__c': value['accountIdC'],
        'EstimateStart__c': value['estimateStartC'],
        'EstimateEnd__c': value['estimateEndC'],
        'Owner__c': value['ownerC'],
        'Amount__c': value['amountC'],
        'CodeBudgetPresales__c': value['codeBudgetPresalesC'],
        'PresalesEstimateEffort__c': value['presalesEstimateEffortC'],
        'DueDateforProposal__c': value['dueDateforProposalC'],
        'ProjectLeadEmail__c': value['projectLeadEmailC'],
        'ProjectLead__c': value['projectLeadC'],
        'Name__c': value['nameC'],
        'Description__c': value['descriptionC'],
        'OwnerEmail__c': value['ownerEmailC'],
        'CreateByEmail__c': value['createByEmailC'],
        'MailResponsabileStrutturaDelivery__c': value['mailResponsabileStrutturaDeliveryC'],
        'MailResponsabileStrutturaBusiness__c': value['mailResponsabileStrutturaBusinessC'],
        'IdAzienda__c': value['idAziendaC'],
        'RevenueType__c': value['revenueTypeC'],
    };
}
