/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the DettaglioMeseAnnoDto interface.
 */
export function instanceOfDettaglioMeseAnnoDto(value) {
    return true;
}
export function DettaglioMeseAnnoDtoFromJSON(json) {
    return DettaglioMeseAnnoDtoFromJSONTyped(json, false);
}
export function DettaglioMeseAnnoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'mese': json['mese'] == null ? undefined : json['mese'],
        'anno': json['anno'] == null ? undefined : json['anno'],
        'fkCcAppartenenza': json['fkCcAppartenenza'] == null ? undefined : json['fkCcAppartenenza'],
        'sftAfGuOthAssenza': json['sftAfGuOthAssenza'] == null ? undefined : json['sftAfGuOthAssenza'],
        'sftAfGuMalattia': json['sftAfGuMalattia'] == null ? undefined : json['sftAfGuMalattia'],
        'stfAfGuFerieTot': json['stfAfGuFerieTot'] == null ? undefined : json['stfAfGuFerieTot'],
        'stfAfFteTot': json['stfAfFteTot'] == null ? undefined : json['stfAfFteTot'],
        'stfAfGuTot': json['stfAfGuTot'] == null ? undefined : json['stfAfGuTot'],
    };
}
export function DettaglioMeseAnnoDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'mese': value['mese'],
        'anno': value['anno'],
        'fkCcAppartenenza': value['fkCcAppartenenza'],
        'sftAfGuOthAssenza': value['sftAfGuOthAssenza'],
        'sftAfGuMalattia': value['sftAfGuMalattia'],
        'stfAfGuFerieTot': value['stfAfGuFerieTot'],
        'stfAfFteTot': value['stfAfFteTot'],
        'stfAfGuTot': value['stfAfGuTot'],
    };
}
