/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SeniorityDtoFromJSON, SeniorityDtoToJSON, } from './SeniorityDto';
/**
 * Check if a given object implements the ProfiloProfessionaleDto interface.
 */
export function instanceOfProfiloProfessionaleDto(value) {
    return true;
}
export function ProfiloProfessionaleDtoFromJSON(json) {
    return ProfiloProfessionaleDtoFromJSONTyped(json, false);
}
export function ProfiloProfessionaleDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idPps': json['idPps'] == null ? undefined : json['idPps'],
        'descrizionePps': json['descrizionePps'] == null ? undefined : json['descrizionePps'],
        'pptList': json['pptList'] == null ? undefined : (json['pptList'].map(SeniorityDtoFromJSON)),
    };
}
export function ProfiloProfessionaleDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idPps': value['idPps'],
        'descrizionePps': value['descrizionePps'],
        'pptList': value['pptList'] == null ? undefined : (value['pptList'].map(SeniorityDtoToJSON)),
    };
}
