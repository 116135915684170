/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BaselineOffertaDtoFromJSON, BaselineOffertaDtoToJSON, } from './BaselineOffertaDto';
import { CompetenzeProgettoDtoFromJSON, CompetenzeProgettoDtoToJSON, } from './CompetenzeProgettoDto';
import { DettagliProgettoDtoFromJSON, DettagliProgettoDtoToJSON, } from './DettagliProgettoDto';
import { ExternalCostDtoFromJSON, ExternalCostDtoToJSON, } from './ExternalCostDto';
import { GiorniLavorativiMeseAnnoDtoFromJSON, GiorniLavorativiMeseAnnoDtoToJSON, } from './GiorniLavorativiMeseAnnoDto';
import { MeseAnnoLightFromJSON, MeseAnnoLightToJSON, } from './MeseAnnoLight';
import { RevenueMonthDtoFromJSON, RevenueMonthDtoToJSON, } from './RevenueMonthDto';
import { RevenuePlanDtoFromJSON, RevenuePlanDtoToJSON, } from './RevenuePlanDto';
import { StaffExtendedDtoFromJSON, StaffExtendedDtoToJSON, } from './StaffExtendedDto';
/**
 * Check if a given object implements the DettaglioProjectDto interface.
 */
export function instanceOfDettaglioProjectDto(value) {
    return true;
}
export function DettaglioProjectDtoFromJSON(json) {
    return DettaglioProjectDtoFromJSONTyped(json, false);
}
export function DettaglioProjectDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'editDeliveryEnabled': json['editDeliveryEnabled'] == null ? undefined : json['editDeliveryEnabled'],
        'readOnly': json['readOnly'] == null ? undefined : json['readOnly'],
        'dettaglioOfferta': json['dettaglioOfferta'] == null ? undefined : DettagliProgettoDtoFromJSON(json['dettaglioOfferta']),
        'baselineOfferta': json['baselineOfferta'] == null ? undefined : BaselineOffertaDtoFromJSON(json['baselineOfferta']),
        'competenzeComplessive': json['competenzeComplessive'] == null ? undefined : CompetenzeProgettoDtoFromJSON(json['competenzeComplessive']),
        'competenzeAnnoCorrente': json['competenzeAnnoCorrente'] == null ? undefined : CompetenzeProgettoDtoFromJSON(json['competenzeAnnoCorrente']),
        'staffList': json['staffList'] == null ? undefined : (json['staffList'].map(StaffExtendedDtoFromJSON)),
        'otherCosts': json['otherCosts'] == null ? undefined : (json['otherCosts'].map(ExternalCostDtoFromJSON)),
        'giorniLavorativi': json['giorniLavorativi'] == null ? undefined : (json['giorniLavorativi'].map(GiorniLavorativiMeseAnnoDtoFromJSON)),
        'revenuePlan': json['revenuePlan'] == null ? undefined : (json['revenuePlan'].map(RevenuePlanDtoFromJSON)),
        'mesiProgetto': json['mesiProgetto'] == null ? undefined : (json['mesiProgetto'].map(MeseAnnoLightFromJSON)),
        'ricaviMeseDaConsolidare': json['ricaviMeseDaConsolidare'] == null ? undefined : RevenueMonthDtoFromJSON(json['ricaviMeseDaConsolidare']),
    };
}
export function DettaglioProjectDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'editDeliveryEnabled': value['editDeliveryEnabled'],
        'readOnly': value['readOnly'],
        'dettaglioOfferta': DettagliProgettoDtoToJSON(value['dettaglioOfferta']),
        'baselineOfferta': BaselineOffertaDtoToJSON(value['baselineOfferta']),
        'competenzeComplessive': CompetenzeProgettoDtoToJSON(value['competenzeComplessive']),
        'competenzeAnnoCorrente': CompetenzeProgettoDtoToJSON(value['competenzeAnnoCorrente']),
        'staffList': value['staffList'] == null ? undefined : (value['staffList'].map(StaffExtendedDtoToJSON)),
        'otherCosts': value['otherCosts'] == null ? undefined : (value['otherCosts'].map(ExternalCostDtoToJSON)),
        'giorniLavorativi': value['giorniLavorativi'] == null ? undefined : (value['giorniLavorativi'].map(GiorniLavorativiMeseAnnoDtoToJSON)),
        'revenuePlan': value['revenuePlan'] == null ? undefined : (value['revenuePlan'].map(RevenuePlanDtoToJSON)),
        'mesiProgetto': value['mesiProgetto'] == null ? undefined : (value['mesiProgetto'].map(MeseAnnoLightToJSON)),
        'ricaviMeseDaConsolidare': RevenueMonthDtoToJSON(value['ricaviMeseDaConsolidare']),
    };
}
