import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from '../../../../core/store';
import {
  AggiornaCostiRendicontazioniMesiRequest,
  ApplicationControlDto,
  Configuration,
  ConsolidaCostiRendicontazioniRequest,
  LongTermActivityDto,
  SpalmaCostiCoordinamentoRequest,
  WFMControllerApi,
} from '@api/client';
import getConfiguration, { errorHandler } from '../../../../core/store/config';
import { AppToastService } from '../../../../shared/design-system/components/app-toast/app-toast.service';

enum WFMActions {
  consolidateCosts = '[WFM] Consolidate Costs',
  activateStateCdg = '[WFM] Activate State CDG',
  activateStateCl = '[WFM] Activate State CL',
  deactivateStateCdg = '[WFM] Deactivate State CDG',
  workflowInfo = '[WFM] Workflow Informations',
  activityInfo = '[WFM] Activity Informations',
  spreadCoordinationCosts = '[WFM] Spread Coordination Costs',
  updateCostsReportingMonths = '[WFM] Update Costs Reporting Months',
  updateOtherCostsMonths = '[WFM] Update Other Costs Months',
}

export const consolidateCosts = createAsyncThunk<
  string | undefined,
  ConsolidaCostiRendicontazioniRequest,
  ThunkApiConfig
>(WFMActions.consolidateCosts, (payload, { dispatch, extra, getState }) => {
  try {
    new WFMControllerApi(getConfiguration(Configuration, extra, getState)).consolidaCostiRendicontazioni(payload);
    AppToastService.success(`It will take a few minutes, keep browsing`);
    return undefined;
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const activateStateCdg = createAsyncThunk<string | undefined, void, ThunkApiConfig>(
  WFMActions.activateStateCdg,
  async (_, { dispatch, extra, getState }) => {
    try {
      const res = await new WFMControllerApi(
        getConfiguration(Configuration, extra, getState)
      ).statoControlloCDGAttivo();
      if (res) {
        AppToastService.success(`CDG session opened successfully`);
      }
      return res;
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const deactivateStateCdg = createAsyncThunk<string | undefined, void, ThunkApiConfig>(
  WFMActions.deactivateStateCdg,
  async (_, { dispatch, extra, getState }) => {
    try {
      const res = await new WFMControllerApi(
        getConfiguration(Configuration, extra, getState)
      ).chiudiProcessoControlloCosti();
      if (res) {
        AppToastService.success(`CDG session closed successfully`);
      }
      return res;
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const activateStateCl = createAsyncThunk<string | undefined, void, ThunkApiConfig>(
  WFMActions.activateStateCl,
  async (_, { dispatch, extra, getState }) => {
    try {
      const res = await new WFMControllerApi(getConfiguration(Configuration, extra, getState)).statoControlloCLAttivo();
      if (res) {
        AppToastService.success(`CL session opened successfully`);
      }
      return res;
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);
export const getWorkflowInfo = createAsyncThunk<ApplicationControlDto | undefined, void, ThunkApiConfig>(
  WFMActions.workflowInfo,
  async (_, { dispatch, extra, getState }) => {
    try {
      const api = new WFMControllerApi(getConfiguration(Configuration, extra, getState));
      const res = await api.workflowInfo();
      if (res) {
        return res;
      } else {
        console.error(`Failed to retrieve workflow information`);
        return undefined;
      }
    } catch (e) {
      errorHandler(e, dispatch, getState);
      return undefined;
    }
  }
);

export const getActivityInfo = createAsyncThunk<LongTermActivityDto | undefined, void, ThunkApiConfig>(
  WFMActions.activityInfo,
  async (_, { dispatch, extra, getState }) => {
    try {
      const api = new WFMControllerApi(getConfiguration(Configuration, extra, getState));
      const res = await api.activityInfo();
      if (res) {
        return res;
      } else {
        console.error(`Failed to retrieve activity information`);
        return undefined;
      }
    } catch (e) {
      errorHandler(e, dispatch, getState);
      return undefined;
    }
  }
);

export const spreadCoordinationCosts = createAsyncThunk<void, SpalmaCostiCoordinamentoRequest, ThunkApiConfig>(
  WFMActions.spreadCoordinationCosts,
  async (payload, { dispatch, extra, getState }) => {
    try {
      const res = await new WFMControllerApi(getConfiguration(Configuration, extra, getState)).spalmaCostiCoordinamento(
        payload
      );
      if (res) {
        AppToastService.success(`Coordination costs spread successfully`);
      }
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const updateCostsReportingMonths = createAsyncThunk<
  void,
  AggiornaCostiRendicontazioniMesiRequest,
  ThunkApiConfig
>(WFMActions.updateCostsReportingMonths, async (payload, { dispatch, extra, getState }) => {
  try {
    const res = await new WFMControllerApi(
      getConfiguration(Configuration, extra, getState)
    ).aggiornaCostiRendicontazioniMesi(payload);
    if (res) {
      AppToastService.success(`Reporting costs updated successfully`);
    }
  } catch (e) {
    errorHandler(e, dispatch, getState);
  }
});

export const updateOtherCostsMonths = createAsyncThunk<void, AggiornaCostiRendicontazioniMesiRequest, ThunkApiConfig>(
  WFMActions.updateOtherCostsMonths,
  async (payload, { dispatch, extra, getState }) => {
    try {
      const res = await new WFMControllerApi(
        getConfiguration(Configuration, extra, getState)
      ).aggiornaAltriCostiDaRendicontazioniMesi(payload);
      if (res) {
        AppToastService.success(`Other costs updated successfully`);
      }
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);
