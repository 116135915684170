import {
  DashboardBookingListDto,
  DashboardProjectDto,
  DashboardProjectFilterDto,
  DashboardProjectFilterInputDto,
  DashboardProjectTotalsDto,
  DashboardUserListDto,
  FilterDtoStatoEnum,
  UserListFilterDto,
} from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import {
  getDashboardProjectFilters,
  getDashboardProjects,
  getDashboardProjectsCurrentYear,
  getDashboardUsers,
  getOverbookingUsers,
  getUnderbookingUsers,
  saveDashboardPeopleFilters,
  saveDashboardProjectFilters,
} from './actions';

interface InitialState {
  dashboardProjects: DashboardProjectDto[] | undefined | null;
  dashboardProjectTotals: DashboardProjectTotalsDto | undefined | null;
  dashboardProjectsCurrentYear: DashboardProjectDto[] | undefined | null;
  dashboardProjectTotalsCurrentYear: DashboardProjectTotalsDto | undefined | null;
  dashboardUsers: DashboardUserListDto | undefined | null;
  dashboardUnderbookingUsers: DashboardBookingListDto | undefined | null;
  dashboardOverbookingUsers: DashboardBookingListDto | undefined | null;
  filtersDashboardProject: DashboardProjectFilterDto | undefined | null;
  filtersDashboardProjectUsed: DashboardProjectFilterInputDto | undefined | null;
  filtersDashboardPeopleUsed: UserListFilterDto | undefined | null;
}

const initialState: InitialState = {
  dashboardProjects: [],
  dashboardProjectTotals: null,
  dashboardProjectsCurrentYear: [],
  dashboardProjectTotalsCurrentYear: null,
  dashboardUsers: null,
  dashboardUnderbookingUsers: null,
  dashboardOverbookingUsers: null,
  filtersDashboardProject: null,
  filtersDashboardProjectUsed: {
    projectStatus: [
      // FilterDtoStatoEnum.Offering,
      FilterDtoStatoEnum.Pending,
      FilterDtoStatoEnum.Planning,
      FilterDtoStatoEnum.ClosedLost,
      FilterDtoStatoEnum.IntranetPending,
      FilterDtoStatoEnum.WorkInProgress,
      FilterDtoStatoEnum.ClosedWonPlanning,
      FilterDtoStatoEnum.PlanningOk,
      // FilterDtoStatoEnum.WorkInProgressDraft,
      FilterDtoStatoEnum.ControlloCdg,
      FilterDtoStatoEnum.ControlloCl,
      // FilterDtoStatoEnum.ControlloResponsabile,
      FilterDtoStatoEnum.EconomicsClosed,
      FilterDtoStatoEnum.ReportingClosed,
    ],
  },
  filtersDashboardPeopleUsed: null,
};

export const dashboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getDashboardProjects.fulfilled, (state, action) => {
      state.dashboardProjects = action.payload?.projects;
      state.dashboardProjectTotals = action.payload?.totals;
    })
    .addCase(getDashboardProjectsCurrentYear.fulfilled, (state, action) => {
      state.dashboardProjectsCurrentYear = action.payload?.projects;
      state.dashboardProjectTotalsCurrentYear = action.payload?.totals;
    })
    .addCase(getDashboardUsers.fulfilled, (state, action) => {
      state.dashboardUsers = action.payload;
    })
    .addCase(getUnderbookingUsers.fulfilled, (state, action) => {
      state.dashboardUnderbookingUsers = action.payload;
    })
    .addCase(getOverbookingUsers.fulfilled, (state, action) => {
      state.dashboardOverbookingUsers = action.payload;
    })
    .addCase(getDashboardProjectFilters.fulfilled, (state, action) => {
      state.filtersDashboardProject = action.payload;
    })
    .addCase(saveDashboardProjectFilters, (state, action) => {
      state.filtersDashboardProjectUsed = action.payload;
    })
    .addCase(saveDashboardPeopleFilters, (state, action) => {
      state.filtersDashboardPeopleUsed = action.payload;
    });
});
