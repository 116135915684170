import { toast } from "react-toastify";

export namespace AppToastService {
	export const success = (message: string) => {
		toast.success(message);
	};

	export const error = (message: string) => {
		toast.error(message);
	};

	export const warning = (message: string) => {
		toast.warning(message);
	};
}
