import { createReducer } from '@reduxjs/toolkit';
import { layoutToggleSidebar } from './actions';

interface InitialState {
  sidebarToggled: boolean;
}

const initialState: InitialState = {
  sidebarToggled: true,
};

export const layoutReducer = createReducer(initialState, (builder) => {
  builder.addCase(layoutToggleSidebar, (state) => {
    state.sidebarToggled = !state.sidebarToggled;
  });
});
