import { useAccordionButton } from "react-bootstrap";

const CustomToggle = ({ children, eventKey }: any) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => { });

    return (
        <button
            type="button"
            style={{
                backgroundColor: 'transparent',
                border: 'none',
            }}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
};
export default CustomToggle;