/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AllocationDtoFromJSON, AllocationDtoToJSON, } from './AllocationDto';
/**
 * Check if a given object implements the StaffExtendedDto interface.
 */
export function instanceOfStaffExtendedDto(value) {
    return true;
}
export function StaffExtendedDtoFromJSON(json) {
    return StaffExtendedDtoFromJSONTyped(json, false);
}
export function StaffExtendedDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idPpt': json['idPpt'] == null ? undefined : json['idPpt'],
        'idPps': json['idPps'] == null ? undefined : json['idPps'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'name': json['name'] == null ? undefined : json['name'],
        'cost': json['cost'] == null ? undefined : json['cost'],
        'allocazioni': json['allocazioni'] == null ? undefined : (json['allocazioni'].map(AllocationDtoFromJSON)),
        'active': json['active'] == null ? undefined : json['active'],
    };
}
export function StaffExtendedDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idPpt': value['idPpt'],
        'idPps': value['idPps'],
        'userId': value['userId'],
        'name': value['name'],
        'cost': value['cost'],
        'allocazioni': value['allocazioni'] == null ? undefined : (value['allocazioni'].map(AllocationDtoToJSON)),
        'active': value['active'],
    };
}
