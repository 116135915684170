import { BillingDto } from '@api/client';
import saveAs from 'file-saver';
import { createEvent, EventAttributes } from 'ics';

const useDownloadBillIcs = (projectName: string | undefined) => {
  const downloadIcs = (billing: BillingDto) => {
    const date = billing.date || new Date();
    const event: EventAttributes = {
      start: [date.getFullYear(), date.getMonth(), date.getDate()],
      duration: { hours: 8, minutes: 0 },
      title: `I.M. Fattura - ${projectName}`,
      description: billing.name,
    };
    createEvent(event, (error, value) => {
      const blob = new Blob([value], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'event-schedule.ics');
    });
  };

  return { downloadIcs };
};

export default useDownloadBillIcs;
