/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DashboardProjectDtoFromJSON, DashboardProjectDtoToJSON, } from './DashboardProjectDto';
import { DashboardProjectTotalsDtoFromJSON, DashboardProjectTotalsDtoToJSON, } from './DashboardProjectTotalsDto';
/**
 * Check if a given object implements the DashboardProjectListDto interface.
 */
export function instanceOfDashboardProjectListDto(value) {
    return true;
}
export function DashboardProjectListDtoFromJSON(json) {
    return DashboardProjectListDtoFromJSONTyped(json, false);
}
export function DashboardProjectListDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'projects': json['projects'] == null ? undefined : (json['projects'].map(DashboardProjectDtoFromJSON)),
        'totals': json['totals'] == null ? undefined : DashboardProjectTotalsDtoFromJSON(json['totals']),
    };
}
export function DashboardProjectListDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'projects': value['projects'] == null ? undefined : (value['projects'].map(DashboardProjectDtoToJSON)),
        'totals': DashboardProjectTotalsDtoToJSON(value['totals']),
    };
}
