/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DimAlberoDeliveryL2DtoFromJSON, DimAlberoDeliveryL2DtoToJSON, } from './DimAlberoDeliveryL2Dto';
/**
 * Check if a given object implements the DimAlberoDeliveryL1Dto interface.
 */
export function instanceOfDimAlberoDeliveryL1Dto(value) {
    return true;
}
export function DimAlberoDeliveryL1DtoFromJSON(json) {
    return DimAlberoDeliveryL1DtoFromJSONTyped(json, false);
}
export function DimAlberoDeliveryL1DtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idStrutturaFunzione': json['idStrutturaFunzione'] == null ? undefined : json['idStrutturaFunzione'],
        'nomeStrutturaFunzione': json['nomeStrutturaFunzione'] == null ? undefined : json['nomeStrutturaFunzione'],
        'nomeTipoStruttura': json['nomeTipoStruttura'] == null ? undefined : json['nomeTipoStruttura'],
        'idTipoStruttura': json['idTipoStruttura'] == null ? undefined : json['idTipoStruttura'],
        'l1IdUtenteResp': json['l1IdUtenteResp'] == null ? undefined : json['l1IdUtenteResp'],
        'l1NominativoResp': json['l1NominativoResp'] == null ? undefined : json['l1NominativoResp'],
        'l1EmailResp': json['l1EmailResp'] == null ? undefined : json['l1EmailResp'],
        'l2Children': json['l2Children'] == null ? undefined : (json['l2Children'].map(DimAlberoDeliveryL2DtoFromJSON)),
    };
}
export function DimAlberoDeliveryL1DtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idStrutturaFunzione': value['idStrutturaFunzione'],
        'nomeStrutturaFunzione': value['nomeStrutturaFunzione'],
        'nomeTipoStruttura': value['nomeTipoStruttura'],
        'idTipoStruttura': value['idTipoStruttura'],
        'l1IdUtenteResp': value['l1IdUtenteResp'],
        'l1NominativoResp': value['l1NominativoResp'],
        'l1EmailResp': value['l1EmailResp'],
        'l2Children': value['l2Children'] == null ? undefined : (value['l2Children'].map(DimAlberoDeliveryL2DtoToJSON)),
    };
}
