/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ServiceContractEntity interface.
 */
export function instanceOfServiceContractEntity(value) {
    return true;
}
export function ServiceContractEntityFromJSON(json) {
    return ServiceContractEntityFromJSONTyped(json, false);
}
export function ServiceContractEntityFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'requirePOC': json['RequirePO__c'] == null ? undefined : json['RequirePO__c'],
        'totalPlannedInvoiceC': json['TotalPlannedInvoice__c'] == null ? undefined : json['TotalPlannedInvoice__c'],
        'createdByEmailC': json['CreatedByEmail__c'] == null ? undefined : json['CreatedByEmail__c'],
        'totalCostC': json['TotalCost__c'] == null ? undefined : json['TotalCost__c'],
        'totalOrdersAmountC': json['TotalOrdersAmount__c'] == null ? undefined : json['TotalOrdersAmount__c'],
        'totalInvoicedC': json['TotalInvoiced__c'] == null ? undefined : json['TotalInvoiced__c'],
        'contractNumberC': json['ContractNumber__c'] == null ? undefined : json['ContractNumber__c'],
        'contractNameC': json['ContractName__c'] == null ? undefined : json['ContractName__c'],
        'endDateC': json['EndDate__c'] == null ? undefined : json['EndDate__c'],
        'totalPriceC': json['TotalPrice__c'] == null ? undefined : json['TotalPrice__c'],
        'opportunityRootIdC': json['OpportunityRootId__c'] == null ? undefined : json['OpportunityRootId__c'],
        'opportunitiesIdC': json['OpportunitiesId__c'] == null ? undefined : json['OpportunitiesId__c'],
        'createdById': json['CreatedById'] == null ? undefined : json['CreatedById'],
        'profitC': json['Profit__c'] == null ? undefined : json['Profit__c'],
        'createdDate': json['CreatedDate'] == null ? undefined : (new Date(json['CreatedDate'])),
        'idC': json['Id__c'] == null ? undefined : json['Id__c'],
        'startDateC': json['StartDate__c'] == null ? undefined : json['StartDate__c'],
        'grandTotalC': json['GrandTotal__c'] == null ? undefined : json['GrandTotal__c'],
        'accountNameC': json['AccountName__c'] == null ? undefined : json['AccountName__c'],
        'subtotalC': json['Subtotal__c'] == null ? undefined : json['Subtotal__c'],
    };
}
export function ServiceContractEntityToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'RequirePO__c': value['requirePOC'],
        'TotalPlannedInvoice__c': value['totalPlannedInvoiceC'],
        'CreatedByEmail__c': value['createdByEmailC'],
        'TotalCost__c': value['totalCostC'],
        'TotalOrdersAmount__c': value['totalOrdersAmountC'],
        'TotalInvoiced__c': value['totalInvoicedC'],
        'ContractNumber__c': value['contractNumberC'],
        'ContractName__c': value['contractNameC'],
        'EndDate__c': value['endDateC'],
        'TotalPrice__c': value['totalPriceC'],
        'OpportunityRootId__c': value['opportunityRootIdC'],
        'OpportunitiesId__c': value['opportunitiesIdC'],
        'CreatedById': value['createdById'],
        'Profit__c': value['profitC'],
        'CreatedDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'Id__c': value['idC'],
        'StartDate__c': value['startDateC'],
        'GrandTotal__c': value['grandTotalC'],
        'AccountName__c': value['accountNameC'],
        'Subtotal__c': value['subtotalC'],
    };
}
