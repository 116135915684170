import { DashboardProjectDto } from '@api/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useGetDashboardProjectsExcelData = (projects: Partial<DashboardProjectDto | any>[]) => {
  const [excelData, setExcelData] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getExportableExcelData();
  }, [projects]);

  const getExportableExcelData = () => {
    const mappedProjects = projects.map((item) => {
      return {
        [`${t('dashboard.projects.table-titles.id-title')}`]: item.intranetId,
        [`${t('dashboard.projects.table-titles.project-name-title')}`]: item.name,
        [`${t('dashboard.projects.table-titles.status-title')}`]: item.stato,
        [`${t('dashboard.projects.table-titles.no-plan-title')}`]: item.prjNPRicavi,
        [`${t('dashboard.projects.table-titles.actual-revenues-title')}`]: item.actualRevenues,
        [`${t('dashboard.projects.table-titles.actual-costs-title')}`]: item.actualCosts,
        [`${t('dashboard.projects.table-titles.actual-hr-costs-title')}`]: item.actualHrCosts,
        [`${t('dashboard.projects.table-titles.actual-other-costs-title')}`]: item.actualOtherCosts,
        [`${t('dashboard.projects.table-titles.actual-margin-title')}`]: item.actualMargin,
        [`${t('dashboard.projects.table-titles.actual-margin-perc-title')}`]: item.actualMarginRate,
        [`${t('dashboard.projects.table-titles.actual-rate-title')}`]: item.actualRate,
        [`${t('dashboard.projects.table-titles.af-revenues-title')}`]: item.afRevenues,
        [`${t('dashboard.projects.table-titles.af-costs-title')}`]: item.prjAFCosti,
        [`${t('dashboard.projects.table-titles.af-margin-title')}`]: item.prjAFMargin,
        [`${t('dashboard.projects.table-titles.af-margin-perc-title')}`]: item.prjAFMarginPerc,
      };
    });

    setExcelData(mappedProjects);
  };

  return { excelData };
};

export default useGetDashboardProjectsExcelData;
