/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StatoAvanzamentoLavoriFromJSON, StatoAvanzamentoLavoriToJSON, } from './StatoAvanzamentoLavori';
/**
 * Check if a given object implements the BillingDto interface.
 */
export function instanceOfBillingDto(value) {
    return true;
}
export function BillingDtoFromJSON(json) {
    return BillingDtoFromJSONTyped(json, false);
}
export function BillingDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'stage': json['stage'] == null ? undefined : json['stage'],
        'order': json['order'] == null ? undefined : json['order'],
        'amount': json['amount'] == null ? undefined : json['amount'],
        'date': json['date'] == null ? undefined : (new Date(json['date'])),
        'statoAvanzamentoLavori': json['statoAvanzamentoLavori'] == null ? undefined : StatoAvanzamentoLavoriFromJSON(json['statoAvanzamentoLavori']),
    };
}
export function BillingDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'stage': value['stage'],
        'order': value['order'],
        'amount': value['amount'],
        'date': value['date'] == null ? undefined : ((value['date']).toISOString()),
        'statoAvanzamentoLavori': StatoAvanzamentoLavoriToJSON(value['statoAvanzamentoLavori']),
    };
}
