import { BillingDto, ModificaStatoAvanzamentoRequest } from '@api/client';
import { useAppDispatch } from '../../../../../../../core/store/hooks';
import { getBillingPlan, updateStatoAvanzamentoBilling } from '../_redux/actions';

const useSaveBill = (projectId: number, setIsBillingDirty: React.Dispatch<React.SetStateAction<boolean>>) => {
  const dispatch = useAppDispatch();

  const handleSaveBill = (billing: BillingDto) => {
    const payload: ModificaStatoAvanzamentoRequest = {
      statoAvanzamentoLavoriDto: {
        idInvoiceMilestone: billing.id,
        isInviato: billing.statoAvanzamentoLavori?.isInviato,
        dataDiInvio: billing.statoAvanzamentoLavori?.dataDiInvio,
        tipologiaBlocco: billing.statoAvanzamentoLavori?.idTipologiaBlocco?.value,
        importo: Number(billing.statoAvanzamentoLavori?.importo),
      },
    };
    dispatch(updateStatoAvanzamentoBilling(payload))
      .unwrap()
      .then(() => {
        setIsBillingDirty(false);
        dispatch(getBillingPlan(projectId));
      });
  };

  return { handleSaveBill };
};

export default useSaveBill;
