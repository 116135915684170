/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the OpportunityClosedEntity interface.
 */
export function instanceOfOpportunityClosedEntity(value) {
    return true;
}
export function OpportunityClosedEntityFromJSON(json) {
    return OpportunityClosedEntityFromJSONTyped(json, false);
}
export function OpportunityClosedEntityFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'createdDate': json['CreatedDate'] == null ? undefined : (new Date(json['CreatedDate'])),
        'createdById': json['CreatedById'] == null ? undefined : json['CreatedById'],
        'quoteIdC': json['QuoteId__c'] == null ? undefined : json['QuoteId__c'],
        'opportunityIdC': json['OpportunityId__c'] == null ? undefined : json['OpportunityId__c'],
        'amountC': json['Amount__c'] == null ? undefined : json['Amount__c'],
        'closeDateC': json['CloseDate__c'] == null ? undefined : json['CloseDate__c'],
        'wonC': json['Won__c'] == null ? undefined : json['Won__c'],
    };
}
export function OpportunityClosedEntityToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'CreatedDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'CreatedById': value['createdById'],
        'QuoteId__c': value['quoteIdC'],
        'OpportunityId__c': value['opportunityIdC'],
        'Amount__c': value['amountC'],
        'CloseDate__c': value['closeDateC'],
        'Won__c': value['wonC'],
    };
}
