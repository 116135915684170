import {
  CicloPassivoFieldsDto,
  ContractOrderOutputDto,
  ContractOrderSearchDto,
  ProjectCicloPassivoOutputDto,
} from '@api/client';
import {
  createOrder,
  getOtherAssociatibleCosts,
  getPassiveCycleOptions,
  saveFiltersOrdersUsed,
  searchOrders,
} from './actions';
import { createReducer } from '@reduxjs/toolkit';

// const mock2 = {
//   projectCicloPassivoOutputDtoList: [
//     {
//       projectId: 26630,
//       projectName: 'MPS - Digital Advisory - 2023Q4',
//       nomeCliente: 'PROMETEIA  SPA',
//       businessManager: 'Andriani De Vito Gaetano',
//       projectManager: 'Calcagnile Federica',
//       stato: 'ECONOMICS_CLOSED',
//       tipologia: 'CONSUMO',
//       inizio: new Date(),
//       fine: new Date(),
//       prezzo: undefined,
//       otherCostList: {
//         otherCosts: [
//           {
//             description: undefined,
//             costType: {
//               id: 0,
//               value: 'COSTI_GENERICI',
//               description: 'Costi di progetto non riconducibili a nessuna delle altre categorie',
//               duplicable: true,
//             },
//             groupId: 1,
//             costAllocation: [
//               {
//                 id: 318486,
//                 mese: 1,
//                 anno: 2024,
//                 extimatedCost: 0.0,
//                 actualCost: 0.0,
//                 editEnabled: false,
//               },
//               {
//                 id: 318989,
//                 mese: 3,
//                 anno: 2024,
//                 extimatedCost: 3245.0,
//                 actualCost: 0.0,
//                 editEnabled: false,
//               },
//             ],
//             isAssociated: true,
//           },
//           {
//             description: 'travel1',
//             costType: {
//               id: 1,
//               value: 'COSTI_GENERICI',
//               description: 'travel',
//               duplicable: true,
//             },
//             groupId: 1,
//             costAllocation: [
//               {
//                 id: 318486,
//                 mese: 1,
//                 anno: 2024,
//                 extimatedCost: 5.1,
//                 actualCost: 0.0,
//                 editEnabled: false,
//               },
//               {
//                 id: 318989,
//                 mese: 3,
//                 anno: 2024,
//                 extimatedCost: 3245.0,
//                 actualCost: 0.0,
//                 editEnabled: false,
//               },
//             ],
//             isAssociated: false,
//           },
//           {
//             description: 'travel2',
//             costType: {
//               id: 1,
//               value: 'COSTI_GENERICI',
//               description: 'travel',
//               duplicable: true,
//             },
//             groupId: 2,
//             costAllocation: [
//               {
//                 id: 318486,
//                 mese: 1,
//                 anno: 2024,
//                 extimatedCost: 0.0,
//                 actualCost: 0.0,
//                 editEnabled: false,
//               },
//               {
//                 id: 318989,
//                 mese: 3,
//                 anno: 2024,
//                 extimatedCost: 3245.0,
//                 actualCost: 0.0,
//                 editEnabled: false,
//               },
//             ],
//             isAssociated: false,
//           },
//         ],
//         monthWithCostsOrPlan: [
//           {
//             mese: 2,
//             anno: 2024,
//             consolidato: true,
//           },
//           {
//             mese: 3,
//             anno: 2024,
//             consolidato: true,
//           },
//           {
//             mese: 1,
//             anno: 2024,
//             consolidato: true,
//           },
//           {
//             mese: 12,
//             anno: 2023,
//             consolidato: true,
//           },
//           {
//             mese: 4,
//             anno: 2024,
//             consolidato: true,
//           },
//           {
//             mese: 5,
//             anno: 2024,
//             consolidato: true,
//           },
//           {
//             mese: 6,
//             anno: 2024,
//             consolidato: true,
//           },
//           {
//             mese: 7,
//             anno: 2024,
//             consolidato: true,
//           },
//         ],
//       },
//     },
//   ],
// };

// const mock = {
//   contractOrderOutputDtoList: [
//     {
//       id: 1,
//       contractStatus: {
//         id: 1,
//         value: 'ATTIVO',
//       },
//       processingStatus: {
//         id: 1,
//         value: 'AVVIATO',
//       },
//       orderType: {
//         id: 2,
//         value: 'PASSIVO',
//       },
//       contractValue: 5000.0,
//       resaleProjectCode: '435',
//       resaleDetachmentRate: 5000.0,
//       automaticRenewal: true,
//       startDate: new Date(),
//       endDate: new Date(),
//       signatureDate: new Date(),
//       creationDate: new Date(),
//       lastmodifiedDate: new Date(),
//       createdBy: undefined,
//       modifiedBy: undefined,
//       projectExternalCost: [
//         {
//           projectId: 26794,
//           intranetId: '17901',
//           projectName: 'REGIONE PUGLIA - ECOSISTEMA TURISMO E CULTURA',
//           externalCosts: [
//             {
//               description: undefined,
//               costType: {
//                 id: 0,
//                 value: 'COSTI_GENERICI',
//                 description: 'Costi di progetto non riconducibili a nessuna delle altre categorie',
//                 duplicable: true,
//               },
//               groupId: 1,
//               costAllocation: [
//                 {
//                   id: 318735,
//                   mese: 1,
//                   anno: 2024,
//                   extimatedCost: 0.0,
//                   actualCost: 0.0,
//                   editEnabled: false,
//                 },
//                 {
//                   id: 327081,
//                   mese: 2,
//                   anno: 2024,
//                   extimatedCost: 0.0,
//                   actualCost: 0.0,
//                   editEnabled: false,
//                 },
//                 {
//                   id: 327082,
//                   mese: 3,
//                   anno: 2024,
//                   extimatedCost: 0.0,
//                   actualCost: 0.0,
//                   editEnabled: false,
//                 },
//                 {
//                   id: 327083,
//                   mese: 4,
//                   anno: 2024,
//                   extimatedCost: 0.0,
//                   actualCost: 0.0,
//                   editEnabled: false,
//                 },
//                 {
//                   id: 321980,
//                   mese: 5,
//                   anno: 2024,
//                   extimatedCost: 0.0,
//                   actualCost: 0.0,
//                   editEnabled: false,
//                 },
//                 {
//                   id: 322223,
//                   mese: 6,
//                   anno: 2024,
//                   extimatedCost: 0.0,
//                   actualCost: 0.0,
//                   editEnabled: false,
//                 },
//                 {
//                   id: 322224,
//                   mese: 7,
//                   anno: 2024,
//                   extimatedCost: 0.0,
//                   actualCost: 10000.0,
//                   editEnabled: false,
//                 },
//                 {
//                   id: 326187,
//                   mese: 8,
//                   anno: 2024,
//                   extimatedCost: 0.0,
//                   actualCost: 7000.0,
//                   editEnabled: false,
//                 },
//                 {
//                   id: 326188,
//                   mese: 9,
//                   anno: 2024,
//                   extimatedCost: 0.0,
//                   actualCost: 10000.0,
//                   editEnabled: false,
//                 },
//                 {
//                   id: 326189,
//                   mese: 10,
//                   anno: 2024,
//                   extimatedCost: 0.0,
//                   actualCost: 0.0,
//                   editEnabled: false,
//                 },
//                 {
//                   id: 326190,
//                   mese: 11,
//                   anno: 2024,
//                   extimatedCost: 0.0,
//                   actualCost: 0.0,
//                   editEnabled: false,
//                 },
//                 {
//                   id: 318368,
//                   mese: 12,
//                   anno: 2023,
//                   extimatedCost: 80100.0,
//                   actualCost: 80100.0,
//                   editEnabled: false,
//                 },
//               ],
//               isAssociated: undefined,
//             },
//           ],
//         },
//       ],
//       expenseItems: [
//         {
//           expenseItemId: {
//             id: 2,
//             value: 'DISTACCO',
//           },
//         },
//         {
//           expenseItemId: {
//             id: 3,
//             value: 'COSTI_PER_LA_RIVENDITA',
//           },
//         },
//       ],
//       remainingBalance: 4900.0,
//       note: 'test',
//       isDeleted: false,
//     },
//   ],
// };
interface InitialState {
  passiveCycleOptions: CicloPassivoFieldsDto | undefined;
  orders: ContractOrderOutputDto[];
  filtersOrdersUsed: ContractOrderSearchDto | undefined;
  associableOtherCostsProjects: ProjectCicloPassivoOutputDto[];
}

const initialState: InitialState = {
  passiveCycleOptions: undefined,
  orders: [],
  filtersOrdersUsed: undefined,
  associableOtherCostsProjects: [],
};

export const passiveCycleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPassiveCycleOptions.fulfilled, (state, action) => {
      state.passiveCycleOptions = action.payload;
    })
    .addCase(createOrder.fulfilled, (state, action) => {
      // AppToastService.success('Order created successfully');
    })
    .addCase(searchOrders.fulfilled, (state, action) => {
      state.orders = action.payload?.contractOrderOutputDtoList || [];
    })
    .addCase(saveFiltersOrdersUsed, (state, action) => {
      state.filtersOrdersUsed = action.payload;
    })
    .addCase(getOtherAssociatibleCosts.fulfilled, (state, action) => {
      state.associableOtherCostsProjects = action.payload?.projectCicloPassivoOutputDtoList || [];
    });
});
