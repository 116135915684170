/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StrutturaFunzioneFromJSON, StrutturaFunzioneToJSON, } from './StrutturaFunzione';
import { StrutturaRuoloFromJSON, StrutturaRuoloToJSON, } from './StrutturaRuolo';
/**
 * Check if a given object implements the StrutturaUtenteFunzioneRuoloOutputDto interface.
 */
export function instanceOfStrutturaUtenteFunzioneRuoloOutputDto(value) {
    return true;
}
export function StrutturaUtenteFunzioneRuoloOutputDtoFromJSON(json) {
    return StrutturaUtenteFunzioneRuoloOutputDtoFromJSONTyped(json, false);
}
export function StrutturaUtenteFunzioneRuoloOutputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'idUtente': json['idUtente'] == null ? undefined : json['idUtente'],
        'idRuolo': json['idRuolo'] == null ? undefined : StrutturaRuoloFromJSON(json['idRuolo']),
        'idFunzione': json['idFunzione'] == null ? undefined : StrutturaFunzioneFromJSON(json['idFunzione']),
    };
}
export function StrutturaUtenteFunzioneRuoloOutputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'idUtente': value['idUtente'],
        'idRuolo': StrutturaRuoloToJSON(value['idRuolo']),
        'idFunzione': StrutturaFunzioneToJSON(value['idFunzione']),
    };
}
