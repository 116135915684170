/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { CreateUpdateProjectQuoteDtoFromJSON, CreateUpdateProjectQuoteDtoToJSON, ProjectPastDataDatailFromJSON, SavePastDataForProjectToJSON, } from '../models/index';
/**
 *
 */
export class ControllerDedicatoAFunzioniSoloPerAmministratoriApi extends runtime.BaseAPI {
    /**
     * Recupera tutti i possibili valori che un progetto può assumere
     * Possibili valori
     */
    adminCreateIntranetProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling adminCreateIntranetProject().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/administration/crete-project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Recupera tutti i possibili valori che un progetto può assumere
     * Possibili valori
     */
    adminCreateIntranetProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.adminCreateIntranetProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Crea il progetto e la quote passandogli i vari parametri
     * Crea progetto e quote
     */
    createProjectAndQuoteAdminRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createUpdateProjectQuoteDto'] == null) {
                throw new runtime.RequiredError('createUpdateProjectQuoteDto', 'Required parameter "createUpdateProjectQuoteDto" was null or undefined when calling createProjectAndQuoteAdmin().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/administration/create`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateUpdateProjectQuoteDtoToJSON(requestParameters['createUpdateProjectQuoteDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateUpdateProjectQuoteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Crea il progetto e la quote passandogli i vari parametri
     * Crea progetto e quote
     */
    createProjectAndQuoteAdmin(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createProjectAndQuoteAdminRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutti i dettagli del progetto e della quote passandogli l\'id del progetto
     * Recupera project e quote
     */
    getProjectAndQuoteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling getProjectAndQuote().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/administration/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateUpdateProjectQuoteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti i dettagli del progetto e della quote passandogli l\'id del progetto
     * Recupera project e quote
     */
    getProjectAndQuote(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getProjectAndQuoteRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Modifica il progetto e la quote passandogli i vari parametri (modifica la quote tramite il project id e la quote attiva
     * Modifica progetto e quote
     */
    updateProjectAndQuoteAdminRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling updateProjectAndQuoteAdmin().');
            }
            if (requestParameters['createUpdateProjectQuoteDto'] == null) {
                throw new runtime.RequiredError('createUpdateProjectQuoteDto', 'Required parameter "createUpdateProjectQuoteDto" was null or undefined when calling updateProjectAndQuoteAdmin().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/administration/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: CreateUpdateProjectQuoteDtoToJSON(requestParameters['createUpdateProjectQuoteDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateUpdateProjectQuoteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica il progetto e la quote passandogli i vari parametri (modifica la quote tramite il project id e la quote attiva
     * Modifica progetto e quote
     */
    updateProjectAndQuoteAdmin(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateProjectAndQuoteAdminRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Restituisce un progetto con i dati di costi e plan consolidati fino a quel momento
     * Salva revenue plan e costi esterni
     */
    updateProjectWithRevenutAndOtherCostsCostsByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling updateProjectWithRevenutAndOtherCostsCostsById().');
            }
            if (requestParameters['savePastDataForProject'] == null) {
                throw new runtime.RequiredError('savePastDataForProject', 'Required parameter "savePastDataForProject" was null or undefined when calling updateProjectWithRevenutAndOtherCostsCostsById().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/administration/past-data/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SavePastDataForProjectToJSON(requestParameters['savePastDataForProject']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProjectPastDataDatailFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce un progetto con i dati di costi e plan consolidati fino a quel momento
     * Salva revenue plan e costi esterni
     */
    updateProjectWithRevenutAndOtherCostsCostsById(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateProjectWithRevenutAndOtherCostsCostsByIdRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Aggiorna i costi HR e gli altri costi presenti in rendicontazione per mese anno e progetto indicati
     * Aggiorna i costi HR e gli altri costi presenti in rendicontazione
     */
    updateReportingCostForProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling updateReportingCostForProject().');
            }
            if (requestParameters['year'] == null) {
                throw new runtime.RequiredError('year', 'Required parameter "year" was null or undefined when calling updateReportingCostForProject().');
            }
            if (requestParameters['month'] == null) {
                throw new runtime.RequiredError('month', 'Required parameter "month" was null or undefined when calling updateReportingCostForProject().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/administration/past-data/{projectId}/{year}/{month}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))).replace(`{${"year"}}`, encodeURIComponent(String(requestParameters['year']))).replace(`{${"month"}}`, encodeURIComponent(String(requestParameters['month']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProjectPastDataDatailFromJSON(jsonValue));
        });
    }
    /**
     * Aggiorna i costi HR e gli altri costi presenti in rendicontazione per mese anno e progetto indicati
     * Aggiorna i costi HR e gli altri costi presenti in rendicontazione
     */
    updateReportingCostForProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateReportingCostForProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
