/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CompetenzeProgettoDto interface.
 */
export function instanceOfCompetenzeProgettoDto(value) {
    return true;
}
export function CompetenzeProgettoDtoFromJSON(json) {
    return CompetenzeProgettoDtoFromJSONTyped(json, false);
}
export function CompetenzeProgettoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'sal': json['sal'] == null ? undefined : json['sal'],
        'ricaviTotali': json['ricaviTotali'] == null ? undefined : json['ricaviTotali'],
        'ricaviConsolidati': json['ricaviConsolidati'] == null ? undefined : json['ricaviConsolidati'],
        'ricaviResiduiPianificati': json['ricaviResiduiPianificati'] == null ? undefined : json['ricaviResiduiPianificati'],
        'costoTotaleOfferta': json['costoTotaleOfferta'] == null ? undefined : json['costoTotaleOfferta'],
        'costoHrOfferta': json['costoHrOfferta'] == null ? undefined : json['costoHrOfferta'],
        'costoEsternoOfferta': json['costoEsternoOfferta'] == null ? undefined : json['costoEsternoOfferta'],
        'costoTotalePianificato': json['costoTotalePianificato'] == null ? undefined : json['costoTotalePianificato'],
        'costoHrConsolidato': json['costoHrConsolidato'] == null ? undefined : json['costoHrConsolidato'],
        'costoHrPianificatoResiduo': json['costoHrPianificatoResiduo'] == null ? undefined : json['costoHrPianificatoResiduo'],
        'costoEsternoConsolidato': json['costoEsternoConsolidato'] == null ? undefined : json['costoEsternoConsolidato'],
        'costoEsternoPianificatoResiduo': json['costoEsternoPianificatoResiduo'] == null ? undefined : json['costoEsternoPianificatoResiduo'],
        'marginePianificato': json['marginePianificato'] == null ? undefined : json['marginePianificato'],
        'margineOfferta': json['margineOfferta'] == null ? undefined : json['margineOfferta'],
        'marginePianificatoPerc': json['marginePianificatoPerc'] == null ? undefined : json['marginePianificatoPerc'],
        'margineOffertaPerc': json['margineOffertaPerc'] == null ? undefined : json['margineOffertaPerc'],
        'ratePianificato': json['ratePianificato'] == null ? undefined : json['ratePianificato'],
        'rateOfferta': json['rateOfferta'] == null ? undefined : json['rateOfferta'],
        'rateConsolidato': json['rateConsolidato'] == null ? undefined : json['rateConsolidato'],
        'rateResiduo': json['rateResiduo'] == null ? undefined : json['rateResiduo'],
        'margineActual': json['margineActual'] == null ? undefined : json['margineActual'],
        'margineActualPerc': json['margineActualPerc'] == null ? undefined : json['margineActualPerc'],
        'noPlan': json['noPlan'] == null ? undefined : json['noPlan'],
        'lost': json['lost'] == null ? undefined : json['lost'],
        'totaleGiorniUomoOfferta': json['totaleGiorniUomoOfferta'] == null ? undefined : json['totaleGiorniUomoOfferta'],
        'totaleGiorniUomoPianificato': json['totaleGiorniUomoPianificato'] == null ? undefined : json['totaleGiorniUomoPianificato'],
        'totaleGiorniUomoConsolidato': json['totaleGiorniUomoConsolidato'] == null ? undefined : json['totaleGiorniUomoConsolidato'],
        'totaleGiorniUomoPianificatoResiduo': json['totaleGiorniUomoPianificatoResiduo'] == null ? undefined : json['totaleGiorniUomoPianificatoResiduo'],
    };
}
export function CompetenzeProgettoDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'sal': value['sal'],
        'ricaviTotali': value['ricaviTotali'],
        'ricaviConsolidati': value['ricaviConsolidati'],
        'ricaviResiduiPianificati': value['ricaviResiduiPianificati'],
        'costoTotaleOfferta': value['costoTotaleOfferta'],
        'costoHrOfferta': value['costoHrOfferta'],
        'costoEsternoOfferta': value['costoEsternoOfferta'],
        'costoTotalePianificato': value['costoTotalePianificato'],
        'costoHrConsolidato': value['costoHrConsolidato'],
        'costoHrPianificatoResiduo': value['costoHrPianificatoResiduo'],
        'costoEsternoConsolidato': value['costoEsternoConsolidato'],
        'costoEsternoPianificatoResiduo': value['costoEsternoPianificatoResiduo'],
        'marginePianificato': value['marginePianificato'],
        'margineOfferta': value['margineOfferta'],
        'marginePianificatoPerc': value['marginePianificatoPerc'],
        'margineOffertaPerc': value['margineOffertaPerc'],
        'ratePianificato': value['ratePianificato'],
        'rateOfferta': value['rateOfferta'],
        'rateConsolidato': value['rateConsolidato'],
        'rateResiduo': value['rateResiduo'],
        'margineActual': value['margineActual'],
        'margineActualPerc': value['margineActualPerc'],
        'noPlan': value['noPlan'],
        'lost': value['lost'],
        'totaleGiorniUomoOfferta': value['totaleGiorniUomoOfferta'],
        'totaleGiorniUomoPianificato': value['totaleGiorniUomoPianificato'],
        'totaleGiorniUomoConsolidato': value['totaleGiorniUomoConsolidato'],
        'totaleGiorniUomoPianificatoResiduo': value['totaleGiorniUomoPianificatoResiduo'],
    };
}
