/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectCicloPassivoOutputDtoFromJSON, ProjectCicloPassivoOutputDtoToJSON, } from './ProjectCicloPassivoOutputDto';
/**
 * Check if a given object implements the ProjectCicloPassivoOutputListDto interface.
 */
export function instanceOfProjectCicloPassivoOutputListDto(value) {
    return true;
}
export function ProjectCicloPassivoOutputListDtoFromJSON(json) {
    return ProjectCicloPassivoOutputListDtoFromJSONTyped(json, false);
}
export function ProjectCicloPassivoOutputListDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'projectCicloPassivoOutputDtoList': json['projectCicloPassivoOutputDtoList'] == null ? undefined : (json['projectCicloPassivoOutputDtoList'].map(ProjectCicloPassivoOutputDtoFromJSON)),
    };
}
export function ProjectCicloPassivoOutputListDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'projectCicloPassivoOutputDtoList': value['projectCicloPassivoOutputDtoList'] == null ? undefined : (value['projectCicloPassivoOutputDtoList'].map(ProjectCicloPassivoOutputDtoToJSON)),
    };
}
