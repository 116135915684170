/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DimAlberoCommercialeL3DtoFromJSON, DimAlberoCommercialeL3DtoToJSON, } from './DimAlberoCommercialeL3Dto';
/**
 * Check if a given object implements the DimAlberoCommercialeL2Dto interface.
 */
export function instanceOfDimAlberoCommercialeL2Dto(value) {
    return true;
}
export function DimAlberoCommercialeL2DtoFromJSON(json) {
    return DimAlberoCommercialeL2DtoFromJSONTyped(json, false);
}
export function DimAlberoCommercialeL2DtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'l2': json['l2'] == null ? undefined : json['l2'],
        'l2NomeStrutturaFunzione': json['l2NomeStrutturaFunzione'] == null ? undefined : json['l2NomeStrutturaFunzione'],
        'l2IdUtenteResp': json['l2IdUtenteResp'] == null ? undefined : json['l2IdUtenteResp'],
        'l2NominativoResp': json['l2NominativoResp'] == null ? undefined : json['l2NominativoResp'],
        'l2EmailResp': json['l2EmailResp'] == null ? undefined : json['l2EmailResp'],
        'l3Children': json['l3Children'] == null ? undefined : (json['l3Children'].map(DimAlberoCommercialeL3DtoFromJSON)),
    };
}
export function DimAlberoCommercialeL2DtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'l2': value['l2'],
        'l2NomeStrutturaFunzione': value['l2NomeStrutturaFunzione'],
        'l2IdUtenteResp': value['l2IdUtenteResp'],
        'l2NominativoResp': value['l2NominativoResp'],
        'l2EmailResp': value['l2EmailResp'],
        'l3Children': value['l3Children'] == null ? undefined : (value['l3Children'].map(DimAlberoCommercialeL3DtoToJSON)),
    };
}
