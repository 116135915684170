import { Spinner } from 'react-bootstrap';
import './style.scss';
import { generalLoaderSelector } from '../../../../core/store/selectors';
import { useAppSelector } from '../../../../core/store/hooks';

const AppLoader: React.FC = () => {
  const loaderSelector = useAppSelector(generalLoaderSelector);

  if (!loaderSelector) return null;

  return (
    <div className="app-loader">
      <Spinner animation="border" variant="primary" />
    </div>
  );
};
export default AppLoader;
