import {
  BillingDataResult,
  Configuration,
  ControllerProjectApi,
  DettaglioProjectDto,
  FilterDto,
  ProjectSearchRequest,
  SearchFilterDto,
  SearchProjectResponseDto,
} from '@api/client';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from '../../../../core/store';
import getConfiguration, { errorHandler } from '../../../../core/store/config';

enum ProjectListAction {
  getProgettiInEvidenza = '[ProjectList] Get Progetti In Evidenza',
  getFilterFields = '[ProjectList] get Filtri Progetti',
  getProjectDetails = '[ProjectList] Get Project Details',
  getFilteredProjects = '[ProjectList] get Progetti Filtrati',
  insertStaff = '[ProjectList] Insert Staff',
  saveFiltersUsed = '[ProjectList] Save Filters Used',
  resetCurrentProject = '[ProjectList] Reset Current Project',
}

export const progettiGetlist = createAsyncThunk<SearchProjectResponseDto | undefined, void, ThunkApiConfig>(
  ProjectListAction.getProgettiInEvidenza,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerProjectApi(getConfiguration(Configuration, extra, getState)).projectEvidenza();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getProjectSearchFilter = createAsyncThunk<SearchFilterDto | undefined, void, ThunkApiConfig>(
  ProjectListAction.getFilterFields,
  async (_, { dispatch, extra, getState }) => {
    try {
      return await new ControllerProjectApi(getConfiguration(Configuration, extra, getState)).projectSearchFilter();
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getProjectDetails = createAsyncThunk<DettaglioProjectDto | undefined, number, ThunkApiConfig>(
  ProjectListAction.getProjectDetails,
  async (id, { dispatch, extra, getState }) => {
    try {
      return await new ControllerProjectApi(getConfiguration(Configuration, extra, getState)).projectDetail({
        projectId: id,
      });
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const getFilteredProjects = createAsyncThunk<SearchProjectResponseDto | undefined, FilterDto, ThunkApiConfig>(
  ProjectListAction.getFilteredProjects,
  async (filters, { getState, dispatch, extra }) => {
    try {
      const req: ProjectSearchRequest = {
        filterDto: filters,
      };
      return await new ControllerProjectApi(getConfiguration(Configuration, extra, getState)).projectSearch(req);
    } catch (e) {
      errorHandler(e, dispatch, getState);
    }
  }
);

export const saveFiltersUsed = createAction<FilterDto>(ProjectListAction.saveFiltersUsed);

export const resetCurrentProject = createAction(ProjectListAction.resetCurrentProject);
