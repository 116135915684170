import { FilterDtoTipologiaEnum } from '@api/client';
import i18n from '../i18n/i18n';

export interface Typology {
  id: string;
  name: string;
  label: string;
}
export const typologiesData: Typology[] = [
  {
    id: '1',
    name: FilterDtoTipologiaEnum.Corpo,
    label: i18n.t('typology.corpo'),
  },
  {
    id: '3',
    name: FilterDtoTipologiaEnum.Canone,
    label: i18n.t('typology.canone'),
  },
  {
    id: '13',
    name: FilterDtoTipologiaEnum.Costo,
    label: i18n.t('typology.cost'),
  },
  {
    id: '14',
    name: FilterDtoTipologiaEnum.Consumo,
    label: i18n.t('typology.consumo'),
  },
];
