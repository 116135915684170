import React, { useEffect, useState } from 'react';
import './style.scss';
import { Dropdown, Button } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import PriceRangeInput from '../ant-priceRange';
import { useTranslation } from 'react-i18next';
import { projectListFormData } from '../app-accordion/_components/accordion-form';

interface RangeValue {
  value: number;
  label: string;
}
interface PriceRangeProps {
  onPriceRangeChange: (value: [number | null, number | null]) => void;
  value: any;
  clear?: any;
}
const PriceRange: React.FC<PriceRangeProps> = ({ onPriceRangeChange, value, clear }) => {
  const { t } = useTranslation();
  const [minValue, setMinValue] = useState<number | null>(null);
  const [maxValue, setMaxValue] = useState<number | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [activeMinValue, setActiveMinValue] = useState<number | null>(null);
  const [activeMaxValue, setActiveMaxValue] = useState<number | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handlePriceRangeChange = (value: any) => {
    onPriceRangeChange(value);
  };

  useEffect(() => {
    if (value) {
      setMinValue(value[0]);
      setMaxValue(value[1]);
    }
  }, [value]);
  useEffect(() => {
    setIsActive(minValue !== null || maxValue !== null);
  }, [minValue, maxValue]);
  const maxValues: RangeValue[] = [
    { value: 10000, label: '10.000 €' },
    { value: 20000, label: '20.000 €' },
    { value: 30000, label: '30.000 €' },
    { value: 40000, label: '40.000 €' },
    { value: 50000, label: '50.000 €' },
    { value: 60000, label: '60.000 €' },
    { value: 70000, label: '70.000 €' },
    { value: 80000, label: '80.000 €' },
    { value: 90000, label: '90.000 €' },
    { value: 100000, label: '100.000 €' },
  ];

  const minValues: RangeValue[] = [
    { value: 10000, label: '10.000 €' },
    { value: 20000, label: '20.000 €' },
    { value: 30000, label: '30.000 €' },
    { value: 40000, label: '40.000 €' },
    { value: 50000, label: '50.000 €' },
    { value: 60000, label: '60.000 €' },
    { value: 70000, label: '70.000 €' },
    { value: 80000, label: '80.000 €' },
    { value: 90000, label: '90.000 €' },
    { value: 100000, label: '100.000 €' },
  ];

  const handleMinValueChange = (value: number | null) => {
    setMinValue(value);
    setActiveMinValue(value);
    if (maxValue !== null && value! > maxValue) {
      setMaxValue(null);
    }
  };

  const handleMaxValueChange = (value: number | null) => {
    setMaxValue(value);
    setActiveMaxValue(value);
    if (minValue !== null && value! < minValue) {
      setMinValue(null);
    }
  };

  const confirmChanges = () => {
    onPriceRangeChange([minValue, maxValue]);
    setDropdownOpen(false);
  };

  const isMinDisabled = (value: number) => {
    return maxValue !== null && value >= maxValue;
  };

  const isMaxDisabled = (value: number) => {
    return minValue !== null && value <= minValue;
  };

  const { control } = useForm({
    mode: 'onChange',
    defaultValues: projectListFormData,
  });
  const clearData = () => {
    setMinValue(null);
    setMaxValue(null);
    setActiveMinValue(null);
    setActiveMaxValue(null);
    onPriceRangeChange([null, null]);
    setDropdownOpen(false);
  };
  const menuStyle = {
    background: '#fff',
    borderRadius: '4px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <div className="span2 investRange border-r">
      <Dropdown
        dropdownRender={(menu) => (
          <div className="rangemenu" style={menuStyle}>
            <div className="d-flex">
              <div></div>
            </div>
            <div className="price_Ranges rangesMax col-md-6">
              <div id="header">
                <span className="span-head">Max</span>
              </div>
              {maxValues.map((range) => (
                <div
                  key={range.value}
                  className={`max_value ${activeMaxValue === range.value ? 'active' : ''} ${
                    isMaxDisabled(range.value) ? 'disabled' : ''
                  }`}
                  onClick={() => handleMaxValueChange(range.value)}
                >
                  {range.label}
                </div>
              ))}
            </div>
            <div className="col-md-2"></div>
            <div className="price_Ranges rangesMin col-md-6">
              <div id="header">Min</div>
              {minValues.map((range) => (
                <div
                  key={range.value}
                  className={`min_value ${activeMinValue === range.value ? 'active' : ''} ${
                    isMinDisabled(range.value) ? 'disabled' : ''
                  }`}
                  onClick={() => handleMinValueChange(range.value)}
                >
                  {range.label}
                </div>
              ))}
            </div>
            <div className="btnClear">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <button className="m-2 prangeB btn btn-sm btn-outline-primary btn-links-outline" onClick={clearData}>
                    {t('common.cancel')}
                  </button>
                </div>
                <div>
                  <button
                    className="m-2 prangeB btn btn-sm btn-primary btn-links"
                    onClick={confirmChanges}
                    disabled={!isActive}
                  >
                    {t('common.confirm')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        trigger={['click']}
        open={dropdownOpen}
        onOpenChange={toggleDropdown}
      >
        <div className="filter-option pull-left span_price">
          <Controller
            name="priceRange"
            control={control}
            render={({ field }) => (
              <>
                <PriceRangeInput
                  value={[minValue !== null ? minValue : undefined, maxValue !== null ? maxValue : undefined]}
                  onChange={([min, max]) => {
                    handleMinValueChange(min);
                    handleMaxValueChange(max);
                  }}
                ></PriceRangeInput>
              </>
            )}
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default PriceRange;
