import { useEffect, useState } from 'react';

const usePagination = (items: any[], itemsPerPage: number, secondaryItems?: any[] | null, changeSort?: number) => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [visibleItems, setVisibleItems] = useState<any[]>(
    items.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)
  );
  const [visibleSecondaryItems, setVisibleSecondaryItems] = useState<any[]>(
    secondaryItems?.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage) || []
  );

  const totalPages = Math.ceil(items.length / itemsPerPage);

  useEffect(() => {
    if (items.length === 0) return;
    setVisibleItems(items.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage));
    if (secondaryItems) {
      setVisibleSecondaryItems(secondaryItems.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage));
    }
  }, [pageNumber, items, secondaryItems, itemsPerPage, changeSort]);

  useEffect(() => {
    setPageNumber(0);
  }, [items.length]);

  return { pageNumber, setPageNumber, totalPages, visibleItems, visibleSecondaryItems };
};

export default usePagination;
