import React, { useEffect, useRef, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { formatCurrency, getFormattedDateHeader } from '../../../../shared/utils/common.utils';
import './styles.scss';
import { useAppSelector } from '../../../../core/store/hooks';
import { dashboardUsersSelector } from '../_redux/selectors';
import { DashboardUserDto, DashboardUserListDto, ProjectDto, UserDaysDto } from '@api/client';
import NoData from '../../../../shared/design-system/components/no-data-box';
import usePagination from '../../../../core/hooks/usePagination';
import { StorageKeysEnum } from '../../../../enums/storageKeys.enum';
import AppPaginator from '../../../../shared/design-system/components/app-paginator';
import AppAccordion from '../../../../shared/design-system/components/app-accordion';
import FiltersDashboardPeople, { filtersDashboardPeopleData } from '../_components/filters-dashboard-people';
import useFormDashboardPeople from '../../../../shared/design-system/components/app-accordion/_hooks/useFormDashboardPeople';
import { PathEnum } from '../../../../core/router/path.enum';
import { trackUserIdGa4 } from '../../../../shared/utils/gtag';
import { LocationFilterEnum, TrackPagesGA4Enum } from '../../../../enums/global.enum';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DashboardUserTables } from '../../../../enums/dashboard.enum';

const PeopleTable: React.FC = () => {
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState<any>({});
  const dashboardUsers = useAppSelector(dashboardUsersSelector);
  const [users, setUsers] = useState<DashboardUserListDto | null | undefined>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [consolidatedMonths, setConsolidatedMonths] = useState<any[]>([]);
  const [allMonths, setAllMonths] = useState<any[]>([]);
  const tableRef = useRef<HTMLTableElement>(null);

  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset, filterParams } = useFormDashboardPeople(
    filtersDashboardPeopleData,
    DashboardUserTables.RESOURCE_PLANNING
  );

  const {
    pageNumber: pageNumberUser,
    setPageNumber: setPageNumberUser,
    totalPages: totalPagesUser,
    visibleItems: visibleUsers,
  } = usePagination(users?.userList || [], 50);

  const [visUsers, setVisUsers] = useState(visibleUsers);

  useEffect(() => {
    if (visibleUsers) {
      setVisUsers(visibleUsers);
    }
  }, [visibleUsers]);

  const handleReset = () => {
    onReset();
    chipsFormData && setChipsFormData([]);
  };

  const handleRowClick = (userId: any) => {
    setExpandedRows((prevExpandedRows: any) => ({
      ...prevExpandedRows,
      [userId]: !prevExpandedRows[userId],
    }));
  };

  useEffect(() => {
    trackUserIdGa4(TrackPagesGA4Enum.DASHBOARD_RESOURCES);
  }, []);

  useEffect(() => {
    if (dashboardUsers) {
      getConsolidatedMonths(dashboardUsers.userList || []);
    }
  }, [dashboardUsers]);

  useEffect(() => {
    if (dashboardUsers) {
      const mappedUsers = getMappedUsers(dashboardUsers.userList || []);
      setUsers(mappedUsers);
    }
  }, [consolidatedMonths]);

  useEffect(() => {
    const consolidatedMonthsLength = consolidatedMonths.length;
    scrollToRight(consolidatedMonthsLength * 70);
  }, [users]);

  const scrollToRight = (px: number) => {
    if (tableRef.current) {
      tableRef.current.scrollBy({
        top: 0,
        left: px,
        behavior: 'smooth',
      });
    }
  };

  const getConsolidatedMonths = (users: DashboardUserDto[]) => {
    let consolidatedMonths: any[] = [];
    users.forEach((user) => {
      user.userDaysDtoList?.forEach((month) => {
        if (month.isConsolidato && !consolidatedMonths.find((m) => m.month === month.month && m.year === month.year)) {
          consolidatedMonths.push({ month: month.month, year: month.year });
        }
      });
    });
    setConsolidatedMonths(consolidatedMonths);
  };

  const getAllMonths = (users: DashboardUserDto[]) => {
    let allMonths: any[] = [];
    users.forEach((user) => {
      user.userDaysDtoList?.forEach((month) => {
        if (!allMonths.find((m) => m.month === month.month && m.year === month.year)) {
          allMonths.push({ month: month.month, year: month.year });
        }
      });
    });
    allMonths.sort((a, b) => {
      if (a.year === b.year) {
        return a.month - b.month;
      }
      return a.year - b.year;
    });
    setAllMonths(allMonths);
    return allMonths;
  };

  const getMappedUsers = (users: DashboardUserDto[]) => {
    // users may have diiferent months, so we need to get all months from all users (set daysactualforecast to 0 if not present)
    const allMonths = getAllMonths(users);
    const mappedUsers = users.map((user) => {
      let userDaysDtoList: UserDaysDto[] = [];
      allMonths.forEach((month) => {
        const userMonth = user.userDaysDtoList?.find((m) => m.month === month.month && m.year === month.year);
        if (!userMonth) {
          userDaysDtoList.push({
            month: month.month,
            year: month.year,
            daysActualForecast: 0,
            isConsolidato: isMeseConsolidato(month.month, month.year),
          });
        } else {
          userDaysDtoList.push({ ...userMonth, isConsolidato: isMeseConsolidato(month.month, month.year) });
        }
      });
      let projects: ProjectDto[] = [];
      user.projects?.forEach((project) => {
        let projectDaysDtoList: UserDaysDto[] = [];
        allMonths.forEach((month) => {
          const projectMonth = project.projectDaysDtoList?.find(
            (m) => m.month === month.month && m.year === month.year
          );
          if (!projectMonth) {
            projectDaysDtoList.push({
              month: month.month,
              year: month.year,
              daysActualForecast: 0,
              isConsolidato: isMeseConsolidato(month.month, month.year),
            });
          } else {
            projectDaysDtoList.push({ ...projectMonth, isConsolidato: isMeseConsolidato(month.month, month.year) });
          }
        });
        projectDaysDtoList.sort((a, b) => {
          if (a.year === b.year) {
            return (a.month ?? 0) - (b.month ?? 0);
          }
          return (a.year ?? 0) - (b.year ?? 0);
        });
        projects.push({ ...project, projectDaysDtoList });
      });
      userDaysDtoList.sort((a, b) => {
        if (a.year === b.year) {
          return (a.month ?? 0) - (b.month ?? 0);
        }
        return (a.year ?? 0) - (b.year ?? 0);
      });
      return { ...user, userDaysDtoList, projects };
    });
    return { userList: mappedUsers };
  };

  const isMeseConsolidato = (month: number, year: number) => {
    return consolidatedMonths.some((consolidatedMonth) => {
      return consolidatedMonth.month === month && consolidatedMonth.year === year;
    });
  };

  return (
    <>
      <AppAccordion
        formData={filtersDashboardPeopleData}
        location={LocationFilterEnum.DASHBOARD_RESOURCES}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        chipsFormData={chipsFormData}
        setChipsFormData={setChipsFormData}
        reset={reset}
      >
        <FiltersDashboardPeople onSubmit={onSubmit} handleReset={handleReset} />
      </AppAccordion>
      {!users || users?.userList?.length === 0 ? (
        <div className="mt-3">
          <NoData msg={t('dashboard.resources.no-resources')} />
        </div>
      ) : (
        <>
          <p className="d-flex justify-content-end">{t('dashboard.resources.values-legend')}</p>
          <div className="d-flex" style={{ overflowX: 'auto' }} ref={tableRef}>
            <Table bordered className="revenue-table sticky-table test-table mt-0 mb-0">
              <thead>
                <tr>
                  <th></th>
                  <th style={{ minWidth: '350px' }}>{t('dashboard.resources.table-titles.name')}</th>
                  <th style={{ minWidth: '80px' }}>
                    <span>{t('dashboard.resources.table-titles.rend')}</span>
                    <Tooltip title={t('project-detail.current-month-rendicontations-tooltip')} color="white">
                      <i className="fas fa-circle-info text-blue-links ps-2" />
                    </Tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                {visUsers?.map((user: DashboardUserDto, index: number) => (
                  <React.Fragment key={`${user.userName}${index}`}>
                    <tr>
                      <td className="text-center">
                        <div className="container-dashboard">
                          <Button
                            className="btn-sm btn-icon p-0 border-none"
                            onClick={() => handleRowClick(user.userName)}
                          >
                            <i className="fst-normal fw-bold fs-6 text-blue-links">
                              {expandedRows[user.userName!] ? '-' : '+'}
                            </i>
                          </Button>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="container-dashboard">{user.userName}</div>
                          {user.isAttivo === false && (
                            <Tooltip title={`Inactive user`} key={index}>
                              <ExclamationCircleOutlined className="pe-3 ps-3 text-red" />
                            </Tooltip>
                          )}
                        </div>
                      </td>
                      <td>
                        <div style={{ minWidth: '80px' }}>
                          {formatCurrency(user.rendicontazioniMeseTotali?.totaleGiorni)}
                        </div>
                      </td>
                    </tr>
                    {expandedRows[user.userName!] &&
                      user.projects?.map((project: ProjectDto) => (
                        <tr key={project.projectName} className="project-row">
                          <td></td>
                          <td className="align-top">
                            <div className="container-dashboard project-name">
                              <a
                                href={`${window.location.origin}${window.location.pathname}#/${PathEnum.PRIVATE}/${PathEnum.PROGETTI}/${project?.projectId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-links"
                              >
                                {project.projectName}
                              </a>
                            </div>
                          </td>
                          <td>
                            <div>{formatCurrency(project.rendicontazioneMese?.totaleGiorni)}</div>
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
            <Table bordered className="revenue-table test-table mt-0 mb-0">
              <thead>
                <tr>
                  {allMonths.map((date) => (
                    <th key={`${date.month}-${date.year}`} style={{ minWidth: '70px' }}>
                      {getFormattedDateHeader(date.month, date.year)}
                    </th>
                  ))}

                  <th>{t('dashboard.resources.table-titles.total')}</th>
                </tr>
              </thead>
              <tbody>
                {visUsers?.map((user: DashboardUserDto, index: number) => (
                  <React.Fragment key={`${user.userName}${index}`}>
                    <tr>
                      {user.userDaysDtoList?.map((days) => (
                        <td key={`${days.month}-${days.year}`} className={days.isConsolidato ? 'bg-disabled' : ''}>
                          <div className="container-dashboard">{formatCurrency(days.daysActualForecast) || '-'}</div>
                        </td>
                      ))}

                      <td>
                        <div className="container-dashboard">
                          {formatCurrency(
                            user.userDaysDtoList?.reduce(
                              (acc, userDaysDto) => acc + (userDaysDto?.daysActualForecast || 0),
                              0
                            )
                          )}
                        </div>
                      </td>
                    </tr>
                    {expandedRows[user.userName!] &&
                      user.projects?.map((project: ProjectDto, index: number) => (
                        <tr key={`${project.projectName}${index}`} className="project-row">
                          {project.projectDaysDtoList?.map((days) => (
                            <td key={`${days.month}-${days.year}`} className="align-top">
                              <div className="container-dashboard" style={{ height: 'fit-content' }}>
                                {formatCurrency(days?.daysActualForecast)}
                              </div>
                            </td>
                          ))}
                          <td className="align-top">
                            {formatCurrency(
                              project.projectDaysDtoList?.reduce(
                                (acc, allocation) => acc + (allocation.daysActualForecast || 0),
                                0
                              )
                            )}
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
          {visibleUsers?.length > 0 ? (
            <AppPaginator
              keyPaginator={StorageKeysEnum.CURRENT_PAGE_DASHBOARD_USERS}
              currentPage={pageNumberUser}
              totalPages={totalPagesUser}
              totalElements={users?.userList?.length || 0}
              pageSize={50}
              onPageChange={setPageNumberUser}
            />
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default PeopleTable;
