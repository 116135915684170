/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OtherCostListDtoFromJSON, OtherCostListDtoToJSON, } from './OtherCostListDto';
/**
 * Check if a given object implements the ProjectCicloPassivoOutputDto interface.
 */
export function instanceOfProjectCicloPassivoOutputDto(value) {
    return true;
}
export function ProjectCicloPassivoOutputDtoFromJSON(json) {
    return ProjectCicloPassivoOutputDtoFromJSONTyped(json, false);
}
export function ProjectCicloPassivoOutputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'projectName': json['projectName'] == null ? undefined : json['projectName'],
        'nomeCliente': json['nomeCliente'] == null ? undefined : json['nomeCliente'],
        'businessManager': json['businessManager'] == null ? undefined : json['businessManager'],
        'projectManager': json['projectManager'] == null ? undefined : json['projectManager'],
        'stato': json['stato'] == null ? undefined : json['stato'],
        'tipologia': json['tipologia'] == null ? undefined : json['tipologia'],
        'inizio': json['inizio'] == null ? undefined : (new Date(json['inizio'])),
        'fine': json['fine'] == null ? undefined : (new Date(json['fine'])),
        'prezzo': json['prezzo'] == null ? undefined : json['prezzo'],
        'otherCostList': json['otherCostList'] == null ? undefined : OtherCostListDtoFromJSON(json['otherCostList']),
    };
}
export function ProjectCicloPassivoOutputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'projectId': value['projectId'],
        'projectName': value['projectName'],
        'nomeCliente': value['nomeCliente'],
        'businessManager': value['businessManager'],
        'projectManager': value['projectManager'],
        'stato': value['stato'],
        'tipologia': value['tipologia'],
        'inizio': value['inizio'] == null ? undefined : ((value['inizio']).toISOString()),
        'fine': value['fine'] == null ? undefined : ((value['fine']).toISOString()),
        'prezzo': value['prezzo'],
        'otherCostList': OtherCostListDtoToJSON(value['otherCostList']),
    };
}
