import React, { useEffect, useState } from 'react';
// import ProjectListTable, { TableProps } from '../../../shared/design-system/components/app-table';
import AppCard from '../../../shared/design-system/components/app-card';
import useGetAndMapProjects from './_hooks/useGetAndMapProjects';
import usePagination from '../../../core/hooks/usePagination';
import AppAccordion from '../../../shared/design-system/components/app-accordion';
import { useAppDispatch } from '../../../core/store/hooks';
import { resetTeamAndCostsData } from './project-detail/_redux/actions';
import { useTranslation } from 'react-i18next';
import AccordionForm, {
  projectListFormData,
} from '../../../shared/design-system/components/app-accordion/_components/accordion-form';
import useAccordionSubmit from '../../../shared/design-system/components/app-accordion/_hooks/useFormSubmit';
import NoData from '../../../shared/design-system/components/no-data-box';
import { trackUserIdGa4 } from '../../../shared/utils/gtag';
import AppPaginator from '../../../shared/design-system/components/app-paginator';
import { StorageKeysEnum } from '../../../enums/storageKeys.enum';
import { LocationFilterEnum, TrackPagesGA4Enum } from '../../../enums/global.enum';
import ProjectListTable, { TableProps } from './project-list-table';

const ProjectList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { projects } = useGetAndMapProjects();
  const {
    pageNumber: pageNumberProject,
    setPageNumber: setPageNumberProject,
    totalPages: totalPagesProject,
    visibleItems: visibleProjects,
  } = usePagination(projects, 10);

  useEffect(() => {
    trackUserIdGa4(TrackPagesGA4Enum.PROJECT_LIST);
    dispatch(resetTeamAndCostsData());
  }, []);

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset, filterParams } = useAccordionSubmit(
    projectListFormData,
    setIsOpen
  );

  const [controlledDate, setControlledDate] = useState<any>(null);

  const handleReset = () => {
    onReset();
    controlledDate && setControlledDate([]);
    chipsFormData && setChipsFormData([]);
  };

  return (
    <>
      <h4 className="text-gray-800 ">{t('project-list.title')}</h4>
      <AppAccordion
        formData={projectListFormData}
        location={LocationFilterEnum.PROJECT_LIST}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        chipsFormData={chipsFormData}
        setChipsFormData={setChipsFormData}
        reset={reset}
      >
        <AccordionForm
          onSubmit={onSubmit}
          handleReset={handleReset}
          setControlledDate={setControlledDate}
          // onReset={onReset} control={control} register={register}
        />
      </AppAccordion>

      <AppCard title={t('project-list.all-projects')}>
        {projects.length === 0 ? (
          <NoData msg={t('project-list.no-projects')} />
        ) : (
          <ProjectListTable data={visibleProjects as unknown as TableProps['data']} />
        )}
        {visibleProjects?.length > 0 ? (
          <AppPaginator
            keyPaginator={StorageKeysEnum.CURRENT_PAGE_PROJECT_LIST}
            currentPage={pageNumberProject}
            totalPages={totalPagesProject}
            totalElements={projects.length}
            pageSize={10}
            onPageChange={setPageNumberProject}
          />
        ) : (
          ''
        )}
      </AppCard>
    </>
  );
};

export default ProjectList;
