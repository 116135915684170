import { CSSProperties, MouseEventHandler } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import "./style.scss";

interface Props extends ButtonProps {
	children: any;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	type?: "button" | "submit";
	className?: string;
	style?: CSSProperties;
	isDisabled?: any;
}

const LinksButton: React.FC<Props> = (props) => {
	const { onClick, children, className, style, isDisabled, type } = props;
	return (
		<button
			type={type}
			className={`btn ${className} `}
			style={style}
			onClick={onClick}
			disabled={isDisabled}
		>
			{children}
		</button>
	);
};
export default LinksButton;
