import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../../../../shared/utils/common.utils';
import { DatePicker, Input, Select, Tooltip } from 'antd';
import { format } from 'date-fns';
import NoData from '../../../../../../shared/design-system/components/no-data-box';
import useGetBillingData from './_hooks/useGetBillingData';
import useDownloadBillIcs from './_hooks/useDownloadBillIcs';
import FormBootstrap from 'react-bootstrap/Form';
import dayjs from 'dayjs';
import useGetBillingOptions from './_hooks/useGetBillingOptions';
import useEditBill from './_hooks/useEditBill';
import useSaveBill from './_hooks/useSaveBill';
import './styles.scss';
interface Props {
  projectName: string | undefined;
  projectId: number;
  setIsBillingDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const BillingPlan: React.FC<Props> = ({ projectName, projectId, setIsBillingDirty }) => {
  const { t } = useTranslation();
  const { billingPlan, setBillingPlan, isEditEnabled } = useGetBillingData(projectId);
  const { mappedBillingOptions } = useGetBillingOptions();
  const { downloadIcs } = useDownloadBillIcs(projectName);
  const { handleChangeCheckbox, handleChangeDate, handleSelectChange, handleChangeImporto } = useEditBill(
    setBillingPlan,
    setIsBillingDirty
  );
  const { handleSaveBill } = useSaveBill(projectId, setIsBillingDirty);

  return (
    <>
      {billingPlan?.length ? (
        <div style={{ overflowY: 'auto', height: 'calc(100vh - 315px)' }}>
          <Table bordered>
            <thead>
              <tr>
                <th>{t('project-detail.billing-plan.table.name')}</th>
                <th>{t('project-detail.billing-plan.table.stage')}</th>
                <th>{t('project-detail.billing-plan.table.order')}</th>
                <th>{t('project-detail.billing-plan.table.date')}</th>
                <th className="text-end">{t('project-detail.billing-plan.table.amount')}</th>
                <th className="text-center">{t('project-detail.billing-plan.table.sal')}</th>
                <th>{t('project-detail.billing-plan.table.sending-date')}</th>
                <th>{t('project-detail.billing-plan.table.block')}</th>
                {/* <th>{t('project-detail.billing-plan.table.further-amount')}</th> */}
                <th>{t('project-detail.billing-plan.table.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {billingPlan?.map((billing) => (
                <tr key={billing.id}>
                  <td>
                    <a
                      href={`https://links.lightning.force.com/lightning/r/InvoiceMilestone__c/${billing.id}/view`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#22519e' }}
                    >
                      {billing.name}
                    </a>
                  </td>
                  <td>{billing.stage}</td>
                  <td>{billing.order}</td>
                  <td>{billing.date ? format(billing.date, 'dd/MM/yy') : '-'}</td>
                  <td className="text-end">{`${formatCurrency(billing.amount)} €`}</td>
                  <td className="text-center">
                    <FormBootstrap.Check
                      type={'checkbox'}
                      onChange={(e) => handleChangeCheckbox(e, billing.id as string)}
                      checked={billing.statoAvanzamentoLavori?.isInviato}
                      disabled={!isEditEnabled}
                    />
                  </td>
                  <td>
                    <DatePicker
                      size="middle"
                      className="w-100"
                      format="DD/MM/YYYY"
                      placeholder={t('project-detail.billing-plan.placeholders.select-date')}
                      value={
                        billing.statoAvanzamentoLavori?.dataDiInvio
                          ? dayjs(billing.statoAvanzamentoLavori?.dataDiInvio)
                          : undefined
                      }
                      onChange={(e) => handleChangeDate(e, billing.id as string)}
                      disabled={!isEditEnabled}
                    />
                  </td>
                  <td style={{ width: '200px' }}>
                    <Select
                      className="w-100"
                      allowClear
                      placeholder={t('project-detail.billing-plan.placeholders.select-block')}
                      value={billing.statoAvanzamentoLavori?.idTipologiaBlocco?.value}
                      onChange={(e) => handleSelectChange(e, billing.id as string)}
                      disabled={!isEditEnabled}
                    >
                      {mappedBillingOptions?.mappedTipologiaBloccoEnumList?.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  {/* <td style={{ width: '150px' }}>
                    <Input
                      type="number"
                      allowClear
                      placeholder={t('project-detail.billing-plan.placeholders.insert-amount')}
                      value={billing.statoAvanzamentoLavori?.importo}
                      onChange={(e) => handleChangeImporto(e.target.value, billing.id as string)}
                      disabled={!isEditEnabled}
                    />
                  </td> */}
                  <td style={{ width: '50px' }}>
                    <div className="d-flex">
                      <Tooltip placement="top" color="white" title={t('project-detail.billing-plan.actions.download')}>
                        <button
                          onClick={() => {
                            downloadIcs(billing);
                          }}
                          className="btn-icon"
                        >
                          <i className={'icon-file-download text-blue-links me-1'}></i>
                        </button>
                      </Tooltip>
                      <Tooltip placement="top" color="white" title={t('project-detail.billing-plan.actions.save')}>
                        <button
                          onClick={() => {
                            handleSaveBill(billing);
                          }}
                          className="btn-icon"
                          disabled={!isEditEnabled}
                        >
                          <i className={`icon-save ${isEditEnabled ? 'text-blue-links' : 'text-placeholder'} me-1`}></i>
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <NoData msg={t('project-detail.billing-plan.no-invoices')} />
        </div>
      )}
    </>
  );
};

export default BillingPlan;
