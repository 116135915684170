import { MeseAnnoLight } from '@api/client';
import { DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

const useDateRange = (mesiProgetto: MeseAnnoLight[] | undefined) => {
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);

  const onDateChange: DatePickerProps['onChange'] = (dates, dateStr) => {
    if (!dates) setDateRange(null);
    const [meseInizio, annoInizio] = dateStr[0].split('/');
    const [meseFine, annoFine] = dateStr[1].split('/');
    const dataInizio = new Date(parseInt(annoInizio), parseInt(meseInizio) - 1, 5);
    const dataFine = new Date(parseInt(annoFine), parseInt(meseFine) - 1, 5);
    setDateRange([dataInizio, dataFine]);
  };

  const getDefaultDateRange = (): [Dayjs | null | undefined, Dayjs | null | undefined] => {
    if (!mesiProgetto || mesiProgetto.length === 0) return [null, null];

    const firstMonth = mesiProgetto.reduce((acc, curr) => {
      if (acc.anno! > curr.anno! || (acc.anno === curr.anno && acc.mese! > curr.mese!)) return curr;
      return acc;
    });
    const lastMonth = mesiProgetto.reduce((acc, curr) => {
      if (acc.anno! < curr.anno! || (acc.anno === curr.anno && acc.mese! < curr.mese!)) return curr;
      return acc;
    });

    const dataInizio = dayjs(new Date(firstMonth.anno!, firstMonth.mese! - 1, 5));
    const dataFine = dayjs(new Date(lastMonth.anno!, lastMonth.mese! - 1, 5));

    if (dateRange === null) setDateRange([dataInizio.toDate(), dataFine.toDate()]);

    return [dataInizio, dataFine];
  };

  return {
    dateRange,
    onDateChange,
    getDefaultDateRange,
  };
};

export default useDateRange;
