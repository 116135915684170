import { Select } from 'antd';
import AppCard from '../../../shared/design-system/components/app-card';
import { useEffect, useState } from 'react';
import { getMonths } from '../../../shared/utils/common.utils';
import LinksButton from '../../../shared/design-system/components/links-button';
import { useAppDispatch } from '../../../core/store/hooks';
import {
  AggiornaCostiRendicontazioniMesiRequest,
  ApplicationControlDto,
  ConsolidaCostiRendicontazioniRequest,
  LongTermActivityDto,
  SpalmaCostiCoordinamentoRequest,
} from '@api/client';
import {
  activateStateCdg,
  activateStateCl,
  consolidateCosts,
  deactivateStateCdg,
  getActivityInfo,
  getWorkflowInfo,
  spreadCoordinationCosts,
  updateCostsReportingMonths,
  updateOtherCostsMonths,
} from './_redux/actions';
import AppModal from '../../../shared/design-system/components/app-modal';
import { useTranslation } from 'react-i18next';
import { StatusWfm } from '../../../enums/statusWfm';

const Wfm: React.FC = () => {
  const [wfmStatus, setWfmStatus] = useState<string | undefined>('');
  const [activityInfo, setActivityInfo] = useState<LongTermActivityDto | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchWorkflowInfo = () => {
      dispatch(getWorkflowInfo())
        .unwrap()
        .then((workflowInfo: ApplicationControlDto | undefined) => {
          if (workflowInfo && workflowInfo.key === 'WORKFLOW_STATUS') {
            setWfmStatus(workflowInfo.value);
          }
        })
        .catch((error: Error) => {
          console.error('err wfminfo:', error);
        });
    };

    fetchWorkflowInfo();
    dispatch(getActivityInfo())
      .unwrap()
      .then((activityInfo: LongTermActivityDto | undefined) => {
        setActivityInfo(activityInfo);
      })
      .catch((error: Error) => {
        console.error('err activityInfo:', error);
      });
  }, [dispatch]);

  const getPreviousMonth = () => {
    let dataAttuale = new Date();
    let mese = dataAttuale.getMonth() - 1;

    if (mese < 0) {
      mese = 11; // Dicembre
      dataAttuale.setFullYear(dataAttuale.getFullYear() - 1);
    }
    let dataMesePrecedente = new Date(dataAttuale.getFullYear(), mese);
    return dataMesePrecedente;
  };

  const [month, setMonth] = useState(getPreviousMonth().getMonth() + 1);
  const [year, setYear] = useState(getPreviousMonth().getFullYear());
  const [monthCoordination, setMonthCoordination] = useState(getPreviousMonth().getMonth() + 1);
  const [yearCoordination, setYearCoordination] = useState(getPreviousMonth().getFullYear());
  const [startMonthReportingCosts, setStartMonthReportingCosts] = useState(1);
  const [endMonthReportingCosts, setEndMonthReportingCosts] = useState(getPreviousMonth().getMonth() + 1);
  const [yearReportingCosts, setYearReportingCosts] = useState(getPreviousMonth().getFullYear());
  const [startMonthOtherCosts, setStartMonthOtherCosts] = useState(1);
  const [endMonthOtherCosts, setEndMonthOtherCosts] = useState(getPreviousMonth().getMonth() + 1);
  const [yearOtherCosts, setYearOtherCosts] = useState(getPreviousMonth().getFullYear());
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmAction, setConfirmAction] = useState<(() => void) | null>(null);
  const [textMessage, setTextMessage] = useState('');

  const freezeMonth = () => {
    setConfirmAction(() => {
      const request: ConsolidaCostiRendicontazioniRequest = {
        mese: month,
        anno: year,
      };
      return () => dispatch(consolidateCosts(request));
    });
    setTextMessage(t('wfm.confirm-freeze-message-modal'));
    setConfirmVisible(true);
  };

  const openClSession = () => {
    setConfirmAction(() => () => dispatch(activateStateCl()));
    setTextMessage(t('wfm.confirm-open-cl-session-message-modal'));
    setConfirmVisible(true);
  };

  const openCgdSession = () => {
    setConfirmAction(() => () => dispatch(activateStateCdg()));
    setTextMessage(t('wfm.confirm-open-cdg-session-message-modal'));
    setConfirmVisible(true);
  };

  const closeCgdSession = () => {
    setConfirmAction(() => () => dispatch(deactivateStateCdg()));
    setTextMessage(t('wfm.confirm-close-cdg-session-message-modal'));
    setConfirmVisible(true);
  };

  const confirmActionAndCloseModal = () => {
    if (confirmAction) {
      confirmAction();
    }
    setConfirmVisible(false);
  };

  const spreadCoordinationCost = () => {
    setConfirmAction(() => {
      const request: SpalmaCostiCoordinamentoRequest = {
        month: monthCoordination,
        year: yearCoordination,
      };
      return () => dispatch(spreadCoordinationCosts(request));
    });
    setTextMessage(t('wfm.confirm-spread-coordination-costs-message-modal'));
    setConfirmVisible(true);
  };

  const updateCostsReportingMonth = () => {
    setConfirmAction(() => {
      const request: AggiornaCostiRendicontazioniMesiRequest = {
        meseI: startMonthReportingCosts,
        meseF: endMonthReportingCosts,
        anno: yearReportingCosts,
      };
      return () => dispatch(updateCostsReportingMonths(request));
    });
    setTextMessage(t('wfm.confirm-update-costs-reporting-message-modal'));
    setConfirmVisible(true);
  };

  const updateOtherCostsMonth = () => {
    setConfirmAction(() => {
      const request: AggiornaCostiRendicontazioniMesiRequest = {
        meseI: startMonthOtherCosts,
        meseF: endMonthOtherCosts,
        anno: yearOtherCosts,
      };
      return () => dispatch(updateOtherCostsMonths(request));
    });
    setTextMessage(t('wfm.confirm-update-other-costs-reporting-message-modal'));
    setConfirmVisible(true);
  };

  return (
    <>
      {activityInfo?.onGoingActivity ? (
        <div className="d-flex align-items-center mb-3">
          <i className="fa-solid fa-triangle-exclamation me-2 text-danger"></i>
          <span className="text-danger">Operazione in corso: {activityInfo.onGoingActivity}</span>
        </div>
      ) : (
        <div className="mb-3">
          <span className="text-green">Ultima attività eseguita: {activityInfo?.lastActivityReport}</span>
        </div>
      )}
      <AppCard title={t('wfm.title')}>
        {wfmStatus === StatusWfm.OPEN ? (
          <>
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  <label>{t('wfm.month')}</label>
                  <Select
                    className="w-100"
                    size="large"
                    onChange={(value) => {
                      setMonth(value);
                    }}
                    value={month}
                  >
                    {getMonths().map((el) => (
                      <Select.Option key={el.month} value={el.month}>
                        {el.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>{t('wfm.year')}</label>
                  <input
                    type="number"
                    name="year"
                    value={year}
                    onChange={(e) => setYear(e.target.value as unknown as number)}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-3 align-self-end mb-1">
                <LinksButton className="btn btn-links" onClick={freezeMonth}>
                  {t('wfm.freeze-month')}
                </LinksButton>
              </div>
            </div>{' '}
          </>
        ) : (
          ''
        )}
        <div className="row mt-4">
          <div className="col-12">
            {wfmStatus === StatusWfm.CONTROLLO_PL ? (
              <LinksButton className="btn btn-links" onClick={openClSession}>
                {t('wfm.open-cl-session')}
              </LinksButton>
            ) : (
              ''
            )}
            {wfmStatus === StatusWfm.CONTROLLO_CL ? (
              <LinksButton className="btn btn-links" onClick={openCgdSession}>
                {t('wfm.open-cdg-session')}
              </LinksButton>
            ) : (
              ''
            )}
            {wfmStatus === StatusWfm.CONTROLLO_CDG ? (
              <LinksButton className="btn btn-links" onClick={closeCgdSession}>
                {t('wfm.close-cdg-session')}
              </LinksButton>
            ) : (
              ''
            )}
          </div>
        </div>
      </AppCard>
      <AppCard title={t('wfm.title-costs')} className="mt-3">
        <div className="mt-2">
          <h6 className="text-gray-600 filter-header mb-2">
            <b>{t('wfm.spread-coordination-costs').toUpperCase()}</b>
          </h6>
        </div>
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label>{t('wfm.month')}</label>
              <Select
                className="w-100"
                size="large"
                onChange={(value) => {
                  setMonthCoordination(value);
                }}
                value={monthCoordination}
              >
                {getMonths().map((el) => (
                  <Select.Option key={el.month} value={el.month}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>{t('wfm.year')}</label>
              <input
                type="number"
                name="yearCoordination"
                value={yearCoordination}
                onChange={(e) => setYearCoordination(e.target.value as unknown as number)}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-3 align-self-end mb-1">
            <LinksButton className="btn btn-links" onClick={spreadCoordinationCost}>
              {t('wfm.spread-coordination-costs')}
            </LinksButton>
          </div>
        </div>
        <div className="mt-2">
          <h6 className="text-gray-600 filter-header mt-3 mb-2">
            <b>{t('wfm.update-costs-reporting').toUpperCase()}</b>
          </h6>
        </div>
        <div className="row mt-3">
          <div className="col-3">
            <div className="form-group">
              <label>{t('wfm.start-month')}</label>
              <Select
                className="w-100"
                size="large"
                onChange={(value) => {
                  setStartMonthReportingCosts(value);
                }}
                value={startMonthReportingCosts}
              >
                {getMonths().map((el) => (
                  <Select.Option key={el.month} value={el.month}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>{t('wfm.end-month')}</label>
              <Select
                className="w-100"
                size="large"
                onChange={(value) => {
                  setEndMonthReportingCosts(value);
                }}
                value={endMonthReportingCosts}
              >
                {getMonths().map((el) => (
                  <Select.Option key={el.month} value={el.month}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>{t('wfm.year')}</label>
              <input
                type="number"
                name="yearReportingCosts"
                value={yearReportingCosts}
                onChange={(e) => setYearReportingCosts(e.target.value as unknown as number)}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-3 align-self-end mb-1">
            <LinksButton className="btn btn-links" onClick={updateCostsReportingMonth}>
              {t('wfm.update-costs')}
            </LinksButton>
          </div>
        </div>
        <div className="mt-2">
          <h6 className="text-gray-600 filter-header mt-3 mb-2">
            <b>{t('wfm.update-other-costs-reporting').toUpperCase()}</b>
          </h6>
        </div>
        <div className="row mt-3">
          <div className="col-3">
            <div className="form-group">
              <label>{t('wfm.start-month')}</label>
              <Select
                className="w-100"
                size="large"
                onChange={(value) => {
                  setStartMonthOtherCosts(value);
                }}
                value={startMonthOtherCosts}
              >
                {getMonths().map((el) => (
                  <Select.Option key={el.month} value={el.month}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>{t('wfm.end-month')}</label>
              <Select
                className="w-100"
                size="large"
                onChange={(value) => {
                  setEndMonthOtherCosts(value);
                }}
                value={endMonthOtherCosts}
              >
                {getMonths().map((el) => (
                  <Select.Option key={el.month} value={el.month}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>{t('wfm.year')}</label>
              <input
                type="number"
                name="yearOtherCosts"
                value={yearOtherCosts}
                onChange={(e) => setYearOtherCosts(e.target.value as unknown as number)}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-3 align-self-end mb-1">
            <LinksButton className="btn btn-links" onClick={updateOtherCostsMonth}>
              {t('wfm.update-other-costs')}
            </LinksButton>
          </div>
        </div>
      </AppCard>
      <AppModal
        setShow={setConfirmVisible}
        show={confirmVisible}
        title={t('common.attention')}
        cancelText={t('common.cancel')}
        confirmText={t('common.confirm')}
        onConfirm={confirmActionAndCloseModal}
        onCancel={() => setConfirmVisible(false)}
      >
        <p>{textMessage}</p>
      </AppModal>
    </>
  );
};

export default Wfm;
