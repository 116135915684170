import { AllocationDto, GiorniLavorativiMeseAnnoDto, RevenuePlanDto, StaffExtendedDto } from '@api/client';
import { Input, InputNumber } from 'antd';
import { formatCurrency } from '../../../../../../../shared/utils/common.utils';
import { TotalsRevenuesAndGUStickyLabels } from '../../../_utils/revenue-plan.utils';

interface Props {
  fullAllocationsShown: Partial<StaffExtendedDto>[] | null | undefined;
  totalsRevenuePlanShown: RevenuePlanDto[] | null | undefined;
  isWorkInProgress: boolean;
  giorniLavorativi: GiorniLavorativiMeseAnnoDto[] | null | undefined;
  visibleRows: { [key: string]: boolean };
}

const TotalsAllocations: React.FC<Props> = ({
  fullAllocationsShown,
  totalsRevenuePlanShown,
  isWorkInProgress,
  visibleRows,
  giorniLavorativi,
}) => {
  const getGiorniLavorativi = (index: number) => {
    const month = fullAllocationsShown?.[0].allocazioni?.[index]?.month;
    const year = fullAllocationsShown?.[0].allocazioni?.[index]?.year;
    const workingDays = giorniLavorativi?.find((el) => el.mese === month && el.anno === year)?.giorniLvorativi;
    return workingDays || 21;
  };
  return (
    <>
      {visibleRows[TotalsRevenuesAndGUStickyLabels.totalGu.key] && (
        <tr key={'tot-gu'} id="tot-gu">
          {fullAllocationsShown?.[0].allocazioni?.map((allocation: AllocationDto, index: number) => (
            <td className="text-left p-0" key={'GU-per-month-' + index} style={{ minWidth: '100px' }}>
              <InputNumber
                readOnly
                decimalSeparator=","
                variant="borderless"
                value={(
                  fullAllocationsShown
                    ?.map((el) => el.allocazioni?.[index]?.actualAllocation)
                    .reduce((acc, el) => (acc || 0) + (el || 0), 0) ?? 0
                )?.toFixed(2)}
              />
            </td>
          ))}
        </tr>
      )}
      {visibleRows[TotalsRevenuesAndGUStickyLabels.fte.key] && (
        <tr key={'fte'} id="fte">
          {fullAllocationsShown?.[0].allocazioni?.map((allocation: AllocationDto, index: number) => (
            <td className="text-left p-0" key={'fte-per-month-' + index}>
              <InputNumber
                readOnly
                decimalSeparator=","
                variant="borderless"
                stringMode
                value={(
                  (fullAllocationsShown
                    ?.map((el) => el.allocazioni?.[index]?.actualAllocation)
                    .reduce((acc, el) => (acc || 0) + (el || 0), 0) ?? 0) / getGiorniLavorativi(index)
                )?.toFixed(2)}
              />
            </td>
          ))}
        </tr>
      )}
      {visibleRows[TotalsRevenuesAndGUStickyLabels.personnelCosts.key] && (
        <tr key={'costi-personale'} id="costi-personale">
          {totalsRevenuePlanShown?.map((el: RevenuePlanDto, index: number) => (
            <td
              className="text-left p-0"
              key={'costi-personale-per-month-' + index + '-' + el.actualAmount}
              style={{ minWidth: '100px' }}
            >
              <Input readOnly value={formatCurrency(el.actualHrCost)} />
            </td>
          ))}
        </tr>
      )}
      {visibleRows[TotalsRevenuesAndGUStickyLabels.externalCosts.key] && (
        <tr key={'costi-esterni'} id="costi-esterni">
          {totalsRevenuePlanShown?.map((el: RevenuePlanDto, index: number) => (
            <td className="text-left p-0" key={'costi-esterni-per-month-' + index} style={{ minWidth: '100px' }}>
              <Input readOnly value={formatCurrency(el.actualExternalCost)} />
            </td>
          ))}
        </tr>
      )}
      {visibleRows[TotalsRevenuesAndGUStickyLabels.totalCosts.key] && (
        <tr key={'costi-totali'} id="costi-totali">
          {totalsRevenuePlanShown?.map((el: RevenuePlanDto, index: number) => (
            <td className="text-left p-0" key={'costi-totali-per-month-' + index} style={{ minWidth: '100px' }}>
              <Input readOnly value={formatCurrency(el.actualCost)} />
            </td>
          ))}
        </tr>
      )}

      {visibleRows[TotalsRevenuesAndGUStickyLabels.rate.key] && (
        <tr key={'rate'} id="rate">
          {totalsRevenuePlanShown?.map((el: RevenuePlanDto, index: number) => (
            <td className="text-left p-0" key={'rate-per-month-' + index} style={{ minWidth: '100px' }}>
              <Input readOnly value={formatCurrency(el.actualRate)} />
            </td>
          ))}
        </tr>
      )}
      {visibleRows[TotalsRevenuesAndGUStickyLabels.revenues.key] && (
        <tr key={'ricavi'} id="ricavi">
          {totalsRevenuePlanShown?.map((el: RevenuePlanDto, index: number) => (
            <td className="text-left p-0" key={'ricavi-per-month-' + index} style={{ minWidth: '100px' }}>
              <Input readOnly value={formatCurrency(el.actualAmount)} />
            </td>
          ))}
        </tr>
      )}
    </>
  );
};

export default TotalsAllocations;
