import { useState } from 'react';
import { ProfiloProfessionaleDto } from '@api/client';
export interface RuoloTeam {
    id: number;
    idPps: number | null | undefined;
    descrizionePps: string | null | undefined;
    seniority: {
        ppt: number | null | undefined;
        descrizionePpt: string | null | undefined;
        costo: number | null | undefined;
        ricavo: number | null | undefined;
    } | null;
    giorniUomo: number | null | undefined;
    numPersone: number | null | undefined;
}

const generateRuoloTeam = (id: number): RuoloTeam => ({
    id,
    idPps: null,
    descrizionePps: null,
    seniority: {
        ppt: null,
        descrizionePpt: null,
        costo: null,
        ricavo: null,
    },
    giorniUomo: null,
    numPersone: null,
});


const useTeamManagement = (roleList: ProfiloProfessionaleDto[]) => {
    const initialRuoliTeam: RuoloTeam[] = Array.from({ length: 1 }, (_, index) => generateRuoloTeam(index + 1));
    const [ruoliTeam, setRuoliTeam] = useState<RuoloTeam[]>(initialRuoliTeam);

    const handleRoleChange = (id: any, index: number) => {
        const selectedRoleId = id;
        const selectedRole = roleList.find((role) => role.idPps == selectedRoleId);
        if (!selectedRole?.idPps || !selectedRole.descrizionePps) return;
        const updatedRuoliTeam = [...ruoliTeam];
        updatedRuoliTeam[index] = {
            ...updatedRuoliTeam[index],
            idPps: selectedRole?.idPps,
            descrizionePps: selectedRole?.descrizionePps,
            seniority: getFirstSeniority(selectedRole),
        };
        setRuoliTeam(updatedRuoliTeam);
    };

    const getFirstSeniority = (
        role: ProfiloProfessionaleDto
    ): { ppt: number; descrizionePpt: string; costo: number; ricavo: number } => {
        const firstSeniority = role.pptList?.[0];
        return {
            ppt: firstSeniority?.idCostoProfilo || 0,
            descrizionePpt: firstSeniority?.descrizioneCostoProfilo || '',
            costo: firstSeniority?.costo || 0,
            ricavo: firstSeniority?.ricavo || 0,
        };
    };
    const handleSeniorityChange = (id: any, index: number) => {
        const selectedSeniorityPpt = id;
        const updatedRuoliTeam = [...ruoliTeam];
        const selectedSeniority = roleList
            .find((role) => role.descrizionePps === updatedRuoliTeam[index].descrizionePps)
            ?.pptList?.find((ppt) => ppt.idCostoProfilo == selectedSeniorityPpt);
        if (!selectedSeniority?.idCostoProfilo || !selectedSeniority.costo) return;
        updatedRuoliTeam[index] = {
            ...updatedRuoliTeam[index],
            seniority: {
                ppt: selectedSeniority?.idCostoProfilo,
                descrizionePpt: selectedSeniority?.descrizioneCostoProfilo,
                costo: selectedSeniority?.costo,
                ricavo: selectedSeniority?.ricavo,
            },
        };
        setRuoliTeam(updatedRuoliTeam);
    };


    const handleChangeAmounts = (event: any, index: number, name: string) => {
        const updatedRuoliTeam = [...ruoliTeam];
        updatedRuoliTeam[index] = {
            ...updatedRuoliTeam[index],
            [name]: Number(event),
        };
        setRuoliTeam(updatedRuoliTeam);
    };
    const addRole = () => {
        const maxIndex = ruoliTeam.length > 0 ? Math.max(...ruoliTeam.map((ruolo) => ruolo.id)) : 0;
        setRuoliTeam([...ruoliTeam, generateRuoloTeam(maxIndex + 1)]);
    };

    const deleteRole = (index: number) => {
        const idRole = ruoliTeam[index].id;
        const updatedRuoliTeam = ruoliTeam.filter((ruolo) => ruolo.id !== idRole);
        setRuoliTeam(updatedRuoliTeam);
    };


    return {
        ruoliTeam,
        handleRoleChange,
        handleSeniorityChange,
        handleChangeAmounts,
        addRole,
        deleteRole,
    };
};

export default useTeamManagement;