import { useTranslation } from 'react-i18next';
import LinksButton from '../../../../shared/design-system/components/links-button';
import { Formik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../core/store/hooks';
import { allUsersSelector } from '../../project-list/project-detail/_redux/selectors';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { filterOption } from '../../../../shared/utils/common.utils';
import { currentProjectSelector } from '../../project-list/_redux/selectors';
import { closeRendicontazione, getUsers } from '../../project-list/project-detail/_redux/actions';
import { DettagliProgettoDto, FilterDtoStatoEnum, SearchUsersRequest } from '@api/client';
import { useEffect, useState } from 'react';
import { crudEditProjectDelivery } from '../_redux/actions';
import { getProjectDetails, resetCurrentProject } from '../../project-list/_redux/actions';
import NoData from '../../../../shared/design-system/components/no-data-box';
import { Card } from 'react-bootstrap';
import ModalAction from '../../project-list/project-detail/_components/modal-action';
import { AppToastService } from '../../../../shared/design-system/components/app-toast/app-toast.service';
import DelegateManagement from '../../../../shared/_components/delegate-management';
import { DelegateTypesEnum } from '../../../../enums/global.enum';

const ProjectCrudDelivery: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const allUsers = useAppSelector(allUsersSelector);
  const project = useAppSelector(currentProjectSelector);
  const [showModalCloseReporting, setshowModalCloseReporting] = useState(false);
  const projectId = project?.dettaglioOfferta?.id;

  useEffect(() => {
    const request: SearchUsersRequest = {
      userSearchRequest: {},
    };
    if (!allUsers) dispatch(getUsers(request));

    return () => {
      dispatch(resetCurrentProject());
    };
  }, []);

  const handleSubmit = (values: Partial<DettagliProgettoDto>) => {
    const updatedValues: DettagliProgettoDto = {
      ...project?.dettaglioOfferta,
      startDate: dayjs(values.startDate).add(12, 'hour').toDate(),
      endDate: dayjs(values.endDate).add(12, 'hour').toDate(),
      idProjectManager: values.idProjectManager,
    };
    dispatch(crudEditProjectDelivery(updatedValues));
  };

  const closeReporting = (projectId: number) => {
    if (projectId) {
      dispatch(closeRendicontazione(projectId)).then(() => {
        dispatch(getProjectDetails(projectId));
      });
      return AppToastService.success(`${t('project-detail.toast-reporting-success')}-${projectId}`);
    }
  };

  return (
    <>
      <Card className="mt-3">
        <Card.Header className="card-header-border">
          <div className="d-flex justify-content-between">
            <b>{project?.dettaglioOfferta?.name || t('crud.delivery-crud.title-card')}</b>

            <LinksButton
              className="btn-sm btn-outline-danger px-2 ms-0 ms-auto"
              isDisabled={project?.dettaglioOfferta?.stato === FilterDtoStatoEnum?.ReportingClosed || !projectId}
              onClick={() => setshowModalCloseReporting(true)}
            >
              <div className="d-flex align-items-center">
                <i className="icon-time-expire-out-of-time pe-2" />
                {t('project-detail.title-close-reporting')}
              </div>
            </LinksButton>
          </div>
        </Card.Header>
        <Card.Body>
          {project ? (
            <Formik
              onSubmit={handleSubmit}
              initialValues={{
                startDate: project?.dettaglioOfferta?.startDate ?? undefined,
                endDate: project?.dettaglioOfferta?.endDate ?? undefined,
                idProjectManager: project?.dettaglioOfferta?.idProjectManager ?? undefined,
              }}
            >
              {({ handleSubmit: _handleSubmit, handleChange, setFieldValue, values, touched, errors }) => {
                const handleChangeCustom = (input: string) => (e: any) => {
                  setFieldValue(input, e);
                };

                const onChangeDate = (input: string) => (date: dayjs.Dayjs) => {
                  setFieldValue(input, date ? date.format('YYYY-MM-DD') : null);
                };

                return (
                  <form onSubmit={_handleSubmit}>
                    <div className="row mt-3">
                      <div className="col-3">
                        <div className="form-group">
                          <label>{t('crud.input-label.start-date-label')}</label>
                          <DatePicker
                            size="large"
                            className="w-100"
                            format="DD/MM/YYYY"
                            placeholder={t('crud.input-placeholder.start-date-placeholder')}
                            value={values.startDate ? dayjs(values.startDate) : null}
                            onChange={onChangeDate('startDate')}
                            disabled={!project?.editDeliveryEnabled}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label>{t('crud.input-label.end-date-label')}</label>
                          <DatePicker
                            size="large"
                            className="w-100"
                            format="DD/MM/YYYY"
                            placeholder={t('crud.input-placeholder.end-date-placeholder')}
                            value={values.endDate ? dayjs(values.endDate) : null}
                            onChange={onChangeDate('endDate')}
                            disabled={!project?.editDeliveryEnabled}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label>{t('crud.input-label.project-lead-label')}</label>
                          <Select
                            showSearch
                            className="w-100"
                            size="large"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('crud.input-placeholder.placeholder-project-lead')}
                            onChange={handleChangeCustom('idProjectManager')}
                            value={values.idProjectManager}
                            disabled={!project?.editDeliveryEnabled}
                          >
                            {allUsers?.map((el) => (
                              <Select.Option key={`pm${el.id}`} value={el.id}>
                                {el.nominativo}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <LinksButton
                        type="submit"
                        className="btn-primary btn-links"
                        isDisabled={!project?.editDeliveryEnabled}
                      >
                        {t('crud.button-label.update')}
                      </LinksButton>
                    </div>
                  </form>
                );
              }}
            </Formik>
          ) : (
            <NoData msg={t('project-list.no-highlighted-projects')} />
          )}
        </Card.Body>
      </Card>

      <DelegateManagement
        type={DelegateTypesEnum.PROJECT}
        idProject={project?.dettaglioOfferta?.id}
        idProjectManager={project?.dettaglioOfferta?.idProjectManager}
      />

      <ModalAction
        title={t('project-detail.title-close-reporting')}
        actionLabel={t('common.confirm')}
        cancelLabel={t('common.cancel')}
        action={() => {
          closeReporting(projectId!);
        }}
        setShow={(value) => setshowModalCloseReporting(value)}
        show={showModalCloseReporting}
      >
        <span className="text-danger">
          <i className="fa-solid fa-triangle-exclamation"></i> <b>{t('common.attention')}</b>{' '}
        </span>
        <div className="p-1">
          <p>{t('project-detail.content-close-reporting')}</p>
        </div>
      </ModalAction>
    </>
  );
};

export default ProjectCrudDelivery;
