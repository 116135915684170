/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the DashboardProjectTotalsDto interface.
 */
export function instanceOfDashboardProjectTotalsDto(value) {
    return true;
}
export function DashboardProjectTotalsDtoFromJSON(json) {
    return DashboardProjectTotalsDtoFromJSONTyped(json, false);
}
export function DashboardProjectTotalsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'totContrattoTotal': json['totContrattoTotal'] == null ? undefined : json['totContrattoTotal'],
        'pregressoTotal': json['pregressoTotal'] == null ? undefined : json['pregressoTotal'],
        'actualRevenuesTotal': json['actualRevenuesTotal'] == null ? undefined : json['actualRevenuesTotal'],
        'backlogTotal': json['backlogTotal'] == null ? undefined : json['backlogTotal'],
        'forecastRevenuesTotal': json['forecastRevenuesTotal'] == null ? undefined : json['forecastRevenuesTotal'],
        'afRevenuesTotal': json['afRevenuesTotal'] == null ? undefined : json['afRevenuesTotal'],
        'actualCostsTotal': json['actualCostsTotal'] == null ? undefined : json['actualCostsTotal'],
        'actualHrCostsTotal': json['actualHrCostsTotal'] == null ? undefined : json['actualHrCostsTotal'],
        'actualOtherCostsTotal': json['actualOtherCostsTotal'] == null ? undefined : json['actualOtherCostsTotal'],
        'actualMarginTotal': json['actualMarginTotal'] == null ? undefined : json['actualMarginTotal'],
        'actualMarginRateTotal': json['actualMarginRateTotal'] == null ? undefined : json['actualMarginRateTotal'],
        'actualRateTotal': json['actualRateTotal'] == null ? undefined : json['actualRateTotal'],
        'ricaviInizioAnnoTotal': json['ricaviInizioAnnoTotal'] == null ? undefined : json['ricaviInizioAnnoTotal'],
        'prjAFteAnnoTotal': json['prjAFteAnnoTotal'] == null ? undefined : json['prjAFteAnnoTotal'],
        'prjAFFteAnnoTotal': json['prjAFFteAnnoTotal'] == null ? undefined : json['prjAFFteAnnoTotal'],
        'prjAFGUTotal': json['prjAFGUTotal'] == null ? undefined : json['prjAFGUTotal'],
        'prjFGUTotal': json['prjFGUTotal'] == null ? undefined : json['prjFGUTotal'],
        'prjAfMargin': json['prjAfMargin'] == null ? undefined : json['prjAfMargin'],
        'prjAfMarginPerc': json['prjAfMarginPerc'] == null ? undefined : json['prjAfMarginPerc'],
        'prjNPRicavi': json['prjNPRicavi'] == null ? undefined : json['prjNPRicavi'],
        'prjAFCostiTotal': json['prjAFCostiTotal'] == null ? undefined : json['prjAFCostiTotal'],
    };
}
export function DashboardProjectTotalsDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'totContrattoTotal': value['totContrattoTotal'],
        'pregressoTotal': value['pregressoTotal'],
        'actualRevenuesTotal': value['actualRevenuesTotal'],
        'backlogTotal': value['backlogTotal'],
        'forecastRevenuesTotal': value['forecastRevenuesTotal'],
        'afRevenuesTotal': value['afRevenuesTotal'],
        'actualCostsTotal': value['actualCostsTotal'],
        'actualHrCostsTotal': value['actualHrCostsTotal'],
        'actualOtherCostsTotal': value['actualOtherCostsTotal'],
        'actualMarginTotal': value['actualMarginTotal'],
        'actualMarginRateTotal': value['actualMarginRateTotal'],
        'actualRateTotal': value['actualRateTotal'],
        'ricaviInizioAnnoTotal': value['ricaviInizioAnnoTotal'],
        'prjAFteAnnoTotal': value['prjAFteAnnoTotal'],
        'prjAFFteAnnoTotal': value['prjAFFteAnnoTotal'],
        'prjAFGUTotal': value['prjAFGUTotal'],
        'prjFGUTotal': value['prjFGUTotal'],
        'prjAfMargin': value['prjAfMargin'],
        'prjAfMarginPerc': value['prjAfMarginPerc'],
        'prjNPRicavi': value['prjNPRicavi'],
        'prjAFCostiTotal': value['prjAFCostiTotal'],
    };
}
