/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ContractOrderSearchDto interface.
 */
export function instanceOfContractOrderSearchDto(value) {
    return true;
}
export function ContractOrderSearchDtoFromJSON(json) {
    return ContractOrderSearchDtoFromJSONTyped(json, false);
}
export function ContractOrderSearchDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'contractStatus': json['contractStatus'] == null ? undefined : json['contractStatus'],
        'orderProcessingStatus': json['orderProcessingStatus'] == null ? undefined : json['orderProcessingStatus'],
        'automaticRenewal': json['automaticRenewal'] == null ? undefined : json['automaticRenewal'],
        'expenseItems': json['expenseItems'] == null ? undefined : json['expenseItems'],
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'withoutExternalCosts': json['withoutExternalCosts'] == null ? undefined : json['withoutExternalCosts'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'signatureDate': json['signatureDate'] == null ? undefined : (new Date(json['signatureDate'])),
        'startSignatureDate': json['startSignatureDate'] == null ? undefined : (new Date(json['startSignatureDate'])),
        'endSignatureDate': json['endSignatureDate'] == null ? undefined : (new Date(json['endSignatureDate'])),
        'fornitoreIds': json['fornitoreIds'] == null ? undefined : json['fornitoreIds'],
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'cancellationWithin': json['cancellationWithin'] == null ? undefined : (new Date(json['cancellationWithin'])),
        'cancelAtAnyTime': json['cancelAtAnyTime'] == null ? undefined : json['cancelAtAnyTime'],
        'withdrawalWithin': json['withdrawalWithin'] == null ? undefined : (new Date(json['withdrawalWithin'])),
        'categoryIds': json['categoryIds'] == null ? undefined : json['categoryIds'],
        'object': json['object'] == null ? undefined : json['object'],
        'legalEntityIds': json['legalEntityIds'] == null ? undefined : json['legalEntityIds'],
        'idExternalContract': json['idExternalContract'] == null ? undefined : json['idExternalContract'],
    };
}
export function ContractOrderSearchDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'contractStatus': value['contractStatus'],
        'orderProcessingStatus': value['orderProcessingStatus'],
        'automaticRenewal': value['automaticRenewal'],
        'expenseItems': value['expenseItems'],
        'projectId': value['projectId'],
        'withoutExternalCosts': value['withoutExternalCosts'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'signatureDate': value['signatureDate'] == null ? undefined : ((value['signatureDate']).toISOString()),
        'startSignatureDate': value['startSignatureDate'] == null ? undefined : ((value['startSignatureDate']).toISOString()),
        'endSignatureDate': value['endSignatureDate'] == null ? undefined : ((value['endSignatureDate']).toISOString()),
        'fornitoreIds': value['fornitoreIds'],
        'orderId': value['orderId'],
        'cancellationWithin': value['cancellationWithin'] == null ? undefined : ((value['cancellationWithin']).toISOString()),
        'cancelAtAnyTime': value['cancelAtAnyTime'],
        'withdrawalWithin': value['withdrawalWithin'] == null ? undefined : ((value['withdrawalWithin']).toISOString()),
        'categoryIds': value['categoryIds'],
        'object': value['object'],
        'legalEntityIds': value['legalEntityIds'],
        'idExternalContract': value['idExternalContract'],
    };
}
