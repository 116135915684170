/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AllocationInputDto interface.
 */
export function instanceOfAllocationInputDto(value) {
    return true;
}
export function AllocationInputDtoFromJSON(json) {
    return AllocationInputDtoFromJSONTyped(json, false);
}
export function AllocationInputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'actualAllocation': json['actualAllocation'] == null ? undefined : json['actualAllocation'],
        'year': json['year'] == null ? undefined : json['year'],
        'month': json['month'] == null ? undefined : json['month'],
    };
}
export function AllocationInputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'actualAllocation': value['actualAllocation'],
        'year': value['year'],
        'month': value['month'],
    };
}
