/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the UserDto interface.
 */
export function instanceOfUserDto(value) {
    return true;
}
export function UserDtoFromJSON(json) {
    return UserDtoFromJSONTyped(json, false);
}
export function UserDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'nominativo': json['nominativo'] == null ? undefined : json['nominativo'],
        'email': json['email'] == null ? undefined : json['email'],
        'idPpt': json['idPpt'] == null ? undefined : json['idPpt'],
        'idPps': json['idPps'] == null ? undefined : json['idPps'],
        'costo': json['costo'] == null ? undefined : json['costo'],
        'idTipologia': json['idTipologia'] == null ? undefined : json['idTipologia'],
    };
}
export function UserDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'nominativo': value['nominativo'],
        'email': value['email'],
        'idPpt': value['idPpt'],
        'idPps': value['idPps'],
        'costo': value['costo'],
        'idTipologia': value['idTipologia'],
    };
}
