import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useAppSelector } from '../../../../core/store/hooks';
import { ordersSelector } from '../_redux/selectors';
import { ContractOrderOutputDto } from '@api/client';
import { formatCurrency } from '../../../../shared/utils/common.utils';
import { mappedOrderContractStatusEnumList } from '../../../../enums/orderOptions';

interface MappedOrder {
  id: MappedFieldOrder;
  idExternalContract: MappedFieldOrder;
  resaleProjectCode: MappedFieldOrder;
  supplier: MappedFieldOrder;
  contractValue: MappedFieldOrder;
  remainingBalance: MappedFieldOrder;
  object: MappedFieldOrder;
  startDate: MappedFieldOrder;
  endDate: MappedFieldOrder;
  signatureDate: MappedFieldOrder;
  contractStatus: MappedFieldOrder;
  azione: MappedFieldOrder;
}

interface MappedFieldOrder {
  label: string;
  value: string | number | undefined;
  visible: boolean;
  statusBadge?: string;
  isButton?: boolean;
  icon?: string;
  className?: string;
  tooltip?: string;
}

const useGetAndMapOrders = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<MappedOrder[]>([]);

  const filteredOrders = useAppSelector(ordersSelector);

  useEffect(() => {
    if (filteredOrders.length > 0) {
      const nonDeletedOrders = filteredOrders.filter((order) => order.isDeleted === false);
      setOrders(
        mapOrders(nonDeletedOrders)?.sort((a, b) =>
          new Date(a.signatureDate.value!).getTime() < new Date(b.signatureDate.value!).getTime() ? 1 : -1
        )
      );
    }
  }, [filteredOrders]);

  const mapOrders = (orders: ContractOrderOutputDto[]): MappedOrder[] => {
    return orders.map((order) => {
      return {
        id: { label: t('project-list.id'), value: order.id, visible: false },
        idExternalContract: { label: t('project-list.id'), value: order.idExternalContract, visible: true },
        resaleProjectCode: {
          label: t('Resale project code'),
          value: order.resaleProjectCode,
          visible: false,
        },
        supplier: {
          label: t('Supplier'),
          value: `${order.idFornitore?.denominazione}`,
          visible: true,
        },
        contractValue: {
          label: t('Supply amount (€)'),
          value: `${formatCurrency(order.contractValue)}€`,
          visible: true,
        },
        remainingBalance: {
          label: t('Remaining balance  (€)'),
          value: `${formatCurrency(order.remainingBalance)}€`,
          visible: false,
        },
        object: {
          label: t('Object'),
          value: `${order.object}`,
          visible: true,
        },
        startDate: {
          label: t('Start date'),
          value: order?.startDate ? format(new Date(order?.startDate), 'dd/MM/yyyy') : '-',
          visible: true,
        },
        endDate: {
          label: t('End date'),
          value: order?.endDate ? format(new Date(order?.endDate), 'dd/MM/yyyy') : '-',
          visible: true,
        },
        signatureDate: {
          label: t('Signature date'),
          value: order?.signatureDate ? format(new Date(order?.signatureDate), 'dd/MM/yyyy') : '-',
          visible: true,
        },
        contractStatus: {
          label: t('Contract status'),
          value: mappedOrderContractStatusEnumList.find((el) => el.value === order.contractStatus?.value)?.label,
          visible: true,
          statusBadge: order.contractStatus?.id === 1 ? 'success' : 'danger',
        },
        azione: {
          label: t('project-list.action'),
          value: '',
          visible: true,
          isButton: true,
          icon: 'fa fas fa-arrow-right',
          className: 'text-center',
          tooltip: t('project-list.action-tooltip'),
        },
      };
    });
  };
  return { orders };
};

export default useGetAndMapOrders;
