import { ExternalCostDto } from '@api/client';
import React from 'react';
import { useEffect } from 'react';

const useValidationCosts = ({
  totalOtherCosts,
  otherCostsTableData,
  setTotalCostsValidation,
}: {
  totalOtherCosts: number[] | undefined;
  otherCostsTableData: ExternalCostDto[] | null | undefined;
  setTotalCostsValidation: (value: boolean) => void;
}) => {
  const [areTotalsCostsMatch, setAreTotalsCostsMatch] = React.useState<boolean[]>();

  useEffect(() => {
    if (totalOtherCosts && totalOtherCosts.length > 0) {
      let validation = true;
      totalOtherCosts.forEach((el, index) => {
        if (
          el !==
          otherCostsTableData?.[index]?.costAllocation
            ?.filter((alloc) => alloc.editEnabled)
            ?.reduce((acc, el) => acc + (el.actualCost ?? 0), 0)
        ) {
          validation = false;
        }
      });

      setTotalCostsValidation(validation);

      setAreTotalsCostsMatch(
        totalOtherCosts.map(
          (el, index) =>
            el ==
            otherCostsTableData?.[index]?.costAllocation
              ?.filter((alloc) => alloc.editEnabled)
              ?.reduce((acc, el) => acc + (el.actualCost ?? 0), 0)
        )
      );
    }
  }, [totalOtherCosts]);

  const getTooltip = (index: number) => {
    const difference =
      totalOtherCosts?.[index]!! -
      (otherCostsTableData?.[index]?.costAllocation
        ?.filter((alloc) => alloc.editEnabled)
        ?.reduce((acc, el) => acc + (el.actualCost ?? 0), 0) ?? 0);
    return `${difference > 0 ? '+' : ''}${Number(difference.toFixed(2))} €`;
  };

  return {
    areTotalsCostsMatch,
    getTooltip,
  };
};

export default useValidationCosts;
