import React from 'react';
import "./styles.scss"
import LinksButton from '../../../shared/design-system/components/links-button';
import { t } from 'i18next';

const NotFoundPage = () => {
    const goBack = () => {
        window.history.back();
    };
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <div className="hit-the-floor">404</div>
            <h3 className='mt-0 fs-4'>{t('not-found.title')}</h3>
            <p className='mt-2 text-gray'>{t('not-found.content')}<br />
                {t('not-found.content-2')}</p>

            <LinksButton className="btn-primary btn-links" onClick={goBack}>
                {t('not-found.button-label')}
            </LinksButton>
        </div>
    );
};

export default NotFoundPage;