import { DettaglioProjectDto } from '@api/client';
import { useEffect, useState } from 'react';
import { RuoloTeam } from './project-detail/useTeamManagement';

const useCalculateIndicatori = (projectDetails: DettaglioProjectDto | null, ruoliTeam: RuoloTeam[]) => {
  const [indicatori, setIndicatori] = useState({
    importoOfferta: projectDetails?.baselineOfferta?.importoOfferta || 0,
    costoTotaleStimato: 0,
    scostamento: 0,
    totaleGiorniUomo: 0,
    nRisorse: 0,
    ricavi: projectDetails?.baselineOfferta?.importoOfferta || 0,
    margine: 0,
  });

  useEffect(() => {
    setIndicatori({
      ...indicatori,
      importoOfferta: projectDetails?.baselineOfferta?.importoOfferta || 0,
      ricavi: projectDetails?.baselineOfferta?.importoOfferta || 0,
    });
  }, [projectDetails]);

  useEffect(() => {
    const costoTotaleStimato = ruoliTeam.reduce((acc, ruolo) => {
      if (!ruolo.seniority?.costo || !ruolo.giorniUomo) return acc;
      return acc + ruolo.seniority.costo * ruolo.giorniUomo;
    }, 0);
    const scostamento = indicatori.importoOfferta - costoTotaleStimato;
    const totaleGiorniUomo = ruoliTeam.reduce((acc, ruolo) => {
      if (!ruolo.giorniUomo || !ruolo.numPersone) return acc;
      return acc + ruolo.giorniUomo;
    }, 0);
    const nRisorse = ruoliTeam.reduce((acc, ruolo) => {
      if (!ruolo.numPersone) return acc;
      return acc + ruolo.numPersone;
    }, 0);
    const ricavi = indicatori.importoOfferta;
    const margine = indicatori.importoOfferta
      ? ((indicatori.importoOfferta - costoTotaleStimato) / indicatori.importoOfferta) * 100
      : 0;
    setIndicatori({
      ...indicatori,
      costoTotaleStimato,
      scostamento,
      totaleGiorniUomo,
      nRisorse,
      ricavi,
      margine,
    });
  }, [ruoliTeam]);

  return { indicatori };
};

export default useCalculateIndicatori;
