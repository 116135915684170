/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExpenseItemFromJSON, ExpenseItemToJSON, } from './ExpenseItem';
/**
 * Check if a given object implements the OrderExpenseItem interface.
 */
export function instanceOfOrderExpenseItem(value) {
    return true;
}
export function OrderExpenseItemFromJSON(json) {
    return OrderExpenseItemFromJSONTyped(json, false);
}
export function OrderExpenseItemFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'expenseItemId': json['expenseItemId'] == null ? undefined : ExpenseItemFromJSON(json['expenseItemId']),
    };
}
export function OrderExpenseItemToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'expenseItemId': ExpenseItemToJSON(value['expenseItemId']),
    };
}
