import {
  DeleteDesignatedPersonForProjectRequest,
  DeleteDesignatedPersonForUserRequest,
  InsertDesignatedPersonForProjectRequest,
  InsertDesignatedPersonForUserRequest,
  SearchUsersRequest,
  UserDto,
} from '@api/client';
import { useAppDispatch, useAppSelector } from '../../../core/store/hooks';
import { DelegateTypesEnum } from '../../../enums/global.enum';
import { allUsersSelector } from '../../../pages/private/project-list/project-detail/_redux/selectors';
import AppCard from '../../design-system/components/app-card';
import { getUsers } from '../../../pages/private/project-list/project-detail/_redux/actions';
import { useEffect, useState } from 'react';
import {
  deleteDelegateHcc,
  deleteDelegateProject,
  getDelegatesHcc,
  getDelegatesProject,
  insertDelegateHcc,
  insertDelegateProject,
} from './_redux/actions';
import NoData from '../../design-system/components/no-data-box';
import { Table } from 'react-bootstrap';
import { Select } from 'antd';
import { filterOption } from '../../utils/common.utils';
import LinksButton from '../../design-system/components/links-button';
import useRoles from '../../../pages/private/project-list/project-detail/_hooks/project-detail/useRoles';
import AuthService from '../../../core/auth/auth.service';
import { useTranslation } from 'react-i18next';

interface Props {
  type: DelegateTypesEnum;
  idProject?: number;
  idProjectManager?: number;
}

const DelegateManagement: React.FC<Props> = ({ type, idProject, idProjectManager }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const allUsers = useAppSelector(allUsersSelector);
  const roleList = useRoles();
  const [delegates, setDelegates] = useState<UserDto[]>([]);
  const [selectedDelegate, setSelectedDelegate] = useState<number | null>(null);

  useEffect(() => {
    const request: SearchUsersRequest = {
      userSearchRequest: {},
    };
    if (!allUsers) dispatch(getUsers(request));
    getDelegates();
  }, []);

  const getDelegates = () => {
    if (type === DelegateTypesEnum.PROJECT && idProject) {
      dispatch(getDelegatesProject({ idProject }))
        .unwrap()
        .then((res) => {
          setDelegates(res?.utenti || []);
        });
    }
    if (type === DelegateTypesEnum.HCC) {
      dispatch(getDelegatesHcc())
        .unwrap()
        .then((res) => {
          setDelegates(res?.utenti || []);
        });
    }
  };

  const addDelegate = () => {
    if (!selectedDelegate || delegates.find((d) => d.id === selectedDelegate)) return;
    const user = allUsers?.find((u) => u.id === selectedDelegate);
    if (!user) return;
    setSelectedDelegate(null);

    if (type === DelegateTypesEnum.PROJECT && idProject) {
      const payload: InsertDesignatedPersonForProjectRequest = {
        idProject: idProject,
        userDto: user,
      };

      dispatch(insertDelegateProject(payload)).then(() => {
        getDelegates();
      });
    }

    if (type === DelegateTypesEnum.HCC) {
      const payload: InsertDesignatedPersonForUserRequest = {
        userDto: user,
      };

      dispatch(insertDelegateHcc(payload)).then(() => {
        getDelegates();
      });
    }

    console.log(delegates);
  };

  const removeDelegate = (id: number | undefined) => {
    if (!id) return;
    const user = delegates.find((d) => d.id === id);
    if (!user) return;
    if (type === DelegateTypesEnum.PROJECT && idProject) {
      const payload: DeleteDesignatedPersonForProjectRequest = {
        idProject: idProject,
        userDto: user,
      };

      dispatch(deleteDelegateProject(payload)).then(() => {
        getDelegates();
      });
    }

    if (type === DelegateTypesEnum.HCC) {
      const payload: DeleteDesignatedPersonForUserRequest = {
        userDto: user,
      };

      dispatch(deleteDelegateHcc(payload)).then(() => {
        getDelegates();
      });
    }
  };

  const canAddDelegate = () => {
    if (type === DelegateTypesEnum.PROJECT) {
      const userId = AuthService.getUser()?.userId;
      const isPLProject = Number(userId) === idProjectManager || AuthService.isSuperAdmin();
      return selectedDelegate && !delegates.find((d) => d.id === selectedDelegate) && isPLProject;
    }
    return selectedDelegate && !delegates.find((d) => d.id === selectedDelegate);
  };

  return (
    <AppCard title={t('delegates-management.title')} className="mt-3">
      <div className="d-flex">
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          placeholder={t('delegates-management.select-delegate-placeholder')}
          size="large"
          className="col-3"
          onChange={(value) => {
            console.log(value);
            setSelectedDelegate(value as number);
          }}
          value={selectedDelegate}
        >
          {allUsers?.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user.nominativo}
            </Select.Option>
          ))}
        </Select>
        <LinksButton
          className="btn-primary btn-links ms-3"
          isDisabled={!canAddDelegate()}
          onClick={() => {
            addDelegate();
          }}
        >
          {t('common.add')}
        </LinksButton>
      </div>

      {delegates.length === 0 ? (
        <NoData msg="No delegates found" />
      ) : (
        <div>
          <Table bordered className="mt-3">
            <thead>
              <tr>
                <th>{t('delegates-management.name')}</th>
                <th>{t('delegates-management.email')}</th>
                <th>{t('delegates-management.pps-profile')}</th>
                <th>{t('delegates-management.ppt-seniority')}</th>
                <th style={{ width: '70px' }}>{t('delegates-management.action')}</th>
              </tr>
            </thead>
            <tbody>
              {delegates.map((delegate) => (
                <tr key={delegate.id}>
                  <td>{delegate.nominativo}</td>
                  <td>{delegate.email}</td>
                  <td>{roleList.find((el) => el.idPps == delegate.idPps)?.descrizionePps}</td>
                  <td>
                    {
                      roleList
                        .find((el) => el.idPps == delegate.idPps)
                        ?.pptList?.find((el) => el.idCostoProfilo == delegate.idPpt)?.descrizioneCostoProfilo
                    }
                  </td>
                  <td className="text-center">
                    <button
                      onClick={() => {
                        removeDelegate(delegate?.id);
                      }}
                      className="btn-icon"
                    >
                      <i className={'icon-delete'} style={{ color: 'red' }}></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </AppCard>
  );
};

export default DelegateManagement;
