/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StatoAvanzamentoLavoriOutputDtoFromJSON, StatoAvanzamentoLavoriOutputDtoToJSON, } from './StatoAvanzamentoLavoriOutputDto';
/**
 * Check if a given object implements the StatoAvanzamentoOutputList interface.
 */
export function instanceOfStatoAvanzamentoOutputList(value) {
    return true;
}
export function StatoAvanzamentoOutputListFromJSON(json) {
    return StatoAvanzamentoOutputListFromJSONTyped(json, false);
}
export function StatoAvanzamentoOutputListFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'statoAvanzamentoLavoriOutputDtoList': json['statoAvanzamentoLavoriOutputDtoList'] == null ? undefined : (json['statoAvanzamentoLavoriOutputDtoList'].map(StatoAvanzamentoLavoriOutputDtoFromJSON)),
    };
}
export function StatoAvanzamentoOutputListToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'statoAvanzamentoLavoriOutputDtoList': value['statoAvanzamentoLavoriOutputDtoList'] == null ? undefined : (value['statoAvanzamentoLavoriOutputDtoList'].map(StatoAvanzamentoLavoriOutputDtoToJSON)),
    };
}
