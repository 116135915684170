import { RevenuePlanDto, StaffExtendedDto } from '@api/client';
import { formatCurrency } from '../../../../../../../shared/utils/common.utils';
import { Input, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { TotalsRevenuesAndGUStickyLabels } from '../../../_utils/revenue-plan.utils';

interface TotalsStickyProps {
  fullAllocations: Partial<StaffExtendedDto>[] | null | undefined;
  isWorkInProgress: boolean;
  totalsRevenuePlan: RevenuePlanDto[] | null | undefined;
  colSpan: number;
  visibleRows: { [key: string]: boolean };
  visibleColumns: any;
}

const TotalsRevenuesAndGUSticky: React.FC<TotalsStickyProps> = ({
  fullAllocations,
  isWorkInProgress,
  totalsRevenuePlan,
  colSpan,
  visibleRows,
  visibleColumns,
}) => {
  const { t } = useTranslation();

  const getTotRemainingValues = (field: keyof RevenuePlanDto) => {
    const total =
      totalsRevenuePlan?.map((el) => el[field] as number).reduce((acc, el) => (acc || 0) + (el || 0), 0) ?? 0;
    if (!isWorkInProgress) {
      return formatCurrency(total);
    } else {
      const lavorati = getRevenuePlanDataLavorati()?.reduce((acc, el) => acc + ((el[field] as number) ?? 0), 0);
      return formatCurrency(Number(total) - Number(lavorati) ?? 0);
    }
  };

  const getTotRemainingValuesNumbers = (field: keyof RevenuePlanDto) => {
    const total =
      totalsRevenuePlan?.map((el) => el[field] as number).reduce((acc, el) => (acc || 0) + (el || 0), 0) ?? 0;
    if (!isWorkInProgress) {
      return total;
    } else {
      const lavorati = getRevenuePlanDataLavorati()?.reduce((acc, el) => acc + ((el[field] as number) ?? 0), 0);
      return Number(total) - Number(lavorati) ?? 0;
    }
  };

  const getRevenuePlanDataLavorati = () => {
    return totalsRevenuePlan?.filter((el) => el.consolidato);
  };

  const getMinWidth = () => {
    const totalWidth = 580;
    const columnWidth = 200;
    let visibleWidth = totalWidth;

    if (!visibleColumns.ppsProfile) {
      visibleWidth -= columnWidth;
    }
    if (!visibleColumns.pptSeniority) {
      visibleWidth -= columnWidth;
    }
    return visibleWidth + 'px';
  };

  const getRateRemainingDays = () => {
    const remainingRevenues = Number(getTotRemainingValuesNumbers('actualAmount'));
    const remainingTotlasMd = Number(
      fullAllocations
        ?.map((el) =>
          el.allocazioni
            ?.filter((alloc) => alloc.editEnable)
            ?.flatMap((el) => el.actualAllocation)
            .reduce((acc, el) => (acc || 0) + (el || 0), 0)
        )
        .reduce((acc, el) => (acc || 0) + (el || 0), 0) ?? 0
    );
    if (remainingTotlasMd === 0 && remainingRevenues > 0) {
      return '-';
    }
    return formatCurrency(remainingRevenues / Number(remainingTotlasMd));
  };

  const getRateWorkedDays = () => {
    const workedRevenues = Number(getRevenuePlanDataLavorati()?.reduce((acc, el) => acc + (el.actualAmount ?? 0), 0));
    const workedTotlasMd = Number(
      fullAllocations
        ?.map((el) =>
          el.allocazioni
            ?.filter((alloc) => alloc.editEnable === false)
            ?.flatMap((el) => el.actualAllocation)
            .reduce((acc, el) => (acc || 0) + (el || 0), 0)
        )
        .reduce((acc, el) => (acc || 0) + (el || 0), 0) ?? 0
    );
    if (workedTotlasMd === 0 && workedRevenues > 0) {
      return '-';
    }
    return formatCurrency(workedRevenues / workedTotlasMd);
  };

  const getRateTotPlanDays = () => {
    const totPlanRevenues = Number(
      totalsRevenuePlan?.map((el) => el.actualAmount).reduce((acc, el) => (acc || 0) + (el || 0), 0)
    );
    const totPlanTotlasMd = Number(
      fullAllocations
        ?.map((el) =>
          el.allocazioni?.flatMap((el) => el.actualAllocation).reduce((acc, el) => (acc || 0) + (el || 0), 0)
        )
        .reduce((acc, el) => (acc || 0) + (el || 0), 0) ?? 0
    );
    if (totPlanTotlasMd === 0 && totPlanRevenues > 0) {
      return '-';
    }
    return formatCurrency(totPlanRevenues / totPlanTotlasMd);
  };

  return (
    <>
      {visibleRows[TotalsRevenuesAndGUStickyLabels.totalGu.key] && (
        <tr key={'tot-gu'} id="tot-gu">
          <td colSpan={colSpan} className="text-left align-middle p-0 ps-3" style={{ minWidth: getMinWidth() }}>
            <span>{TotalsRevenuesAndGUStickyLabels.totalGu.label}</span>
          </td>
          {visibleColumns.guRemaining && (
            <td className="text-left p-0" style={{ minWidth: isWorkInProgress ? '190px' : '130px' }}>
              <div className="d-flex justify-content-between">
                <Input
                  readOnly
                  value={formatCurrency(
                    fullAllocations
                      ?.map((el) =>
                        el.allocazioni
                          ?.filter((alloc) => alloc.editEnable)
                          ?.flatMap((el) => el.actualAllocation)
                          .reduce((acc, el) => (acc || 0) + (el || 0), 0)
                      )
                      .reduce((acc, el) => (acc || 0) + (el || 0), 0) ?? 0
                  )}
                />
                <span className="text-xs text-gray-600 align-self-center pe-2">{t('project-detail.ggu')}</span>
              </div>
            </td>
          )}
          {visibleColumns.currentMonthRendicontations && (
            <td className="text-left p-0" style={{ minWidth: '100px' }}>
              <div className="d-flex justify-content-between">
                <Input
                  readOnly
                  value={formatCurrency(
                    fullAllocations
                      ?.map((el) =>
                        el.allocazioni
                          ?.filter(
                            (alloc) =>
                              alloc.month === new Date().getMonth() + 1 && alloc.year === new Date().getFullYear()
                          )
                          ?.flatMap((el) => el.realTimeAllocation)
                          .reduce((acc, el) => (acc || 0) + (el || 0), 0)
                      )
                      .reduce((acc, el) => (acc || 0) + (el || 0), 0) ?? 0
                  )}
                />
              </div>
            </td>
          )}
          {isWorkInProgress && (
            <>
              {visibleColumns.worked && (
                <td className="text-left p-0 bg-disabled" style={{ minWidth: '100px' }}>
                  <Input
                    readOnly
                    className="bg-disabled"
                    value={formatCurrency(
                      fullAllocations
                        ?.map((el) =>
                          el.allocazioni
                            ?.filter((alloc) => alloc.editEnable === false)
                            ?.flatMap((el) => el.actualAllocation)
                            .reduce((acc, el) => (acc || 0) + (el || 0), 0)
                        )
                        .reduce((acc, el) => (acc || 0) + (el || 0), 0) ?? 0
                    )}
                  />
                </td>
              )}
              {visibleColumns.totalPlanned && (
                <td className="text-left p-0 bg-disabled" style={{ minWidth: '100px' }}>
                  <Input
                    readOnly
                    className="bg-disabled"
                    value={formatCurrency(
                      fullAllocations
                        ?.map((el) =>
                          el.allocazioni
                            ?.flatMap((el) => el.actualAllocation)
                            .reduce((acc, el) => (acc || 0) + (el || 0), 0)
                        )
                        .reduce((acc, el) => (acc || 0) + (el || 0), 0) ?? 0
                    )}
                  />
                </td>
              )}
            </>
          )}
        </tr>
      )}
      {visibleRows[TotalsRevenuesAndGUStickyLabels.fte.key] && (
        <tr key={'fte'} id="fte">
          <td colSpan={isWorkInProgress ? 7 : 5} className="text-left align-middle p-0 ps-3">
            <span>{TotalsRevenuesAndGUStickyLabels.fte.label}</span>
          </td>
        </tr>
      )}
      {visibleRows[TotalsRevenuesAndGUStickyLabels.personnelCosts.key] && (
        <tr key={'costi-personale'} id="costi-personale">
          <td colSpan={colSpan} className="text-left align-middle p-0 ps-3">
            <span>{TotalsRevenuesAndGUStickyLabels.personnelCosts.label}</span>
          </td>
          {visibleColumns.guRemaining && (
            <td className="text-left p-0">
              <div
                className="d-flex justify-content-between"
                style={{ minWidth: isWorkInProgress ? '190px' : '130px' }}
              >
                <Input readOnly value={getTotRemainingValues('actualHrCost')} />
                <span className="text-xs text-gray-600 align-self-center pe-2">€</span>
              </div>
            </td>
          )}
          {visibleColumns.currentMonthRendicontations && (
            <td className="text-left p-0">
              <div className="d-flex justify-content-between" style={{ minWidth: '100px' }}>
                <Input readOnly value={'-'} />
              </div>
            </td>
          )}
          {isWorkInProgress && (
            <>
              {visibleColumns.worked && (
                <td className="text-left p-0 bg-disabled" style={{ minWidth: '100px' }}>
                  <InputNumber
                    readOnly
                    className="bg-disabled"
                    variant="borderless"
                    value={formatCurrency(
                      Number(getRevenuePlanDataLavorati()?.reduce((acc, el) => acc + (el.actualHrCost ?? 0), 0)) ?? 0
                    )}
                  />
                </td>
              )}
              {visibleColumns.totalPlanned && (
                <td className="text-left p-0 bg-disabled" style={{ minWidth: '100px' }}>
                  <InputNumber
                    readOnly
                    className="bg-disabled"
                    variant="borderless"
                    value={
                      formatCurrency(
                        totalsRevenuePlan?.map((el) => el.actualHrCost).reduce((acc, el) => (acc || 0) + (el || 0), 0)
                      ) ?? 0
                    }
                  />
                </td>
              )}
            </>
          )}
        </tr>
      )}
      {visibleRows[TotalsRevenuesAndGUStickyLabels.externalCosts.key] && (
        <tr key={'costi-esterni'} id="costi-esterni">
          <td colSpan={colSpan} className="text-left align-middle p-0 ps-3">
            <span>{TotalsRevenuesAndGUStickyLabels.externalCosts.label}</span>
          </td>
          {visibleColumns.guRemaining && (
            <td className="text-left p-0" style={{ minWidth: isWorkInProgress ? '190px' : '130px' }}>
              <div className="d-flex justify-content-between">
                <Input readOnly value={getTotRemainingValues('actualExternalCost')} />
                <span className="text-xs text-gray-600 align-self-center pe-2">€</span>
              </div>
            </td>
          )}
          {visibleColumns.currentMonthRendicontations && (
            <td className="text-left p-0">
              <div className="d-flex justify-content-between" style={{ minWidth: '100px' }}>
                <Input readOnly value={'-'} />
              </div>
            </td>
          )}
          {isWorkInProgress && (
            <>
              {visibleColumns.worked && (
                <td className="text-left p-0 bg-disabled" style={{ minWidth: '100px' }}>
                  <Input
                    readOnly
                    className="bg-disabled"
                    value={formatCurrency(
                      Number(
                        getRevenuePlanDataLavorati()?.reduce((acc, el) => acc + (el.actualExternalCost ?? 0), 0)
                      ) ?? 0
                    )}
                  />
                </td>
              )}
              {visibleColumns.totalPlanned && (
                <td className="text-left p-0 bg-disabled" style={{ minWidth: '100px' }}>
                  <Input
                    readOnly
                    className="bg-disabled"
                    value={
                      formatCurrency(
                        totalsRevenuePlan
                          ?.map((el) => el.actualExternalCost)
                          .reduce((acc, el) => (acc || 0) + (el || 0), 0)
                      ) ?? 0
                    }
                  />
                </td>
              )}
            </>
          )}
        </tr>
      )}
      {visibleRows[TotalsRevenuesAndGUStickyLabels.totalCosts.key] && (
        <tr key={'costi-totali'} id="costi-totali">
          <td colSpan={colSpan} className="text-left align-middle p-0 ps-3">
            <span>{TotalsRevenuesAndGUStickyLabels.totalCosts.label}</span>
          </td>
          {visibleColumns.guRemaining && (
            <td className="text-left p-0" style={{ minWidth: isWorkInProgress ? '190px' : '130px' }}>
              <div className="d-flex justify-content-between">
                <Input readOnly value={getTotRemainingValues('actualCost')} />
                <span className="text-xs text-gray-600 align-self-center pe-2">€</span>
              </div>
            </td>
          )}
          {visibleColumns.currentMonthRendicontations && (
            <td className="text-left p-0">
              <div className="d-flex justify-content-between" style={{ minWidth: '100px' }}>
                <Input readOnly value={'-'} />
              </div>
            </td>
          )}
          {isWorkInProgress && (
            <>
              {visibleColumns.worked && (
                <td className="text-left p-0 bg-disabled" style={{ minWidth: '100px' }}>
                  <Input
                    readOnly
                    className="bg-disabled"
                    value={formatCurrency(
                      Number(getRevenuePlanDataLavorati()?.reduce((acc, el) => acc + (el.actualCost ?? 0), 0)) ?? 0
                    )}
                  />
                </td>
              )}
              {visibleColumns.totalPlanned && (
                <td className="text-left p-0 bg-disabled" style={{ minWidth: '100px' }}>
                  <Input
                    readOnly
                    className="bg-disabled"
                    value={
                      formatCurrency(
                        totalsRevenuePlan?.map((el) => el.actualCost).reduce((acc, el) => (acc || 0) + (el || 0), 0)
                      ) ?? 0
                    }
                  />
                </td>
              )}
            </>
          )}
        </tr>
      )}

      {visibleRows[TotalsRevenuesAndGUStickyLabels.rate.key] && (
        <tr key={'rate'} id="rate">
          <td colSpan={colSpan} className="text-left align-middle p-0 ps-3">
            <span>{TotalsRevenuesAndGUStickyLabels.rate.label}</span>
          </td>
          {visibleColumns.guRemaining && (
            <td className="text-left p-0" style={{ minWidth: isWorkInProgress ? '190px' : '130px' }}>
              <div className="d-flex justify-content-between">
                <Input readOnly value={getRateRemainingDays()} />
                <span className="text-xs text-gray-600 align-self-center pe-2">€</span>
              </div>
            </td>
          )}
          {visibleColumns.currentMonthRendicontations && (
            <td className="text-left p-0">
              <div className="d-flex justify-content-between" style={{ minWidth: '100px' }}>
                <Input readOnly value={'-'} />
              </div>
            </td>
          )}
          {isWorkInProgress && (
            <>
              {visibleColumns.worked && (
                <td className="text-left p-0 bg-disabled" style={{ minWidth: '100px' }}>
                  <Input readOnly className="bg-disabled" value={getRateWorkedDays()} />
                </td>
              )}
              {visibleColumns.totalPlanned && (
                <td className="text-left p-0 bg-disabled" style={{ minWidth: '100px' }}>
                  <Input readOnly className="bg-disabled" value={getRateTotPlanDays()} />
                </td>
              )}
            </>
          )}
        </tr>
      )}
      {visibleRows[TotalsRevenuesAndGUStickyLabels.revenues.key] && (
        <tr key={'ricavi'} id="ricavi">
          <td colSpan={colSpan} className="text-left align-middle p-0 ps-3">
            <span>{TotalsRevenuesAndGUStickyLabels.revenues.label}</span>
          </td>
          {visibleColumns.guRemaining && (
            <td className="text-left p-0" style={{ minWidth: isWorkInProgress ? '190px' : '130px' }}>
              <div className="d-flex justify-content-between">
                <Input readOnly value={getTotRemainingValues('actualAmount')} />
                <span className="text-xs text-gray-600 align-self-center pe-2">€</span>
              </div>
            </td>
          )}
          {visibleColumns.currentMonthRendicontations && (
            <td className="text-left p-0">
              <div className="d-flex justify-content-between" style={{ minWidth: '100px' }}>
                <Input readOnly value={'-'} />
              </div>
            </td>
          )}
          {isWorkInProgress && (
            <>
              {visibleColumns.worked && (
                <td className="text-left p-0 bg-disabled" style={{ minWidth: '100px' }}>
                  <Input
                    readOnly
                    className="bg-disabled"
                    value={formatCurrency(
                      Number(getRevenuePlanDataLavorati()?.reduce((acc, el) => acc + (el.actualAmount ?? 0), 0)) ?? 0
                    )}
                  />
                </td>
              )}
              {visibleColumns.totalPlanned && (
                <td className="text-left p-0 bg-disabled" style={{ minWidth: '100px' }}>
                  <Input
                    readOnly
                    className="bg-disabled"
                    value={
                      formatCurrency(
                        totalsRevenuePlan?.map((el) => el.actualAmount).reduce((acc, el) => (acc || 0) + (el || 0), 0)
                      ) ?? 0
                    }
                  />
                </td>
              )}
            </>
          )}
        </tr>
      )}
    </>
  );
};

export default TotalsRevenuesAndGUSticky;
