import './styles.scss';

const AppHeader: React.FC = () => {
  // const unreadCount = useSelector((state: RootState) => state.notifications.unreadCount);
  return (
    <nav className=" mb-3 static-top ">
      {/* <img alt="corporate" className="corporate-img ms-3" src={require('../../assets/svg/corporate.png')} />

			<ul className="navbar-nav ms-auto me-3 align-items-center">
				<i className="cursor-pointer text-blue-links fa-solid fa-arrow-up-right-from-square me-3"></i>
				<NotificationBell>
					{unreadCount}
				</NotificationBell>
				<DropdownUser />
			</ul> */}
    </nav>
  );
};
export default AppHeader;
