import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../core/store/hooks';
import { costiSelector } from '../../pages/private/project-list/project-detail/_redux/selectors';
import { useEffect, useState } from 'react';
import {
  CostTypeDto,
  ExternalCostDto,
  MeseAnnoLight,
  OtherCostListDto,
  ProjectPastDataDatail,
  RevenuePlanDto,
} from '@api/client';
import { getCosts } from '../../pages/private/project-list/project-detail/_redux/actions';
import { AppToastService } from '../design-system/components/app-toast/app-toast.service';

const useHandleOtherCosts = (otherCostsFromService: OtherCostListDto | ProjectPastDataDatail | undefined) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const costList = useAppSelector(costiSelector);
  const [otherCosts, setOtherCosts] = useState<ExternalCostDto[]>([]);
  const [revenuePlan, setRevenuePlan] = useState<RevenuePlanDto[] | null | undefined>([]);
  const [monthWithCostsOrPlan, setMonthWithCostsOrPlan] = useState<MeseAnnoLight[]>([]);

  useEffect(() => {
    if (!costList) dispatch(getCosts());
  }, []);

  useEffect(() => {
    setTableData(otherCostsFromService);
  }, [otherCostsFromService]);

  const setTableData = (data: OtherCostListDto | ProjectPastDataDatail | undefined) => {
    let negativeId = -Math.floor(Math.random() * 100000);
    if (data) {
      setOtherCosts(
        data.otherCosts?.map((el) => ({
          ...el,
          groupId: el.groupId,
          costAllocation: el.costAllocation
            ?.map((cost) => ({
              ...cost,
              id: !cost.id ? negativeId-- : cost.id,
            }))
            .sort((a, b) => {
              if (a.anno && b.anno && a.anno !== b.anno) {
                return a.anno - b.anno;
              }
              return (a.mese ?? 0) - (b.mese ?? 0);
            }),
        })) || []
      );
      if ('revenuePlan' in data) {
        setRevenuePlan(
          data.revenuePlan?.sort((a, b) => {
            if (a.year && b.year && a.year !== b.year) {
              return a.year - b.year;
            }
            return (a.month ?? 0) - (b.month ?? 0);
          })
        );
      }
      if ('monthWithCostsOrPlan' in data) {
        setMonthWithCostsOrPlan(data.monthWithCostsOrPlan || []);
        // merge of costAllocation with month in monthWithCostsOrPlan
        const newOtherCosts = data.otherCosts?.map((el) => ({
          ...el,
          costAllocation: data.monthWithCostsOrPlan
            ?.map((month) => {
              const cost = el.costAllocation?.find((cost) => cost.mese === month.mese && cost.anno === month.anno);
              return (
                cost || {
                  mese: month.mese,
                  anno: month.anno,
                  actualCost: 0,
                  editEnabled: !month.consolidato,
                  id: negativeId--,
                }
              );
            })
            .sort((a, b) => {
              if (a.anno && b.anno && a.anno !== b.anno) {
                return a.anno - b.anno;
              }
              return (a.mese ?? 0) - (b.mese ?? 0);
            }),
        }));
        setOtherCosts(newOtherCosts || []);
      }
    } else {
      setOtherCosts([]);
      setRevenuePlan([]);
    }
  };

  const getFilteredCostList = () => {
    const selectedIds = otherCosts?.map((el) => el.costType?.id);
    return costList?.filter((el) => !selectedIds?.includes(el.id as number));
  };

  const getCostTypeDescriptions = (id: number | undefined) => {
    return t(`project-detail.cost-type-id-${id}`);
  };

  const getValue = (cost: ExternalCostDto) => {
    return cost.costType?.value
      ? {
          value: cost.costType?.value,
          label: t(`project-detail.cost-type-id-${costList?.find((el) => el.id == cost.costType?.id)?.id}`),
        }
      : null;
  };

  const handleChangeCost = (e: any, index: number) => {
    const targetCost = costList?.find((el) => el.id === e);
    if (isPermittedChange(targetCost)) {
      const costTypeCountMap = new Map<number | undefined, number>();
      const newAllocations = otherCosts?.map((el, i) => {
        let groupId;
        if (i === index) {
          groupId = (costTypeCountMap.get(targetCost?.id) || 0) + 1;
          costTypeCountMap.set(targetCost?.id, groupId);
        } else {
          const currentCostType = el.costType?.id;
          groupId = (costTypeCountMap.get(currentCostType) || 0) + 1;
          costTypeCountMap.set(currentCostType, groupId);
        }

        return {
          ...el,
          groupId: groupId,
          costType: i === index ? targetCost : el.costType,
        };
      });

      setOtherCosts(newAllocations);
    } else {
      AppToastService.error(t('project-detail.non-duplicable-cost'));
    }
  };

  const isPermittedChange = (targetCost: CostTypeDto | undefined) => {
    if (targetCost?.duplicable) {
      return true;
    }
    return !otherCosts?.some((el) => el.costType?.id === targetCost?.id);
  };

  const handleChangeDescription = (value: string | null, index: number) => {
    const newAllocations = otherCosts?.map((el, i) => ({
      ...el,
      description: i === index ? value ?? '' : el.description,
    }));
    setOtherCosts(newAllocations);
  };

  const addCost = () => {
    let currentId = -Math.floor(Math.random() * 100000);
    const newAllocations = [
      ...(otherCosts ?? []),
      {
        costType: {
          id: currentId,
          description: undefined,
          value: undefined,
        },
        groupId: 1,
        description: undefined,
        costAllocation: createAllocationsForNewCost(currentId),
      },
    ];
    setOtherCosts(newAllocations);
  };

  const createAllocationsForNewCost = (currentId: number) => {
    if (otherCosts?.length) {
      return otherCosts?.[0]?.costAllocation?.map((el) => ({
        id: currentId--,
        mese: el.mese,
        anno: el.anno,
        actualCost: 0,
        editEnabled: el.editEnabled,
      }));
    } else {
      if (monthWithCostsOrPlan?.length) {
        return monthWithCostsOrPlan
          .map((el) => ({
            id: currentId--,
            mese: el.mese,
            anno: el.anno,
            actualCost: 0,
            editEnabled: !el.consolidato,
          }))
          .sort((a, b) => {
            if (a.anno && b.anno && a.anno !== b.anno) {
              return a.anno - b.anno;
            }
            return (a.mese ?? 0) - (b.mese ?? 0);
          });
      }
      if (revenuePlan?.length) {
        return revenuePlan?.map((el) => ({
          id: currentId--,
          mese: el.month,
          anno: el.year,
          actualCost: 0,
          editEnabled: true,
        }));
      }
    }
  };

  return {
    otherCosts,
    setOtherCosts,
    revenuePlan,
    setRevenuePlan,
    costList,
    getCostTypeDescriptions,
    getValue,
    handleChangeCost,
    handleChangeDescription,
    addCost,
  };
};

export default useHandleOtherCosts;
