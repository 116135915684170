/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const DashboardProjectFilterInputDtoProjectStatusEnum = {
    Pending: 'PENDING',
    Planning: 'PLANNING',
    ClosedLost: 'CLOSED_LOST',
    IntranetPending: 'INTRANET_PENDING',
    WorkInProgress: 'WORK_IN_PROGRESS',
    Completed: 'COMPLETED',
    ClosedWonPlanning: 'CLOSED_WON_PLANNING',
    PlanningOk: 'PLANNING_OK',
    ControlloCdg: 'CONTROLLO_CDG',
    ControlloCl: 'CONTROLLO_CL',
    ReportingClosed: 'REPORTING_CLOSED',
    EconomicsClosed: 'ECONOMICS_CLOSED'
};
/**
 * Check if a given object implements the DashboardProjectFilterInputDto interface.
 */
export function instanceOfDashboardProjectFilterInputDto(value) {
    return true;
}
export function DashboardProjectFilterInputDtoFromJSON(json) {
    return DashboardProjectFilterInputDtoFromJSONTyped(json, false);
}
export function DashboardProjectFilterInputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'projectStatus': json['projectStatus'] == null ? undefined : json['projectStatus'],
        'page': json['page'] == null ? undefined : json['page'],
        'size': json['size'] == null ? undefined : json['size'],
        'id': json['id'] == null ? undefined : json['id'],
        'nome': json['nome'] == null ? undefined : json['nome'],
        'idStrutturaBusiness': json['idStrutturaBusiness'] == null ? undefined : json['idStrutturaBusiness'],
        'idStrutturaDelivery': json['idStrutturaDelivery'] == null ? undefined : json['idStrutturaDelivery'],
        'idCliente': json['idCliente'] == null ? undefined : json['idCliente'],
        'plUserId': json['plUserId'] == null ? undefined : json['plUserId'],
    };
}
export function DashboardProjectFilterInputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'projectStatus': value['projectStatus'],
        'page': value['page'],
        'size': value['size'],
        'id': value['id'],
        'nome': value['nome'],
        'idStrutturaBusiness': value['idStrutturaBusiness'],
        'idStrutturaDelivery': value['idStrutturaDelivery'],
        'idCliente': value['idCliente'],
        'plUserId': value['plUserId'],
    };
}
