import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../core/store/hooks';
import { cdgProjectsSelector, clProjectsSelector, plProjectsSelector } from '../_redux/selectors';
import { ProjectMonthDto } from '@api/client';
import { formatCurrency, getMonths } from '../../../../shared/utils/common.utils';
import { Session } from '../../../../enums/project.enum';
import useGetSessionActions from './useGetSessionActions';

export interface ProjectCdgData {
  id: FieldProps;
  intranetId: FieldProps;
  name: FieldProps;
  clientLead: FieldProps;
  projectLead: FieldProps;
  automaticRevenue: FieldProps;
  actualRevenue: FieldProps;
  actualOtherCosts: FieldProps;
}

interface FieldProps {
  label: string;
  value: string | number | undefined | null;
  type: 'text' | 'number';
  visible: boolean;
  isEditable?: boolean;
  uniqueId?: string | number;
  minWidth?: string;
  className?: string;
  tooltip?: string;
  month?: string;
  maxRevenue?: number;
}

export interface RevenueAndCostsData {
  id: FieldProps;
  previousYearRevenue: FieldProps;
  previousYearCosts: FieldProps;
  januaryCurrentYear: FieldProps;
  januaryCosts: FieldProps;
  februaryCurrentYear: FieldProps;
  februaryCosts: FieldProps;
  marchCurrentYear: FieldProps;
  marchCosts: FieldProps;
  aprilCurrentYear: FieldProps;
  aprilCosts: FieldProps;
  mayCurrentYear: FieldProps;
  mayCosts: FieldProps;
  juneCurrentYear: FieldProps;
  juneCosts: FieldProps;
  julyCurrentYear: FieldProps;
  julyCosts: FieldProps;
  augustCurrentYear: FieldProps;
  augustCosts: FieldProps;
  septemberCurrentYear: FieldProps;
  septemberCosts: FieldProps;
  octoberCurrentYear: FieldProps;
  octoberCosts: FieldProps;
  novemberCurrentYear: FieldProps;
  novemberCosts: FieldProps;
  automaticRevenue: FieldProps;
  actualRevenue: FieldProps;
  actualOtherCosts: FieldProps;
  actualHrCosts: FieldProps;
}

const useGetAndMapManagementToolProjects = (session: Session) => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState<Partial<ProjectCdgData>[]>([]);
  const [revenueAndCostsData, setRevenueAndCostsData] = useState<any[]>([]);
  const currentYear = new Date().getFullYear();

  const { projectsSelector } = useGetSessionActions(session);

  const originalsMTProjects = useAppSelector(projectsSelector());

  useEffect(() => {
    setProjects(mapProjects(originalsMTProjects));
    setRevenueAndCostsData(mapRevenueAndCostsData(originalsMTProjects));
    console.log('originalsMTProjects', originalsMTProjects);
  }, [originalsMTProjects]);

  const mapProjects = (projects: Partial<ProjectMonthDto>[]): Partial<ProjectCdgData>[] => {
    return projects.map((project) => {
      return {
        id: { label: t('cdg.table-cdg.id'), value: project.id, type: 'text', visible: false, minWidth: '80px' },
        intranetId: {
          label: t('cdg.table-cdg.id'),
          value: project.intranetId,
          type: 'text',
          visible: true,
          uniqueId: project.id,
          minWidth: '70px',
        },
        name: {
          label: t('cdg.table-cdg.project-name'),
          value: project.name || '-',
          type: 'text',
          visible: true,
          minWidth: session === Session.CL || session === Session.PL ? '400px' : '470px',
        },
        clientLead: {
          label: t('cdg.table-cdg.client-lead'),
          value: project.clientLead || '-',
          type: 'text',
          visible: true,
          minWidth: session === Session.CL || session === Session.PL ? '160px' : '210px',
        },
        projectLead: {
          label: t('cdg.table-cdg.project-lead'),
          value: project.projectLead || '-',
          type: 'text',
          visible: true,
          minWidth: session === Session.CL || session === Session.PL ? '160px' : '210px',
        },
      };
    });
  };

  const mapRevenueAndCostsData = (projects: Partial<ProjectMonthDto>[]): Partial<RevenueAndCostsData>[] => {
    return projects.map((project) => {
      return {
        id: { label: t('cdg.table-cdg.Id'), value: project.id, type: 'text', visible: false, minWidth: '80px' },
        previousYearRevenue: {
          month: `Prev. years`,
          label: `${t('cdg.table-cdg.revenues')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.year === currentYear - 1)?.actualRevenue) || '-',
          type: 'text',
          visible: true,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        previousYearCosts: {
          month: `Prev. years`,
          label: `${t('cdg.table-cdg.costs')}`,
          value:
            formatCurrency(project.pregresso?.find((item) => item.year === currentYear - 1)?.actualOtherCosts) || '-',
          type: 'text',
          visible: true,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        januaryCurrentYear: {
          month: `${getMonths()[0].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.revenues')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 1)?.actualRevenue) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 1,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        januaryCosts: {
          month: `${getMonths()[0].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.costs')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 1)?.actualOtherCosts) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 1,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        februaryCurrentYear: {
          month: `${getMonths()[1].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.revenues')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 2)?.actualRevenue) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 2,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        februaryCosts: {
          month: `${getMonths()[1].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.costs')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 2)?.actualOtherCosts) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 2,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        marchCurrentYear: {
          month: `${getMonths()[2].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.revenues')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 3)?.actualRevenue) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 3,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        marchCosts: {
          month: `${getMonths()[2].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.costs')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 3)?.actualOtherCosts) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 3,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        aprilCurrentYear: {
          month: `${getMonths()[3].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.revenues')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 4)?.actualRevenue) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 4,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        aprilCosts: {
          month: `${getMonths()[3].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.costs')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 4)?.actualOtherCosts) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 4,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        mayCurrentYear: {
          month: `${getMonths()[4].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.revenues')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 5)?.actualRevenue) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 5,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        mayCosts: {
          month: `${getMonths()[4].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.costs')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 5)?.actualOtherCosts) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 5,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        juneCurrentYear: {
          month: `${getMonths()[5].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.revenues')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 6)?.actualRevenue) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 6,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        juneCosts: {
          month: `${getMonths()[5].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.costs')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 6)?.actualOtherCosts) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 6,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        julyCurrentYear: {
          month: `${getMonths()[6].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.revenues')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 7)?.actualRevenue) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 7,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        julyCosts: {
          month: `${getMonths()[6].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.costs')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 7)?.actualOtherCosts) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 7,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        augustCurrentYear: {
          month: `${getMonths()[7].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.revenues')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 8)?.actualRevenue) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 8,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        augustCosts: {
          month: `${getMonths()[7].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.costs')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 8)?.actualOtherCosts) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 8,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        septemberCurrentYear: {
          month: `${getMonths()[8].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.revenues')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 9)?.actualRevenue) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 9,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        septemberCosts: {
          month: `${getMonths()[8].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.costs')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 9)?.actualOtherCosts) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 9,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        octoberCurrentYear: {
          month: `${getMonths()[9].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.revenues')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 10)?.actualRevenue) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 10,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        octoberCosts: {
          month: `${getMonths()[9].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.costs')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 10)?.actualOtherCosts) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 10,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        novemberCurrentYear: {
          month: `${getMonths()[10].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.revenues')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 11)?.actualRevenue) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 11,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        novemberCosts: {
          month: `${getMonths()[10].name} ${currentYear}`,
          label: `${t('cdg.table-cdg.costs')}`,
          value: formatCurrency(project.pregresso?.find((item) => item.month === 11)?.actualOtherCosts) || '-',
          type: 'text',
          visible: (project.month ?? new Date().getMonth() + 1) > 11,
          uniqueId: project.id,
          minWidth: '150px',
          className: 'bg-disabled',
        },
        totalActualRevenue: {
          month: `${t('cdg.table-cdg.totals')}`,
          label: `${t('cdg.table-cdg.total-a-revevues-total-bid-amount')}`,
          value: `${formatCurrency(project.totalActualRevenue)}/${formatCurrency(
            project.totalBidAmount
          )} (${formatCurrency(((project.totalActualRevenue ?? 0) / (project.totalBidAmount ?? 0)) * 100)}%)`,
          type: 'text',
          visible: true,
          uniqueId: project.id,
          minWidth: '280px',
        },
        clRevenue: {
          month: `${
            project.month ? getMonths()[project.month - 1].name : getMonths()[new Date().getMonth()].name
          } ${currentYear}`,
          label: `${t('cdg.table-cdg.cl-revenues')}`,
          value: formatCurrency(project.clRevenue),
          type: 'text',
          visible: session === Session.CL,
          uniqueId: project.id,
          minWidth: '140px',
        },
        plRevenue: {
          month: `${
            project.month ? getMonths()[project.month - 1].name : getMonths()[new Date().getMonth()].name
          } ${currentYear}`,
          label: `${t('cdg.table-cdg.pl-revenues')}`,
          value: project.plRevenue ? formatCurrency(project.plRevenue) : '-',
          type: 'text',
          visible: session === Session.CL,
          uniqueId: project.id,
          minWidth: '170px',
          tooltip: `${t('cdg.table-cdg.revenues-tooltip')}`,
        },
        actualRevenue: {
          month: `${
            project.month ? getMonths()[project.month - 1].name : getMonths()[new Date().getMonth()].name
          } ${currentYear}`,
          label: session !== Session.PL ? `${t('cdg.table-cdg.revenues')}` : `${t('cdg.table-cdg.pl-revenues')}`,
          value:
            session !== Session.PL
              ? Number(project.actualRevenue?.toFixed(2) ?? 0)
              : project.actualRevenue
              ? Number(project.actualRevenue?.toFixed(2))
              : null,
          type: 'number',
          visible: true,
          isEditable: true,
          uniqueId: project.id,
          minWidth: '145px',
          // tooltip: `${t('cdg.table-cdg.revenues-tooltip')}`,
          maxRevenue: Math.max((project.totalBidAmount || 0) - (project.totalActualRevenue || 0), 0),
        },
        actualOtherCosts: {
          month: `${
            project.month ? getMonths()[project.month - 1].name : getMonths()[new Date().getMonth()].name
          } ${currentYear}`,
          label: `${t('cdg.table-cdg.costs')}`,
          value: formatCurrency(project.actualOtherCosts),
          type: 'text',
          visible: true,
          isEditable: false,
          uniqueId: project.id,
          minWidth: '140px',
        },
        actualHrCosts: {
          month: `${
            project.month ? getMonths()[project.month - 1].name : getMonths()[new Date().getMonth()].name
          } ${currentYear}`,
          label: `${t('cdg.table-cdg.hr-costs')}`,
          value: formatCurrency(project.actualHRCosts),
          type: 'text',
          visible: true,
          isEditable: false,
          uniqueId: project.id,
          minWidth: '130px',
          className: 'bg-disabled',
        },
        actions: {
          month: `${
            project.month ? getMonths()[project.month - 1].name : getMonths()[new Date().getMonth()].name
          } ${currentYear}`,
          label: '',
          value: '',
          type: 'text',
          visible: true,
          uniqueId: project.id,
          minWidth: '30px',
        },
      };
    });
  };

  return { projects, revenueAndCostsData, setRevenueAndCostsData, originalsMTProjects };
};

export default useGetAndMapManagementToolProjects;
