/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the LongTermActivityDto interface.
 */
export function instanceOfLongTermActivityDto(value) {
    return true;
}
export function LongTermActivityDtoFromJSON(json) {
    return LongTermActivityDtoFromJSONTyped(json, false);
}
export function LongTermActivityDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'onGoingActivity': json['onGoingActivity'] == null ? undefined : json['onGoingActivity'],
        'lastActivityReport': json['lastActivityReport'] == null ? undefined : json['lastActivityReport'],
    };
}
export function LongTermActivityDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'onGoingActivity': value['onGoingActivity'],
        'lastActivityReport': value['lastActivityReport'],
    };
}
