/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StrutturaFunzioneOutputDtoFromJSON, StrutturaFunzioneOutputDtoToJSON, } from './StrutturaFunzioneOutputDto';
import { StrutturaUtenteFunzioneRuoloOutputDtoFromJSON, StrutturaUtenteFunzioneRuoloOutputDtoToJSON, } from './StrutturaUtenteFunzioneRuoloOutputDto';
/**
 * Check if a given object implements the AggregatedStrutturaInfoDto interface.
 */
export function instanceOfAggregatedStrutturaInfoDto(value) {
    return true;
}
export function AggregatedStrutturaInfoDtoFromJSON(json) {
    return AggregatedStrutturaInfoDtoFromJSONTyped(json, false);
}
export function AggregatedStrutturaInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'strutturaUtenteFunzioneRuoloOutputDto': json['strutturaUtenteFunzioneRuoloOutputDto'] == null ? undefined : StrutturaUtenteFunzioneRuoloOutputDtoFromJSON(json['strutturaUtenteFunzioneRuoloOutputDto']),
        'strutturaFunzioneOutputDto': json['strutturaFunzioneOutputDto'] == null ? undefined : StrutturaFunzioneOutputDtoFromJSON(json['strutturaFunzioneOutputDto']),
    };
}
export function AggregatedStrutturaInfoDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'strutturaUtenteFunzioneRuoloOutputDto': StrutturaUtenteFunzioneRuoloOutputDtoToJSON(value['strutturaUtenteFunzioneRuoloOutputDto']),
        'strutturaFunzioneOutputDto': StrutturaFunzioneOutputDtoToJSON(value['strutturaFunzioneOutputDto']),
    };
}
