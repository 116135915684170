import React, { ReactNode } from 'react';
import './styles.scss';
import { StatusBadgeData } from '../../../../enums/status';
import BadgeSize from './enum-size';

interface Props {
  label?: boolean;
  className?: string;
  children: ReactNode;
  size?: BadgeSize;
  pill?: boolean;
}

const StatusBadge = ({ label, className, children, size = BadgeSize.MEDIUM, pill = false }: Props) => {
  const item = StatusBadgeData.find((item) => item.name === children);
  if (!item) return null;

  const badgeClassNames = `badge style-sm ${pill ? 'rounded-pill style-pill' : ''} ${className}`;

  return (
    <span className={`${badgeClassNames} d-inline-flex align-items-center my-auto`} style={{ color: item.color, backgroundColor: item.backgroundColor }}>
      <span className={`icon icon-${item.icon}`} style={{ fontSize: size }}></span>
      <i className={`fa text-dark ${item.icon} ${label ? 'me-1' : ''}`} style={{ fontSize: size }}></i>
      <span >{label && item.label}</span>
    </span>
  );
};

export default StatusBadge;
