/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CostTypeFromJSON, CostTypeToJSON, } from './CostType';
import { QuoteFromJSON, QuoteToJSON, } from './Quote';
/**
 * Check if a given object implements the ExternalCost interface.
 */
export function instanceOfExternalCost(value) {
    return true;
}
export function ExternalCostFromJSON(json) {
    return ExternalCostFromJSONTyped(json, false);
}
export function ExternalCostFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'idQuote': json['idQuote'] == null ? undefined : json['idQuote'],
        'idCostType': json['idCostType'] == null ? undefined : json['idCostType'],
        'description': json['description'] == null ? undefined : json['description'],
        'mese': json['mese'] == null ? undefined : json['mese'],
        'anno': json['anno'] == null ? undefined : json['anno'],
        'lastModifiedDate': json['lastModifiedDate'] == null ? undefined : (new Date(json['lastModifiedDate'])),
        'creationDate': json['creationDate'] == null ? undefined : (new Date(json['creationDate'])),
        'midStore': json['midStore'] == null ? undefined : json['midStore'],
        'defStore': json['defStore'] == null ? undefined : json['defStore'],
        'extimatedCost': json['extimatedCost'] == null ? undefined : json['extimatedCost'],
        'actualCost': json['actualCost'] == null ? undefined : json['actualCost'],
        'groupId': json['groupId'] == null ? undefined : json['groupId'],
        'costType': json['costType'] == null ? undefined : CostTypeFromJSON(json['costType']),
        'quote': json['quote'] == null ? undefined : QuoteFromJSON(json['quote']),
    };
}
export function ExternalCostToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'idQuote': value['idQuote'],
        'idCostType': value['idCostType'],
        'description': value['description'],
        'mese': value['mese'],
        'anno': value['anno'],
        'lastModifiedDate': value['lastModifiedDate'] == null ? undefined : ((value['lastModifiedDate']).toISOString()),
        'creationDate': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'midStore': value['midStore'],
        'defStore': value['defStore'],
        'extimatedCost': value['extimatedCost'],
        'actualCost': value['actualCost'],
        'groupId': value['groupId'],
        'costType': CostTypeToJSON(value['costType']),
        'quote': QuoteToJSON(value['quote']),
    };
}
