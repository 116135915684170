/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const SignInRequestDtoLangEnum = {
    It: 'IT',
    En: 'EN'
};
/**
 * Check if a given object implements the SignInRequestDto interface.
 */
export function instanceOfSignInRequestDto(value) {
    if (!('username' in value))
        return false;
    if (!('password' in value))
        return false;
    return true;
}
export function SignInRequestDtoFromJSON(json) {
    return SignInRequestDtoFromJSONTyped(json, false);
}
export function SignInRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'username': json['username'],
        'password': json['password'],
        'lang': json['lang'] == null ? undefined : json['lang'],
    };
}
export function SignInRequestDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'username': value['username'],
        'password': value['password'],
        'lang': value['lang'],
    };
}
