/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { FilterProjectMonthDtoToJSON, FilterProjectMonthValuesDtoFromJSON, OtherCostListDtoFromJSON, OtherCostListDtoToJSON, ProjectMonthListDtoFromJSON, ProjectMonthListDtoToJSON, } from '../models/index';
/**
 *
 */
export class ControllerPerLeFunzioniDedicateAlControlloDelResponsabileDiContrattoApi extends runtime.BaseAPI {
    /**
     * Consente al CL o SUPER ADMIN di modificare i valori di ricavo e costi anche per mesi consolidati
     * Modifica i valori per i progetti passati in input
     */
    clProjectMonthEditRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectMonthListDto'] == null) {
                throw new runtime.RequiredError('projectMonthListDto', 'Required parameter "projectMonthListDto" was null or undefined when calling clProjectMonthEdit().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/cl/project/edit`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ProjectMonthListDtoToJSON(requestParameters['projectMonthListDto']),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Consente al CL o SUPER ADMIN di modificare i valori di ricavo e costi anche per mesi consolidati
     * Modifica i valori per i progetti passati in input
     */
    clProjectMonthEdit(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clProjectMonthEditRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutti i progetti applicando i filtri passati in input
     * Recupera tutti i progetti tramite i filtri
     */
    clProjectMonthSearchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['year'] == null) {
                throw new runtime.RequiredError('year', 'Required parameter "year" was null or undefined when calling clProjectMonthSearch().');
            }
            if (requestParameters['month'] == null) {
                throw new runtime.RequiredError('month', 'Required parameter "month" was null or undefined when calling clProjectMonthSearch().');
            }
            if (requestParameters['filterProjectMonthDto'] == null) {
                throw new runtime.RequiredError('filterProjectMonthDto', 'Required parameter "filterProjectMonthDto" was null or undefined when calling clProjectMonthSearch().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/cl/project/year/{year}/month/{month}`.replace(`{${"year"}}`, encodeURIComponent(String(requestParameters['year']))).replace(`{${"month"}}`, encodeURIComponent(String(requestParameters['month']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: FilterProjectMonthDtoToJSON(requestParameters['filterProjectMonthDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProjectMonthListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti i progetti applicando i filtri passati in input
     * Recupera tutti i progetti tramite i filtri
     */
    clProjectMonthSearch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clProjectMonthSearchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutti i filtri applicabili per la ricerca per un progetto
     * Recupera tutti i filtri di ricerca
     */
    clProjectMonthSearchFiltersRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/cl/search-filters-values`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FilterProjectMonthValuesDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti i filtri applicabili per la ricerca per un progetto
     * Recupera tutti i filtri di ricerca
     */
    clProjectMonthSearchFilters(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clProjectMonthSearchFiltersRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Consente al CL o SUPER ADMIN di visualizzare i costi esterni di un progetto
     * Altri costi
     */
    clProjectOtherCostsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idProject'] == null) {
                throw new runtime.RequiredError('idProject', 'Required parameter "idProject" was null or undefined when calling clProjectOtherCosts().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/cl/project/{idProject}/other-costs`.replace(`{${"idProject"}}`, encodeURIComponent(String(requestParameters['idProject']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OtherCostListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Consente al CL o SUPER ADMIN di visualizzare i costi esterni di un progetto
     * Altri costi
     */
    clProjectOtherCosts(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clProjectOtherCostsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Aggiorna i costi esterni di un progetto
     * Aggiorna i costi esterni
     */
    clProjectOtherCostsUpdateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idProject'] == null) {
                throw new runtime.RequiredError('idProject', 'Required parameter "idProject" was null or undefined when calling clProjectOtherCostsUpdate().');
            }
            if (requestParameters['otherCostListDto'] == null) {
                throw new runtime.RequiredError('otherCostListDto', 'Required parameter "otherCostListDto" was null or undefined when calling clProjectOtherCostsUpdate().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/cl/project/{idProject}/other-costs/update`.replace(`{${"idProject"}}`, encodeURIComponent(String(requestParameters['idProject']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: OtherCostListDtoToJSON(requestParameters['otherCostListDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OtherCostListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Aggiorna i costi esterni di un progetto
     * Aggiorna i costi esterni
     */
    clProjectOtherCostsUpdate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clProjectOtherCostsUpdateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Cambia lo stato di un progetto in ECONOMICS_CLOSE
     */
    closeEconomicsProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling closeEconomicsProject().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/cl/{projectId}/closeEconomicsProject`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Cambia lo stato di un progetto in ECONOMICS_CLOSE
     */
    closeEconomicsProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.closeEconomicsProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
