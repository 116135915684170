export enum UrlEnum {
  HOSTNAME_PRODUCTION = 'tops.linksmt.it',
  HOSTNAME_LOCAL = 'localhost',
  HOSTNAME_WEB_152 = 'web152.linksmt.it',
  HOSTNAME_WEB_SVIL = 'topstar-web-npe.azurewebsites.net',
  URL_MICROSOFT_LOGIN = 'https://tops.linksmt.it/auth',
  URL_MICROSOFT_LOGIN_SVIL = 'https://tops.linksmt.it/auth/npe',
  // EXTERNAL_URLS
  HELP_URL = 'https://linksmtit.sharepoint.com/sites/PeopleOperations/SitePages/Processo-Pianificazione-e-monitoraggio-dei-progetti.aspx?promotedState=0&Mode=Edit',
}
