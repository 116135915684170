export const errorMessages = {
  'topstar.error.msg.not.found': {
    title: 'resource not found',
    description: 'Resource not found',
  },
  'topstar.error.msg.forbidden': {
    title: 'access denied',
    description: 'Access denied',
  },
  'topstar.error.msg.unauthorized': {
    title: 'user unauthorized to view project',
    description: 'User unauthorized to view project',
  },
  'topstar.error.msg.bad.request': {
    title: 'invalid request',
    description: 'Invalid request',
  },
  'topstar.error.msg.invalid.state': {
    title: 'invalid state',
    description: 'Operation not allowed in the current state of the project',
  },
  'topstar.error.msg.internal.server': {
    title: 'internal server error',
    description: 'Internal server error',
  },
  'topstar.error.msg.project.not.found': {
    title: 'project not found',
    description: 'Project not found',
  },
  'topstar.error.msg.invalid.project.state': {
    title: 'invalid project state',
    description: 'Invalid project state',
  },
  'topstar.error.msg.staff.history.not.match': {
    title: 'staff history does not match staff',
    description: 'Staff history does not match staff',
  },
  'topstar.error.msg.invalid.state.for.deletion': {
    title: 'invalid state for deletion',
    description: 'Invalid state for deletion',
  },
  'topstar.error.title.not.found': {
    title: 'resource not found',
    description: 'Resource not found',
  },
  'topstar.error.title.forbidden': {
    title: 'access denied',
    description: 'Access denied',
  },
  'topstar.error.title.unauthorized': {
    title: 'unauthorized access',
    description: 'Unauthorized access',
  },
  'topstar.error.title.bad.request': {
    title: 'bad request',
    description: 'Bad request',
  },
  'topstar.error.title.invalid.state': {
    title: 'invalid state',
    description: 'Operation not allowed in the current state of the project',
  },
  'topstar.error.title.internal.server': {
    title: 'server error',
    description: 'Server error',
  },
  'topstar.error.title.invalid.project.state': {
    title: 'invalid project state',
    description: 'Invalid project state',
  },
  'topstar.error.title.staff.history.not.match': {
    title: 'staff history does not match',
    description: 'Staff history does not match',
  },
  'topstar.error.title.invalid.state.for.deletion': {
    title: 'deletion denied',
    description: 'Deletion denied',
  },
  'topstar.error.title.invalid.staff.compilation': {
    title: 'all staff member required',
    description: 'All staff member required',
  },
  'topstar.error.title.project.not.found': {
    title: 'project not found',
    description: 'Project not found',
  },
  'topstar.error.msg.project.lead.not.defined': {
    title: 'To continue you need to associate a PL with the project',
    description: 'To continue you need to associate a PL with the project',
  },
};
