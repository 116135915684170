import { title } from 'process';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
interface modalProps {
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
  children: React.ReactNode;
  title: string;
  cancelText: string;
  confirmText: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

function AppModal({ setShow, show, children, title, cancelText, confirmText, onConfirm, onCancel }: modalProps) {
  const { t } = useTranslation();

  const handleConfirm = () => {
    setShow && setShow(false);
    onConfirm();
  };
  const handleCancel = () => {
    setShow && setShow(false);
    onCancel && onCancel();
  };
  return (
    <>
      <Modal show={show} onHide={handleCancel} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ backgroundColor: 'f3f4f7', fontSize: '1em' }}>
            <b className="text-blue-links">{title}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" className="btn-links-outline" onClick={handleCancel}>
            {cancelText || 'Annulla'}
          </Button>
          <Button variant="danger" className="px-4" onClick={handleConfirm}>
            {confirmText || 'Conferma'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AppModal;
