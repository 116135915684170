import i18n from 'i18next';
import { projectStatus } from './dashboard.enum';
interface Chip {
  key: string;
  name: string;
}
interface ChipsMap {
  [key: string]: Chip;
}
export const chipsData: ChipsMap = {
  status: {
    key: 'stato',
    name: 'Status',
  },
  projectStatus: {
    key: 'projectStatus',
    name: 'Status',
  },
  stati: {
    key: 'stati',
    name: 'Status',
  },
  projectType: {
    key: 'projectType',
    name: 'Project Type',
  },
  projectName: {
    key: 'projectName',
    name: 'Project Name',
  },
  nomeProgetto: {
    key: 'nomeProgetto',
    name: 'Project Name',
  },
  nome: {
    key: 'nome',
    name: 'Project Name',
  },
  typology: {
    key: 'tipologia',
    name: 'Typology',
  },
  businessUnit: {
    key: 'businessUnit',
    name: 'Business Unit',
  },
  deliveryUnit: {
    key: 'deliveryUnit',
    name: 'Delivery Unit',
  },
  priceRange: {
    key: 'priceRange',
    name: 'Price Range',
  },
  dateRange: {
    key: 'dateRange',
    name: 'Date Range',
  },
  nomeCliente: {
    key: 'nomeCliente',
    name: 'Account Name',
  },
  idCliente: {
    key: 'idCliente',
    name: 'Account Name',
  },
  idProgetto: {
    key: 'idProgetto',
    name: 'Project ID',
  },
  projectId: {
    key: 'projectId',
    name: 'Project ID',
  },
  idProject: {
    key: 'idProject',
    name: 'Project ID',
  },
  id: {
    key: 'id',
    name: 'Project ID',
  },
  personaId: {
    key: 'personaId',
    name: 'Collaborator',
  },
  personaIdList: {
    key: 'personaIdList',
    name: 'Collaborators',
  },
  plUserId: {
    key: 'plUserId',
    name: 'PL',
  },
  businessManager: {
    key: 'businessManager',
    name: 'CL/AM',
  },
  projectManager: {
    key: 'projectManager',
    name: 'Project Manager',
  },
  flagPlaceholderPlanned: {
    key: 'flagPlaceholderPlanned',
    name: 'Placeholder user',
  },
  mesiAnni: {
    key: 'mesiAnni',
    name: 'Period',
  },
  isUserActive: {
    key: 'isUserActive',
    name: 'Active resources',
  },
  quoteId: {
    key: 'quoteId',
    name: 'Quote ID',
  },
  opportunityId: {
    key: 'opportunityId',
    name: 'Opportunity ID',
  },
  signatureRange: {
    key: 'signatureRange',
    name: 'Signature Range',
  },
  orderId: {
    key: 'orderId',
    name: 'Order ID',
  },
  object: {
    key: 'object',
    name: 'Object',
  },
  automaticRenewal: {
    key: 'automaticRenewal',
    name: 'Automatic Renewal',
  },
  withoutExternalCosts: {
    key: 'withoutExternalCosts',
    name: 'Without External Costs',
  },
  legalEntities: {
    key: 'legalEntities',
    name: 'Legal Entities',
  },
  contractStatus: {
    key: 'contractStatus',
    name: 'Contract Status',
  },
  orderProcessingStatus: {
    key: 'orderProcessingStatus',
    name: 'Order Processing Status',
  },
  expenseItems: {
    key: 'expenseItems',
    name: 'Expense Items',
  },
  suppliers: {
    key: 'suppliers',
    name: 'Suppliers',
  },
  categories: {
    key: 'categories',
    name: 'Categories',
  },
  startEndRange: {
    key: 'startEndRange',
    name: 'Start/End Range',
  },
};

// "status": "Status",
//     "projectType": "Project Type",
//     "projectName": "Project Name",
//     "nomeProgetto": "Project Name",
//     "tipologia": "Typology",
//     "businessUnit": "Business Unit",
//     "deliveryUnit": "Delivery Unit",
//     "priceRange": "Price Range",
//     "dateRange": "Date Range",
//     "nomeCliente": "Client Name",
//     "idProgetto": "Project ID",
//     "businessManager": "Business Manager",
//     "projectManager": "Project Manager",
//     "flagPlaceholderPlanned": "Placeholder user"
