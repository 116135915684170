import {
  DashboardBookingDto,
  DashboardBookingListDto,
  DashboardUserDto,
  DettaglioMeseAnnoDto,
  ProjectDaysDto,
  ProjectDto,
} from '@api/client';
import { useEffect, useState } from 'react';
import { trackUserIdGa4 } from '../../../../../shared/utils/gtag';
import { DashboardUserTables } from '../../../../../enums/dashboard.enum';
import { TrackPagesGA4Enum } from '../../../../../enums/global.enum';

const useGetBookingResources = (
  table: DashboardUserTables,
  bookingUsers: DashboardBookingListDto | null | undefined
) => {
  const [users, setUsers] = useState<DashboardBookingListDto | null | undefined>(null);
  const [consolidatedMonths, setConsolidatedMonths] = useState<any[]>([]);
  const [allMonths, setAllMonths] = useState<any[]>([]);

  useEffect(() => {
    trackUserIdGa4(
      table === DashboardUserTables.UNDERBOOKING
        ? TrackPagesGA4Enum.DASHBOARD_UNDERBOOKING
        : TrackPagesGA4Enum.DASHBOARD_OVERBOOKING
    );
  }, []);

  useEffect(() => {
    if (bookingUsers) {
      getConsolidatedMonths(bookingUsers.dashboardBookingDtoList || []);
    }
  }, [bookingUsers]);

  useEffect(() => {
    if (bookingUsers) {
      const mappedUsers = getMappedUsers(bookingUsers.dashboardBookingDtoList || []);
      setUsers(mappedUsers);
    }
  }, [consolidatedMonths]);

  const getConsolidatedMonths = (users: DashboardUserDto[]) => {
    let consolidatedMonths: any[] = [];
    users.forEach((user) => {
      user.userDaysDtoList?.forEach((month) => {
        if (month.isConsolidato && !consolidatedMonths.find((m) => m.month === month.month && m.year === month.year)) {
          consolidatedMonths.push({ month: month.month, year: month.year });
        }
      });
    });
    setConsolidatedMonths(consolidatedMonths);
  };

  const getMappedUsers = (users: DashboardBookingDto[]) => {
    // users may have diiferent months, so we need to get all months from all users (set daysactualforecast to 0 if not present)
    const allMonths = getAllMonths(users);
    const mappedUsers = users.map((user) => {
      let dettagliMeseAnno: DettaglioMeseAnnoDto[] = [];
      allMonths.forEach((month) => {
        const userMonth = user.dettagliMeseAnno?.find((m) => m.mese === month.month && m.anno === month.year);
        if (!userMonth) {
          dettagliMeseAnno.push({
            mese: month.month,
            anno: month.year,
            fkCcAppartenenza: undefined,
            sftAfGuOthAssenza: undefined,
            sftAfGuMalattia: undefined,
            stfAfGuFerieTot: undefined,
            stfAfFteTot: undefined,
            stfAfGuTot: undefined,
          });
        } else {
          dettagliMeseAnno.push(userMonth);
        }
      });
      let projects: ProjectDto[] = [];
      user.projects?.forEach((project) => {
        let projectDaysDtoList: ProjectDaysDto[] = [];
        allMonths.forEach((month) => {
          const projectMonth = project.projectDaysDtoList?.find(
            (m) => m.month === month.month && m.year === month.year
          );
          if (!projectMonth) {
            projectDaysDtoList.push({
              month: month.month,
              year: month.year,
              daysActualForecast: 0,
              isConsolidato: isMeseConsolidato(month.month, month.year),
            });
          } else {
            projectDaysDtoList.push({ ...projectMonth, isConsolidato: isMeseConsolidato(month.month, month.year) });
          }
        });
        projectDaysDtoList.sort((a, b) => {
          if (a.year === b.year) {
            return (a.month ?? 0) - (b.month ?? 0);
          }
          return (a.year ?? 0) - (b.year ?? 0);
        });
        projects.push({ ...project, projectDaysDtoList });
      });
      dettagliMeseAnno.sort((a, b) => {
        if (a.anno === b.anno) {
          return (a.mese ?? 0) - (b.mese ?? 0);
        }
        return (a.anno ?? 0) - (b.anno ?? 0);
      });
      return { ...user, dettagliMeseAnno, projects };
    });
    return { dashboardBookingDtoList: mappedUsers };
  };

  const isMeseConsolidato = (month: number, year: number) => {
    return consolidatedMonths.some((consolidatedMonth) => {
      return consolidatedMonth.month === month && consolidatedMonth.year === year;
    });
  };

  const getAllMonths = (users: DashboardUserDto[]) => {
    let allMonths: any[] = [];
    users.forEach((user) => {
      user.userDaysDtoList?.forEach((month) => {
        if (!allMonths.find((m) => m.month === month.month && m.year === month.year)) {
          allMonths.push({ month: month.month, year: month.year });
        }
      });
    });
    allMonths.sort((a, b) => {
      if (a.year === b.year) {
        return a.month - b.month;
      }
      return a.year - b.year;
    });
    setAllMonths(allMonths);
    return allMonths;
  };

  return { users, allMonths, consolidatedMonths };
};

export default useGetBookingResources;
