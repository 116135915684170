import { Tooltip } from 'antd';
import { t } from 'i18next';
import { Badge, Card } from 'react-bootstrap';
import { useHeader } from './_hooks/useHeader';
import CustomToggle from './_components/custom-toggle';

export const AccordionHeader = ({
  formData,
  isOpen,
  setIsOpen,
  chipsFormData,
  setChipsFormData,
  reset,
  location,
}: any) => {
  const { handleRemoveChip, getChipNameByKey, renderDescription, getTooltipContent } = useHeader(
    formData,
    location,
    reset,
    setChipsFormData
  );

  return (
    <Card.Header
      className={`cursor-pointer d-flex ${isOpen ? 'card-header-border' : ''}`}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <b className="text-blue-links align-items-center my-auto ms-2 me-3">{t('cdg.filters')}</b>
      <div className="">
        {chipsFormData?.map((chips: any, index: number) => {
          const tooltipContent = getTooltipContent(chips);
          if (!tooltipContent) return null;
          const description = renderDescription(chips);
          if (!description) return null;
          const chipTitle = getChipNameByKey(chips[0]);
          return (
            <Tooltip title={`${chipTitle} - ${tooltipContent}`} color="white" key={index}>
              <Badge
                onClick={(e) => e.stopPropagation()}
                text="dark"
                bg="light"
                pill
                className="mx-1 my-0 "
                key={index}
                style={{ border: 'solid 1px' }}
              >
                {description}
                <span className="cursor-pointer ms-1" onClick={(e) => handleRemoveChip(index, e)}>
                  &#x2715;
                </span>
              </Badge>
            </Tooltip>
          );
        })}
      </div>
      <div className="ms-auto">
        <CustomToggle eventKey="0">
          <i className={`fa-solid fa-chevron-${isOpen ? 'up' : 'down'} notification-header`}></i>
        </CustomToggle>
      </div>
    </Card.Header>
  );
};
