import { Session } from '../../../../enums/project.enum';
import { MenuProps } from 'antd';
import useCloseEconomics from './useCloseEconomics';
import useCompleteProject from './useCompleteProject';

enum MenuItemsKeys {
  CLOSE_ECONOMICS = 'close-economics',
  COMPLETE_PROJECT = 'complete-project',
  CONSOLIDATE = 'consolidate',
}

interface Props {
  session: Session;
  consolidateRevenues: (reload?: boolean | undefined, projectId?: number | undefined) => void;
}

const useHandleManagementToolsActions = ({ session, consolidateRevenues }: Props) => {
  const {
    closeEconomics,
    showConfirmCloseEconomicsModal,
    setShowConfirmCloseEconomicsModal,
    idToClose,
    setIdToClose,
    t,
  } = useCloseEconomics();

  const { completeProject, showConfirmCompleteProjectModal, setShowConfirmCompleteProjectModal, setIdToComplete } =
    useCompleteProject();

  const menuProps = (rowIndex: number, id: number) => ({
    items: generateMenuItems(),
    onClick: handleMenuClick(rowIndex, id),
  });

  const generateMenuItems = () => {
    const menu = [
      {
        label: t('cdg.consolidate'),
        key: MenuItemsKeys.CONSOLIDATE,
        icon: <i className={`icon-closed-won-complete text-xl text-blue-links`} />,
        danger: false,
      },
    ];
    switch (session) {
      case Session.CL:
        menu.push({
          label: t('cdg.close-economics.action'),
          key: MenuItemsKeys.CLOSE_ECONOMICS,
          icon: <i className={`icon-time-expire-out-of-time text-xl`} />,
          danger: true,
        });
        break;
      case Session.CDG:
        menu.push({
          label: t('cdg.complete-project.action'),
          key: MenuItemsKeys.COMPLETE_PROJECT,
          icon: <i className={`icon-closed-won-complete text-xl`} />,
          danger: true,
        });
        break;
    }
    return menu;
  };

  const handleMenuClick: (rowIndex: number, id: number) => MenuProps['onClick'] = (rowIndex, id) => (e) => {
    switch (e.key) {
      case MenuItemsKeys.CONSOLIDATE:
        consolidateRevenues(true, id);
        break;
      case MenuItemsKeys.CLOSE_ECONOMICS:
        setIdToClose(id);
        setShowConfirmCloseEconomicsModal(true);
        break;
      case MenuItemsKeys.COMPLETE_PROJECT:
        setIdToComplete(id);
        setShowConfirmCompleteProjectModal(true);
        break;
    }
  };

  return {
    menuProps,
    showConfirmCloseEconomicsModal,
    setShowConfirmCloseEconomicsModal,
    closeEconomics,
    showConfirmCompleteProjectModal,
    setShowConfirmCompleteProjectModal,
    completeProject,
    idToClose,
  };
};

export default useHandleManagementToolsActions;
