/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the RevenuePlanDto interface.
 */
export function instanceOfRevenuePlanDto(value) {
    return true;
}
export function RevenuePlanDtoFromJSON(json) {
    return RevenuePlanDtoFromJSONTyped(json, false);
}
export function RevenuePlanDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'year': json['year'] == null ? undefined : json['year'],
        'month': json['month'] == null ? undefined : json['month'],
        'extimatedAmount': json['extimatedAmount'] == null ? undefined : json['extimatedAmount'],
        'extimatedRate': json['extimatedRate'] == null ? undefined : json['extimatedRate'],
        'extimatedCost': json['extimatedCost'] == null ? undefined : json['extimatedCost'],
        'extimatedHrCost': json['extimatedHrCost'] == null ? undefined : json['extimatedHrCost'],
        'extimatedExternalCost': json['extimatedExternalCost'] == null ? undefined : json['extimatedExternalCost'],
        'extimatedFte': json['extimatedFte'] == null ? undefined : json['extimatedFte'],
        'extimatedDays': json['extimatedDays'] == null ? undefined : json['extimatedDays'],
        'actualAmount': json['actualAmount'] == null ? undefined : json['actualAmount'],
        'actualRate': json['actualRate'] == null ? undefined : json['actualRate'],
        'actualCost': json['actualCost'] == null ? undefined : json['actualCost'],
        'actualHrCost': json['actualHrCost'] == null ? undefined : json['actualHrCost'],
        'actualExternalCost': json['actualExternalCost'] == null ? undefined : json['actualExternalCost'],
        'actualFte': json['actualFte'] == null ? undefined : json['actualFte'],
        'actualDays': json['actualDays'] == null ? undefined : json['actualDays'],
        'consolidato': json['consolidato'] == null ? undefined : json['consolidato'],
    };
}
export function RevenuePlanDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'year': value['year'],
        'month': value['month'],
        'extimatedAmount': value['extimatedAmount'],
        'extimatedRate': value['extimatedRate'],
        'extimatedCost': value['extimatedCost'],
        'extimatedHrCost': value['extimatedHrCost'],
        'extimatedExternalCost': value['extimatedExternalCost'],
        'extimatedFte': value['extimatedFte'],
        'extimatedDays': value['extimatedDays'],
        'actualAmount': value['actualAmount'],
        'actualRate': value['actualRate'],
        'actualCost': value['actualCost'],
        'actualHrCost': value['actualHrCost'],
        'actualExternalCost': value['actualExternalCost'],
        'actualFte': value['actualFte'],
        'actualDays': value['actualDays'],
        'consolidato': value['consolidato'],
    };
}
