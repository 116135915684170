import { Formik } from 'formik';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LinksLogo from '../../../shared/design-system/assets/svg/logo_bianco_links_small.png';
import AppInput from '../../../shared/design-system/components/app-input';
import { useAppDispatch } from '../../../core/store/hooks';
import Yup from '../../../shared/utils/form-validation.utils';
import { authLogin } from '../_redux/actions';
import LinksButton from '../../../shared/design-system/components/links-button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from '../../../core/auth/auth.service';

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const token =
      'eyJhbGciOiJIUzI1NiJ9.eyJOQU1FIjoiQm9uYXRlc3RhIEdpb2VsZSIsIlJPTEUiOiJTVVBFUl9BRE1JTiIsIkVNQUlMIjoiZ2lvZWxlLmJvbmF0ZXN0YUBsaW5rc210Lml0IiwiTEFOR1VBR0UiOiJJVCIsIlVTRVJJRCI6MTQxNiwic3ViIjoiZ2lvZWxlLmJvbmF0ZXN0YUBsaW5rc210Lml0IiwianRpIjoiZTk3YzdmYTItNDEyNC00ZGEzLWI5NzctNDViZDg5ZDlhOGNhIiwiaWF0IjoxNzMxMDYzOTMzLCJleHAiOjQxMDI0NDExOTl9.vTcDqnHSCuClTDsVKzHaPai5nOa2bUV6yK0KvLOvNMc';
    AuthService.setAuthentication(token);
    AuthService.setUser();
    navigate('/');
  }, []);

  const handleSubmit = (values: { username: string; password: string }) => {
    const payload = {
      username: values.username,
      password: btoa(values.password),
    };
    dispatch(authLogin(payload))
      .unwrap()
      .then((res) => {
        if (res) {
          navigate('/');
        } else {
          setError(t('login.error'));
        }
      })
      .catch((err) => {
        setError(t('login.error'));
      });
  };

  // useEffect(() => {
  //   const url = new URL(window.location.href);
  //   const token = url.searchParams.get('token');
  //   if (token) {
  //     AuthService.setAuthentication(token);
  //     AuthService.setUser();
  //     navigate('/');
  //   }
  // }, []);

  // const fetchMicrosoftLogin = async () => {
  // dispatch(authLogin());
  // .unwrap()
  // .then((res) => {
  //   if (res) {
  //     navigate('/');
  //   } else {
  //     setError(t('login.error'));
  //   }
  // })
  // .catch((err) => {
  //   setError(t('login.error'));
  // });
  // };

  return (
    <div className="wrapper min-vh-100 bg-light">
      <nav className="navbar navbar-expand navbar-light topbar mb-4 static-top shadow app-header bg-gradient-primary justify-content-center">
        <img src={LinksLogo} className="logo" alt="Logo" />
      </nav>

      <div className="d-flex justify-content-center mt-5">
        <Card style={{ width: '500px' }}>
          <h3 className="m-4">{t('login.title')}</h3>
          <Card.Body>
            {/* <div className="d-flex justify-content-center">
              <LinksButton className="btn-primary btn-links" onClick={fetchMicrosoftLogin}>
                {t('login.submit')}
              </LinksButton>
            </div> */}

            <Formik
              initialValues={{
                username: '',
                password: '',
                // lang: 'ITALIANO',
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string().required(),
                password: Yup.string().required(),
              })}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit: _handleSubmit, isValid }) => {
                return (
                  <form onSubmit={_handleSubmit} className="d-flex flex-column gap-3">
                    <AppInput
                      label="Email"
                      name="username"
                      value={values?.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors?.username && touched.username}
                      error={errors?.username}
                    />

                    <AppInput
                      type="password"
                      label="Password"
                      name="password"
                      value={values?.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors?.password && touched.password}
                      error={errors?.password}
                    />

                    {error && <div className="text-danger">{error}</div>}

                    <LinksButton className="btn-primary btn-links" type="submit" isDisabled={!isValid}>
                      {t('login.submit')}
                    </LinksButton>
                  </form>
                );
              }}
            </Formik>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
