import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../core/store';

const dashboardReducer = (state: RootState) => state.private.dashboard;

export const dashboardProjectsSelector = createSelector(dashboardReducer, (dashboard) => dashboard.dashboardProjects);
export const dashboardProjectTotalsSelector = createSelector(
  dashboardReducer,
  (dashboard) => dashboard.dashboardProjectTotals
);
export const dashboardProjectsCurrentYearSelector = createSelector(
  dashboardReducer,
  (dashboard) => dashboard.dashboardProjectsCurrentYear
);
export const dashboardProjectTotalsCurrentYearSelector = createSelector(
  dashboardReducer,
  (dashboard) => dashboard.dashboardProjectTotalsCurrentYear
);
export const dashboardUsersSelector = createSelector(dashboardReducer, (dashboard) => dashboard.dashboardUsers);
export const dashboardUnderbookingUsersSelector = createSelector(
  dashboardReducer,
  (dashboard) => dashboard.dashboardUnderbookingUsers
);
export const dashboardOverbookingUsersSelector = createSelector(
  dashboardReducer,
  (dashboard) => dashboard.dashboardOverbookingUsers
);
export const filtersDashboardProjectSelector = createSelector(
  dashboardReducer,
  (dashboard) => dashboard.filtersDashboardProject
);
export const filtersDashboardProjectUsedSelector = createSelector(
  dashboardReducer,
  (dashboard) => dashboard.filtersDashboardProjectUsed
);
export const filtersDashboardPeopleUsedSelector = createSelector(
  dashboardReducer,
  (dashboard) => dashboard.filtersDashboardPeopleUsed
);
