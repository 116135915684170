/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the InvoiceMilestone interface.
 */
export function instanceOfInvoiceMilestone(value) {
    return true;
}
export function InvoiceMilestoneFromJSON(json) {
    return InvoiceMilestoneFromJSONTyped(json, false);
}
export function InvoiceMilestoneFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idInvoiceMilestone': json['idInvoiceMilestone'] == null ? undefined : json['idInvoiceMilestone'],
        'nomeInvoiceMilestone': json['nomeInvoiceMilestone'] == null ? undefined : json['nomeInvoiceMilestone'],
        'idOpportunity': json['idOpportunity'] == null ? undefined : json['idOpportunity'],
        'amountInvoiceMilestone': json['amountInvoiceMilestone'] == null ? undefined : json['amountInvoiceMilestone'],
        'dataPrevista': json['dataPrevista'] == null ? undefined : (new Date(json['dataPrevista'])),
        'serviceContract': json['serviceContract'] == null ? undefined : json['serviceContract'],
        'recordTypeId': json['recordTypeId'] == null ? undefined : json['recordTypeId'],
        'descriptionInvoiceMilestone': json['descriptionInvoiceMilestone'] == null ? undefined : json['descriptionInvoiceMilestone'],
        'statusInvoiceMilestone': json['statusInvoiceMilestone'] == null ? undefined : json['statusInvoiceMilestone'],
        'yearInvoiceMilestone': json['yearInvoiceMilestone'] == null ? undefined : json['yearInvoiceMilestone'],
        'invoiceNumber': json['invoiceNumber'] == null ? undefined : json['invoiceNumber'],
        'isCreditNote': json['isCreditNote'] == null ? undefined : json['isCreditNote'],
        'isDeleted': json['isDeleted'] == null ? undefined : json['isDeleted'],
        'serviceContractNum': json['serviceContractNum'] == null ? undefined : json['serviceContractNum'],
    };
}
export function InvoiceMilestoneToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idInvoiceMilestone': value['idInvoiceMilestone'],
        'nomeInvoiceMilestone': value['nomeInvoiceMilestone'],
        'idOpportunity': value['idOpportunity'],
        'amountInvoiceMilestone': value['amountInvoiceMilestone'],
        'dataPrevista': value['dataPrevista'] == null ? undefined : ((value['dataPrevista']).toISOString()),
        'serviceContract': value['serviceContract'],
        'recordTypeId': value['recordTypeId'],
        'descriptionInvoiceMilestone': value['descriptionInvoiceMilestone'],
        'statusInvoiceMilestone': value['statusInvoiceMilestone'],
        'yearInvoiceMilestone': value['yearInvoiceMilestone'],
        'invoiceNumber': value['invoiceNumber'],
        'isCreditNote': value['isCreditNote'],
        'isDeleted': value['isDeleted'],
        'serviceContractNum': value['serviceContractNum'],
    };
}
