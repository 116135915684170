import { AiChat, ComposerOptions, ConversationOptions, DisplayOptions, useAsStreamAdapter } from '@nlux/react';
import '@nlux/themes/nova.css';
import { send } from './send';
import { personas } from './personas';
import chatmouse from '../../../design-system/assets/svg/chatmouse_Black2.svg';
import topinaAnimata from '../../../design-system/assets/svg/Topina_Animata_1.json';
import { useState } from 'react';
import './styles.scss';
import Lottie from 'react-lottie';

const WidgetAiNlux: React.FC = () => {
  const adapter = useAsStreamAdapter(send, []);
  const [isToggled, setIsToggled] = useState<boolean>(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: topinaAnimata,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const displayOptions: DisplayOptions = {
    colorScheme: 'light',
    themeId: 'nova',
    width: 500,
    height: 600,
  };

  const composerOptions: ComposerOptions = {
    autoFocus: true,
    disableSubmitButton: false,
    placeholder: 'Scrivi qui la tua domanda, cercherò di rispondere',
    submitShortcut: 'Enter',
  };

  const conversationOptions: ConversationOptions = {
    layout: 'bubbles',
    autoScroll: true,
  };

  const toggleChatbot = () => {
    document.body.classList.toggle('show-chatbot');
    setIsToggled(!isToggled);
  };

  return (
    <div style={{ zIndex: '99999' }}>
      <button className="chatbot-toggler" onClick={toggleChatbot}>
        {/* <img src={chatmouse} alt="chatmouse" className={` ${isToggled ? 'd-none' : ''}`} /> */}
        <div className={`animation-container ${isToggled ? 'd-none' : ''}`}>
          <Lottie options={defaultOptions} height={200} width={40} />
        </div>
        <span className="material-symbols-outlined">Close</span>
      </button>
      <div className="chatbot">
        <AiChat
          conversationOptions={conversationOptions}
          composerOptions={composerOptions}
          displayOptions={displayOptions}
          adapter={adapter}
          personaOptions={personas}
        />
      </div>
    </div>
  );
};

export default WidgetAiNlux;
