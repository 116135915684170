import { DashboardProjectDto } from '@api/client';
import { useState } from 'react';

const useSortDashboardProjects = (
  projects: DashboardProjectDto[] | any[],
  setProjects: React.Dispatch<React.SetStateAction<DashboardProjectDto[] | any[]>>
) => {
  const [sort, setSort] = useState<number>(1);

  const sortItems = (key: string) => {
    const sortedProjects = [...projects].sort((a: any, b: any) => {
      if (a[key] > b[key]) {
        return sort === 1 ? -1 : 1;
      }
      if (a[key] < b[key]) {
        return sort === 1 ? 1 : -1;
      }
      return 0;
    });

    setProjects(sortedProjects);
    setSort(sort * -1);
  };

  return { sortItems };
};

export default useSortDashboardProjects;
