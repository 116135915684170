/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StrutturaFromJSON, StrutturaToJSON, } from './Struttura';
import { StrutturaFunzioneFromJSON, StrutturaFunzioneToJSON, } from './StrutturaFunzione';
/**
 * Check if a given object implements the StrutturaFunzioneOutputDto interface.
 */
export function instanceOfStrutturaFunzioneOutputDto(value) {
    return true;
}
export function StrutturaFunzioneOutputDtoFromJSON(json) {
    return StrutturaFunzioneOutputDtoFromJSONTyped(json, false);
}
export function StrutturaFunzioneOutputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'idParent': json['idParent'] == null ? undefined : StrutturaFunzioneFromJSON(json['idParent']),
        'idStruttura': json['idStruttura'] == null ? undefined : StrutturaFromJSON(json['idStruttura']),
        'nome': json['nome'] == null ? undefined : json['nome'],
    };
}
export function StrutturaFunzioneOutputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'idParent': StrutturaFunzioneToJSON(value['idParent']),
        'idStruttura': StrutturaToJSON(value['idStruttura']),
        'nome': value['nome'],
    };
}
