/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ProgettoDto interface.
 */
export function instanceOfProgettoDto(value) {
    return true;
}
export function ProgettoDtoFromJSON(json) {
    return ProgettoDtoFromJSONTyped(json, false);
}
export function ProgettoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'businessManager': json['businessManager'] == null ? undefined : json['businessManager'],
        'projectLeader': json['projectLeader'] == null ? undefined : json['projectLeader'],
        'intranetId': json['intranetId'] == null ? undefined : json['intranetId'],
        'editDeliveryEnabled': json['editDeliveryEnabled'] == null ? undefined : json['editDeliveryEnabled'],
        'progetto': json['progetto'] == null ? undefined : json['progetto'],
        'cliente': json['cliente'] == null ? undefined : json['cliente'],
        'dataModifica': json['dataModifica'] == null ? undefined : (new Date(json['dataModifica'])),
        'stato': json['stato'] == null ? undefined : json['stato'],
    };
}
export function ProgettoDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'businessManager': value['businessManager'],
        'projectLeader': value['projectLeader'],
        'intranetId': value['intranetId'],
        'editDeliveryEnabled': value['editDeliveryEnabled'],
        'progetto': value['progetto'],
        'cliente': value['cliente'],
        'dataModifica': value['dataModifica'] == null ? undefined : ((value['dataModifica']).toISOString()),
        'stato': value['stato'],
    };
}
