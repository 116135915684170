import React, { useEffect, useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import './styles.scss';
interface Props {
  header: string;
  isOpened?: boolean;
  headerContent?: React.ReactNode;
  children?: React.ReactNode;
  setisAccordionOpen?: any;
}

const CommonAccordion: React.FC<Props> = ({
  header,
  isOpened = false,
  headerContent,
  children,
  setisAccordionOpen,
}: Props) => {
  return (
    <Accordion activeKey={isOpened ? '0' : ''} className="acc-btn my-3">
      <Card className="border-left-primary acc-btn">
        <Card.Header
          className={`d-flex justify-content-between align-items-center cursor-pointer ${
            isOpened ? 'card-header-border' : 'row'
          } headerCard `}
          onClick={() => setisAccordionOpen(!isOpened)}
        >
          <Col sm="auto">
            <b className="text-blue-links align-items-center my-auto me-3">{header}</b>
          </Col>
          <>{headerContent}</>
          <i
            className={` fa-solid fa-chevron-${
              isOpened ? 'up' : 'down'
            } notification-header cursor-pointer my-auto align-items-center`}
            style={{ textAlign: 'right', width: '40px' }}
          ></i>
        </Card.Header>
        {isOpened && <Card.Body className="mx-2">{children}</Card.Body>}
      </Card>
    </Accordion>
  );
};

export default CommonAccordion;
