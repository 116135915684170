import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../core/store/hooks';
import { useTranslation } from 'react-i18next';
import AppCard from '../../../../shared/design-system/components/app-card';
import LinksButton from '../../../../shared/design-system/components/links-button';
import { useEffect, useState } from 'react';
import AppAccordion from '../../../../shared/design-system/components/app-accordion';
import AccordionForm, {
  projectListFormData,
} from '../../../../shared/design-system/components/app-accordion/_components/accordion-form';
import useAccordionSubmit from '../../../../shared/design-system/components/app-accordion/_hooks/useFormSubmit';
import OrderData from './_components/order_data';
import { ordersSelector } from '../_redux/selectors';
import { ContractOrderOutputDto } from '@api/client';
import NoData from '../../../../shared/design-system/components/no-data-box';
import { searchOrders } from '../_redux/actions';
import PassiveCycleProjectCard from './_components/passive-cycle-project-card';
import React from 'react';
import PassiveCycleProjectAssociatedCard from './_components/passive-cycle-project-associated-card';
import useHandleAssociableProjects from './_hooks/useHandleAssociableProjects';
import { LocationFilterEnum } from '../../../../enums/global.enum';

const OrderDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: any }>();

  const [currentOrder, setCurrentOrder] = useState<ContractOrderOutputDto | undefined>(undefined);
  const filteredOrders = useAppSelector(ordersSelector);

  useEffect(() => {
    if (filteredOrders) {
      const order = filteredOrders.find((order) => order.id === Number(id));
      setCurrentOrder(order);
    } else {
      dispatch(searchOrders({ contractOrderSearchDto: {} }));
    }
  }, [filteredOrders]);

  const [isAssociateNewProjectVisible, setIsAssociateNewProjectVisible] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset, filterParams } = useAccordionSubmit(
    projectListFormData,
    setIsOpen,
    currentOrder?.id
  );

  const [controlledDate, setControlledDate] = useState<any>(null);

  const handleReset = () => {
    onReset();
    controlledDate && setControlledDate([]);
    chipsFormData && setChipsFormData([]);
  };

  const { associableProjects, externalCostsToAdd, handleAssociateCost, handleChangeAmount, associateExternalCosts } =
    useHandleAssociableProjects(currentOrder, setIsAssociateNewProjectVisible);

  return (
    <>
      <h4 className="text-gray-800 my-0">{t('Order')}</h4>

      {!currentOrder && (
        <AppCard title={t('Order data')} className="border-left-primary mt-3">
          <div className="mt-3">
            <NoData msg={t('No order found')} />
          </div>
        </AppCard>
      )}

      {currentOrder && (
        <>
          <OrderData order={currentOrder} />

          {/* ASSOCIATE COST BUTTON */}
          <div className="d-flex justify-content-between my-3">
            <b className="text-blue-links">{t('Projects and related external costs')}</b>
            <div>
              <LinksButton
                className="btn-sm btn-primary btn-teal px-4 ms-auto"
                onClick={() => {
                  setIsAssociateNewProjectVisible(true);
                }}
              >
                {t('+ Associate')}
              </LinksButton>
            </div>
          </div>

          {/* ASSOCIATED COSTS */}
          {currentOrder.projectExternalCost?.map((project) => {
            return (
              <React.Fragment key={`associate_project_${project.projectId}`}>
                <PassiveCycleProjectAssociatedCard associatedProject={project} contractOrderId={currentOrder.id} />
              </React.Fragment>
            );
          })}

          {/* ASSOCIATE NEW PROJECT CARD */}
          {isAssociateNewProjectVisible && (
            <AppCard title="Associate new project" className="border-left-primary mt-3">
              <AppAccordion
                formData={projectListFormData}
                location={LocationFilterEnum.PROJECT_LIST}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                chipsFormData={chipsFormData}
                setChipsFormData={setChipsFormData}
                reset={reset}
              >
                <AccordionForm
                  onSubmit={onSubmit}
                  handleReset={handleReset}
                  setControlledDate={setControlledDate}
                  // onReset={onReset} control={control} register={register}
                />
              </AppAccordion>
              {associableProjects.map((project) => {
                return (
                  <React.Fragment key={`associable_project_${project.projectId}`}>
                    <PassiveCycleProjectCard
                      project={project}
                      handleChangeAssociated={handleAssociateCost}
                      handleChangeAmount={handleChangeAmount}
                    />
                  </React.Fragment>
                );
              })}
              {associableProjects.length > 0 && (
                <div className="d-flex justify-content-end">
                  <LinksButton className="btn btn-links-outline" onClick={() => setIsAssociateNewProjectVisible(false)}>
                    Cancel
                  </LinksButton>
                  <LinksButton
                    className="btn-primary btn-links"
                    onClick={associateExternalCosts}
                    isDisabled={externalCostsToAdd.length === 0}
                  >
                    Associate
                  </LinksButton>
                </div>
              )}
            </AppCard>
          )}
        </>
      )}
    </>
  );
};

export default OrderDetail;
