/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { LoggedUserDtoFromJSON, SearchUserResponseDtoFromJSON, UserDtoToJSON, UserSearchRequestToJSON, } from '../models/index';
/**
 *
 */
export class ControllerUserApi extends runtime.BaseAPI {
    /**
     * Cancellazione delegato del PL per un dato progetto
     * Cancellazione delegato
     */
    deleteDesignatedPersonForProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idProject'] == null) {
                throw new runtime.RequiredError('idProject', 'Required parameter "idProject" was null or undefined when calling deleteDesignatedPersonForProject().');
            }
            if (requestParameters['userDto'] == null) {
                throw new runtime.RequiredError('userDto', 'Required parameter "userDto" was null or undefined when calling deleteDesignatedPersonForProject().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/user/{idProject}/delete-designated-person`.replace(`{${"idProject"}}`, encodeURIComponent(String(requestParameters['idProject']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UserDtoToJSON(requestParameters['userDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => SearchUserResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Cancellazione delegato del PL per un dato progetto
     * Cancellazione delegato
     */
    deleteDesignatedPersonForProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteDesignatedPersonForProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Cancellazione delegato dell\'utente loggato
     * Cancellazione delegato utente
     */
    deleteDesignatedPersonForUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['userDto'] == null) {
                throw new runtime.RequiredError('userDto', 'Required parameter "userDto" was null or undefined when calling deleteDesignatedPersonForUser().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/user/delete-designated-person-loggedUser`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UserDtoToJSON(requestParameters['userDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => SearchUserResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Cancellazione delegato dell\'utente loggato
     * Cancellazione delegato utente
     */
    deleteDesignatedPersonForUser(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteDesignatedPersonForUserRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Elenco dei delegati del PL per un dato progetto
     * Elenco delegati
     */
    designatedPersonForProjectListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idProject'] == null) {
                throw new runtime.RequiredError('idProject', 'Required parameter "idProject" was null or undefined when calling designatedPersonForProjectList().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/user/{idProject}/designated-person-pl`.replace(`{${"idProject"}}`, encodeURIComponent(String(requestParameters['idProject']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => SearchUserResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Elenco dei delegati del PL per un dato progetto
     * Elenco delegati
     */
    designatedPersonForProjectList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.designatedPersonForProjectListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Elenco dei delegati dell\'utente loggato
     * Elenco delegati utente
     */
    designatedPersonForUserListRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/user/designated-person-loggedUser`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => SearchUserResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Elenco dei delegati dell\'utente loggato
     * Elenco delegati utente
     */
    designatedPersonForUserList(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.designatedPersonForUserListRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Inserimento delegato del PL per un dato progetto
     * Inserimento delegato
     */
    insertDesignatedPersonForProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['idProject'] == null) {
                throw new runtime.RequiredError('idProject', 'Required parameter "idProject" was null or undefined when calling insertDesignatedPersonForProject().');
            }
            if (requestParameters['userDto'] == null) {
                throw new runtime.RequiredError('userDto', 'Required parameter "userDto" was null or undefined when calling insertDesignatedPersonForProject().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/user/{idProject}/insert-designated-person`.replace(`{${"idProject"}}`, encodeURIComponent(String(requestParameters['idProject']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UserDtoToJSON(requestParameters['userDto']),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Inserimento delegato del PL per un dato progetto
     * Inserimento delegato
     */
    insertDesignatedPersonForProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.insertDesignatedPersonForProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Inserimento delegato dell\'utente loggato
     * Inserimento delegato utente
     */
    insertDesignatedPersonForUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['userDto'] == null) {
                throw new runtime.RequiredError('userDto', 'Required parameter "userDto" was null or undefined when calling insertDesignatedPersonForUser().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/user/insert-designated-person-loggedUser`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UserDtoToJSON(requestParameters['userDto']),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Inserimento delegato dell\'utente loggato
     * Inserimento delegato utente
     */
    insertDesignatedPersonForUser(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.insertDesignatedPersonForUserRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    searchUsersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['userSearchRequest'] == null) {
                throw new runtime.RequiredError('userSearchRequest', 'Required parameter "userSearchRequest" was null or undefined when calling searchUsers().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/user`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: UserSearchRequestToJSON(requestParameters['userSearchRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => SearchUserResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    searchUsers(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.searchUsersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    userInfoRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/user/user-info`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => LoggedUserDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    userInfo(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userInfoRaw(initOverrides);
            return yield response.value();
        });
    }
}
