import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../core/store/hooks';
import { useEffect, useState } from 'react';
import { getUsers } from '../../../project-list/project-detail/_redux/actions';
import {
  SearchUsersRequest,
  StrutturaFunzioneOutputDto,
  StrutturaOutputDto,
  StrutturaRuoloOutputDto,
  StrutturaUtenteFunzioneRuoloInputDto,
} from '@api/client';
import { allUsersSelector } from '../../../project-list/project-detail/_redux/selectors';
import {
  crudCreateUserStructureFuncionRole,
  crudGetStructureFunctions,
  crudGetStructureRoles,
  crudGetStructures,
} from '../../_redux/actions';
import AppCard from '../../../../../shared/design-system/components/app-card';
import { Formik } from 'formik';
import { Select } from 'antd';
import { filterOption } from '../../../../../shared/utils/common.utils';
import LinksButton from '../../../../../shared/design-system/components/links-button';
import { Col, Row } from 'react-bootstrap';

interface Props {
  structure?: StrutturaUtenteFunzioneRuoloInputDto | undefined;
}

const StructureCrudForm: React.FC<Props> = ({ structure }) => {
  const dispatch = useAppDispatch();
  const allUsers = useAppSelector(allUsersSelector);
  const { t } = useTranslation();
  const [roles, setRoles] = useState<StrutturaRuoloOutputDto[] | undefined>(undefined);
  const [parents, setParents] = useState<StrutturaFunzioneOutputDto[] | undefined>(undefined);
  const [structures, setStructures] = useState<StrutturaOutputDto[] | undefined>(undefined);

  useEffect(() => {
    if (!allUsers) {
      const request: SearchUsersRequest = {
        userSearchRequest: {},
      };
      dispatch(getUsers(request));
    }
    dispatch(crudGetStructureRoles())
      .unwrap()
      .then((res) => {
        setRoles(res);
      });
    dispatch(crudGetStructureFunctions())
      .unwrap()
      .then((res) => {
        setParents(res);
      });
    dispatch(crudGetStructures())
      .unwrap()
      .then((res) => {
        setStructures(res);
      });
  }, []);

  const handleSubmit = (values: any) => {
    const data: StrutturaUtenteFunzioneRuoloInputDto = {
      nome: values.name,
      idUtente: values.idUser,
      idRuolo: values.idRole,
      idParent: values.idParent,
      idStruttura: values.idStructure,
    };
    dispatch(crudCreateUserStructureFuncionRole(data));
  };

  useEffect(() => {
    if (structure) {
      console.log(structure);
    }
  }, [structure]);

  return (
    <AppCard title={structure ? 'Update Structure' : t('crud.structure-crud.title-card')} className="mt-3">
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          name: structure?.nome || undefined,
          idUser: structure?.idUtente || undefined,
          idRole: structure?.idRuolo || undefined,
          idParent: structure?.idParent || undefined,
          idStructure: structure?.idStruttura || undefined,
        }}
        enableReinitialize
      >
        {({ handleSubmit: _handleSubmit, handleChange, setFieldValue, values, touched, errors }) => {
          const handleChangeCustom = (input: string) => (e: any) => {
            setFieldValue(input, e);
          };
          return (
            <form onSubmit={_handleSubmit}>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <div className="form-group">
                    <label htmlFor="name">{t('crud.input-label.name-label')}</label>
                    <input
                      required
                      placeholder={t('crud.input-placeholder.name-placeholder')}
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                <Col lg={3} md={6} sm={6} xs={12}>
                  <div className="form-group">
                    <label htmlFor="idUser">{t('crud.input-label.user-label')}</label>
                    <Select
                      showSearch
                      className="w-100"
                      size="large"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      placeholder={t('crud.input-placeholder.user-placeholder')}
                      onChange={handleChangeCustom('idUser')}
                      value={values.idUser}
                    >
                      {allUsers?.map((el) => (
                        <Select.Option key={`user${el.id}`} value={el.id}>
                          {el.nominativo}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={6} xs={12}>
                  <div className="form-group">
                    <label htmlFor="idRole">{t('crud.input-label.role-label')}</label>
                    <Select
                      showSearch
                      className="w-100"
                      size="large"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      placeholder={t('crud.input-placeholder.role-placeholder')}
                      onChange={handleChangeCustom('idRole')}
                      value={values.idRole}
                    >
                      {roles?.map((el) => (
                        <Select.Option key={`role${el.id}`} value={el.id}>
                          {el.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={6} xs={12}>
                  <div className="form-group">
                    <label htmlFor="idParent">{t('crud.input-label.parent-label')}</label>
                    <Select
                      showSearch
                      className="w-100"
                      size="large"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      placeholder={t('crud.input-placeholder.parent-placeholder')}
                      onChange={handleChangeCustom('idParent')}
                      value={values.idParent}
                    >
                      {parents?.map((el) => (
                        <Select.Option key={`parent${el.id}`} value={el.id}>
                          {el.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={6} xs={12}>
                  <div className="form-group">
                    <label htmlFor="idStructure">{t('crud.input-label.structure-label')}</label>
                    <Select
                      showSearch
                      className="w-100"
                      size="large"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      placeholder={t('crud.input-placeholder.structure-placeholder')}
                      onChange={handleChangeCustom('idStructure')}
                      value={values.idStructure}
                    >
                      {structures?.map((el) => (
                        <Select.Option key={`role${el.id}`} value={el.id}>
                          {el.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              </Row>

              <div className="d-flex justify-content-end mt-3">
                <LinksButton
                  type="submit"
                  className="btn-primary btn-links"
                  isDisabled={!values.name || !values.idUser || !values.idRole || !values.idStructure}
                >
                  {structure ? 'Update' : 'Create'}
                </LinksButton>
              </div>
            </form>
          );
        }}
      </Formik>
    </AppCard>
  );
};

export default StructureCrudForm;
