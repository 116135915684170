import { CompetenzeProgettoDto } from '@api/client';
import { Col, Row } from 'react-bootstrap';
import { formatCurrency } from '../../../../../../shared/utils/common.utils';
import { TableProjectKeys } from '../../../../../../enums/project.enum';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../core/store/hooks';
import { competenzeAnnoCorrenteSelector, competenzeComplessiveSelector } from '../../_redux/selectors';
import { getCompetenzeComplessive, getCompetenzeAnnoCorrente } from '../../_redux/actions';
import NoData from '../../../../../../shared/design-system/components/no-data-box';

interface Props {
  projectId: number | undefined;
  keyData: TableProjectKeys.OVERALL_DATA | TableProjectKeys.CURRENT_YEAR_DATA;
}

const ProjectDetails: React.FC<Props> = ({ projectId, keyData }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const competenceSelector = useAppSelector(
    keyData === TableProjectKeys.OVERALL_DATA ? competenzeComplessiveSelector : competenzeAnnoCorrenteSelector
  );
  const [competences, setCompetences] = useState<CompetenzeProgettoDto | undefined | null>(competenceSelector);

  useEffect(() => {
    if (!competences && projectId) {
      const action =
        keyData === TableProjectKeys.OVERALL_DATA
          ? getCompetenzeComplessive(projectId)
          : getCompetenzeAnnoCorrente(projectId);
      dispatch(action);
    }
  }, [competences, dispatch, keyData, projectId]);

  useEffect(() => {
    if (competenceSelector) {
      setCompetences(competenceSelector);
    }
  }, [competenceSelector]);

  return (
    <>
      {competences ? (
        <Row>
          {keyData === TableProjectKeys.OVERALL_DATA && (
            <>
              <Col xs={2} className="vertical-divider">
                <div className="d-flex justify-content-between mb-3">
                  <span className="section-title text-teal-links my-auto me-3">
                    {t('project-detail.offer-revenues')}
                  </span>
                </div>
                <div className="span-values">
                  <div className="d-flex align-items-baseline">
                    <div className="biggest-value text-teal-links">
                      {formatCurrency(competences?.ricaviTotali ?? 0)}
                    </div>
                    <span className="text-xs">{`€ (${competences.sal!.toFixed(2) ?? 0}% sal)`}</span>
                  </div>
                  <div className="head">{t('project-detail.expected-revenues')}</div>
                </div>
                <div className="span-values">
                  <div className="d-flex align-items-baseline">
                    <div className="mid-value text-gray-links">
                      {formatCurrency(competences.ricaviConsolidati) ?? '-'}
                    </div>
                    <span className="text-sm">€</span>
                  </div>
                  <div className="head">{t('project-detail.consolidated')}</div>
                </div>
                <div className="span-values">
                  <div className="d-flex align-items-baseline">
                    <div className="mid-value text-gray-links">
                      {formatCurrency(competences?.ricaviResiduiPianificati) ?? '-'}
                    </div>
                    <span className="text-sm">€</span>
                  </div>
                  <div className="head">{t('project-detail.estimated')}</div>
                </div>
                <div className="span-values">
                  <div className="d-flex align-items-baseline">
                    <div className="mid-value text-gray-links">{formatCurrency(competences?.lost) ?? '-'}</div>
                    <span className="text-sm">€</span>
                  </div>
                  <div className="head">{t('project-detail.lost')}</div>
                </div>
                <div className="span-values">
                  <div className="d-flex align-items-baseline">
                    <div className="mid-value text-gray-links">{formatCurrency(competences?.noPlan) ?? '-'}</div>
                    <span className="text-sm">€</span>
                  </div>
                  <div className="head">{t('project-detail.no-plan')}</div>
                </div>
              </Col>
              <Col xs={2} className="vertical-divider">
                <div className="d-flex justify-content-between mb-3">
                  <span className="section-title text-blue-links my-auto me-3">{t('project-detail.offer-costs')}</span>
                </div>
                <div className="span-values">
                  <div className="d-flex align-items-baseline">
                    <div className="biggest-value text-gray-links">
                      {formatCurrency(competences?.costoTotaleOfferta) ?? '-'}
                    </div>
                    <span className="text-sm">€</span>
                  </div>
                  <div className="head">{t('project-detail.expected-costs')}</div>
                </div>
                <div className="span-values">
                  <div className="d-flex align-items-baseline">
                    <div className="mid-value text-gray-links">
                      {formatCurrency(competences?.costoHrOfferta) ?? '-'}
                    </div>
                    <span className="text-sm">€</span>
                  </div>
                  <div className="head">{t('project-detail.hr')}</div>
                </div>
                <div className="span-values">
                  <div className="d-flex align-items-baseline">
                    <div className="mid-value text-gray-links">
                      {formatCurrency(competences?.costoEsternoOfferta) ?? '-'}
                    </div>
                    <span className="text-sm">€</span>
                  </div>
                  <div className="head">{t('project-detail.otm')}</div>
                </div>
              </Col>
            </>
          )}

          {keyData === TableProjectKeys.CURRENT_YEAR_DATA && (
            <Col xs={2} className="vertical-divider">
              <div className="d-flex justify-content-between mb-3">
                <span className="section-title text-teal-links my-auto me-3">
                  {t('project-detail.planned-revenues')}
                </span>
              </div>
              <div className="span-values">
                <div className="d-flex align-items-baseline">
                  <div className="biggest-value text-teal-links">
                    {formatCurrency(
                      (competences?.ricaviConsolidati ?? 0) + (competences?.ricaviResiduiPianificati ?? 0)
                    ) ?? '-'}
                  </div>
                  <span className="text-sm">€</span>
                </div>
                <div className="head">{t('project-detail.revenues')}</div>
              </div>
              <div className="span-values">
                <div className="d-flex align-items-baseline">
                  <div className="mid-value text-gray-links">
                    {formatCurrency(competences?.ricaviConsolidati) ?? '-'}
                  </div>
                  <span className="text-sm">€</span>
                </div>
                <div className="head">{t('project-detail.consolidated')}</div>
              </div>
              <div className="span-values">
                <div className="d-flex align-items-baseline">
                  <div className="mid-value text-gray-links">
                    {formatCurrency(competences?.ricaviResiduiPianificati) ?? '-'}
                  </div>
                  <span className="text-sm">€</span>
                </div>
                <div className="head">{t('project-detail.estimated')}</div>
              </div>
            </Col>
          )}

          <Col xs={4} className="vertical-divider">
            <div className="d-flex justify-content-between mb-3">
              <span className="section-title text-blue-links my-auto me-3">{t('project-detail.planned-costs')}</span>
            </div>
            <div className="span-values">
              <div className="d-flex align-items-baseline">
                <div className="biggest-value text-gray-links">
                  {formatCurrency(competences?.costoTotalePianificato) ?? '-'}
                </div>
                <span className="text-sm">€</span>
              </div>
              <div className="head">{t('project-detail.planned-costs')}</div>
            </div>
            <Row>
              <Col xs={6}>
                <div className="span-values">
                  <div className="d-flex align-items-baseline">
                    <div className="mid-value text-gray-links">
                      {formatCurrency(competences.costoHrConsolidato) ?? '-'}
                    </div>
                    <span className="text-sm">€</span>
                  </div>
                  <div className="head">{t('project-detail.consolidated-hr')}</div>
                </div>
              </Col>
              <Col xs={6}>
                <div className="span-values">
                  <div className="d-flex align-items-baseline">
                    <div className="mid-value text-gray-links">
                      {formatCurrency(competences?.costoHrPianificatoResiduo) ?? '-'}
                    </div>
                    <span className="text-sm">€</span>
                  </div>
                  <div className="head">{t('project-detail.estimated-hr')}</div>
                </div>
              </Col>
              <Col xs={6}>
                <div className="span-values">
                  <div className="d-flex align-items-baseline">
                    <div className="mid-value text-gray-links">
                      {formatCurrency(competences.costoEsternoConsolidato) ?? '-'}
                    </div>
                    <span className="text-sm">€</span>
                  </div>
                  <div className="head">{t('project-detail.consolidated-otm')}</div>
                </div>
              </Col>
              <Col xs={6}>
                <div className="span-values">
                  <div className="d-flex align-items-baseline">
                    <div className="mid-value text-gray-links">
                      {formatCurrency(competences?.costoEsternoPianificatoResiduo) ?? '-'}
                    </div>
                    <span className="text-sm">€</span>
                  </div>
                  <div className="head">{t('project-detail.estimated-otm')}</div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={2} className="vertical-divider">
            <div className="d-flex justify-content-between mb-3">
              <span className="section-title text-blue-links my-auto me-3">{t('project-detail.gu-days')}</span>
            </div>
            <div className="span-values">
              {keyData === TableProjectKeys.OVERALL_DATA && (
                <>
                  <div className="d-flex align-items-baseline">
                    <div className="biggest-value text-gray-links">
                      {formatCurrency(competences?.totaleGiorniUomoPianificato, 0) ?? '-'}
                    </div>
                    <span className="text-sm">
                      / {formatCurrency(competences.totaleGiorniUomoOfferta, 0) ?? '-'} {t('project-detail.ggu')}
                    </span>
                  </div>
                  <div className="head">{t('project-detail.planned-offer')}</div>
                </>
              )}
              {keyData === TableProjectKeys.CURRENT_YEAR_DATA && (
                <>
                  <div className="d-flex align-items-baseline">
                    <div className="biggest-value text-gray-links">
                      {formatCurrency(competences.totaleGiorniUomoPianificato, 0) ?? '-'}
                    </div>
                    <span className="text-sm">{t('project-detail.ggu')}</span>
                  </div>
                  <div className="head">{t('project-detail.planned')}</div>
                </>
              )}
            </div>
            <div className="span-values">
              <div className="d-flex align-items-baseline">
                <div className="mid-value text-gray-links">
                  {' '}
                  {formatCurrency(competences.totaleGiorniUomoConsolidato, 0) ?? '-'}
                </div>
                <span className="text-sm">{t('project-detail.ggu')}</span>
              </div>
              <div className="head">{t('project-detail.consolidated-gu')}</div>
            </div>
            <div className="span-values">
              <div className="d-flex align-items-baseline">
                <div className="mid-value text-gray-links">
                  {' '}
                  {formatCurrency(competences.totaleGiorniUomoPianificatoResiduo, 0) ?? '-'}
                </div>
                <span className="text-sm">{t('project-detail.ggu')}</span>
              </div>
              <div className="head">{t('project-detail.estimated-gu')}</div>
            </div>
          </Col>

          {keyData === TableProjectKeys.OVERALL_DATA && (
            <Col xs={2}>
              <div className="d-flex justify-content-between mb-3">
                <span className="section-title text-blue-links my-auto me-3">{t('project-detail.margins')}</span>
              </div>
              <div className="span-values">
                <div className="d-flex align-items-baseline">
                  <div className="biggest-value text-gray-links">
                    {formatCurrency(competences?.marginePianificato, 2) ?? '-'}
                  </div>
                  <span className="text-sm">/ {formatCurrency(competences?.margineOfferta, 2) ?? '-'} €</span>
                </div>
                <div className="head">{t('project-detail.margin-planned-expected')}</div>
              </div>
              <div className="span-values">
                <div className="d-flex align-items-baseline">
                  <div className="biggest-value text-gray-links">
                    {formatCurrency(competences?.marginePianificatoPerc, 2) ?? '-'}
                  </div>
                  <span className="text-sm">/ {formatCurrency(competences?.margineOffertaPerc, 2) ?? '-'} %</span>
                </div>
                <div className="head">{t('project-detail.margin-planned-expected-%')}</div>
              </div>
              <div className="span-values">
                <div className="d-flex align-items-baseline">
                  <div className="biggest-value text-gray-links">
                    {formatCurrency(competences?.margineActual, 2) ?? '-'}
                  </div>
                  <span className="text-xs">{`€ (${competences.margineActualPerc!.toFixed(2) ?? 0}%)`}</span>
                </div>
                <div className="head">{t('project-detail.actual-margin-€-%')}</div>
              </div>
            </Col>
          )}

          {keyData === TableProjectKeys.CURRENT_YEAR_DATA && (
            <Col xs={2} className="vertical-divider">
              <div className="d-flex justify-content-between mb-3">
                <span className="section-title text-blue-links my-auto me-3">{t('project-detail.margins')}</span>
              </div>
              <div className="span-values">
                <div className="d-flex align-items-baseline">
                  <div className="biggest-value text-gray-links">
                    {formatCurrency(competences?.marginePianificato, 2) ?? '-'}
                  </div>
                  <span className="text-sm">€</span>
                </div>
                <div className="head">{t('project-detail.margin-planned')}</div>
              </div>
              <div className="span-values">
                <div className="d-flex align-items-baseline">
                  <div className="biggest-value text-gray-links">
                    {formatCurrency(competences?.marginePianificatoPerc, 2) ?? '-'}
                  </div>
                  <span className="text-sm">%</span>
                </div>
                <div className="head">{t('project-detail.margin-planned-%')}</div>
              </div>
              <div className="span-values">
                <div className="d-flex align-items-baseline">
                  <div className="biggest-value text-gray-links">
                    {formatCurrency(competences?.margineActual, 2) ?? '-'}
                  </div>
                  <span className="text-xs">{`€ (${competences.margineActualPerc!.toFixed(2) ?? 0}%)`}</span>
                </div>
                <div className="head">{t('project-detail.actual-margin-€-%')}</div>
              </div>
            </Col>
          )}

          {keyData === TableProjectKeys.CURRENT_YEAR_DATA && (
            <Col xs={2}>
              <div className="d-flex justify-content-between mb-3">
                <span className="section-title text-blue-links my-auto me-3">{t('project-detail.rate')}</span>
              </div>
              <div className="span-values">
                <div className="d-flex align-items-baseline">
                  <div className="biggest-value text-gray-links">
                    {formatCurrency(competences?.ratePianificato, 2) ?? '-'}
                  </div>
                  <span className="text-sm">€</span>
                </div>
                <div className="head">{t('project-detail.rate-planned')}</div>
              </div>
              <div className="span-values">
                <div className="d-flex align-items-baseline">
                  <div className="biggest-value text-gray-links">
                    {formatCurrency(competences?.rateConsolidato, 2) ?? '-'}
                  </div>
                  <span className="text-sm">€</span>
                </div>
                <div className="head">{t('project-detail.consolidated')}</div>
              </div>
              <div className="span-values">
                <div className="d-flex align-items-baseline">
                  <div className="biggest-value text-gray-links">
                    {formatCurrency(competences?.rateOfferta, 2) ?? '-'}
                  </div>
                  <span className="text-sm">€</span>
                </div>
                <div className="head">{t('project-detail.estimated')}</div>
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <div className="d-flex justify-content-center">
          <NoData msg={t('project-detail.no-data')} />
        </div>
      )}
    </>
  );
};

export default ProjectDetails;
