/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AllocationInputDtoFromJSON, AllocationInputDtoToJSON, } from './AllocationInputDto';
/**
 * Check if a given object implements the StaffInputDto interface.
 */
export function instanceOfStaffInputDto(value) {
    return true;
}
export function StaffInputDtoFromJSON(json) {
    return StaffInputDtoFromJSONTyped(json, false);
}
export function StaffInputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idPps': json['idPps'] == null ? undefined : json['idPps'],
        'idPpt': json['idPpt'] == null ? undefined : json['idPpt'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'allocazioni': json['allocazioni'] == null ? undefined : (json['allocazioni'].map(AllocationInputDtoFromJSON)),
    };
}
export function StaffInputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idPps': value['idPps'],
        'idPpt': value['idPpt'],
        'userId': value['userId'],
        'allocazioni': value['allocazioni'] == null ? undefined : (value['allocazioni'].map(AllocationInputDtoToJSON)),
    };
}
