/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApplicationControlDtoFromJSON, LongTermActivityDtoFromJSON, } from '../models/index';
/**
 *
 */
export class WFMControllerApi extends runtime.BaseAPI {
    /**
     */
    activityInfoRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/wfm/activity-info`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => LongTermActivityDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    activityInfo(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.activityInfoRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera i costi di trasferte e straordinari dalla intranet per tutti i progetti, per anno e intervallo mesi indicati
     * Recupera altri costi reali da intranet
     */
    aggiornaAltriCostiDaRendicontazioniMesiRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['anno'] == null) {
                throw new runtime.RequiredError('anno', 'Required parameter "anno" was null or undefined when calling aggiornaAltriCostiDaRendicontazioniMesi().');
            }
            if (requestParameters['meseI'] == null) {
                throw new runtime.RequiredError('meseI', 'Required parameter "meseI" was null or undefined when calling aggiornaAltriCostiDaRendicontazioniMesi().');
            }
            if (requestParameters['meseF'] == null) {
                throw new runtime.RequiredError('meseF', 'Required parameter "meseF" was null or undefined when calling aggiornaAltriCostiDaRendicontazioniMesi().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/wfm/aggiorna-other-costs/anno/{anno}/meseInizio/{meseI}/meseFine/{meseF}`.replace(`{${"anno"}}`, encodeURIComponent(String(requestParameters['anno']))).replace(`{${"meseI"}}`, encodeURIComponent(String(requestParameters['meseI']))).replace(`{${"meseF"}}`, encodeURIComponent(String(requestParameters['meseF']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Recupera i costi di trasferte e straordinari dalla intranet per tutti i progetti, per anno e intervallo mesi indicati
     * Recupera altri costi reali da intranet
     */
    aggiornaAltriCostiDaRendicontazioniMesi(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.aggiornaAltriCostiDaRendicontazioniMesiRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera i costi del personale dalla intranet per tutti i progetti, per anno e intervallo mesi indicati
     * Recupera costi HR reali da intranet
     */
    aggiornaCostiHRDaRendicontazioniMesiRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['anno'] == null) {
                throw new runtime.RequiredError('anno', 'Required parameter "anno" was null or undefined when calling aggiornaCostiHRDaRendicontazioniMesi().');
            }
            if (requestParameters['meseI'] == null) {
                throw new runtime.RequiredError('meseI', 'Required parameter "meseI" was null or undefined when calling aggiornaCostiHRDaRendicontazioniMesi().');
            }
            if (requestParameters['meseF'] == null) {
                throw new runtime.RequiredError('meseF', 'Required parameter "meseF" was null or undefined when calling aggiornaCostiHRDaRendicontazioniMesi().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/wfm/aggiorna-hr-costs/anno/{anno}/meseInizio/{meseI}/meseFine/{meseF}`.replace(`{${"anno"}}`, encodeURIComponent(String(requestParameters['anno']))).replace(`{${"meseI"}}`, encodeURIComponent(String(requestParameters['meseI']))).replace(`{${"meseF"}}`, encodeURIComponent(String(requestParameters['meseF']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Recupera i costi del personale dalla intranet per tutti i progetti, per anno e intervallo mesi indicati
     * Recupera costi HR reali da intranet
     */
    aggiornaCostiHRDaRendicontazioniMesi(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.aggiornaCostiHRDaRendicontazioniMesiRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera i costi dalla intranet per tutti i progetti, per anno e intervallo mesi indicati
     * Recupera costi reali da intranet
     */
    aggiornaCostiRendicontazioniMesiRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['anno'] == null) {
                throw new runtime.RequiredError('anno', 'Required parameter "anno" was null or undefined when calling aggiornaCostiRendicontazioniMesi().');
            }
            if (requestParameters['meseI'] == null) {
                throw new runtime.RequiredError('meseI', 'Required parameter "meseI" was null or undefined when calling aggiornaCostiRendicontazioniMesi().');
            }
            if (requestParameters['meseF'] == null) {
                throw new runtime.RequiredError('meseF', 'Required parameter "meseF" was null or undefined when calling aggiornaCostiRendicontazioniMesi().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/wfm/aggiorna-costi/anno/{anno}/meseInizio/{meseI}/meseFine/{meseF}`.replace(`{${"anno"}}`, encodeURIComponent(String(requestParameters['anno']))).replace(`{${"meseI"}}`, encodeURIComponent(String(requestParameters['meseI']))).replace(`{${"meseF"}}`, encodeURIComponent(String(requestParameters['meseF']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Recupera i costi dalla intranet per tutti i progetti, per anno e intervallo mesi indicati
     * Recupera costi reali da intranet
     */
    aggiornaCostiRendicontazioniMesi(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.aggiornaCostiRendicontazioniMesiRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Tutti i progetti in stato di Controllo vengono riportati in  WIP. La richiesta avviene in modo asincrono
     * Riporta i progetti WIP
     */
    chiudiProcessoControlloCostiRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/wfm/disattiva-stato-cdg`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Tutti i progetti in stato di Controllo vengono riportati in  WIP. La richiesta avviene in modo asincrono
     * Riporta i progetti WIP
     */
    chiudiProcessoControlloCosti(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.chiudiProcessoControlloCostiRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Consolida i costi di staff e costi esterni di tutti i progetti per anno e mese indicati
     * Recupera costi reali da intranet
     */
    consolidaCostiRendicontazioniRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['anno'] == null) {
                throw new runtime.RequiredError('anno', 'Required parameter "anno" was null or undefined when calling consolidaCostiRendicontazioni().');
            }
            if (requestParameters['mese'] == null) {
                throw new runtime.RequiredError('mese', 'Required parameter "mese" was null or undefined when calling consolidaCostiRendicontazioni().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/wfm/consolida-costi/anno/{anno}/mese/{mese}`.replace(`{${"anno"}}`, encodeURIComponent(String(requestParameters['anno']))).replace(`{${"mese"}}`, encodeURIComponent(String(requestParameters['mese']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Consolida i costi di staff e costi esterni di tutti i progetti per anno e mese indicati
     * Recupera costi reali da intranet
     */
    consolidaCostiRendicontazioni(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.consolidaCostiRendicontazioniRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Divide la rendicontazione del delivery, sui business
     * Spalma costi coordinamento
     */
    spalmaCostiCoordinamentoRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['year'] == null) {
                throw new runtime.RequiredError('year', 'Required parameter "year" was null or undefined when calling spalmaCostiCoordinamento().');
            }
            if (requestParameters['month'] == null) {
                throw new runtime.RequiredError('month', 'Required parameter "month" was null or undefined when calling spalmaCostiCoordinamento().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/wfm/spalma-coordinamento/{year}/{month}`.replace(`{${"year"}}`, encodeURIComponent(String(requestParameters['year']))).replace(`{${"month"}}`, encodeURIComponent(String(requestParameters['month']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Divide la rendicontazione del delivery, sui business
     * Spalma costi coordinamento
     */
    spalmaCostiCoordinamento(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.spalmaCostiCoordinamentoRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * I progetti in qualsiasi stato di lavorazione in corso, passano in Controllo di Gestione.
     * Attiva lo stato CDG
     */
    statoControlloCDGAttivoRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/wfm/attiva-stato-cdg`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * I progetti in qualsiasi stato di lavorazione in corso, passano in Controllo di Gestione.
     * Attiva lo stato CDG
     */
    statoControlloCDGAttivo(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.statoControlloCDGAttivoRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * I progetti in stato WIP, WIP Draft o Controllo Responsabile passano in Controllo CL.
     * Attiva lo stato Controllo CL
     */
    statoControlloCLAttivoRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/wfm/attiva-stato-cl`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * I progetti in stato WIP, WIP Draft o Controllo Responsabile passano in Controllo CL.
     * Attiva lo stato Controllo CL
     */
    statoControlloCLAttivo(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.statoControlloCLAttivoRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    workflowInfoRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/wfm/workflow-info`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationControlDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    workflowInfo(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.workflowInfoRaw(initOverrides);
            return yield response.value();
        });
    }
}
