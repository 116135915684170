import { BillingDto } from '@api/client';
import dayjs from 'dayjs';
import { ChangeEvent, useState } from 'react';

const useEditBill = (
  setBillingPlan: React.Dispatch<React.SetStateAction<BillingDto[] | undefined>>,
  setIsBillingDirty: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    setIsBillingDirty(true);
    setBillingPlan((prev) => {
      return prev?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            statoAvanzamentoLavori: {
              ...item.statoAvanzamentoLavori,
              isInviato: e.target.checked,
            },
          };
        }
        return item;
      });
    });
  };

  const handleChangeDate = (date: dayjs.Dayjs, id: string) => {
    setIsBillingDirty(true);
    setBillingPlan((prev) => {
      return prev?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            statoAvanzamentoLavori: {
              ...item.statoAvanzamentoLavori,
              dataDiInvio: date?.toDate() || null,
            },
          };
        }
        return item;
      });
    });
  };

  const handleSelectChange = (e: string, id: string) => {
    setIsBillingDirty(true);
    setBillingPlan((prev) => {
      return prev?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            statoAvanzamentoLavori: {
              ...item.statoAvanzamentoLavori,
              idTipologiaBlocco: {
                ...item.statoAvanzamentoLavori?.idTipologiaBlocco,
                value: e,
              },
            },
          };
        }
        return item;
      });
    });
  };

  const handleChangeImporto = (e: any, id: string) => {
    setIsBillingDirty(true);
    setBillingPlan((prev) => {
      return prev?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            statoAvanzamentoLavori: {
              ...item.statoAvanzamentoLavori,
              importo: e,
            },
          };
        }
        return item;
      });
    });
  };

  return {
    handleChangeCheckbox,
    handleChangeDate,
    handleSelectChange,
    handleChangeImporto,
  };
};

export default useEditBill;
