/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const StatoAvanzamentoFieldsDtoTipologiaBloccoEnumListEnum = {
    SalDelDelivery: 'SAL_DEL_DELIVERY',
    InApprovazioneDelCliente: 'IN_APPROVAZIONE_DEL_CLIENTE',
    InRedazioneDelPartner: 'IN_REDAZIONE_DEL_PARTNER',
    LavorazioniInCorso: 'LAVORAZIONI_IN_CORSO',
    ChiusoFinanziariamente: 'CHIUSO_FINANZIARIAMENTE',
    VerificaCicloAttivo: 'VERIFICA_CICLO_ATTIVO'
};
/**
 * Check if a given object implements the StatoAvanzamentoFieldsDto interface.
 */
export function instanceOfStatoAvanzamentoFieldsDto(value) {
    return true;
}
export function StatoAvanzamentoFieldsDtoFromJSON(json) {
    return StatoAvanzamentoFieldsDtoFromJSONTyped(json, false);
}
export function StatoAvanzamentoFieldsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'tipologiaBloccoEnumList': json['tipologiaBloccoEnumList'] == null ? undefined : json['tipologiaBloccoEnumList'],
    };
}
export function StatoAvanzamentoFieldsDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'tipologiaBloccoEnumList': value['tipologiaBloccoEnumList'],
    };
}
